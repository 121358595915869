import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  makeSelectLocale,
  makeSelectEnvVariablesData,
  makeSelectApi,
} from 'containers/App/selectors';
import { API_ENPOINT_PREFIX } from 'containers/App/constants';
import { isServer, wpurl } from 'utils/detection';
import request from 'utils/request';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOAD_MARKETPLACE_PAGE_DATA } from './constants';
import {
  loadMarketPlacePageDataSuccess,
  loadMarketPlacePageDataFailure,
  loadPropertyDetails,
  loadPropertyDetailsSuccess,
  loadPropertyDetailsFailure,
} from './actions';

function* getPropertyDetails() {
  const locale = yield select(makeSelectLocale());
  yield put(loadPropertyDetails());
  try {
    const api = yield select(makeSelectApi());
    const someData = yield api.get(`api/properties?text=language%3A${locale}`);
    yield put(loadPropertyDetailsSuccess(someData));
  } catch (e) {
    yield put(loadPropertyDetailsFailure());
  }
}
function* getMarketplaceWpContent(API_URL) {
  const API_URL_MARKETPLACE_PAGE = `${API_URL}/getmarketplacecontent`;
  const locale = yield select(makeSelectLocale());
  const requestUrl = `${API_URL_MARKETPLACE_PAGE}/${locale}`;
  try {
    const someData = yield call(request, requestUrl);
    yield put(loadMarketPlacePageDataSuccess(someData));
  } catch (e) {
    yield put(loadMarketPlacePageDataFailure());
  }
}

export default function* marketplaceSaga() {
  const { variables } = yield select(makeSelectEnvVariablesData());
  const API_URL = `${wpurl(variables)}/${API_ENPOINT_PREFIX}`; // wordpressURL
  // if (isServer) {
  //   // yield call([LOAD_MARKETPLACE_PAGE_DATA, getMarketplaceWpContent], API_URL);
  // }
  yield call(getPropertyDetails);
  // yield call(getMarketplaceWpContent, API_URL);
  // yield takeLatest(
  //   [LOAD_MARKETPLACE_PAGE_DATA, LOCATION_CHANGE],
  //   getMarketplaceWpContent,
  //   API_URL,
  // );
}
