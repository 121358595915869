/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Nav } from 'react-bootstrap';

const StyledNavLink = styled(
  ({ navtab, tabwidth, responsivewidth, ...rest }) => <Nav.Link {...rest} />,
)`
  ${({ size, theme }) => css`
    position: relative;

    outline: none;
    padding-bottom: ${theme.spacing.xsmall};
    padding-left: ${theme.spacing.xxxsmall};
    padding-right: ${theme.spacing.xxxsmall};

    color: ${theme.colors.greys.dark};
    text-align: center;

    &::before {
      content: '';
      position: absolute;
      opacity: 0;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      top: 2px;
      bottom: 2px;
      left: 2px;
      right: 2px;

      box-shadow: 0 0 0 2px ${theme.colors.brand.xlight};
      background-color: transparent !important;
      transition: all ${theme.durations.fast};
    }

    &.active {
      border-bottom: 3px solid ${theme.colors.brand.medium};
      color: ${theme.colors.brand.medium} !important;
    }

    &:hover {
      color: ${theme.colors.brand.medium} !important;
    }

    &:focus {
      &::before {
        opacity: 1;
      }
      &:not(:focus-visible) {
        &::before {
          opacity: 0;
        }
      }
    }
  `}

  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      font-size: 14px;
      padding-bottom: ${theme.spacing.xxsmall};
    `}

  ${({ navtab, theme }) =>
    navtab &&
    css`
      padding-bottom: ${theme.spacing.small};
    `}

  ${({ tabwidth }) =>
    tabwidth &&
    css`
      width: ${tabwidth};
    `}
`;

const StyledNavItem = styled(
  ({ eventKey, navtab, tabwidth, responsivewidth, itemClassName, ...rest }) => (
    <Nav.Item {...rest} />
  ),
)`
  ${({ theme }) => css`
    padding: ${theme.spacing.none} ${theme.spacing.xsmall};
    transition: all ${theme.durations.fast} ease-in;
  `}
  ${({ responsivewidth, theme }) =>
    responsivewidth === 'true' &&
    css`
      flex: 1;
      padding: ${theme.spacing.none};
    `}
`;

const WillowTabItem = props => {
  const { itemProps, itemClassName, ...rest } = props;
  return (
    <StyledNavItem
      responsivewidth={props.responsivewidth}
      className={itemClassName}
    >
      <StyledNavLink {...rest} className={itemClassName}>
        {props.children}
      </StyledNavLink>
    </StyledNavItem>
  );
};

export default WillowTabItem;
