export const LOAD_TRADEVIEW_CONTENT =
  'boilerlplate/Trade/LOAD_TRADEVIEW_CONTENT';
export const LOAD_TRADEVIEW_CONTENT_SUCCESS =
  'boilerlplate/Trade/LOAD_TRADEVIEW_CONTENT_SUCCESS';
export const LOAD_TRADEVIEW_CONTENT_FAILURE =
  'boilerlplate/Trade/LOAD_TRADEVIEW_CONTENT_FAILURE';
export const LOAD_SINGLE_PROPERTY_CONTENT =
  'boilerlplate/Trade/LOAD_SINGLE_PROPERTY_CONTENT';
export const LOAD_SINGLE_PROPERTY_CONTENT_SUCCESS =
  'boilerlplate/Trade/LOAD_SINGLE_PROPERTY_CONTENT_SUCCESS';
export const LOAD_SINGLE_PROPERTY_CONTENT_FAILURE =
  'boilerlplate/Trade/LOAD_SINGLE_PROPERTY_CONTENT_FAILURE';

export const LOAD_KYC_WP_CONTENT = 'boilerlplate/Trade/LOAD_KYC_WP_CONTENT';
export const LOAD_KYC_WP_CONTENT_SUCCESS =
  'boilerlplate/Trade/LOAD_KYC_WP_CONTENT_SUCCESS';
export const LOAD_KYC_WP_CONTENT_FAILURE =
  'boilerlplate/Trade/LOAD_KYC_WP_CONTENT_FAILURE';

export const SUBMIT_WATCHLIST = 'boilerlplate/Trade/SUBMIT_WATCHLIST';
export const REMOVE_WATCHLIST = 'boilerlplate/Trade/REMOVE_WATCHLIST';
export const SUBMIT_FORM_DATA = 'boilerlplate/Trade/SUBMIT_FORM_DATA';
export const SUBMIT_REVIEW_ORDER = 'boilerlplate/Trade/SUBMIT_REVIEW_ORDER';
export const LOAD_REVIEW_DATA = 'boilerlplate/Trade/LOAD_REVIEW_DATA';
export const LOAD_REVIEW_DATA_SUCCESS =
  'boilerlplate/Trade/LOAD_REVIEW_DATA_SUCCESS';
export const LOAD_REVIEW_DATA_FAILURE =
  'boilerlplate/Trade/LOAD_REVIEW_DATA_FAILURE';
export const SET_REVIEW_DATA = 'boilerlplate/Trade/SET_REVIEW_DATA';
export const DOCUMENTS_API_URL =
  'https://api.mywillow.dev/api/documents/create/cradoc';
export const TOGGLE_STAGE = 'boilerlplate/Tradeview/TOGGLE_STAGE';
export const TOGGLE_TRADE_TAB = 'boilerlplate/Tradeview/TOGGLE_TRADE_TAB';
export const TOGGLE_MAIN_TAB = 'boilerlplate/Tradeview/TOGGLE_MAIN_TAB';
export const TOGGLE_CAPSULE = 'boilerlplate/Tradeview/TOGGLE_CAPSULE';
export const LOAD_REGISTRATION = 'boilerlplate/Tradeview/LOAD_REGISTRATION';

export const ORDERBOX_LOADER = 'boilerlplate/Tradeview/ORDERBOX_LOADER';

export const LOAD_ISSUES_DATA = 'boilerlplate/Tradeview/LOAD_ISSUES_DATA';
export const LOAD_ISSUE_DATA = 'boilerlplate/Tradeview/LOAD_ISSUE_DATA';
export const LOAD_USER_DOCUMENTS = 'boilerlplate/Tradeview/LOAD_USER_DOCUMENTS';
export const TOGGLE_DOWNLOAD = 'boilerlplate/Tradeview/TOGGLE_DOWNLOAD';
export const CLICKED_DOCUMENT = 'boilerlplate/Tradeview/CLICKED_DOCUMENT';



