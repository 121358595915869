import { AUTH_CHANGED } from 'containers/AuthProvider/constants';
import { eventChannel } from 'redux-saga';
import { put, select, take, takeLatest } from 'redux-saga/effects';
import { connect } from '../../../utils/events';
import { notify } from '../actions';
import { makeSelectApi } from '../selectors';
import { raiseServerEvent } from './actions';

function* handleConnect() {
  let emitter = null;
  const channel = yield eventChannel(current => {
    emitter = current;
    return () => {};
  });

  const api = yield select(makeSelectApi());
  // yield connect(api, event => {
  //   event.data = JSON.parse(event.data);
  //   emitter(event);
  // });

  while (true) {
    const event = yield take(channel);
    yield put(raiseServerEvent(event));
    // if (event.data && event.data.notify) {
    //   yield put(notify(event.name, JSON.stringify(event), 'info', true));
    // }
  }
}

export default function* eventsSaga() {
  yield takeLatest([AUTH_CHANGED], handleConnect);
}
