/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { CHANGE_LOCALE } from 'containers/LanguageProvider/constants';
import { getFirstBrowserLanguage, wpurl, isServer } from 'utils/detection';
import { SIGN_OUT_SUCCESS } from 'containers/AuthProvider/constants';
import { makeSelectAuth } from 'containers/AuthProvider/selectors';
import { SERVER_EVENT } from 'containers/App/Events/constants';
import {
  loadAllPostUrl,
  loadAllPostUrlFailure,
  loadAllPostUrlSuccess,
  // loadFooterContent,
  // loadFooterContentFailure,
  loadFooterContentSuccess,
  loadNavBarContent,
  // loadNavBarContentFailure,
  loadNavBarContentSuccess,
  lookupsLoadedSuccess,
  lookupsLoadedFailure,
  loadUserProperties,
  loadUserPropertiesSuccess,
  loadUserPropertiesFailure,
} from './actions';
import { API_ENPOINT_PREFIX } from './constants';
import {
  makeSelectEnvVariablesData,
  makeSelectLocale,
  makeSelectLocation,
  makeSelectApi,
} from './selectors';

import { makeSelectUsers } from './Users/selectors';
import { USER_LOADED } from './Users/constants';

function* getAllPostUrl(API_URL) {
  const API_URL_ALLPOSTS_URL = `${API_URL}/getallposturl`;

  yield put(loadAllPostUrl());
  const requestUrl = API_URL_ALLPOSTS_URL;
  try {
    const someData = yield call(request, requestUrl);
    yield put(loadAllPostUrlSuccess(someData));
  } catch (e) {
    yield put(loadAllPostUrlFailure());
  }
}

function* getNavBarContent() {
  const locale = yield select(makeSelectLocale());
  const { current, initialized } = yield select(makeSelectUsers());
  const { registrationStatus } = current;
  const auth = yield select(makeSelectAuth());
  yield put(loadNavBarContent());
  let requestUrl = "unauthenticated";
  if (current && current.isAuthenticated && auth.isAuthenticated && registrationStatus <= 1) {
    requestUrl = "authenticated";
  } else if (current && current.isAuthenticated && auth.isAuthenticated && registrationStatus > 1) {
    requestUrl = "kyc_complete_authenticated";
  }
 
  if (initialized && auth.initialized) {
    try {
      const data = yield import(`./navbar_${locale}.json`);
      const dataBasedOnPath = data.default.find((dt) => dt.type === requestUrl);
      yield put(loadNavBarContentSuccess(dataBasedOnPath));
    } catch (error) {
      console.error('Error loading JSON data:', error);
      // yield put(loadNavBarContentFailure(error));
    }
  }
}

function* getFooterContent() {
  const locale = yield select(makeSelectLocale());
  // yield put(loadFooterContent());
  try {
    const data = yield import(`./footer_${locale}.json`);
    const dataBasedOnPath = data.default;
    yield put(loadFooterContentSuccess(dataBasedOnPath));
  } catch (error) {
    console.error('Error loading JSON data:', error);
    // yield put(loadFooterContentFailure());
  }
}

function* changeLocaleOnUrlPop({ payload }) {
  if (payload.action === 'POP') {
    const currentLocation = window.location;
    const localeFromURL = currentLocation.pathname.split('/')[1];
    yield put(changeLocale(localeFromURL));
  }
}

function* onLocaleToggleUpdatePath({ locale }) {
  const currentLocation = yield select(makeSelectLocation());
  const pathArray = currentLocation.pathname.split('/');
  // eslint-disable-next-line no-unused-vars
  const removeFirstTwo = pathArray.splice(0, 2); // don't remove this
  const pathnameWithoutLocale = pathArray.join('/');
  yield put(push(`/${locale}/${pathnameWithoutLocale}`));
}

function* lookupsSaga() {
  const api = yield select(makeSelectApi());
  try {
    const sorting = {
      field: 'name.keyword',
      direction: 'ASC',
    };
    const url = encodeURIComponent(JSON.stringify(sorting));
    const countries = yield api.get(`api/countries?sorting=${url}`);
    yield put(lookupsLoadedSuccess(countries));
  } catch (error) {
    yield put(lookupsLoadedFailure(error));
  }
}

function* getTradeProperties() {
  yield put(loadUserProperties());
  const api = yield select(makeSelectApi());
  const locale = yield select(makeSelectLocale());
  try {
    const result = yield api.get(`api/properties?text=language:${locale}`);
    yield put(loadUserPropertiesSuccess(result));
  } catch (e) {
    yield put(loadUserPropertiesFailure());
  }
}
function* handleServerEvent({ name }) {
  if (name === 'Properties Changed') {
    yield call(getTradeProperties);
  } else if (name === 'Order Placed') {
    yield call(getTradeProperties);
  }
}

export function* AppSagas() {
  // const { variables } = yield select(makeSelectEnvVariablesData());
  // const WORDPRESS_API_URL = `${wpurl(variables)}/${API_ENPOINT_PREFIX}`; // wordpressURL
  // yield call(getAllPostUrl, WORDPRESS_API_URL); // this sets the article urls in the sitemap file
  // Set locale based on user's browser settings
  if (typeof window !== 'undefined') {
    const currentLocation = yield select(makeSelectLocation());
    const pathArray = currentLocation.pathname.split('/');
    pathArray.splice(0, 2);
    const pathnameWithoutLocale = pathArray.join('/');
    if (
      getFirstBrowserLanguage() === 'en' ||
      getFirstBrowserLanguage() === 'fr'
    ) {
      yield put(
        push(
          `/${getFirstBrowserLanguage()}/${pathnameWithoutLocale}${
            currentLocation.search
          }`,
        ),
      );
      yield put(changeLocale(getFirstBrowserLanguage()));
    }
  }
  yield takeLatest(LOCATION_CHANGE, changeLocaleOnUrlPop); // This updates the locale if browser back button is triggered
  yield takeLatest(CHANGE_LOCALE, onLocaleToggleUpdatePath); // This updates the path in url when locale toggle is hit
  // /* GET Navbar and Footer Content */
  yield takeLatest(
    [LOCATION_CHANGE, USER_LOADED, SIGN_OUT_SUCCESS, CHANGE_LOCALE],
    getNavBarContent
  );
  yield takeLatest(
    [USER_LOADED, SIGN_OUT_SUCCESS, CHANGE_LOCALE],
    getFooterContent,
  );
  
  if (!isServer) {
    yield call(getFooterContent);
    yield call(getNavBarContent);
  }
  yield call(lookupsSaga);
  // yield call(getTradeProperties);
  // yield takeLatest(CHANGE_LOCALE, getTradeProperties);
  yield takeLatest(SERVER_EVENT, handleServerEvent);
}
export default AppSagas;
