export const quantcast_conversion = name => {
  typeof window !== 'undefined' && window._qevents ? window._qevents : [];

  var elem = document.createElement('script');
  elem.src =
    (document.location.protocol == 'https:'
      ? 'https://secure'
      : 'http://edge') + '.quantserve.com/quant.js';
  elem.async = true;
  elem.type = 'text/javascript';
  var scpt = document.getElementsByTagName('script')[0];
  scpt.parentNode.insertBefore(elem, scpt);

  typeof window !== 'undefined' &&
    window._qevents.push({
      qacct: 'p-zUg_238sHRMpf',
      uid: '__INSERT_EMAIL_HERE__',
      labels: `_fp.event.${name},_fp.pcat.INSERT+PRODUCT+CATEGORY,_fp.customer.INSERT+CUSTOMER+TYPE`,
      orderid: 'INSERT+ORDER+ID',
      revenue: 'INSERT+REVENUE',
      event: 'refresh',
    });
};
