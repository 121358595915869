/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React from 'react';
import { Col } from 'react-bootstrap';
import ListGroup from 'components/List';
import { uid } from '../../../utils/ui';

const group = (rawdata, twoCol, className, fluid) => (
  <ListGroup twoCol={twoCol} className={className} fluid={fluid}>
    <ListGroup.Main>
      {rawdata &&
        rawdata.map(row => (
          <ListGroup.Row
            hr={row.hr}
            key={`row${row.id}`}
            condensed={row.condensed}
            className={row.class}
            nonbordered={row.nonbordered}
          >
            {row.columns &&
              row.columns.map(col => (
                <Col
                  key={`col${col.id || uid()}`}
                  {...col.widths}
                  className={col.classes}
                >
                  {col.content}
                  {col.rows && group(col.rows, twoCol, className, fluid)}
                </Col>
              ))}
          </ListGroup.Row>
        ))}
    </ListGroup.Main>
  </ListGroup>
);
const ListLayout = ({ rawdata, twoCol, className, fluid }) =>
  group(rawdata, twoCol, className, fluid);

export default ListLayout;
