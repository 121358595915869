import { createSelector } from 'reselect';
import firebase from 'firebase/app';
import 'firebase/auth'; // for authentication
import { initialState } from './reducer';

const selectAuth = state => state.auth || initialState;

const makeSelectAuth = () =>
  createSelector(selectAuth, substate => {
    const current = firebase.auth().currentUser;
    return {
      ...substate.data,
      ...current,
      mfaEnabled: current && current.multiFactor.enrolledFactors.length > 0,
      isAuthenticated: !!current
    };
  });

export { selectAuth, makeSelectAuth };
