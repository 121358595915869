import {
  TOAST,
  SET_PARAMS,
  TOGGLE_VERIFICATION_MODAL,
  LOAD_AUTHPAGE_CONTENT,
  LOAD_AUTHPAGE_CONTENT_FAILURE,
  LOAD_AUTHPAGE_CONTENT_SUCCESS,
  FORM_LOADER,
  SET_LOADER,
} from './constants';

export function loadAuthPageContent() {
  return {
    type: LOAD_AUTHPAGE_CONTENT,
  };
}

export function loadAuthPageContentSuccess(data) {
  return {
    type: LOAD_AUTHPAGE_CONTENT_SUCCESS,
    data,
  };
}
export function loadAuthPageContentFailure(error) {
  return {
    type: LOAD_AUTHPAGE_CONTENT_FAILURE,
    error,
  };
}

export function setParams(params) {
  return {
    type: SET_PARAMS,
    params,
  };
}

export function setToast(toast) {
  return {
    type: TOAST,
    toast,
  };
}

export function setFormLoader(formLoader) {
  return {
    type: FORM_LOADER,
    formLoader,
  };
}

export function toggleVerificationModal(verificationModal) {
  return {
    type: TOGGLE_VERIFICATION_MODAL,
    verificationModal,
  };
}

export function setLoader(loading) {
  return {
    type: SET_LOADER,
    loading,
  };
}
