import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import Icon from '../Icon';
import styled, { css } from 'styled-components';

const StyledToggleButton = styled(ToggleButton)`
  ${({ theme }) => css`
    padding-top: ${theme.spacing.none} !important;
    padding-bottom: ${theme.spacing.none} !important;
    height: 40px;
    display: flex;
    item-align: center;
    background-color: ${theme.colors.greys.white};
    border-color: ${theme.colors.greys.light};
    // outline: none;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      top: 0px;
      left: 0px;
      right: 0;
      bottom: 0;
      box-shadow: 0 0 0 2px ${theme.colors.brand.xlight};
      background-color: transparent !important;
      transition: all ${theme.durations.fast};
    }

    &.focus,
    &:focus,
    &:active,
    &.active {
      border-color: ${theme.colors.greys.light} !important;
      box-shadow: none !important;
    }

    &.focus.active {
      &::before {
        opacity: 1;
      }
    }

    &.focus,
    &:focus {
      background-color: ${theme.colors.greys.white};
      color: ${theme.colors.greys.xdark};
    }

    &:active,
    &.active {
      background-color: ${theme.colors.greys.black} !important;
      color: ${theme.colors.greys.white};
    }

    &:hover {
      background-color: ${theme.colors.greys.light} !important;
      border-color: ${theme.colors.greys.light} !important;
      color: ${theme.colors.greys.xdark} !important;
      &.disabled {
        border-color: ${theme.colors.greys.light} !important;
      }
    }

    &.focus,
    &:focus {
      &:not(:focus-visible) {
        &::before {
          opacity: 1;
        }
      }
    }

    &.disabled {
      border-color: ${theme.colors.greys.light} !important;
      background-color: ${theme.colors.greys.light} !important;
      color: ${theme.colors.greys.medium} !important;
    }
  `}
`;

const StyledToggleButtonLeft = styled(StyledToggleButton)`
  ${({ theme }) => css`
    padding-right: ${theme.spacing.xxsmall} !important;
    border-top-left-radius: ${theme.shapes.borderRadiusLarge} !important;
    border-bottom-left-radius: ${theme.shapes.borderRadiusLarge} !important;
    &::before {
      border-top-left-radius: ${theme.shapes.borderRadiusLarge} !important;
      border-bottom-left-radius: ${theme.shapes.borderRadiusLarge} !important;
    }
  `}
`;

const StyledToggleButtonRight = styled(StyledToggleButton)`
  ${({ theme }) => css`
    padding-left: ${theme.spacing.xxsmall} !important;
    border-top-right-radius: ${theme.shapes.borderRadiusLarge} !important;
    border-bottom-right-radius: ${theme.shapes.borderRadiusLarge} !important;
    &::before {
      border-top-right-radius: ${theme.shapes.borderRadiusLarge} !important;
      border-bottom-right-radius: ${theme.shapes.borderRadiusLarge} !important;
    }
  `}
`;

const WillowToggle = props => {
  return (
    <ToggleButtonGroup
      {...props}
      name="toggle"
      size="lg"
      type="radio"
      defaultValue={props.defaultValue}
    >
      <StyledToggleButtonLeft
        value={props.options[0].value}
        disabled={props.disabled || props.options[0].disabled}
        type="radio"
        size="sm"
        aria-label={props.options[0].value}
      >
        <Icon
          variant={props.options[0].icon}
          className="d-flex align-items-center"
        />
      </StyledToggleButtonLeft>
      <StyledToggleButtonRight
        value={props.options[1].value}
        disabled={props.disabled || props.options[1].disabled}
        type="radio"
        size="sm"
        aria-label={props.options[1].value}
      >
        <Icon
          variant={props.options[1].icon}
          className="d-flex align-items-center"
        />
      </StyledToggleButtonRight>
    </ToggleButtonGroup>
  );
};

export default WillowToggle;
