/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Container } from 'react-bootstrap';

const IntroVideo = styled.video`
  position: absolute;

  ${({ isMobile }) =>
    isMobile
      ? css`
          background: #ededed;
          top: 0%;
          left: 50%;
          min-width: 100%;
          transform: translate(-50%, 0);
          /* width: 120vw !important; --- Commented out due to the addition of video on homepage, please uncomment once the video is taken out */
        `
      : css`
          top: 50%;
          left: 50%;
          min-width: auto;
          min-height: 100vh;
          transform: translate(-50%, -50%);
          background: #ededed;
        `}

  @media (orientation: portrait) {
    display: block;
  }
  @media screen and (min-width: 1600px) {
    min-width: 100%;
  }
`;

const VideoBanner = ({ videos, poster, isMobile, children }) => {
  const [showBannerText, setShowBannerText] = useState(false);
  const [startLoaded, setStartLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    // const vid = document.getElementById('videobanner');
    // vid.addEventListener('ended', myHandler, false);
    // function myHandler() {
    setShowBannerText(true);
    // }
    // const timer = setTimeout(() => {
    //   if (!startLoaded || (startLoaded && !dataLoaded)) {
    //     setShowBannerText(true);
    //   }
    // }, 1000);
    // return () => clearTimeout(timer);
  }, []);
  return (
    <Container fluid className="h-100 p-0" key="videobanner">
      <div
        className={
          showBannerText
            ? 'position-relative w-100 overflow-hidden h-auto'
            : 'position-relative min-vh-100 vh-100 w-100 overflow-hidden'
        }
      >
        <IntroVideo
          id="videobanner"
          autoPlay
          muted="muted"
          playsInline="playsinline"
          allowfullscreen="false"
          onLoadStart={() => setStartLoaded(true)}
          onLoadedData={() => setDataLoaded(true)}
          onEnded={() => setShowBannerText(true)}
          className="mt-6"
          poster={showBannerText ? poster : ''}
          isMobile={isMobile}
        >
          {!showBannerText && videos}
          <img
            src={poster}
            title="Your browser does not support the <video> tag"
            alt="videobanner"
          />
        </IntroVideo>
        {showBannerText && children}
      </div>
    </Container>
  );
};

export default VideoBanner;
