/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';

import { Dropdown } from 'react-bootstrap';
import DropdownToggle from './DropdownToggle';
import DropdownMenu from './DropdownMenu';

const ChildrenWithProps = props => {
  const elements = React.Children.toArray(props.children).map(element =>
    React.cloneElement(element, {
      size: props.size,
      width: props.width,
      toggleClassName: props.toggleClassName,
    }),
  );
  return <>{elements}</>;
};

const StyledDropdown = styled(Dropdown)`
  ${({ theme }) => css`
    display: flex;
  `}
`;

const WillowDropdown = props => {
  const { menuClassName, toggleClassName, ...rest } = props;
  return (
    <StyledDropdown {...rest}>
      <ChildrenWithProps {...props} />
    </StyledDropdown>
  );
};

const StyledDropdownItem = styled(Dropdown.Item)`
  ${({ theme }) => css`
    :focus-visible {
      outline-color: ${theme.colors.brand.primary};
    }
  `}
`;

WillowDropdown.Toggle = DropdownToggle;
WillowDropdown.Menu = DropdownMenu;
WillowDropdown.Item = StyledDropdownItem;

export default WillowDropdown;
