/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { ListGroup, Container, Row } from 'react-bootstrap';
import mediasizes from '../../utils/sizes';

/*
 */

const StyledListItem = styled(({ isEven, twoCol, condensed, ...rest }) => (
  <ListGroup.Item {...rest} />
))`
  ${({ theme }) => css`
    border: none;
    background-color: transparent;
    padding: ${theme.spacing.none};
    &:last-child {
      ${InnerRow} {
        border-bottom: none;
      }
    }
  `}
  ${({ isEven, twoCol, theme }) =>
    isEven &&
    twoCol &&
    css`
      &:nth-last-child(2) {
        ${InnerRow} {
          border-bottom: none;
          ${mediasizes.mobile`
            border-bottom: 1px solid ${theme.colors.greys.medium};
          `}
          ${mediasizes.small`
            border-bottom: 1px solid ${theme.colors.greys.medium};
          `}
        }
      }
    `}
`;

const InnerRow = styled(({ twoCol, condensed, ...rest }) => (
  <Container {...rest} />
))`
  ${({ theme }) => css`
    height: 100%;
    border: none;
    padding: ${theme.spacing.none};
    padding-bottom: 0px;
    border-bottom: 1px solid ${theme.colors.greys.medium};
  `}

  ${({ condensed, theme }) =>
    condensed &&
    css`
      padding-bottom: ${theme.spacing.xxsmall};
    `}

  ${({ nonbordered, theme }) =>
    nonbordered &&
    css`
      border-bottom: 1px solid transparent;
    `}
`;

const ListRow = ({
  twoCol,
  isEven,
  item,
  condensed,
  nonbordered,
  className,
  children,
  hr,
}) => (
  <StyledListItem isEven={isEven} twoCol={twoCol} className={className}>
    <InnerRow fluid condensed={condensed} nonbordered={nonbordered}>
      <Row className="pt-2">{children}</Row>
      {hr && <hr />}
    </InnerRow>
  </StyledListItem>
);

export default ListRow;
