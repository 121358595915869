/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import WillowTabItem from './TabItem';

const StyledRow = styled(({ responsivewidth, ...rest }) => <Row {...rest} />)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.none};
    margin-right: ${theme.spacing.none};

    overflow-x: auto;
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    .nav {
      flex-wrap: nowrap;
      white-space: nowrap;
    }
  `}
  ${({ responsivewidth }) =>
    responsivewidth === 'true' &&
    css`
      flex-grow: 1;
      flex-basis: 0;
    `}
`;

const ChildrenWithProps = props => {
  const elements = React.Children.toArray(props.children).map(element =>
    React.cloneElement(element, {
      size: props.size,
      navtab: props.navtab,
      tabwidth: props.tabwidth,
      responsivewidth: props.responsivewidth,
    }),
  );
  return <>{elements}</>;
};

const WillowTabs = props => (
  <Tab.Container {...props}>
    <ChildrenWithProps {...props} />
  </Tab.Container>
);

// Control
const TabControl = props => (
  <StyledRow
    responsivewidth={props.responsivewidth}
    className={props.className}
  >
    <Col className="px-0">
      <Nav>
        <ChildrenWithProps {...props} />
      </Nav>
    </Col>
  </StyledRow>
);

// Custom properties
WillowTabs.Container = WillowTabs;
WillowTabs.Control = TabControl;
WillowTabs.Item = WillowTabItem;

// Extend from RB Tabs component
WillowTabs.Content = Tab.Content;
WillowTabs.Pane = Tab.Pane;

export default WillowTabs;
