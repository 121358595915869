/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Container, ProgressBar } from 'react-bootstrap';

const StyledProgressBarParent = styled(ProgressBar)`
  ${({ theme }) => css`
    height: 8px;
    margin-top: ${theme.spacing.xxsmall};
  `}
`;

const StyledProgressBar = styled(ProgressBar)`
  ${({ theme }) => css`
    height: 8px;
    border-radius: ${theme.shapes.borderRadiusMedium};
    .progress-bar {
      border-radius: ${theme.shapes.borderRadiusMedium};
    }
  `}
`;

const StyledProgressPercentage = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.typography.size.medium};
    text-align: right;
  `}
`;
const StyledProgressTag = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.typography.size.xxsmall};
    color: ${theme.colors.greys.dark};
    text-align: right;
  `}
`;

const CardProgressBar = props => {
  return (
    <Container
      className={`p-0 text-right d-flex flex-column justify-content-end`}
    >
      <StyledProgressPercentage>{props.now}%</StyledProgressPercentage>
      <StyledProgressTag>{props.tag}</StyledProgressTag>
      <StyledProgressBarParent>
        <StyledProgressBar {...props} isChild />
      </StyledProgressBarParent>
    </Container>
  );
};

export default CardProgressBar;
