export const uid = () => {
  if (typeof window !== 'undefined') {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16),
    );
  }
};


export const copyUrl = (settooltip, value) => {
  const inputc = document.body.appendChild(document.createElement('input'));
  inputc.value = value; // TODO: this will the gsrp link
  inputc.select();
  navigator.clipboard.writeText(inputc.value);
  inputc.parentNode.removeChild(inputc);
  settooltip(true);
  setTimeout(() => {
    settooltip(false);
  }, 1000);
};