/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Container } from 'react-bootstrap';
import Icon from '../Icon';

const StyledTrendContainer = styled(Container)`
  ${({ theme }) => css`
    padding: ${theme.spacing.none};
  `}
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      padding: ${theme.spacing.none};
    `}
`;

const StyledTrendText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.size.xxsmall};
    color: ${theme.colors.greys.dark};
  `}
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      margin-left: ${theme.spacing.none};
      margin-right: ${theme.spacing.xxsmall};
    `}
`;

const WillowCardPriceTrend = props => {
  const { iconVariant, sizeVariant, price, percentage } = props;

  if (sizeVariant === 'sm') {
    return (
      <StyledTrendContainer size={sizeVariant}>
        <StyledTrendText className={`me-2`}>${price}</StyledTrendText>
        <Icon variant={iconVariant} />
        <StyledTrendText className={`ms-1`}>{percentage}%</StyledTrendText>
      </StyledTrendContainer>
    );
  } else {
    return (
      <StyledTrendContainer size={sizeVariant}>
        <Icon variant={iconVariant} />
        <StyledTrendText className={`ms-2`}>${price}</StyledTrendText>
        <StyledTrendText className={`ms-2`}>({percentage}%)</StyledTrendText>
      </StyledTrendContainer>
    );
  }
};

export default WillowCardPriceTrend;
