/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Form } from 'react-bootstrap';
import Icon from '../../Icon';

const StyledFormText = styled(({ status, ...rest }) => <Form.Text {...rest} />)`
  ${({ theme }) => css`
    color: ${theme.colors.greys.dark};
  `}
  ${({ status, theme }) =>
    status === 'success' &&
    css`
      color: ${theme.colors.system.success};
    `}
  ${({ status, theme }) =>
    status === 'error' &&
    css`
      color: ${theme.colors.system.danger};
    `}
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.greys.medium};
    `}
`;

const HelperText = props => (
  <StyledFormText {...props}>{props.children}</StyledFormText>
);

const StyledFormGroup = styled(({ helperMessage, ...rest }) => (
  <Form.Group {...rest} />
))`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    border-width: 1px;
    border-style: solid;
    min-height: 59px;
    align-items: center;

    border-radius: ${theme.shapes.borderRadiusSmall};
    background-color: ${theme.colors.greys.light};
    border-color: ${theme.colors.greys.medium};
    padding-right: ${theme.spacing.small};
    margin-bottom: ${theme.spacing.none};

    transition: all ${theme.durations.fast} ease-in;

    &:focus-within {
      border-color: ${theme.colors.brand.dark};
    }
    select:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px ${theme.colors.greys.light} inset !important;
    }
  `}
  ${({ autofillcolor }) =>
    autofillcolor &&
    css`
      select:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px ${autofillcolor} inset !important;
      }
    `}
  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-color: ${theme.colors.greys.medium};
      background-color: ${theme.colors.greys.light};
      color: ${theme.colors.greys.medium};
    `}
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor} !important;
    `}
`;

const StyledFormLabel = styled(Form.Label)`
  ${({ theme }) => css`
    pointer-events: none;

    display: flex;
    align-items: center;
    position: absolute;

    z-index: 1;
    top: 0;
    left: 12px;
    height: 100%;

    font-size: ${theme.typography.size.small};
    font-weight: ${theme.typography.weight.normal};

    color: ${theme.colors.greys.dark};

    transition: all ${theme.durations.fast} ease-in;

    margin-top: -10px;
    font-size: 12px;
  `}
  ${({ disabled, theme }) =>
    disabled &&
    css`
      display: none;
    `}
`;

const StyledIcon = styled(Icon)`
  ${({ theme }) => css`
    pointer-events: none;
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;

    z-index: 1;
    top: 0;
    right: 12px;
  `}
`;

const StyledSelect = styled.select`
  ${({ theme }) => css`
    position: absolute;
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;

    font-size: ${theme.typography.size.small};
    transform: translateY(12px);
    &:active {
      background-color: transparent !important;
    }
    -webkit-appearance: none;
  `}
  ${({ disabled, theme }) =>
    disabled &&
    css`
      transform: translateY(0);
      color: ${theme.colors.greys.medium};
    `}
`;

const WillowFormSelect = props => {
  const {
    helperMessage,
    label,
    options,
    onChange,
    status,
    disabled,
    value,
    backgroundColor,
  } = props;
  const [hasValue, setHasValue] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');
  const defaultOnChange = e => setDefaultValue(e.target.value);
  useEffect(() => {
    setHasValue(!!(value || defaultValue));
  }, [value, defaultValue]);
  return (
    <div>
      <StyledFormGroup {...props}>
        <StyledFormLabel disabled={disabled}>{label}</StyledFormLabel>
        <StyledIcon variant="chevronDown" />
        <Form.Control
          as={StyledSelect}
          value={value || defaultValue}
          onChange={onChange || defaultOnChange}
          defaultValue={defaultValue}
          disabled={disabled}
        >
          <option>{}</option>
          {options && options.map && (
            <>
              {props.name === 'taxResidentCountry'
                ? options.map((option, i) => (
                    <option key={i} value={option.code}>
                      {option.name}
                    </option>
                  ))
                : options.map((option, i) => (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  ))}
            </>
          )}
        </Form.Control>
      </StyledFormGroup>
      {helperMessage && (
        <HelperText status={status} disabled={disabled}>
          {helperMessage}
        </HelperText>
      )}
    </div>
  );
};

export default WillowFormSelect;
