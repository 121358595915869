/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import { combineReducers } from 'redux';
import {
  LOAD_PORTFOLIO_PAGE_DATA,
  LOAD_PORTFOLIO_PAGE_DATA_SUCCESS,
  LOAD_PORTFOLIO_PAGE_DATA_FAILURE,
  SET_TOAST,
  SET_PURCHASES
} from './constants';

const initialWpContent = {
  data: false,
  loading: false,
  error: false,
  toast: false,
  purchases: false
};
export const initialState = {
  wpcontent: initialWpContent,
};

const wpcontent = (state = initialWpContent, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_PURCHASES:
        draft.purchases =  action.purchases;
        break;
      case LOAD_PORTFOLIO_PAGE_DATA:
        draft.loading = true;
        draft.error = false;
        draft.data = false;
        break;
      case LOAD_PORTFOLIO_PAGE_DATA_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.data = action.data;
        break;
      case LOAD_PORTFOLIO_PAGE_DATA_FAILURE:
        draft.loading = false;
        draft.error = true;
        draft.data = false;
        break;
      case SET_TOAST:
        draft.toast = action.toast;
        break;
    }
  });

const portfolioPageReducer = combineReducers({
  wpcontent,
});

export default portfolioPageReducer;
