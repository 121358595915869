import {
  LOAD_MARKETPLACE_PAGE_DATA,
  LOAD_MARKETPLACE_PAGE_DATA_SUCCESS,
  LOAD_MARKETPLACE_PAGE_DATA_FAILURE,
  LOAD_PROPERTY_DETAILS,
  LOAD_PROPERTY_DETAILS_SUCCESS,
  LOAD_PROPERTY_DETAILS_FAILURE,
  TOGGLE_VIEW,
} from './constants';

export function toggleView(view) {
  return {
    type: TOGGLE_VIEW,
    view,
  };
}
export function loadPropertyDetails() {
  return {
    type: LOAD_PROPERTY_DETAILS,
  };
}
export function loadPropertyDetailsSuccess(data) {
  return {
    type: LOAD_PROPERTY_DETAILS_SUCCESS,
    data,
  };
}
export function loadPropertyDetailsFailure(error) {
  return {
    type: LOAD_PROPERTY_DETAILS_FAILURE,
    error,
  };
}
export function loadMarketPlacePageData() {
  return {
    type: LOAD_MARKETPLACE_PAGE_DATA,
  };
}

export function loadMarketPlacePageDataSuccess(data) {
  return {
    type: LOAD_MARKETPLACE_PAGE_DATA_SUCCESS,
    data,
  };
}

export function loadMarketPlacePageDataFailure(error) {
  return {
    type: LOAD_MARKETPLACE_PAGE_DATA_FAILURE,
    error,
  };
}
