/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Link from 'components/Link';
import { useSelector } from 'react-redux';
import { segmentTrack } from 'utils/segment';

const SocialShare = ({ url, events, quote, mobile }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  function fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(
      /[!'()*]/g,
      c => `%${c.charCodeAt(0).toString(16)}`,
    );
  }
  const copyUrl = () => {
    const inputc = document.body.appendChild(document.createElement('input'));
    inputc.value = `${url}`;
    inputc.select();
    navigator.clipboard.writeText(inputc.value);
    inputc.parentNode.removeChild(inputc);
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 2000);
  };

  const locale = useSelector(state => state.language.locale);

  return (
    <Row className={`${mobile ? 'justify-content-left' : 'justify-content-center'} mb-4`}>
      <Col md={6} sm={12} xs={12} className="text-center">
        <Row className={`${mobile ? 'justify-content-left' : 'justify-content-center'}`}>
          <Col lg={1} md={2} sm={2} xs={2} className="text-center">
            <div data-href={url} data-size="small">
              <Link
                target="_blank"
                className="fb-xfbml-parse-ignore text-xdark d-flex align-items-baseline p-0"
                rel="noopener"
                to={`https://www.facebook.com/sharer/sharer.php?u=${url}&amp;src=sdkpreparse&quote=${quote ? quote : ''}`}
                data-ana-apply-intent="false"
                data-meta="share-primary-follow-0--link"
                data-ana="facebook"
                aria-label="Share our Midasboard article page, opens in a new tab"
                variant="basic"
                disabled={false}
                type="external"
                nodecoration="true"
                // onClick={() =>
                //   // events &&
                //   // segmentTrack(
                //   //   events.filter(event => event.name === 'facebookiconEvent'),
                //   // )
                // }
              >
                <Icon variant="facebook" size="xlarge" className="text-xdark" />
              </Link>
            </div>
          </Col>
          <Col lg={1} md={2} sm={2} xs={2} className="text-center">
            <Link
              target="_blank"
              className="twitter-share-button text-xdark d-flex align-items-baseline p-0"
              rel="noopener"
              to={`https://twitter.com/intent/tweet?url=${url}&text=${quote ? quote : ''}`}
              data-ana-apply-intent="false"
              data-meta="share-primary-follow-1--link"
              data-ana="twitter"
              aria-label="Share our Midasboard article page, opens in a new tab"
              data-size="small"
              variant="basic"
              disabled={false}
              type="external"
              nodecoration="true"
              // onClick={() =>
              //   // events &&
              //   // segmentTrack(
              //   //   events.filter(event => event.name === 'twittericonEvent'),
              //   // )
              // }
            >
              <Icon variant="twitter" size="xlarge" className="text-xdark" />
            </Link>
          </Col>
          <Col lg={1} md={2} sm={2} xs={2} className="text-center">
            <Button
              rel="noopener"
              data-ana-apply-intent="false"
              data-meta="share-primary-follow-3--link"
              data-ana="copy this link"
              aria-label="Copy our Midasboard article page"
              data-size="small"
              ref={target}
              onClick={() => copyUrl(!show)}
              variant="basic-alt"
              className="p-0"
            >
              <Icon
                variant="link"
                size="xlarge"
                className="text-xdark"
                // onClick={() =>
                //   // events &&
                //   // segmentTrack(
                //   //   events.filter(event => event.name === 'clipboardiconEvent'),
                //   // )
                // }
              />
            </Button>
            <Tooltip
              className="p-0 text-center"
              target={target.current}
              show={show}
              placement="top"
              size="sm"
              message={
                locale === 'en'
                  ? 'Link copied to clipboard'
                  : 'Lien copié dans le presse-papiers'
              }
              bodywidth="200px"
            />
          </Col>
          <Col lg={1} md={2} sm={2} xs={2} className="text-center">
            <Link
              target="_blank"
              className="twitter-share-button text-xdark d-flex align-items-baseline p-0"
              rel="noopener"
              to={`https://www.linkedin.com/sharing/share-offsite/?url=${fixedEncodeURIComponent(
                url,
              )}&summary=${quote ? quote : ''}/?${Math.random()}`}
              data-ana-apply-intent="false"
              data-meta="share-primary-follow-3--link"
              data-ana="linkedin"
              aria-label="Share our Midasboard article page, opens in a new tab"
              data-size="small"
              variant="basic"
              disabled={false}
              type="external"
              nodecoration="true"
              // onClick={() =>
              //   // events &&
              //   // segmentTrack(
              //   //   events.filter(event => event.name === 'linkediniconEvent'),
              //   // )
              // }
            >
              <Icon variant="linkedin" size="xlarge" className="text-xdark" />
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default SocialShare;
