import {
  TAB_STATUS,
  TOGGLE_KYC_TAB,
  TOGGLE_SIDEPANEL,
  REGISTRATION_LOADED,
  ACCEPT,
  SET_TOAST,
  CTA_BUTTON,
  SET_PDF,
  STORE_RESPONSE,
  LOAD_WPFINANCIAL_CONTENT,
  LOAD_WPFINANCIAL_CONTENT_SUCCESS,
  LOAD_WPFINANCIAL_CONTENT_FAILURE,
  SET_ACCOUNT_TYPE,
  SET_JSON_DATA,
  CLICKEDPLAID,
} from './constants';

export function initiatePlaid(activatePlaid) {
  return {
    type: CLICKEDPLAID,
    activatePlaid,
  };
}

export function loadWpFinancialContent() {
  return {
    type: LOAD_WPFINANCIAL_CONTENT,
  };
}
export function loadWpFinancialContentSuccess(wpFinContent) {
  return {
    type: LOAD_WPFINANCIAL_CONTENT_SUCCESS,
    wpFinContent,
  };
}
export function loadWpFinancialContentFailure() {
  return {
    type: LOAD_WPFINANCIAL_CONTENT_FAILURE,
  };
}

export function setResponse(apiresponse) {
  return {
    type: STORE_RESPONSE,
    apiresponse,
  };
}

export function setPdf(pdf) {
  return {
    type: SET_PDF,
    pdf,
  };
}

export function setToast(toast) {
  return {
    type: SET_TOAST,
    toast,
  };
}

export function setTabStatus(tabStatus) {
  return {
    type: TAB_STATUS,
    tabStatus,
  };
}

export function toggleSidepanel(sidepanel, sidepanelContent) {
  return {
    type: TOGGLE_SIDEPANEL,
    sidepanel,
    sidepanelContent,
  };
}

export function triggerCtaButton(selectedTab, formData, kind) {
  return {
    type: CTA_BUTTON,
    selectedTab,
    formData,
    kind,
  };
}

export function handleAccept(selectedTab) {
  return {
    type: ACCEPT,
    selectedTab,
  };
}

export function toggleKycTab(selectedTab, isTabOpen) {
  return {
    type: TOGGLE_KYC_TAB,
    selectedTab,
    isTabOpen,
  };
}

export function setAccountType(accountType) {
  return {
    type: SET_ACCOUNT_TYPE,
    accountType,
  };
}

export function registrationLoaded(registration) {
  return {
    type: REGISTRATION_LOADED,
    registration,
  };
}

export function setJSONData(data) {
  return {
    type: SET_JSON_DATA,
    data,
  };
}

export function setLoader(loading) {
  return {
    type: SET_JSON_DATA,
    loading,
  };
}

