/* eslint-disable import/no-unresolved */
import { createGlobalStyle } from 'styled-components';
const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    color: black;
    background-color: #ededed;
    overflow-x: hidden !important /* Hide horizontal scrollbar */
  }
  .ll-powered-by {
    display: none !important;
  }
  .ll-nps-feedback-link{
    display: none !important;
  }
  .leaflet-control-attribution {
    display: none !important;
  }
  #insent-iframe{
    max-height: 60vh !important;
    bottom: 15px !important;
    right: 15px !important;
  }
  #insent-card-close{
    background: #ededed !important;
  }
  .insent-link-container {
    display: none !important;
  }
  #app {
    color: black;
    background-color: #ededed;
    min-height: 100%;
    min-width: 100%;
  }
  .bgformRegister{
    background-color: transparent;
  }
  /* Animation */

  @keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
  }

  @-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
  }

  #kol_contestbox_container {
    display: none;
  }

.scroll-down {
  border: 2px solid #fff;
  border-radius: 20px;
  bottom: 60px;
  height: 50px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 30px;
}
@media screen and (device-aspect-ratio: 2/3) {
  .scroll-down {
    border: 2px solid #fff;
    border-radius: 20px;
    bottom: 14px;
    height: 50px;
    left: 82%;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 30px;
}
}
@media screen and (device-aspect-ratio: 40/71) {
  .scroll-down {
    border: 2px solid #fff;
    border-radius: 20px;
    bottom: 14px;
    height: 50px;
    left: 82%;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 30px;
}
}
.scroll-down::before {
  animation: scrollDownAnimation 2s infinite;
  background-color: #fff;
  border-radius: 100%;
  content: '';
  height: 6px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 10px;
  width: 6px;
}
@-moz-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
@keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 960px) {
  .bgformRegister{
    background-color: #fff;
  }
}

@media (max-width: 767px) {
  #insent-iframe{
   display: none !important;
  }
  }
  

@media (max-width: 400px) {
  #insent-iframe{
    width: 360px !important;
  }
}

@media (max-width: 360px) {
  #insent-iframe{
    width: 320px !important;
  }
}

@media (max-width: 320px) {
  #insent-iframe{
    width: 290px !important;
  }
}



@media (max-width: 767px) {
    #tooltip {
      left: 160px !important;
  }
}
.circle:hover{
  stroke: black;
  stroke-width: 4px;
}
`;

export default GlobalStyle;
