/* eslint-disable react/prop-types */
import React from 'react';
import Tab from 'components/Tab';
import Card from 'components/Card';
import { Row, Col, Image, Carousel } from 'react-bootstrap';
import Flag from 'components/Flag';
import Button from 'components/Button';
import TradeviewChart from 'components/Chart/TradeviewChart';
import LocalLogic from 'components/LocalLogic';
import GoogleMaps from 'components/GoogleMaps';
import { uid } from '../../../../utils/ui';

const MainSection = ({
  tabs,
  defaultTab,
  toggleMainTab,
  data,
  tradeImages = [],
  numberOfImages,
  propertyDetail,
  offeringLabel,
  lat,
  lng,
  locale,
}) => {
  const ipoInfo = propertyDetail;

  let newimageOrder = null;

  // creating new array with first image as the main image
  const firstImage = tradeImages && tradeImages.filter(tr => tr.category === 'main');

  if (tradeImages) {
    if (firstImage) {
      const otherImages = tradeImages !== false && tradeImages.filter(tr => tr.category !== 'main');
      newimageOrder = [...firstImage, ...otherImages];
    } else {
      newimageOrder = [...tradeImages];
    }
  }
  const filteredImages =
    newimageOrder !== null &&
    newimageOrder.length > 0 !== null &&
    newimageOrder.filter(
      e => e.category !== 'neighbourhood' && e.category !== 'video',
    );
  return (
    <div id={Math.random()}>
      {defaultTab && (
        <Tab.Container defaultActiveKey={defaultTab} id={Math.random()}>
          <Tab.Control id={Math.random()}>
            {tabs &&
              tabs.map((item, i) => (
                <Tab.Item
                  onClick={() => toggleMainTab(item.header)}
                  key={Math.random()}
                  eventKey={item.header}
                  className={`${i === 0 ? 'ps-0' : ''}`}
                  responsivewidth="true"
                >
                  {item.header}
                </Tab.Item>
              ))}
          </Tab.Control>
        </Tab.Container>
      )}

      <Row>
        <Col md={12} className="mt-4 mb-0 bg-wihte">
          {data && data.content === 'image' && (
            <Card
              style={{
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
              }}
            >
              <div className="position-relative">
                <Carousel
                  controls={filteredImages.length > 1}
                  indicators={filteredImages.length > 1}
                >
                  {newimageOrder &&
                    newimageOrder
                      .filter(
                        e =>
                          e.category !== 'neighbourhood' &&
                          e.category !== 'video',
                      )
                      .map(item => (
                        <Carousel.Item key={uid()}>
                          <img
                            className="d-block w-100"
                            src={`${item.url}?w=1000&ar=16:9&fit=crop`}
                            alt={item.title}
                          />
                        </Carousel.Item>
                      ))}
                </Carousel>
                {offeringLabel &&
                  <Flag
                    className={`${
                      ipoInfo && ipoInfo.ipoDetails
                        ? 'bg-primary'
                        : 'bg-warning'
                    } position-absolute text-black top-5 end-5 opacity-75 fw-bold`}
                    variant="primary"
                  >
                    {offeringLabel}
                  </Flag>
                }
                {filteredImages.length > 1 &&
                  <Button
                    className="position-absolute end-5 bottom-5"
                    variant="basic"
                    icon="images"
                    size="sm"
                  >
                    {numberOfImages}
                  </Button>
                }
              </div>
            </Card>
          )}
          {data && data.content === 'chart' && (
            <>
              <TradeviewChart />
            </>
          )}
          {data && data.content === 'localLogic' && (
            <GoogleMaps defaultTab={defaultTab} lat={lat} lng={lng} />
            // <LocalLogic
            //   defaultTab={defaultTab}
            //   lat={lat}
            //   lng={lng}
            //   locale={locale}
            // />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MainSection;
