/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React from 'react';
import ListLayout from 'components/List/ListLayout';
import { Container } from 'react-bootstrap';
import Tab from 'components/Tab';
import moment from 'moment-timezone';
import { formatters } from '../../../../utils/formatters';
import { uid } from '../../../../utils/ui';

const TradeAreaSection = ({
  tabs,
  toggleTradeTab,
  defaultTab,
  data,
  propertyDetail,
}) => {
  // const financials = propertyDetail.financials;
  // const ipo = propertyDetail.ipoDetails;

  // const prices = [
  //   { id: 'propertyType', value: financials.propertyType },
  //   {
  //     id: 'totalFundingAmount',
  //     value: financials.totalFundingAmount,
  //     format: 'amount',
  //   },
  //   { id: 'mortgageAmt', value: financials.mortgageAmount, format: 'amount' },
  //   { id: 'monthlyRent', value: financials.monthlyRent, format: 'amount' },
  //   // {
  //   //   id: 'currentReserve',
  //   //   value: financials.currentReserve,
  //   //   format: 'amount',
  //   // },
  //   {
  //     id: 'targetReserve',
  //     value: financials.targetReserve,
  //     format: 'amount',
  //   },
  //   {
  //     id: 'expectedDividend',
  //     value: financials.expectedDividend,
  //     format: 'percent',
  //   },
  //   {
  //     id: 'monthlyExpense',
  //     value: financials.latestMonthlyExpense,
  //     format: 'currency',
  //   },
  //   { id: 'totalEquity', value: financials.totalEquity, format: 'amount' },
  //   // {
  //   //   id: 'investmentTerm',
  //   //   value: financials.investmentTerm,
  //   //   format: 'percent',
  //   // },
  //   // {
  //   //   id: 'totalUnits',
  //   //   value: ipo.totalPropertyShares,
  //   //   format: 'squarefootage',
  //   // },
  //   {
  //     id: 'targetsellDate',
  //     value: moment(financials.targetSellDate).tz('America/New_York'),
  //     format: 'short_date',
  //   },
  // ];

  // const getValue = column => {
  //   const current = prices.filter(e => e.id == column.id)[0];
  //   const format = formatters[current.format] || formatters.none;
  //   return format(current.value);
  // };

  const activeTab = tabs && tabs.map(tab => tab.key);

  return (
    <Container className="px-0 pt-3 pb-3">
      <Tab
        defaultActiveKey={activeTab[0]}
        tabwidth="180px"
        responsivewidth="true"
      >
        <Tab.Control>
          {tabs &&
            tabs.map((option, i) => (
              <Tab.Item
                onClick={() => toggleTradeTab(option.key)}
                key={option.key}
                eventKey={option.key}
                className={`${i === 0 ? 'ps-0' : ''}`}
                responsivewidth="true"
              >
                {option.header}
              </Tab.Item>
            ))}
        </Tab.Control>
        <hr className="mt-0" />
        <Container>
          <ListLayout
            rawdata={[
              {
                columns: data && [
                  ...data.map((item, i) => ({
                    content: '',
                    id: `tradeareaSection${i}`,
                    widths: { sm: '12', md: '12', lg: '6' },
                    rows: [{
                      hr: true,
                      condensed: true,
                      columns: [{
                        id: `tradeareaSubSection${uid()}`,
                        content: item.label,
                        widths: {
                          xs: '6',
                          md: '6',
                          lg: '6',
                          xxl: '6',
                        },
                        classes: 'text-start',
                      },
                      {
                        id: `tradeareaSubSection${uid()}`,
                        widths: {
                          xs: '6',
                          md: '6',
                          lg: '6',
                          xxl: '6',
                        },
                        content: item.value,
                        classes: 'text-end text-lg-end text-xxl-end',
                      }]
                    }
                    ],
                  })),
                ],
              },
            ]}
          />
        </Container>
        {/* rawdata={[ //this code will eventually replace the above code
    {
      columns: [
        ...data.content.map((list, i) => {
          return {
            content: '',
            id: `accordianSection${i}`,
            widths: { sm: '12', md: '12', lg: '6' },
            rows: [
              ...list.columns.map(col => {
                const format =
                  formatters[col.format] || formatters.none;
                return {
                  hr: true,
                  condensed: true,
                  columns: [
                    {
                      id: `tradeareaSubSection${uid()}`,
                      content: col.label,
                      widths: { xs: '7' },
                      classes: 'text-start text-black',
                    },
                    {
                      id: `tradeareaSubSection${uid()}`,
                      content: format(tradeInfo && tradeInfo[col.id]),
                      classes: 'text-end',
                    },
                  ],
                };
              }),
            ],
          };
        }),
      ],
    },
  ]} */}
        {/* {tabIndex === 1 && (
                    <OrderBookTable
                      data={propertyData && propertyData.orders}
                    />
                  )}
                  {tabIndex === 2 && (
                    <TradeHistoryTable
                      data={propertyData && propertyData.trades.recent}
                    />
                  )} */}
      </Tab>
    </Container>
  );
};
export default TradeAreaSection;
