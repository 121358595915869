/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import { combineReducers } from 'redux';
import {
  LOAD_KYC_WP_CONTENT,
  LOAD_KYC_WP_CONTENT_SUCCESS,
  LOAD_KYC_WP_CONTENT_FAILURE,
  LOAD_TRADEVIEW_CONTENT,
  LOAD_TRADEVIEW_CONTENT_SUCCESS,
  LOAD_TRADEVIEW_CONTENT_FAILURE,
  LOAD_SINGLE_PROPERTY_CONTENT,
  LOAD_SINGLE_PROPERTY_CONTENT_SUCCESS,
  LOAD_SINGLE_PROPERTY_CONTENT_FAILURE,
  TOGGLE_STAGE,
  TOGGLE_TRADE_TAB,
  TOGGLE_MAIN_TAB,
  SUBMIT_WATCHLIST,
  REMOVE_WATCHLIST,
  SUBMIT_REVIEW_ORDER,
  SUBMIT_FORM_DATA,
  TOGGLE_CAPSULE,
  LOAD_REVIEW_DATA,
  LOAD_REVIEW_DATA_SUCCESS,
  LOAD_REVIEW_DATA_FAILURE,
  LOAD_REGISTRATION,
  ORDERBOX_LOADER,
  LOAD_ISSUES_DATA,
  LOAD_ISSUE_DATA,
  LOAD_USER_DOCUMENTS,
  TOGGLE_DOWNLOAD,
} from './constants';

const initialWpContent = {
  pageContent: false,
  pageContentLoading: false,
  pageContentError: false,
  propertyContent: false,
  propertyContentLoading: false,
  propertyContentError: false,
  activeStage: false,
  activeTradeTab: 'details',
  activeMainTab: false,
  formdata: false,
  activeCapsule: false,
  orderboxLoader: false,
  issues: false,
  issue: false,
  documents: false,
  document: false
};
const initialreviewData = {
  data: false,
  loading: false,
  error: false,
  errorMessage: false,
};
const initialKycData = {
  data: false,
  loading: false,
  error: false,
  registration: false,
};
export const initialState = {
  wpcontent: initialWpContent,
  reviewData: initialreviewData,
  kycData: initialKycData,
};
const kycData = (state = initialKycData, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_KYC_WP_CONTENT:
        // draft.data = false;
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_KYC_WP_CONTENT_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        draft.error = false;
        break;
      case LOAD_KYC_WP_CONTENT_FAILURE:
        draft.data = false;
        draft.loading = false;
        draft.error = true;
        break;
      case LOAD_REGISTRATION:
        draft.registration = action.registration;
        break;
    }
  });

const wpcontent = (state = initialWpContent, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_ISSUES_DATA:
        draft.issues = action.issues;
        break;
      case LOAD_ISSUE_DATA:
        draft.issue = action.issue;
        break;
      case LOAD_USER_DOCUMENTS:
        draft.documents = action.documents;
        break;
      case TOGGLE_DOWNLOAD:
        draft.document = action.document;
        break;
      case ORDERBOX_LOADER:
        draft.orderboxLoader = action.orderboxLoader;
        break;
      case LOAD_TRADEVIEW_CONTENT:
        draft.pageContentLoading = true;
        draft.pageContentError = false;
        break;
      case LOAD_TRADEVIEW_CONTENT_SUCCESS:
        draft.pageContent = action.pageContent;
        draft.pageContentLoading = false;
        draft.pageContentError = false;
        break;
      case LOAD_TRADEVIEW_CONTENT_FAILURE:
        draft.pageContent = false;
        draft.pageContentLoading = false;
        draft.pageContentError = true;
        break;
      case LOAD_SINGLE_PROPERTY_CONTENT:
        draft.propertyContentLoading = true;
        draft.propertyContentError = false;
        break;
      case LOAD_SINGLE_PROPERTY_CONTENT_SUCCESS:
        draft.propertyContent = action.propertyContent;
        draft.propertyContentLoading = false;
        draft.propertyContentError = false;
        break;
      case LOAD_SINGLE_PROPERTY_CONTENT_FAILURE:
        draft.propertyContent = false;
        draft.propertyContentLoading = false;
        draft.propertyContentError = true;
        break;
      case TOGGLE_STAGE:
        draft.activeStage = action.activeStage;
        break;
      case TOGGLE_TRADE_TAB:
        draft.activeTradeTab = action.activeTradeTab;
        break;
      case TOGGLE_MAIN_TAB:
        draft.activeMainTab = action.activeMainTab;
        break;
      case SUBMIT_WATCHLIST:
        draft.formdata = action.formdata;
        break;
      case REMOVE_WATCHLIST:
        draft.formdata = action.formdata;
        break;
      case SUBMIT_REVIEW_ORDER:
        draft.formdata = action.formdata;
        break;
      case SUBMIT_FORM_DATA:
        draft.formdata = action.formdata;
        break;
      case TOGGLE_CAPSULE:
        draft.activeCapsule = action.activeCapsule;
        break;
    }
  });
const reviewData = (state = initialreviewData, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_REVIEW_DATA:
        draft.loading = true;
        draft.error = false;
        draft.errorMessage = false;
        break;
      case LOAD_REVIEW_DATA_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        draft.error = false;
        draft.errorMessage = false;
        break;
      case LOAD_REVIEW_DATA_FAILURE:
        draft.data = false;
        draft.loading = false;
        draft.error = true;
        draft.errorMessage = action.errorMessage;
        break;
    }
  });

const tradeviewReducer = combineReducers({
  wpcontent,
  reviewData,
  kycData,
});
export default tradeviewReducer;
