/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Container } from 'react-bootstrap';
import Icon from '../Icon';

// Footer
const StyledFooterContainer = styled(Container)`
  ${({ theme }) => css`
    padding: ${theme.spacing.none};
  `}
`;

const StyledCardFooterTextBlock = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.small};
    font-size: ${theme.typography.size.xxsmall};
    color: ${theme.colors.greys.dark};
  `}
`;

const StyledCardFooterNumber = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.greys.xdark};
    font-weight: ${theme.typography.weight.book};
  `}
`;

const WillowCardPriceFooter = props => {
  const { invested, daysLeft, daysleftlabel, investedlabel, locale } = props;

  return (
    <StyledFooterContainer>
      {/* <StyledCardFooterTextBlock>
        <StyledCardFooterNumber>
          {invested ? `$${invested}` : ''}
        </StyledCardFooterNumber>{' '}
        {investedlabel}
      </StyledCardFooterTextBlock>{' '}*/}
      <br />
      <StyledCardFooterTextBlock>
        {/* <StyledCardFooterNumber>{daysLeft}</StyledCardFooterNumber>{' '}
        {daysleftlabel} */}
        {invested ? (
          <>
            <span className="text-black">
              {locale === 'en' ? 'Added to Midasboard' : 'Ajouté à Midasboard'}
            </span>{' '}
            <Icon variant="Verified" color="" />
          </>
        ) : (
          ''
        )}
      </StyledCardFooterTextBlock>
    </StyledFooterContainer>
  );
};

export default WillowCardPriceFooter;
