/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { currency, percent } from 'utils/tradeview';
import { Row, Col } from 'react-bootstrap';
import Flag from 'components/Flag';
import Icon from 'components/Icon';
import Button from 'components/Button';
import styled from 'styled-components';
import mediasizes from 'utils/sizes';
import Toast from 'components/Toast';
export const BtnCloseWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 15px;
  transform: translate(0, -150%);

  ${mediasizes.tablet` display: none !important; `}
  ${mediasizes.desktop` display: none !important; `}
`;
const BtnWatchlistWrapper = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 0;
  transform: translate(0, 100%);
  z-index: 1;

  ${mediasizes.mobile`
          top: 0;
          left: 0;
          transform: none;
      `}
`;

const HeaderSection = ({
  data,
  rawdata,
  propertyContent,
  symbol,
  submitWatchlist,
  removeWatchlist,
  watchList,
  authenticationStatus,
  locale,
}) => {
  const [watched, setWatched] = useState(watchList && watchList);
  const acquisitionPrice =
    data && data.financials && data.financials.propertyAcquisitionPrice;
  const priceperShare =
    data && data.ipoDetails && data.ipoDetails.pricePerShare;

  const handleWatchListClicked = async () => {
    if (watched && watched.indexOf(symbol) >= 0) {
      const watch = watched && watched.filter(item => item !== symbol);
      setWatched(watch);
      removeWatchlist(symbol);
    } else {
      setWatched([...watched, symbol]);
      submitWatchlist(symbol);
    }
  };

  if (
    propertyContent &&
    propertyContent.caardd &&
    propertyContent.caardd.notFound
  ) {
    return (
      <>
        <p className="mb-9 p-4">
          <Icon variant="error" className="d-inline-block m-0" /> No content
          found, please check if you are connected to the right environment
        </p>
      </>
    );
  } else {
    return (
      <Row className="position-relative pt-7 pt-md-0">
        <Col>
          {/* <p className="m-0 text-greys-dark">{data.location}</p> */}
          <h2 className="mb-2 mt-7">
            {propertyContent && propertyContent.name}
            <span className="ms-3 text-greys-dark">
              {propertyContent && propertyContent.symbol}
            </span>
          </h2>
          <div className="pb-5">
            <Row>
              <Col xs={12} md={7} lg={8}>
                <p className="text-greys-dark mb-0">
                  <span className="text-black h3">
                    {propertyContent && propertyContent.subTitle1}
                  </span>
                </p>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <p className="text-greys-dark mb-0">
                  <span className="text-black h3">
                    {propertyContent && propertyContent.subTitle2}
                  </span>
                </p>
              </Col>
            </Row>
            {/* <p className="d-inline-block m-0" style={{ lineHeight: `1` }}>
              
             
            </p> */}
            {/* {rawdata.category_name === 'IPO' ? (
              ''
            ) : (
              <span className="ml-4">
                <Icon
                  variant="tickUp"
                  color="green"
                  className="d-inline-block m-0"
                />
                <p className="d-inline-block m-0 ml-2 text-muted">
                  {percent(tradesDetail.changePercent)} (
                  {currency(tradesDetail.change)})
                </p>
              </span>
            )} */}
          </div>
        </Col>
        <BtnCloseWrapper>
          <Button
            variant="basic"
            icon="close"
            iconOnly="true"
            size="lg"
            aria-label="Close"
          ></Button>
        </BtnCloseWrapper>

        {/* {authenticationStatus ? (
          <div onClick={handleWatchListClicked}>
            {watchList !== null ? (
              <BtnWatchlistWrapper>
                <Flag
                  variant={`${
                    watched && watched.indexOf(symbol) >= 0
                      ? 'primary'
                      : 'secondary'
                  }`}
                  icon="watchList"
                  tabIndex="0"
                >
                  {watched &&
                    watched.indexOf(symbol) >= 0 &&
                    `${rawdata.removewatchlistlabel}`}
                  {watched &&
                    watched.indexOf(symbol) === -1 &&
                    `${rawdata.addwatchlistlabel}`}
                </Flag>
              </BtnWatchlistWrapper>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )} */}
      </Row>
    );
  }
};

export default HeaderSection;
