/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import {
  TOGGLE_KYC_TAB,
  TAB_STATUS,
  LOAD_KYC_TEMPLATE,
  LOAD_KYC_TEMPLATE_SUCCESS,
  LOAD_KYC_TEMPLATE_FAILURE,
  TOGGLE_SIDEPANEL,
  REGISTRATION_LOADED,
  SET_TOAST,
  SET_PDF,
  STORE_RESPONSE,
  LOAD_WPFINANCIAL_CONTENT,
  LOAD_WPFINANCIAL_CONTENT_SUCCESS,
  LOAD_WPFINANCIAL_CONTENT_FAILURE,
  SET_ACCOUNT_TYPE,
  SET_JSON_DATA,
  SET_LOADER,
  CLICKEDPLAID
} from './constants';

export const initialState = {
  tabStatus: { choice: 0, gettingstarted: 2, personal: 0, business: 0 }, // This needs to 0, 1, 2
  selectedTab: '',
  data: false,
  error: false,
  loading: false,
  sidepanel: false,
  sidepanelContent: false,
  toast: false,
  registration: false,
  pdf: false,
  trulioo: false,
  experienceid: false,
  apiresponse: false,
  truliooLoader: false,
  wpFinContent: false,
  wpFinLoading: false,
  wpFinError: false,
  accountType: false,
  activatePlaid: false,
};

const dashboardReducer = produce((draft, action) => {
  switch (action.type) {
    case STORE_RESPONSE:
      draft.apiresponse = action.apiresponse;
      break;
    case SET_TOAST:
      draft.toast = action.toast;
      break;
    case SET_PDF:
      draft.pdf = action.pdf;
      break;
    case TAB_STATUS:
      draft.tabStatus = action.tabStatus;
      break;
    case REGISTRATION_LOADED:
      draft.registration = action.registration;
      break;
    case TOGGLE_KYC_TAB:
      draft.selectedTab = action.selectedTab;
      draft.isTabOpen = action.isTabOpen;
      break;
    case TOGGLE_SIDEPANEL:
      draft.sidepanel = !draft.sidepanel;
      draft.sidepanelContent = action.sidepanelContent;
      break;
    case LOAD_WPFINANCIAL_CONTENT:
      draft.wpFinContent = false;
      draft.wpFinLoading = true;
      draft.wpFinError = false;
      break;
    case LOAD_WPFINANCIAL_CONTENT_SUCCESS:
      draft.wpFinContent = action.wpFinContent;
      draft.wpFinLoading = false;
      draft.wpFinError = false;
      break;
    case LOAD_WPFINANCIAL_CONTENT_FAILURE:
      draft.wpFinContent = false;
      draft.wpFinLoading = false;
      draft.wpFinError = true;
      break;
    case SET_ACCOUNT_TYPE:
      draft.accountType = action.accountType;
      // draft.accountTypeLoading = false;
      // draft.accountTypeError = false;
      break;
    case SET_JSON_DATA:
      draft.data = action.data;
      break;
    case SET_LOADER:
      draft.loading = action.loading;
      break;
    case CLICKEDPLAID:
      draft.activatePlaid = action.activatePlaid;
      break;
  }
}, initialState);

export default dashboardReducer;
