/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'react-bootstrap';
import Icon from '../Icon';

const StyledButton = styled(({ iconOnly, ...rest }) => <Button {...rest} />)`
  ${({ theme }) => css`
    position: relative;
    transition: all ${theme.durations.fast} ease-in;

    border: none;
    border-width: ${theme.shapes.borderWidthSmall};
    border-radius: ${theme.shapes.borderRadiusLarge};

    outline: none;
    cursor: pointer;

    padding: ${theme.spacing.small} ${theme.spacing.large};
    @media screen and (max-device-width: 823px) and (orientation: landscape) {
      padding: 10px 25px;
    }
    background-color: ${theme.colors.brand.xlight};

    color: ${theme.colors.brand.dark};

    font-family: ${theme.typography.primary};
    line-height: ${theme.typography.lineHeight.small};
    letter-spacing: ${theme.typography.letterSpacing.small};
    white-space: nowrap;

    box-shadow: ${theme.depths['2']};

    a {
      text-decoration: none;
      color: #000000;
    }

    svg {
      margin-right: ${theme.spacing.xsmall};
    }

    &::before {
      content: '';
      position: absolute;
      opacity: 0;
      width: calc(100% + ${theme.spacing.xxxsmall} * 2);
      height: calc(100% + ${theme.spacing.xxxsmall} * 2);
      top: -${theme.spacing.xxxsmall};
      left: -${theme.spacing.xxxsmall};
      right: 0;
      bottom: 0;
      border-radius: ${theme.shapes.borderRadiusLarge};
      box-shadow: 0 0 0 2px ${theme.colors.brand.xlight};
      background-color: transparent !important;
      transition: all ${theme.durations.fast};
    }
    ${({ placing }) =>
      placing === 'card' &&
      css`
        background-color: ${theme.colors.brand.secondary} !important;
        &:hover {
          transform: translate3d(0, 0px, 0) !important;
          background-color: ${theme.colors.brand.secondary} !important;
          box-shadow: ${theme.depths['3']};
        }
    `}
    &:hover {
      transform: translate3d(0, -2px, 0);
      background-color: ${theme.colors.brand.xlight};
      box-shadow: ${theme.depths['3']};
    }

    &:focus {
      &::before {
        opacity: 1;
      }
    }

    &.focus,
    &:focus {
      box-shadow: ${theme.depths['3']} !important;
    }

    &:focus:not(:focus-visible) {
      &::before {
        opacity: 0;
      }
    }

    &:disabled {
      background-color: ${theme.colors.greys.light};
      box-shadow: ${theme.depths['1']};
      color: ${theme.colors.greys.medium} !important;
      &:hover {
        transform: translate3d(0, 0, 0);
      }
    }
  `}

  ${({ theme, variant }) =>
    variant === 'primary-alt' &&
    css`
      color: ${theme.colors.greys.xdark} !important;
      background-color: ${theme.colors.brand.secondary};
      &:hover {
        background-color: ${theme.colors.brand.secondary};
      }
    `}

  ${({ theme, variant }) =>
    variant === 'primary-flat' &&
    css`
      color: ${theme.colors.brand.dark} !important;
      background-color: ${theme.colors.brand.xlight};

      box-shadow: none;
      border-width: 0;
      &:hover {
        background-color: ${theme.colors.brand.xlight};
      }
    `}

  ${({ theme, variant }) =>
    variant === 'primary-flat-alt' &&
    css`
      color: ${theme.colors.greys.xdark} !important;
      background-color: ${theme.colors.brand.secondary};
      box-shadow: none;
      border-width: 0;
      &:hover {
        background-color: ${theme.colors.brand.secondary};
      }
    `}
    ${({ theme, variant }) =>
      variant === 'basic-alt' &&
      css`
        background-color: ${theme.colors.greys.xlight};
        box-shadow: none;
        border-color: ${theme.colors.greys.medium};
        border-style: solid;
        color: ${theme.colors.greys.dark};
        &:hover {
          background-color: ${theme.colors.greys.xlight};
          border-color: ${theme.colors.greys.dark};
          transform: translate3d(0, 0, 0) !important;
          box-shadow: none !important;
        }
        .focus,
        :focus {
          transform: translate3d(0, 0, 0) !important;
          box-shadow: none !important;
        }
      `}

  ${({ theme, variant }) =>
    variant === 'basic' &&
    css`
      background-color: ${theme.colors.greys.xlight};
      box-shadow: none;
      border-color: ${theme.colors.greys.medium};
      border-style: solid;
      color: ${theme.colors.greys.dark};
      &:hover {
        background-color: ${theme.colors.greys.xlight};
        border-color: ${theme.colors.greys.dark};
        transform: translate3d(0, 0, 0);
        box-shadow: none;
      }
    `}

    ${({ theme, variant }) =>
    variant === 'transparent' &&
    css`
      background-color: ${theme.colors.greys.light};
      box-shadow: none;
      border-color: ${theme.colors.greys.light};
      border-style: solid;
      color: ${theme.colors.greys.dark};
      &:hover {
        background-color: ${theme.colors.greys.xlight};
        border-color: ${theme.colors.greys.dark};
        transform: translate3d(0, 0, 0);
        box-shadow: none;
      }
    `}

  ${({ theme, variant, iconOnly }) =>
    variant === 'basic' &&
    iconOnly &&
    css`
      background-color: ${theme.colors.greys.xlight} !important;
    `}

  ${({ theme, iconOnly }) =>
    iconOnly &&
    css`
      background-color: ${theme.colors.brand.primary};
      color: ${theme.colors.brand.dark};
      svg {
        margin-right: ${theme.spacing.none};
      }
      padding: ${theme.spacing.none};
      width: ${theme.spacing.xlarge};
      height: ${theme.spacing.xlarge};
    `}

  ${({ theme, size, iconOnly }) =>
    size === 'sm' &&
    !iconOnly &&
    css`
      height: 40px;
      font-size: 1rem;
      padding: ${theme.spacing.xxsmall} ${theme.spacing.large};
    `}

  ${({ theme, size, iconOnly }) =>
    size === 'sm' &&
    iconOnly &&
    css`
      background-color: ${theme.colors.greys.xlight};
      box-shadow: none;
      border-color: ${theme.colors.greys.medium};
      width: ${theme.spacing.large};
      height: ${theme.spacing.large};
      &:hover {
        background-color: ${theme.colors.greys.xlight};
        border-color: ${theme.colors.greys.dark};
        transform: translate3d(0, 0, 0);
        box-shadow: none;
      }
    `}
`;

const WillowButton = props => {
  const { icon, iconOnly, iconsize } = props;
  return (
    <StyledButton {...props}>
      {icon && <Icon variant={icon} size={iconsize ? iconsize : 'xlarge'} />}
      {!iconOnly && props.children}
    </StyledButton>
  );
};

export default WillowButton;
