/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Toast } from 'react-bootstrap';
import Icon from '../Icon';
import Button from '../Button';
import mediasizes from '../../utils/sizes';

// Header
const StyledToastHeader = styled(Toast.Header)`
  ${({ theme }) => css`
    grid-area: header;
    border-top-left-radius: ${theme.shapes.borderRadiusMedium};
    border-top-right-radius: ${theme.shapes.borderRadiusMedium};
    background-color: transparent;
    border-bottom: none;
    padding: 0;
  `}
`;

const StyledText = styled.strong`
  ${({ theme }) => css`
    margin-right: auto;
    color: ${theme.colors.greys.xdark};
    font-size: ${theme.typography.size.small};
    font-weight: ${theme.typography.weight.regular};
  `}
`;

const ToastHeader = props => (
  <StyledToastHeader {...props} closeButton={false}>
    <StyledText>{props.heading}</StyledText>
  </StyledToastHeader>
);

// Body
const StyledToastBody = styled(Toast.Body)`
  ${({ theme }) => css`
    grid-area: body;
    padding: 0;
    // padding-bottom: ${theme.spacing.xxsmall};
  `}
`;

const ToastBody = props => <StyledToastBody>{props.message}</StyledToastBody>;

// Main
const StyledIconButton = styled.strong`
  ${({ theme }) => css`
    grid-area: close;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: ${theme.colors.greys.xdark};
    :focus {
      outline: none;
      box-shadow: 0 0 0 2px ${theme.colors.brand.xlight};
    }
  `}
`;

const StyledToastButton = styled(Button)`
  ${({ theme }) => css`
    grid-area: button;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: ${theme.spacing.large};
    ${mediasizes.mobile`
			width: 100%;
			margin: auto;
		`}
  `}
`;
const StyledToast = styled(Toast)`
  ${({ theme }) => css`
    display: grid !important;
    grid-template-columns: 1fr auto auto;
    grid-gap: ${theme.spacing.none};
    grid-template-areas:
      'header button close'
      'body button close';
    position: relative;
    border: none;
    border-radius: ${theme.shapes.borderRadiusMedium};
    padding: ${theme.spacing.xsmall} ${theme.spacing.small};
    max-width: 100%;
    ${mediasizes.mobile`
      grid-gap: ${theme.spacing.xsmall};
      grid-template-columns: 1fr auto;
      grid-template-areas:
        "header close"
        "body body"
        "button button";
    `}
  `}
  ${({ priority, theme }) =>
    priority === 'high' &&
    css`
      background-color: ${theme.colors.brand.secondary};
    `}
  ${({ priority, theme }) =>
    priority === 'medium' &&
    css`
      background-color: ${theme.colors.accent.blue};
    `}
  ${({ priority, theme }) =>
    priority === 'low' &&
    css`
      background-color: ${theme.colors.greys.white};
    `}
`;

const WillowToast = props => (
  <StyledToast {...props}>
    <ToastHeader heading={props.heading} />
    <ToastBody message={props.message} />
    {props.button && (
      <StyledToastButton
        onClick={props.button.onClick}
        size="sm"
        variant="basic-alt"
      >
        {props.button.text}
      </StyledToastButton>
    )}
    <StyledIconButton
      onClick={props.onClose}
      onKeyPress={props.onClose}
      type="button"
      role="button"
      aria-label="Close"
      tabIndex="0"
    >
      <Icon variant="close" Events />
    </StyledIconButton>
  </StyledToast>
);

export default WillowToast;
