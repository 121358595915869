import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import LanguageProvider from 'containers/LanguageProvider';
import AuthProvider from 'containers/AuthProvider';
import { HelmetProvider, Helmet } from 'react-helmet-async';

export default function renderInBrowser({ messages, store, Routes, history }) {
  const helmetContext = {};

  if (process.env.NODE_ENV === 'production') {
    ReactDOM.hydrate(
      <Provider store={store}>
        <AuthProvider>
          <LanguageProvider messages={messages}>
            <ConnectedRouter history={history}>
              <HelmetProvider context={helmetContext}>
                <div>{renderRoutes(Routes)}</div>
              </HelmetProvider>
            </ConnectedRouter>
          </LanguageProvider>
        </AuthProvider>
      </Provider>,
      document.getElementById('app'),
    );
  } else {
    ReactDOM.render(
      // development
      <Provider store={store}>
        <AuthProvider>
          <LanguageProvider messages={messages}>
            <ConnectedRouter history={history}>
              <HelmetProvider context={helmetContext}>
                <div>{renderRoutes(Routes)}</div>
              </HelmetProvider>
            </ConnectedRouter>
          </LanguageProvider>
        </AuthProvider>
      </Provider>,
      document.getElementById('app'),
    );
  }
}
