/* eslint-disable no-continue */
/* eslint-disable for-direction */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable guard-for-in */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { API_ENPOINT_PREFIX } from 'containers/App/constants';
import firebase from 'firebase/app';
import 'firebase/auth'; // for authentication
import {
  makeSelectEnvVariablesData,
  makeSelectLocale,
  makeSelectLocation,
  makeSelectApi,
} from 'containers/App/selectors';
import { CHANGE_LOCALE } from 'containers/LanguageProvider/constants';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { wpurl } from 'utils/detection';
import request from 'utils/request';
import { makeSelectAuth } from 'containers/AuthProvider/selectors';
import { makeSelectUsers } from 'containers/App/Users/selectors';
import { SIGN_OUT_SUCCESS } from 'containers/AuthProvider/constants';
import { USER_LOADED } from 'containers/App/Users/constants';

import {
  registrationLoaded,
  toggleKycTab,
  setTabStatus,
  setToast,
  setPdf,
  setResponse,
  loadWpFinancialContent,
  loadWpFinancialContentSuccess,
  loadWpFinancialContentFailure,
  setAccountType,
} from './actions';
import {
  TOGGLE_KYC_TAB,
  CTA_BUTTON,
  STORE_RESPONSE,
  SET_ACCOUNT_TYPE,
  TAB_STATUS,
  SET_JSON_DATA,
  CLICKEDPLAID,
} from './constants';
import { makeSelectDashboardData } from './selectors';
import { reloadUser } from '../App/Users/actions';
import { notify } from '../App/actions';
import { authChanged, signOutSuccess } from '../AuthProvider/actions';

// function* getWpFinancialContent(API_URL) {
//   const API_URL_SINGLE_PROPERTY = `${API_URL}/getkycfinancial`;

//   const locale = yield select(makeSelectLocale());
//   const requestUrl = `${API_URL_SINGLE_PROPERTY}/${locale}`;
//   yield put(loadWpFinancialContent());
//   try {
//     const someData = yield call(request, requestUrl);
//     yield put(loadWpFinancialContentSuccess(someData));
//   } catch (e) {
//     yield put(loadWpFinancialContentFailure());
//   }
// }

const getStatus = (registration, data, auth) => {
  let status = {};
  if (registration.customerType === 'NONE' || registration.customerType === undefined) {
    status  = {
      choice: 0,
      gettingstarted: 0
    }
  } else {
    status  = {
      choice: 2,
      gettingstarted: 2
    }
  }
  
  let last = null;
  const stepList = data.steps.find(
    step => step.type === registration.customerType,
  );

  for (let index = 0; index < stepList.values.length; index++) {
    const element = stepList.values[index];

    // if the user has not verified their email
    // if (!auth.emailVerified) {
    //   status[element.step_keyname] = -1;
    //   continue;
    // }

    // if the registration is complete
    if (registration.status > 1) {
      status[element] = 2;
      continue;
    }

    // anything after the last actionable step should be locked
    if (last && index > last) {
      status[element] = -1;
      continue;
    }

    const current = registration[element];
    if (current) {
      status[element] = current.status;
    } else if (element === 'gettingstarted' || element === 'choice') {
      status[element] = 2;
    } else {
      status[element] = -1;
    }

    // if the step is not complete, make it as the last so all following steps will be locked
    if (status[element] < 2) {
      last = index;
    }
  }
  // if (status[data[data.length - 2].step_keyname] === 2) {
  //   status[data[data.length - 1].step_keyname] = 0;
  // }
  return status;
};

function* determineTabStatus() {
  const user = yield select(makeSelectUsers());
  const { registration, data } = yield select(makeSelectDashboardData());
  if (
    user.initialized &&
    user.current.isAuthenticated &&
    data &&
    registration.customerType !== 'NONE'
  ) {
    const auth = yield select(makeSelectAuth());
    const status = getStatus(registration, data, auth);
    yield put(setTabStatus(status));
  }
}

function* handleSave(selectedTab, formData) {
  const autocompleteService = { current: null };
  Object.keys(formData).map(item => {
    if (typeof formData[item] === 'string' && formData[item].includes('Yes')) {
      if (formData[item].includes('Yes,')) {
        return { [item]: formData[item] };
      }
      Object.assign(formData, { [item]: 'Yes' }); // This is needed to make value YesnotTaxResident -> Yes
    }
    return { [item]: formData[item] };
  });

  Object.keys(formData).map(item => {
    if (typeof formData[item] === 'string' && formData[item].includes('No')) {
      Object.assign(formData, { [item]: 'No' }); // This is needed to make value NoareyouPermittedInvestor -> No
    }
    return { [item]: formData[item] };
  });
  const requestUrl = `kyc/registration/${selectedTab}`;
  const additional = {};
  if (selectedTab === 'personal' || selectedTab === 'business' || selectedTab === 'tradeAuthority') {
    try {
      if (!autocompleteService.current && window.google) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
      }
      const addresses = yield new Promise((resolve, reject) => {
        autocompleteService.current.getPlacePredictions(
          {
            input: `${formData.residentialAddress.address} ${formData.residentialAddress.city} ${formData.residentialAddress.province}`,
          },
          resolve,
        );
      });
      additional.addressMatches = addresses.filter(e => e.terms.length === 5);
    } catch (error) {
      // fail silently
      // TODO: log wihtout going to console
    }
  }
  try {
    const api = yield select(makeSelectApi());
    yield api.post(requestUrl, { ...formData, ...additional, status: 1 });
    yield call(loadRegistration);
    yield call(determineTabStatus);
    yield put(toggleKycTab('gettingstarted', true));
  } catch (error) {
    if (error.length) {
      yield put(
        notify(
          'Failed to Save',
          error.map(e => e.message),
          'danger',
          true,
        ),
      );
    } else {
      console.log(error);
      yield put(setToast('Failed to Save, try again later!!'));
    }
    yield put(setToast(error.message));
  }
}

function* loadRegistration() {
  const user = yield select(makeSelectUsers());
  if (user.current.isAuthenticated) {
    try {
      const api = yield select(makeSelectApi());
      const result = yield api.get('kyc/registration/current');
      // TODO: Add any request modifications here
      if (result.personal && result.personal.dependants !== null) {
        result.personal.dependants = result.personal.dependants.toString(); // TODO: is this necessary coz wordpress has boolean True/False, I could be wrong
      }
      if (result.employment && result.employment.areYouPEPorHIO !== null) {
        result.employment.areYouPEPorHIO = result.employment.areYouPEPorHIO.toString(); // TODO: is this necessary coz wordpress has boolean True/False, I could be wrong
      }
      yield put(registrationLoaded(result));
      if (result.customerType !== undefined) {
        if (result.customerType === 'NONE') {
          yield put(toggleKycTab('choice', true));
        } else {
          yield put(toggleKycTab('gettingstarted', true));
        }
        yield put(setAccountType(result.customerType));
      }
      return result;
    } catch (error) {
      if(error.response.status === 401) {
        firebase.auth().signOut();
        yield put(authChanged(false, false));
        yield put(signOutSuccess());
        yield put(setToast('Make sure you are on the correct subdomain.'));
      } else {
        yield put(setToast('Server unable to respond, try again later!!'));
      }
    }
  }
}

function* handleAccept(selectedTab) {
  const requestUrl = `kyc/registration/${selectedTab}`;
  const { registration } = yield select(makeSelectDashboardData());

  try {
    const api = yield select(makeSelectApi());
    const response = yield api.post(requestUrl, {
      customerType: registration.customerType,
      status: 2
    });
    yield put(setResponse(response));
    yield call(determineTabStatus);
    yield call(loadRegistration);
    yield call(handleNext, selectedTab);
  } catch (error) {
    console.error(error);
    yield put(setToast('Server unable to respond, try again later!!'));
  }
}

function* handleIdentitiyVerification({ activatePlaid }) {
  console.log('activatePlaid: ', activatePlaid);
  const requestUrl = `kyc/registration/identityVerification`;
  const { registration } = yield select(makeSelectDashboardData());
    try {
      const api = yield select(makeSelectApi());
      const response = yield api.post(requestUrl, {
        customerType: registration.customerType,
        status: activatePlaid === 'success' ? 2 : 0,
      });
      yield put(setResponse(response));
      yield call(determineTabStatus);
      yield call(loadRegistration);
      yield put(reloadUser());
    } catch (error) {
      console.error(error);
      yield put(setToast('Server unable to respond, try again later!!'));
    }
}

function* postAccountType({ accountType }) {
  const { selectedTab } = yield select(makeSelectDashboardData());
  if (selectedTab === 'choice') {
    const requestUrl = `kyc/registration/${selectedTab}`;
    try {
      const api = yield select(makeSelectApi());
      const result = yield api.post(requestUrl, {
        customerType: accountType,
      });
      if (accountType === 'NONE') {
        yield put(toggleKycTab('choice', true));
      } else {
        yield put(toggleKycTab('gettingstarted', true));
      }
      yield put(registrationLoaded(result));
      yield call(determineTabStatus);
    } catch (error) {
      console.error(error);
      yield put(setToast('Server unable to respond, try again later!!'));
    }
  }
}

function* handleSubmit(selectedTab, formData) {
  const autocompleteService = { current: null };
  Object.keys(formData).map(item => {
    if (typeof formData[item] === 'string' && formData[item].includes('Yes')) {
      if (formData[item].includes('Yes,')) {
        return { [item]: formData[item] };
      }
      Object.assign(formData, { [item]: 'Yes' }); // This is needed to make value YesnotTaxResident -> Yes
    }
    return { [item]: formData[item] };
  });

  Object.keys(formData).map(item => {
    if (typeof formData[item] === 'string' && formData[item].includes('No')) {
      Object.assign(formData, { [item]: 'No' }); // This is needed to make value NoareyouPermittedInvestor -> No
    }
    return { [item]: formData[item] };
  });

  const requestUrl = `kyc/registration/${selectedTab}`;

  const additional = {};
  if (selectedTab === 'personal' || selectedTab === 'tradeAuthority') {
    try {
      if (!autocompleteService.current && window.google) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
      }
      const addresses = yield new Promise((resolve, reject) => {
        autocompleteService.current.getPlacePredictions(
          {
            input: `${formData.residentialAddress.address} ${formData.residentialAddress.city} ${formData.residentialAddress.province}`,
          },
          resolve,
        );
      });
      additional.addressMatches = addresses.filter(e => e.terms.length === 5);
    } catch (error) {
      // fail silently
      // TODO: log wihtout going to console
    }
  }
  if (selectedTab === 'documents') {
    const requestDocUrl = `documents/user/document`;
    try {
      const api = yield select(makeSelectApi());
      if (formData !== undefined && formData.legal_file !== undefined) {
        const formDataOp = new FormData();
        formDataOp.append('document', formData.legal_file[0]);
        formDataOp.append('fileName', formData.legal_file[0].name);
        formDataOp.append('locale', 'en');
        yield api.post(requestDocUrl, formDataOp);
      }
      yield call(loadRegistration);
      yield call(determineTabStatus);
      yield call(handleNext, selectedTab);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        yield put(
          notify(
            'Failed to Submit',
            'You can only upload PDF and PNG files',
            'danger',
            true,
          ),
        );
      }

      // TODO: handle error
      if (error.length) {
        yield put(
          notify(
            'Failed to Submit',
            error.map(e => e.message),
            'danger',
            true,
          ),
        );
      } else {
        console.log(error);
        yield put(setToast('Failed to load resource, try again later!'));
      }
    }
  }
  try {
    const api = yield select(makeSelectApi());
    yield api.post(requestUrl, { ...formData, ...additional, status: 2 });
    yield call(loadRegistration);
    yield call(determineTabStatus);
    yield call(handleNext, selectedTab);
  } catch (error) {
    // TODO: handle error
    if (error.length) {
      yield put(
        notify(
          'Failed to Submit',
          error.map(e => e.message),
          'danger',
          true,
        ),
      );
    } else {
      console.log(error);
      yield put(setToast('Failed to load resource, try again later!'));
    }
  }
}

function* handleNext(selectedTab) {
  const { registration } = yield select(makeSelectDashboardData());
  if (!registration) {
    return;
  }
  const { data } = yield select(makeSelectDashboardData());

  if (selectedTab !== 'identityVerification') {
    if (registration.status > 1) {
      yield put(reloadUser());
      yield put(toggleKycTab('gettingstarted', true));
      // yield put(push('portfolio'));
      return;
    }
    const stepList = data.steps.find(
      step => step.type === registration.customerType,
    );
    for (let index = 0; index < stepList.values.length; index++) {
      const element = stepList.values[index];
      const current = registration[element];
      if (selectedTab === element) {
        continue;
      }
      // if (element === 'agreements') {
      //   // agreements step from response comes as null, so we can't look for status
      //   yield put(toggleKycTab(element));
      //   return;
      // }

      if (current !== undefined && current.status < 2) {
        yield put(toggleKycTab(element));
        return;
      }
    }
    yield put(toggleKycTab('gettingstarted', true));
  }
}

function* handlePrevious() {
  const { selectedTab } = yield select(makeSelectDashboardData());
  if (selectedTab === 'gettingstarted') {
    yield put(toggleKycTab('choice'));
  } else {
    yield put(toggleKycTab('gettingstarted'));
  }
}

function* fetchPdf() {
  const user = yield select(makeSelectUsers());
  if (user.initialized && user.current.isAuthenticated) {
    const api = yield select(makeSelectApi());
    const requestUrl = api.getUrl('documents/user/documents/en/agreement.pdf');
    const idToken = yield firebase.auth().currentUser.getIdToken();
    try {
      yield put(
        setPdf({
          url: requestUrl,
          httpHeaders: {
            Authorization: `Bearer ${idToken}`,
          },
        }),
      );
    } catch (error) {
      if (error.length) {
        yield put(
          notify(
            'Failed to Submit',
            error.map(e => e.message),
            'danger',
            true,
          ),
        );
      } else {
        console.log(error);
        yield put(setToast('Failed to load resource, try again later!'));
      }
      yield put(setToast('Failed to load resource, try again later!'));
    }
  }
}

// function* onTabChange({ selectedTab }) {
//   if (selectedTab === 'gettingstarted') {
//     yield put(push('dashboard'));
//   } else {
//     yield put(push(`?step=${selectedTab}`));
//   }
// }

function* handleCtaButtons({ selectedTab, formData, kind }) {
  if (kind === 'save') {
    yield call(handleSave, selectedTab, formData);
  }
  if (kind === 'next') {
    if (formData) {
      yield call(handleSubmit, selectedTab, formData);
    } else {
      yield call(handleNext, selectedTab);
    }
  }
  if (kind === 'previous') {
    yield call(handlePrevious);
  }
  if (kind === 'accept') {
    yield call(handleAccept, selectedTab);
  }
}

// function* sendExperienceId({ experienceid }) {
//   const requestUrl = `kyc/document/verification/${experienceid.experienceTransactionId}`;
//   try {
//     const api = yield select(makeSelectApi());
//     const response = yield api.get(requestUrl);

//     if (response.status === 204) {
//       yield put(reloadUser()); // changes status to 2 redirect to portfolio
//       yield put(push('portfolio'));
//     }
//   } catch (error) {
//     if (error.length) {
//       yield put(
//         notify(
//           'Failed to Submit',
//           error.map(e => e.message),
//           'danger',
//           true,
//         ),
//       );
//     } else {
//       console.log(error);
//       yield put(setToast('Failed to load resource, try again later!'));
//     }
//     yield put(setToast('Failed to load resource, try again later!'));
//   }
// }

// function* setLocation() {
//   const location = yield select(makeSelectLocation());
//   const queryString = location.search;
//   const urlParams = new URLSearchParams(queryString);
//   const step = urlParams.get('step');
//   const { selectedTab, tabStatus } = yield select(makeSelectDashboardData());
//   if (step) {
//     yield put(toggleKycTab(step, true));
//   } else if (tabStatus.choice !== 2){
//     yield put(toggleKycTab('choice', true));
//   } else {
//     yield put(toggleKycTab('gettingstarted', true));
//   }
// }
function* getAuthRelatedContent() {
  yield call(fetchPdf);
  yield call(loadRegistration);
  yield call(determineTabStatus);
}
// function* setSelectedTabFromRegistration() {
//   const { tabStatus } = yield select(makeSelectDashboardData());
//   console.log('tabStatus: ', tabStatus);
//   // if (tabStatus.choice !== 2) {
//   //   yield put(toggleKycTab('choice', true));
//   // }
// }
export default function* dashboardSaga() {
  // const { variables } = yield select(makeSelectEnvVariablesData());
  // const API_URL = `${wpurl(variables)}/${API_ENPOINT_PREFIX}`; // wordpressURL
  // load user's kyc response from database
  // determine tab if there is param in the url
  // yield call(setLocation);
  yield put(setToast(false))
  const user = yield select(makeSelectUsers());
  // if (user.initialized && user.current.isAuthenticated && data) {
  //   yield call(determineTabStatus);
  // }
  if (user.initialized && user.current.isAuthenticated) {
    yield call(fetchPdf);
    yield call(loadRegistration);
  }
  // action handlers
  // yield takeLatest(
  //   [LOCATION_CHANGE, CHANGE_LOCALE],
  //   getWpFinancialContent,
  //   API_URL,
  // );
  yield takeLatest(LOCATION_CHANGE, reloadUser);
  // yield takeLatest([LOCATION_CHANGE, CHANGE_LOCALE], determineTabStatus);
  yield takeLatest([SET_JSON_DATA], determineTabStatus);
  yield takeLatest([SIGN_OUT_SUCCESS], determineTabStatus);
  // yield takeLatest([TAB_STATUS], setSelectedTabFromRegistration); // this is to update the step from choice to gettingstarted
  yield takeLatest(CTA_BUTTON, handleCtaButtons);
  // yield takeLatest(TOGGLE_KYC_TAB, onTabChange);
  yield takeLatest(TOGGLE_KYC_TAB, fetchPdf);
  yield takeLatest(USER_LOADED, getAuthRelatedContent);
  yield takeLatest(SET_ACCOUNT_TYPE, postAccountType);
  yield takeLatest(CLICKEDPLAID, handleIdentitiyVerification);
}
