import React, { useState, useEffect } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  Sector,
  ResponsiveContainer,
} from 'recharts';

const chartData = [
  {
    name: 'Toronto',
    properties: 10,
  },
  {
    name: 'Montreal',
    properties: 20,
  },
  {
    name: 'Vancouver',
    properties: 10,
  },
];

const colors = ['#c4c4c4', '#d3d3d3', '#a9a9a9'];

const Tip = ({ setShowTooltip, ...rest }) => {
  const [payload, setPayload] = useState(rest.payload);

  useEffect(() => {
    rest.payload.length && setPayload(rest.payload);
  }, [rest.payload]);

  return payload.length ? (
    <div
      onMouseLeave={() => setShowTooltip(false)}
      // Prevent Rechart events while the mouse is over the tooltip
      onMouseMove={e => e.stopPropagation()}
      className="bg-black p-3 text-center rounded"
    >
      <p className="h5 text-white">{payload[0].name}</p>
      <span className="h6 text-muted">{`${payload[0].payload.properties} Properties`}</span>
    </div>
  ) : null;
};

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      {/* <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        /> */}
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      {/* <defs>
          <filter x="0" y="0" width="1" height="1" id="solid">
            <feFlood flood-color="#000" result="bg" />
            <feMerge>
              <feMergeNode in="bg" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs> */}
      {/* <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          fill="#000"
          textAnchor={textAnchor}
          dominant-baseline="middle"
          // filter="url(#solid)"
        >
          {`${payload.payload.name}`}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={21}
          textAnchor={textAnchor}
          fill="#999"
          // filter="url(#solid)"
        >
          {`${payload.properties} properties`}
        </text> */}
    </g>
  );
};

// const renderCustomizedLabel = ({
//   cx,
//   cy,
//   midAngle,
//   innerRadius,
//   outerRadius,
//   value,
//   index,
// }) => {
//   console.log(value, 'handling label?');
//   const RADIAN = Math.PI / 180;
//   const radius = 25 + innerRadius + (outerRadius - innerRadius);
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <g fill="#000">
//       <text
//         x={x}
//         y={y}
//         fill="#8884d8"
//         textAnchor={x > cx ? 'start' : 'end'}
//         dominantBaseline="central"
//       >
//         {chartData[index].name}
//       </text>

//       <text
//         x={x}
//         y={y}
//         fill="#8884d8"
//         textAnchor={x > cx ? 'start' : 'end'}
//         dominantBaseline="central"
//       >
//         {value}
//       </text>
//     </g>
//   );
// };

const DefaultChart = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div>
      <ResponsiveContainer width="100%" height={200}>
        <PieChart
          width={300}
          height={300}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            outerRadius={90}
            innerRadius={58}
            fill="#8884d8"
            dataKey="properties"
            // label={renderActiveShape}
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>

          {showTooltip && (
            <Tooltip
              isAnimationActive={true}
              content={<Tip setShowTooltip={setShowTooltip} />}
              wrapperStyle={{ visibility: 'visible' }}
            />
          )}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DefaultChart;
