import {
  LOAD_DEFAULT_PAGE_DATA,
  LOAD_DEFAULT_PAGE_DATA_SUCCESS,
  LOAD_DEFAULT_PAGE_DATA_FAILURE,
  LOAD_ISSUER_DATA,
} from './constants';

export function loadDefaultPageData() {
  return {
    type: LOAD_DEFAULT_PAGE_DATA,
  };
}

export function loadDefaultPageDataSuccess(data) {
  return {
    type: LOAD_DEFAULT_PAGE_DATA_SUCCESS,
    data,
  };
}

export function loadDefaultPageDataFailure() {
  return {
    type: LOAD_DEFAULT_PAGE_DATA_FAILURE,
  };
}

export function loadIssuerData(issuer) {
  return {
    type: LOAD_ISSUER_DATA,
    issuer
  }
}