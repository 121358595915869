/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';
import styled, { css } from 'styled-components';
import { useSwipeable } from 'react-swipeable';

import { Col } from 'react-bootstrap';
import Icon from 'components/Icon';
import Button from 'components/Button';
import mediasizes from '../../utils/sizes';

const StyledLeft = styled(Col)`
  background: ${props => props.theme.colors.greys.white};
  padding-top: ${props => props.theme.spacing.xxxlarge};
  padding-left: 0;
  padding-right: 0;
  position: sticky;
  top: 0;
  height: 100vh;
  /* max-height: 100%; */

  display: flex;
  flex-direction: column;

  ${mediasizes.mobile`
		z-index: 100;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		max-width: initial;
		filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3));

		display: flex;
  		flex-direction: column;

		transition: transform 0.375s ease;
		transform: translate3d(-100%, 0, 0);
		${({ open }) =>
      open &&
      css`
        transform: translate3d(0, 0, 0);
      `}
	`};

  ${mediasizes.tablet`
		z-index: 100;
		position: fixed;
		top: 0;
		left: 0;
		width: 400px;
		height: 100vh;
		max-width: initial;
		filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3));
		
		display: flex;
  		flex-direction: column;
		
		transition: transform 0.375s ease;
		transform: translate3d(-100%, 0, 0);
		${({ open }) =>
      open &&
      css`
        transform: translate3d(0, 0, 0);
      `}
    `}

${mediasizes.desktop`
		z-index: 100;
		position: fixed;
		top: 0;
		left: 0;
		width: 400px;
		height: 100vh;
		max-width: initial;
		filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3));
		
		display: flex;
  		flex-direction: column;
		
		transition: transform 0.375s ease;
		transform: translate3d(-100%, 0, 0);
		${({ open }) =>
      open &&
      css`
        transform: translate3d(0, 0, 0);
      `}
    `}

  /* ${mediasizes.desktop`
		transition: none !important;
    `} */
`;

const StyledRight = styled(Col)`
  position: sticky;
  top: 0;
  height: 100%;

  display: flex;
  flex-direction: column;

  ${mediasizes.mobile`
		z-index: 200;

		position: fixed;
		top: 0;
		right: 0;
		width: 100%;
		max-width: initial;
		background: ${props => props.theme.colors.greys.white};
		padding-top: ${props => props.theme.spacing.xxxlarge};
		filter: drop-shadow(-5px -5px -5px rgba(0,0,0,0.3));
		
		transition: transform 0.375s ease;
		transform: translate3d(-100%, 0, 0);
		${({ open }) =>
      open &&
      css`
        transform: translate3d(0, 0, 0);
      `}

		padding-left: 0;
		padding-right: 0;
	`}
  ${mediasizes.tablet`
		transition: none !important;
    `}
`;

const Toggle = styled.button`
  background: ${props => props.theme.colors.greys.white};
  z-index: 3;
  position: fixed;
  top: 20px;
  right: 0;
  width: 60px;
  height: 60px;
  transform: translate(100%, 100%);
  border-radius: 0 50% 50% 0;
  cursor: pointer;
  display: block;

  outline: 0;
  border: 0;

  &.focus,
  &:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.colors.brand.xlight};
  }
`;

const Panel = ({ variant, isOpen, onToggle, showPanelClose, ...props }) => {
  // GESTURE HANDLERS
  const config = {
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: true, // call e.preventDefault *See Details*
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
  };
  const swipeHandler = useSwipeable({
    onSwipedLeft: e => {
      if (isOpen) onToggle();
    },
    ...config,
  });

  if (variant === 'left') {
    return (
      <StyledLeft md="3" open={isOpen} {...swipeHandler}>
        <div className="d-block d-md-none px-4 pb-2">
          <Button
            variant="basic"
            icon="chevronLeft"
            iconOnly="true"
            size="sm"
            className="my-2"
            onClick={onToggle}
            aria-label="Close Panel"
          ></Button>
        </div>
        <Toggle
          onClick={onToggle}
          aria-label="Toggle Side Panel"
          aria-pressed={isOpen}
        >
          {isOpen ? (
            <Icon variant="chevronLeft" className="my-3" />
          ) : (
            <Icon variant="search" className="my-3" />
          )}
        </Toggle>
        {props.children}
      </StyledLeft>
    );
  }

  return (
    <StyledRight open={isOpen} {...swipeHandler}>
      {showPanelClose && (
        <div className="d-block d-md-none px-3 bg-white">
          <Button
            variant="basic"
            icon="chevronLeft"
            iconOnly="true"
            size="sm"
            className="mt-3"
            onClick={onToggle}
            aria-label="Close Panel"
          >
            Test
          </Button>
        </div>
      )}
      {props.children}
    </StyledRight>
  );
};

export default Panel;
