/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Card, Container } from 'react-bootstrap';
import moment from 'moment-timezone';
import CardPriceTrend from './CardPriceTrend';
import CardPriceFooter from './CardPriceFooter';
import mediasizes from '../../utils/sizes';
import { formatters } from '../../utils/formatters';

// Kicker
const StyledCardPriceWrapper = styled(Container)`
  ${({ theme }) => css`
    padding: ${theme.spacing.none};
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      padding-left: ${theme.spacing.xxsmall};
    `}
`;

// Kicker
const StyledCardKicker = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.typography.size.xxsmall};
    color: ${theme.colors.greys.dark};
    line-height: ${theme.typography.lineHeight.medium};
  `}
`;

// Heading
const StyledCardHeading = styled(Card.Title)`
  ${({ theme }) => css`
    font-size: ${theme.typography.size.large};
    color: ${theme.colors.greys.xdark};
    line-height: ${theme.typography.lineHeight.medium};
    margin-top: ${theme.spacing.xxxsmall};
    margin-bottom: ${theme.spacing.none};
    ${mediasizes.mobile`
      font-size: ${theme.typography.size.medium};
    `}
  `}
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      font-size: ${theme.typography.size.small};
      line-height: ${theme.typography.lineHeight.small};
    `}
`;

const StyledCardPriceNameContainer = styled(Container)`
  ${({ theme }) => css`
    padding: ${theme.spacing.none};
  `}
  ${({ size }) =>
    size === 'sm' &&
    css`
      display: none;
    `}
`;

const StyledCardPriceName = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.xxsmall};
    font-size: ${theme.typography.size.medium};
    color: ${theme.colors.greys.dark};
    ${mediasizes.mobile`
      font-size: ${theme.typography.size.medium};
    `}
  `}
`;

const StyledCardPriceRate = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.size.medium};
    color: ${theme.colors.greys.xdark};
    ${mediasizes.mobile`
      font-size: ${theme.typography.size.medium};
    `}
  `}
`;

const WillowCardPrice = props => {
  const {
    size,
    kicker,
    heading,
    symbol,
    propertyValue,
    showTrend,
    trendPrice = 0,
    trendPercentage = 0,
    totalInvestedAmount,
    closingDate,
    pricePerShare,
    daysleftlabel,
    investedlabel,
    symbolcs,
    locale,
  } = props;

  const iconVariant = trendPercentage >= 0 ? 'tickUp' : 'tickDown';
  const formattedInvested = totalInvestedAmount
    ? new Intl.NumberFormat().format(totalInvestedAmount)
    : '';
  const formattedTrendPrice = trendPrice.toFixed(2);
  const formattedTrendPercentage = trendPercentage.toFixed(2);

  const closeDate = moment
    .tz(closingDate, 'America/New_York')
    .format('YYYY-MM-DD HH:mm:ss');

  const daysLeft = moment(closeDate).diff(moment(), 'days');

  return (
    <StyledCardPriceWrapper size={size}>
      <StyledCardKicker>{kicker}</StyledCardKicker>
      <StyledCardHeading size={size}>{heading}</StyledCardHeading>
      <StyledCardPriceNameContainer size={size}>
        <StyledCardPriceName>{symbol ? symbol : symbolcs}</StyledCardPriceName>
        <StyledCardPriceRate>
          {pricePerShare ? formatters.currency(pricePerShare) : ''}
        </StyledCardPriceRate>
      </StyledCardPriceNameContainer>
      {size === 'sm' || showTrend ? (
        <CardPriceTrend
          iconVariant={iconVariant}
          sizeVariant={size}
          price={formattedTrendPrice}
          percentage={formattedTrendPercentage}
        />
      ) : (
        <CardPriceFooter
          daysLeft={daysLeft ? daysLeft : ''}
          invested={formattedInvested}
          daysleftlabel={daysleftlabel}
          investedlabel={investedlabel}
          locale={locale}
        />
      )}
    </StyledCardPriceWrapper>
  );
};

export default WillowCardPrice;
