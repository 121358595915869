/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

import Slider from 'react-slick';
import Card from 'components/Card';
import { Col, Placeholder, Container, Row } from 'react-bootstrap';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Flag from 'components/Flag';
import { segmentTrack } from 'utils/segment';
import { formatters } from '../../utils/formatters';

const StyledContainer = styled.div`
  .slick-slide {
    padding-right: 15px;
  }
  margin-bottom: 10px;
  @media (max-width: 576px) {
    width: 100%;
    margin-left: 0px;
  }
  .slick-arrow {
    position: absolute;
    left: 79%;
    top: -56px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: white;
    border: 1px solid #bcbcbc;
    padding: 0;
    color: #bcbcbc;
    text-align: center;

    &.slick-prev {
      transform: translateX(-50%) translateX(-40px);
      &:after {
        transform: translateX(-50%) translateX(-2px) translateY(-50%);
      }
      &:before {
        color: #191919;
        font-size: 16px;
        content: '';
      }
    }
    &.slick-next {
      transform: translateX(-50%);
      &:after {
        transform: translateX(-50%) translateX(2px) translateY(-50%);
      }
      &:before {
        color: #191919;
        font-size: 16px;
        content: '';
      }
    }
  }
`;
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
    >
      <Icon variant="chevronRight" color="#191919" />
    </div>
  );
}
function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
    >
      <Icon variant="chevronLeft" color="#191919" />
    </div>
  );
}

const Carousel = props => {
  const propertyclickEvent = props.events;

  const combinedData =
    props.data &&
    props.data.map(item => ({
      ...item,
      ...{
        ...item,
        ownership:
          props.ownership &&
          props.ownership
            .filter(o => o.symbol === item.symbol)
            .map(p => p.ownership)[0],
      },
    }));

  const ipoData =
    combinedData &&
    combinedData.filter(
      item => item.state === 'IPO_ACQUIRED' || item.state === 'COMING_SOON',
    );

  let settings = {
    dots: false,
    arrows: false,
    variableWidth: true,
  };
  if (combinedData) {
    if (ipoData.length > 4) {
      settings = {
        // rtl: true,
        dots: false,
        slide: '.slick-slideshow__center',
        slidesToShow: 3,
        centerPadding: '60px',
        variableWidth: true,
        speed: 500,
        infinite: true,
        initialSlide: 0,
        slidesToScroll: 1,
        swipeToSlide: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
          {
            breakpoint: 1265,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1030,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              centerPadding: '0px',
              dots: true,
              arrows: false,
            },
          },
        ],
      };
    }
    if (ipoData.length < 4) {
      settings = {
        // rtl: true,
        dots: false,
        slide: '.slick-slideshow__center',
        slidesToShow: 3,
        centerPadding: '60px',
        variableWidth: true,
        speed: 500,
        infinite: false,
        initialSlide: 0,
        slidesToScroll: 1,
        swipeToSlide: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
          {
            breakpoint: 1265,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1030,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              centerPadding: '0px',
              dots: true,
              arrows: false,
            },
          },
        ],
      };
    }
  }

  if (props.data) {
    return (
      <StyledContainer {...props}>
        {combinedData && (
          <Slider {...settings}>
            {combinedData
              .sort((a, b) =>
                a.name.localeCompare(b.name, 'en', {
                  numeric: true,
                  sensitivity: 'base',
                }),
              )
              .map(i => (
                <div className="slick-slideshow__center" key={i.id}>
                  <Link
                    to={`/${props.locale}/trade/${i.symbol}`}
                    className="text-decoration-none"
                    style={
                      i.state !== 'IPO' && i.state !== 'IPO_ACQUIRED'
                        ? { pointerEvents: 'none' }
                        : null
                    }
                    onClick={() => {
                      // segmentTrack(
                      //   propertyclickEvent &&
                      //     propertyclickEvent.map(p =>
                      //       p.name === 'Property - [Symbol]'
                      //         ? {
                      //           ...p,
                      //           name: `Property - ${i.symbol} Clicked`,
                      //         }
                      //         : p,
                      //     ),
                      // );
                    }}
                  >
                    <Card width="100%" size="lg" tabIndex="0" className="ms-1">
                      <Card.Content>
                        <div className="position-relative">
                          <Card.Img
                            src={`${
                              (
                                i.images.filter(
                                  e => e.category === 'main',
                                )[0] || i.images[0]
                              ).url
                            }?w=286&h=170&fit=crop`}
                            alt="property"
                            className="img-fluid"
                          />
                          <Flag
                            className=" bg-primary position-absolute text-black opacity-75 fw-bold top-5 end-5"
                            variant="primary"
                          >
                            {/* {i.state === 'IPO' ||
                            i.state === 'IPO_ACQUIRED' ||
                            i.state === 'COMING_SOON'
                              ? props.primaryOfferingLabel
                              : props.secondaryOfferingLabel} */}
                            {props.primaryOfferingLabel}
                          </Flag>
                          {typeof i.ownership !== 'undefined' && (
                            <Flag
                              className="position-absolute text-black bottom-5 end-5 text-white fw-bold"
                              variant="primary"
                            >
                              {`${formatters.percent(i.ownership)} ${
                                props.ownedLabel
                              }`}
                            </Flag>
                          )}
                        </div>

                        <Card.Body>
                          <Container className="px-0">
                            <Row>
                              <Col xs={8} className="pe-0">
                                <p className="mb-0 small text-greys-dark">
                                  {i.location}
                                </p>
                                <p className="mb-0 text-black h5 v-10">
                                  {i.name}
                                </p>
                                <Card.Price
                                  {...i.ipoDetails}
                                  daysleftlabel={
                                    i.state === 'IPO' ? props.daysLeftLabel : ''
                                  }
                                  investedlabel={
                                    i.state === 'IPO' ? props.investedLabel : ''
                                  }
                                  symbolcs={i.symbol}
                                  locale={props.locale}
                                />
                              </Col>
                              <Col xs={4} className="align-items-end">
                                {i.state === 'IPO' ? (
                                  <>
                                    <Card.ProgressBar
                                      now={
                                        i.ipoDetails &&
                                        i.ipoDetails.reservedPercentage
                                      }
                                      tag={`${props.reserved}`}
                                      aria-label="progress-bar"
                                      className="bg-primary"
                                    />
                                  </>
                                ) : i.state === 'IPO_ACQUIRED' ? (
                                  <>
                                    <div className="position-relative h-100 w-100 d-flex justify-content-end align-items-center">
                                      <img
                                        className="float-end"
                                        alt="ipo_acquired"
                                        src="https://willow.imgix.net/2022/04/ipoacquiredh_small.png?w=87&h=116"
                                      />
                                      <div className="position-absolute d-flex align-items-center top-50 z-index-2">
                                        <Icon
                                          variant="Verified"
                                          color="#fff"
                                          size="xlarge"
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <h5 className="pt-1">
                                    <br />
                                    <br />
                                  </h5>
                                )}
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card.Content>
                    </Card>
                  </Link>
                </div>
              ))}
          </Slider>
        )}
      </StyledContainer>
    );
  }

  return (
    <StyledContainer {...props}>
      <Slider {...settings} className="w-100">
        {[...Array(3)].map((card, inx) => (
          <div style={{ width: 300 }} key={`LoaderColumn${inx}`}>
            <div className="pe-3">
              <Card width="100%" size="lg" tabIndex="0">
                <Card.Img
                  variant="top"
                  src="https://willow.imgix.net/2022/01/Article-Card.png?fm=png&ixlib=php-3.3.0"
                />
                <Card.Body loading="true">
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                  <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={7} /> <Placeholder xs={4} />{' '}
                    <Placeholder xs={4} /> <Placeholder xs={6} />{' '}
                    <Placeholder xs={8} />
                  </Placeholder>
                </Card.Body>
              </Card>
            </div>
          </div>
        ))}
      </Slider>
    </StyledContainer>
  );
};

export default Carousel;
