export const LOAD_ACCOUNT_LAYOUT = 'app/AccountPage/LOAD_ACCOUNT_LAYOUT';
export const LOAD_ACCOUNT_LAYOUT_SUCCESS =
  'app/AccountPage/LOAD_ACCOUNT_LAYOUT_SUCCESS';
export const LOAD_ACCOUNT_LAYOUT_FAILURE =
  'app/AccountPage/LOAD_ACCOUNT_LAYOUT_FAILURE';

export const TOGGLE_VERIFICATION_MODAL =
  'app/AccountPage/TOGGLE_VERIFICATION_MODAL';
export const TOGGLE_CREDENTIALS_MODAL =
  'app/AccountPage/TOGGLE_CREDENTIALS_MODAL';

export const STORE_FORM_DATA = 'boilerlplate/AccountPage/STORE_FORM_DATA';
export const SET_TAB = 'app/AccountPage/SET_TAB';
export const TOGGLE_CAPSULE = 'app/AccountPage/TOGGLE_CAPSULE';
export const SET_ERROR_MESSAGE = 'app/AccountPage/SET_ERROR_MESSAGE';
export const SET_MODAL_ERROR_MESSAGE =
  'app/AccountPage/SET_MODAL_ERROR_MESSAGE';

export const CONNECT_ACCOUNTS = 'app/AccountPage/CONNECT_ACCOUNTS';
export const REMOVE_ACCOUNT = 'app/AccountPage/REMOVE_ACCOUNT';

export const SET_TOAST = 'app/AccountPage/SET_TOAST';
export const SET_FLINKS_MOODAL = 'app/AccountPage/SET_FLINKS_MOODAL';
export const SET_FLINKS_LOADER = 'app/AccountPage/SET_FLINKS_LOADER';
export const SET_TRANSFER_FUNDS_LOADER =
  'app/AccountPage/SET_TRANSFER_FUNDS_LOADER';

export const TOGGLE_SIDEPANEL = 'app/AccountPage/TOGGLE_SIDEPANEL';
export const TOGGLE_DOWNLOAD = 'app/AccountPage/TOGGLE_DOWNLOAD';
export const LOAD_USER_DOCUMENTS = 'boilerlplate/Tradeview/LOAD_USER_DOCUMENTS';
export const SET_INITIALVALUES = 'boilerlplate/Tradeview/SET_INITIALVALUES';
export const SET_CUSTOMER_TYPE = 'boilerlplate/Tradeview/SET_CUSTOMER_TYPE';
