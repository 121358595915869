/* eslint-disable prefer-destructuring */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  TOAST,
  SET_PARAMS,
  TOGGLE_VERIFICATION_MODAL,
  LOAD_AUTHPAGE_CONTENT,
  LOAD_AUTHPAGE_CONTENT_SUCCESS,
  LOAD_AUTHPAGE_CONTENT_FAILURE,
  FORM_LOADER,
  SET_LOADER
} from './constants';

export const initialState = {
  loading: false,
  error: false,
  data: false,
  params: {},
  toast: false,
  verificationModal: false,
  formLoader: false,
};

const authPageReducer = produce((draft, action) => {
  switch (action.type) {
    case FORM_LOADER:
      draft.formLoader = action.formLoader;
      break;
    case LOAD_AUTHPAGE_CONTENT:
      draft.loading = true;
      // draft.data = false;
      draft.error = false;
      break;
    case LOAD_AUTHPAGE_CONTENT_SUCCESS:
      draft.loading = false;
      draft.data = action.data;
      draft.error = false;
      break;
    case LOAD_AUTHPAGE_CONTENT_FAILURE:
      draft.loading = false;
      draft.data = false;
      draft.error = true;
      break;
    case TOGGLE_VERIFICATION_MODAL:
      draft.verificationModal = action.verificationModal;
      break;
    case SET_PARAMS:
      draft.params = action.params;
      break;
    case TOAST:
      draft.toast = action.toast;
      break;
    case SET_LOADER:
      draft.loading = action.loading;
      break;
  }
}, initialState);

export default authPageReducer;
