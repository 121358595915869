/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Card, Container } from 'react-bootstrap';
import Placeholder from 'react-bootstrap/Placeholder';
import Flag from '../Flag';
import CardProgressBar from './CardProgressBar';
import CardPrice from './CardPrice';
import mediasizes from '../../utils/sizes';
const ChildrenWithProps = props => {
  const elements = React.Children.toArray(props.children).map(element =>
    React.cloneElement(element, {
      size: props.size,
    }),
  );
  return <>{elements}</>;
};

const StyledCard = styled(
  ({ size, width, hover, rotate, loading, fullMobile, ...rest }) => <Card {...rest} />,
)`
  ${({ theme, width }) =>
    css`
      overflow: hidden;
      z-index: 1;
      will-change: transform;
      transform: translateZ(0);
      transition: all ${theme.durations.fast} ease-in-out;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;

      width: ${width};
      border-width: 3px;
      border-radius: ${theme.shapes.borderRadiusMedium};
      border-color: transparent;

      background-color: ${theme.colors.greys.white};
      

      .card-image {
        width: 100%;
        height: 278px;
        object-fit: cover;
      }
      .card-container {
        margin: 30px 10px;
        height: 485px;
      }
      /* &::before {
        pointer-events: none;
        content: "";
        position: absolute;
        opacity: 0;
        width: calc(100% + ${theme.spacing.xxsmall} * 2);
        height: calc(100% + ${theme.spacing.xxsmall} * 2);
        top: -${theme.spacing.xxsmall};
        left: -${theme.spacing.xxsmall};
        right: 0;
        bottom: 0;
        border-radius: ${theme.shapes.borderRadiusMedium};
        box-shadow: 0 0 0 2px ${theme.colors.brand.xlight};
        background-color: transparent !important;
        transition: all ${theme.durations.fast};
      } */

      /* 
      &:focus {
        &::before {
          opacity: 1;
        }
      }

      &.focus,
      &:focus {
        outline: none;
        box-shadow: ${theme.depths['0']} !important;
      }

      &:focus:not(:focus-visible) {
        &::before {
          opacity: 0;
        }
      }
      */

      &.focus,
      &:focus {
        outline: none !important;
        box-shadow: 0 0 0 3px ${theme.colors.brand.xlight};
      }
    `}

  ${({ size, theme }) =>
    size !== 'sm' &&
    css`
      border-width: 0;
    `}

  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      flex-direction: row;
      padding: ${theme.spacing.xxsmall};
      width: 100%;
      border-width: 2px;
      &:hover {
        background-color: ${theme.colors.greys.light};
      }
      &:active {
        border-color: ${theme.colors.brand.xlight};
      }
      &.focus,
      &:focus {
        box-shadow: 0 0 0 2px ${theme.colors.brand.xlight};
      }
      ${mediasizes.mobile`
        border-radius: 12px !important;
      `}
    `}

  ${({ theme, hover, rotate }) =>
    hover &&
    css`
      &:hover {
        transform: ${rotate ? 'rotate(-2deg)' : 'rotate(0)'};
        box-shadow: ${theme.depths['2']};
      }
      ${mediasizes.mobile`
        &:hover {
          transform: none;
        }
      `}
    `}

  ${({ fullMobile }) =>
    fullMobile &&
    css`
      ${mediasizes.mobile`
        max-width: none;
        border-radius: 0;
      `}
    `}
`;

const StyledCardContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  `}

  ${({ size }) =>
    size === 'sm' &&
    css`
      flex-direction: row;
    `}
`;

const CardContent = props => (
  <StyledCardContent size={props.size}>
    <ChildrenWithProps {...props} />
  </StyledCardContent>
);

const WillowCard = props => (
  <StyledCard {...props}>
    <ChildrenWithProps {...props} />
  </StyledCard>
);

// Image
const StyledCardImgOuter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      width: calc(100% / 3);
    `}
`;

const StyledCardImgInner = styled.div`
  ${({ theme }) => css`
    height: 100%;
    width: 100%;
    border-top-left-radius: ${theme.shapes.borderRadiusMedium};
    border-top-right-radius: ${theme.shapes.borderRadiusMedium};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /* padding-top: 64%; */

    position: relative;
    overflow: hidden;
    ${mediasizes.mobile`
      border-top-left-radius: 0;
      border-top-right-radius:0;
    `}
  `}

  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      padding-top: 100%;
      border-radius: 12px !important;
      height: 0;
    `}
`;

const StyledCardImg = styled(Card.Img)`
  ${({ theme }) => css`
    width: 100%;
    height: 12vw;
    object-fit: cover;
    max-width: 100%;
    /* border-radius: 0; */
    border-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    ${mediasizes.mobile`
      height: 100%;
    `}
    ${mediasizes.tablet`
      height: 25vw;
    `}
  `}
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      object-fit: cover;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      width: auto;
      height: auto;
    `}
`;

const CardImg = props => (
  <StyledCardImgOuter size={props.size}>
    <StyledCardImgInner size={props.size}>
      <StyledCardImg {...props} />
    </StyledCardImgInner>
  </StyledCardImgOuter>
);

// Flag
const StyledCardFlag = styled(Flag)`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.medium};
    right: ${theme.spacing.medium};
    ${mediasizes.mobile`
      top: ${theme.spacing.xsmall};
      right: ${theme.spacing.xsmall};
    `}
  `}
`;

// Layout Body
const StyledCardBodyWrapper = styled.div`
  width: 100%;
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      padding-left: ${theme.spacing.xxsmall};
      width: 66.67%;
    `}
`;
const StyledCardBody = styled(Card.Body)`
  ${({ theme, loading, height }) =>
    loading === 'true'
      ? css`
          width: 100%;
          display: block;
          ${mediasizes.extraextralarge`
    height: ${height || '185px'};
  `}
        `
      : css`
          width: 100%;
          display: flex;
          ${mediasizes.extraextralarge`
    height: ${height || '185px'};
  `}
        `}
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      align-items: center;
      height: 100%;
      margin-top: auto;
      margin-bottom: auto;
      padding: ${theme.spacing.none};
    `}
`;

const CardBody = props => (
  <StyledCardBodyWrapper size={props.size}>
    <StyledCardBody
      size={props.size}
      className={props.className}
      loading={props.loading}
      rotate={props.rotate}
      height={props.height}
    >
      <ChildrenWithProps {...props} />
    </StyledCardBody>
  </StyledCardBodyWrapper>
);

// Layout: Body Left
const CardBodyLeft = props => (
  <Container className={`${props.className ? props.className : 'w-35 px-0'}`} >{props.children}</Container>
);

// Layout: Body Right
const CardBodyRight = props => (
  <Container className={`${props.className ? props.className : 'w-35 px-0'}`} >{props.children}</Container>
);

// Replacing the existing Bootstrap
WillowCard.Img = CardImg;
WillowCard.Body = CardBody;
WillowCard.Title = Card.Title;
WillowCard.Subtitle = Card.Subtitle;
WillowCard.Text = Card.Text;

// Extensions
WillowCard.Content = CardContent;
WillowCard.Flag = StyledCardFlag;
WillowCard.Price = CardPrice;
WillowCard.ProgressBar = CardProgressBar;
WillowCard.Body.Left = CardBodyLeft;
WillowCard.Body.Right = CardBodyRight;

export default WillowCard;
