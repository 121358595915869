/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Accordion from 'components/Accordion';
import ListLayout from 'components/List/ListLayout';
import Filter from 'components/Filter';
import Icon from 'components/Icon';
import { formatters } from '../../../../utils/formatters';
import { uid } from '../../../../utils/ui';
import { isClientMobile, isClientTablet } from '../../../../utils/detection';

const TradeviewAccordion = ({
  data,
  symbol,
  propertyDetail,
  toggleCapsule,
  activeCapsule,
  propertyContent,
  holdings,
  locale,
  userDocuments,
  authenticationStatus,
  toggleDownload,
  clickDocument
}) => {

  const isMobile = isClientMobile();
  const isTablet = isClientTablet();

  const dwellingInfo = propertyDetail && propertyDetail.details;

  const holdingInfo = holdings && holdings[0];

  const documentsList = authenticationStatus && userDocuments;

  const propType = propertyDetail && propertyDetail.propertyType;

  // const hasVideo =
  //   propertyDetail && propertyDetail.images.filter(i => i.category === 'video');

  // if (
  //   propertyContent &&
  //   propertyContent.caardd &&
  //   propertyContent.caardd.notFound
  // ) {
  //   return (
  //     <>
  //       <div>
  //         <Icon variant="error" className="d-inline-block m-0" /> No content
  //         found, please check if you are connected to the right environment
  //       </div>
  //     </>
  //   );
  // }
  return (
    <>
      <Accordion>
        {data &&
          data.map(item => {
            return (
              <Accordion.Item key={item.title}>
                <Accordion.Toggle eventKey={item.title}>
                  {item.title}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={item.title}>
                  {/* {item.hasvideo && symbol === 'QSWT' && (
                    // <video controls className="w-100 px-0 py-4">
                    //   <source
                    //     src={hasVideo && hasVideo[0].url}
                    //     type="video/mp4"
                    //   />
                    // </video>

                    //3d videos
                    <>
                      <div className="px-0 py-4 embed-responsive embed-responsive-21by9">
                        {isMobile ? (
                          <iframe
                            className="embed-responsive-item overflow-hidden w-100"
                            height="200"
                            src="https://3d.gryd.com/Show/8ssuxLjbjoa"
                            frameborder="0"
                            allowfullscreen
                            allow="xr-spatial-tracking"
                          ></iframe>
                        ) : isTablet ? (
                          <iframe
                            className="embed-responsive-item overflow-hidden w-100"
                            height="290"
                            src="https://3d.gryd.com/Show/8ssuxLjbjoa"
                            frameborder="0"
                            allowfullscreen
                            allow="xr-spatial-tracking"
                          ></iframe>
                        ) : (
                          <iframe
                            className="embed-responsive-item overflow-hidden w-100"
                            height="425"
                            src="https://3d.gryd.com/Show/8ssuxLjbjoa"
                            frameborder="0"
                            allowfullscreen
                            allow="xr-spatial-tracking"
                          ></iframe>
                        )}
                      </div>

                      <ul>
                        <li>
                          <a
                            href="https://3d.gryd.com/Show/QoinFndoWay"
                            target="_blank"
                            className="text-decoration-none text-primary"
                          >
                            Virtual Tours - Suite 1
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://3d.gryd.com/Show/xhNDazscS1L"
                            target="_blank"
                            className="text-decoration-none text-primary"
                          >
                            Virtual Tours - Suite 2
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://3d.gryd.com/Show/8ssuxLjbjoa"
                            target="_blank"
                            className="text-decoration-none text-primary"
                          >
                            Virtual Tours - Suite 3
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://3d.gryd.com/Show/epJDdTm3t9E"
                            target="_blank"
                            className="text-decoration-none text-primary"
                          >
                            Virtual Tours - Suite 4
                          </a>
                        </li>
                      </ul>
                    </>
                  )} */}

                  {/* {item.haslist && (
                    <ListLayout
                      rawdata={[
                        {
                          columns: [
                            ...item.listData.map((list, i) => {
                              return {
                                content: '',
                                id: `accordianSection${i}`,
                                widths: { sm: '12', md: '6' },
                                rows: [
                                  ...list.columns
                                    .filter(item =>
                                      item.propertyType.includes(
                                        propType && propType.toLowerCase(),
                                      ),
                                    )
                                    .map(col => {
                                      const format =
                                        formatters[col.format] ||
                                        formatters.none;
                                      return {
                                        hr: true,
                                        condensed: true,
                                        columns: [
                                          {
                                            id: `accordianSubSection${uid()}`,
                                            content: col.label,
                                            widths: { xs: '7' },
                                            classes: 'text-start text-black',
                                          },
                                          {
                                            id: `accordianSubSection${uid()}`,
                                            content: list.hasdwelling
                                              ? format(
                                                  dwellingInfo &&
                                                    dwellingInfo[col.id],
                                                )
                                              : list.hasholdings
                                              ? format(
                                                  (holdingInfo &&
                                                    holdingInfo[col.id]) ||
                                                    0,
                                                )
                                              : null,
                                            classes: 'text-end',
                                          },
                                        ],
                                      };
                                    }),
                                ],
                              };
                            }),
                          ],
                        },
                      ]}
                    />
                  )} */}

                  {( item.title === 'Documents' &&
                    <ListLayout
                      rawdata={
                        documentsList && [
                          ...documentsList
                            .filter(doc => doc.locale === locale)
                            .map(pinfo => ({
                              columns: [
                                {
                                  content: '',
                                  rows: [
                                    {
                                      columns: [
                                        {
                                          widths: {
                                            xs: 6,
                                            md: 8,
                                          },
                                          content: (
                                            <>
                                              <span className="mb-1 text-black p">
                                                {pinfo && pinfo.title}
                                              </span>
                                            </>
                                          ),
                                        },
                                        {
                                          widths: {
                                            xs: 6,
                                            md: 4,
                                          },
                                          content: (
                                            <>
                                              <span className="mb-1 text-greys-dark d-flex justify-content-end p">
                                                <div
                                                style={{cursor: "pointer"}}
                                                  // href={pinfo && pinfo.sourcePath}
                                                  className="pe-2 text-xdark pointer-click"
                                                  // target="_blank"
                                                  onClick={() => {toggleDownload(pinfo); clickDocument(pinfo);}}
                                                >
                                                  Download pdf
                                                </div>{' '}
                                                <Icon
                                                  variant="download"
                                                  color="#1F5728"
                                                />
                                              </span>
                                            </>
                                          ),
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            })),
                        ]
                      }
                    />
                  )}

                    <div className="py-3">
                      {item.hasImage && propertyContent && typeof propertyContent.images !== 'undefined' && 
                      
                      <img
                        src={
                          (propertyContent && 
                            propertyContent.images.filter(e =>
                              /neigh/i.test(e.category),
                            )[0] ||
                            propertyContent && propertyContent.images[
                              propertyContent.images.length - 1
                            ]
                          ).url
                        }
                        alt="Property Image"
                        className="img-fluid rounded"
                      />}
                      

                      {/* <p
                        className="py-3"
                        dangerouslySetInnerHTML={{
                          __html:
                            propertyDetail && propertyDetail.profile.intro,
                        }}
                      /> */}

                      {/* <Filter
                        options={item.capsule && item.capsule}
                        defaultKey={firstItem}
                        activeKey={activeCapsule}
                        onSelect={v => toggleCapsule(v)}
                        className="w-100 pt-3"
                      /> */}

                      {/* <p className="py-3">
                        {propertyDetail.profile[activeCapsule]}
                      </p> */}
                    </div>
                

                  {item.description && (
                    <>
                      <p
                        className="pt-3 pb-0"
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                    </>
                  )}
                </Accordion.Collapse>
              </Accordion.Item>
            );
          })}
      </Accordion>
    </>
  );
};

export default TradeviewAccordion;
