import React, { useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Bar,
  Line,
  LineChart,
  ResponsiveContainer,
} from 'recharts';
import './allcharts.scss';

const chartData = [
  { date: ' 01-Dec-21 ', price: 50, volume: 5 },
  { date: ' 02-Dec-21 ', price: 50.04, volume: 7 },
  { date: ' 03-Dec-21 ', price: 50.06, volume: 3 },
  { date: ' 04-Dec-21 ', price: 50.2, volume: 6 },
  { date: ' 05-Dec-21 ', price: 50.1, volume: 7 },
  { date: ' 07-Dec-21 ', price: 50.98, volume: 8 },
  { date: ' 08-Dec-21 ', price: 53, volume: 4 },
  { date: ' 09-Dec-21 ', price: 54, volume: 2 },
  { date: ' 10-Dec-21 ', price: 55, volume: 7 },
  { date: ' 11-Dec-21 ', price: 51, volume: 10 },
  { date: ' 13-Dec-21 ', price: 51, volume: 55 },
  { date: ' 14-Dec-21 ', price: 51, volume: 33 },
  { date: ' 15-Dec-21 ', price: 49, volume: 22 },
  { date: ' 16-Dec-21 ', price: 48, volume: 6 },
  { date: ' 17-Dec-21 ', price: 48.2, volume: 88 },
  { date: ' 19-Dec-21 ', price: 48.8, volume: 4 },
  { date: ' 20-Dec-21 ', price: 53, volume: 120 },
  { date: ' 21-Dec-21 ', price: 56, volume: 140 },
  { date: ' 22-Dec-21 ', price: 53, volume: 200 },
  { date: ' 23-Dec-21 ', price: 52, volume: 4 },
  { date: ' 25-Dec-21 ', price: 50, volume: 2 },
  { date: ' 26-Dec-21 ', price: 57, volume: 55 },
  { date: ' 27-Dec-21 ', price: 56, volume: 43 },
  { date: ' 28-Dec-21 ', price: 56, volume: 34 },
  { date: ' 29-Dec-21 ', price: 56, volume: 10 },
  { date: ' 31-Dec-21 ', price: 56, volume: 55 },
  { date: ' 01-Jan-22 ', price: 56.2, volume: 33 },
  { date: ' 02-Jan-22 ', price: 56.5, volume: 22 },
  { date: ' 03-Jan-22 ', price: 56.8, volume: 6 },
  { date: ' 04-Jan-22 ', price: 59, volume: 88 },
  { date: ' 06-Jan-22 ', price: 63, volume: 4 },
  { date: ' 07-Jan-22 ', price: 65, volume: 120 },
  { date: ' 08-Jan-22 ', price: 62, volume: 140 },
  { date: ' 09-Jan-22 ', price: 64, volume: 0 },
  { date: ' 10-Jan-22 ', price: 68, volume: 4 },
  { date: ' 12-Jan-22 ', price: 67, volume: 2 },
  { date: ' 13-Jan-22 ', price: 66, volume: 55 },
  { date: ' 14-Jan-22 ', price: 65, volume: 43 },
  { date: ' 15-Jan-22 ', price: 64, volume: 34 },
  { date: ' 16-Jan-22 ', price: 65, volume: 10 },
  { date: ' 18-Jan-22 ', price: 62, volume: 55 },
  { date: ' 19-Jan-22 ', price: 68, volume: 33 },
  { date: ' 20-Jan-22 ', price: 66, volume: 22 },
  { date: ' 21-Jan-22 ', price: 67.2, volume: 6 },
  { date: ' 22-Jan-22 ', price: 67.1, volume: 88 },
  { date: ' 24-Jan-22 ', price: 67.8, volume: 10 },
  { date: ' 25-Jan-22 ', price: 67.4, volume: 55 },
  { date: ' 26-Jan-22 ', price: 67.1, volume: 33 },
  { date: ' 27-Jan-22 ', price: 69, volume: 22 },
  { date: ' 28-Jan-22 ', price: 69.4, volume: 6 },
  { date: ' 30-Jan-22 ', price: 69.8, volume: 10 },
  { date: ' 31-Jan-22 ', price: 70, volume: 55 },
  { date: ' 01-Feb-22 ', price: 60, volume: 33 },
  { date: ' 02-Feb-22 ', price: 62, volume: 22 },
  { date: ' 03-Feb-22 ', price: 64, volume: 6 },
  { date: ' 05-Feb-22 ', price: 65, volume: 88 },
  { date: ' 06-Feb-22 ', price: 64, volume: 4 },
  { date: ' 07-Feb-22 ', price: 66, volume: 120 },
  { date: ' 08-Feb-22 ', price: 67, volume: 140 },
  { date: ' 09-Feb-22 ', price: 68, volume: 0 },
  { date: ' 11-Feb-22 ', price: 68, volume: 4 },
  { date: ' 12-Feb-22 ', price: 68.5, volume: 2 },
  { date: ' 13-Feb-22 ', price: 69, volume: 55 },
  { date: ' 14-Feb-22 ', price: 73, volume: 43 },
  { date: ' 15-Feb-22 ', price: 74, volume: 34 },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <>
        <Container>
          <Row className="d-flex justify-content-center">
            <small className="mb-2">
              {payload ? payload[0].payload.date : null}
            </small>
          </Row>
          <Row>
            <div className="sindicator bg-white text-center rounded shadow-lg px-2 py-3">
              <p className="text-black mb-0">
                <small>Portfolio</small> <br />
                Price: {payload ? `$${payload[0].payload.price}` : null} <br />
                Volume: {payload ? `$${payload[0].payload.volume}` : null}
              </p>
            </div>
          </Row>
        </Container>
      </>
    );
  }

  return null;
};

const TradeviewChart = () => {
  return (
    <ResponsiveContainer width="100%" aspect={2}>
      <LineChart
        width={600}
        height={300}
        // data={layoutData && layoutData.values}
        data={chartData}
        margin={{
          top: 15,
          right: 30,
          left: -45,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke="#d8d8d8" horizontal="true" vertical="" />
        <XAxis dataKey="date" />
        <YAxis tick={false} />
        <Tooltip
          // contentStyle={{ backgroundColor: '#000', color: '#fff' }}
          // itemStyle={{ color: '#fff' }}
          // cursor={false}
          content={<CustomTooltip />}
        />
        <Line
          type="linear"
          dataKey="price"
          stroke="#8cd487"
          strokeWidth="4"
          dot={false}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TradeviewChart;
