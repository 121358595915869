/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
/*
 * HomeReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';

import { combineReducers } from 'redux';

import {
  LOAD_HOMEPAGE_CONTENT,
  LOAD_HOMEPAGE_CONTENT_SUCCESS,
  LOAD_HOMEPAGE_CONTENT_FAILURE,
  TOGGLE_WAITLIST_SUCCESS_MODAL,
  TOGGLE_VIDEO,
} from './constants';
const generalContentSliceInitialState = {
  loading: false,
  error: false,
  data: false,  
  isWaitlistSuccessModalOpen: false,
  hasVideoPlayed: false,
};

export const initialState = {
  content: generalContentSliceInitialState,
};
const homepagecontent = (state = generalContentSliceInitialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_HOMEPAGE_CONTENT:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_HOMEPAGE_CONTENT_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        draft.error = false;
        break;
      case LOAD_HOMEPAGE_CONTENT_FAILURE:
        draft.data = false;
        draft.loading = false;
        draft.error = true;
        break;
      case TOGGLE_WAITLIST_SUCCESS_MODAL:
        draft.isWaitlistSuccessModalOpen = action.isWaitlistSuccessModalOpen;
        break;
      case TOGGLE_VIDEO:
        draft.hasVideoPlayed = true;
        break;
    }
  });
const homeReducer = combineReducers({
  homepagecontent,
});

export default homeReducer;
