import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const GoogleMaps = ({ defaultTab, lat, lng, locale }) => {
  const REACT_APP_GOOGLE_MAPS_API_KEY = process.env
    .REACT_APP_GOOGLE_MAPS_API_KEY
    ? undefined
    : 'AIzaSyA9z3-IOJ3Hlj8wJk3zGyrmcl4PeMG_Lx4';

  const center = {
    lat: lat,
    lng: lng,
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  return (defaultTab === 'Location' || defaultTab === 'Emplacement') &&
    isLoaded ? (
    <div className="w-100 bg-white h-100 border">
      <GoogleMap
        center={center}
        zoom={18}
        mapContainerStyle={{
          height: 400,
          width: '100%',
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'center',
          padding: 0,
        }}
        // options={{
        //   zoomControl: false,
        //   streetViewControl: false,
        //   mapTypeControl: false,
        //   fullscreenControl: false,
        // }}
      >
        <Marker
          position={center}
          icon={
            'https://willow.imgix.net/2022/07/gmap.png?fm=png&ixlib=php-3.3.0'
          }
          clickable
        />
      </GoogleMap>
    </div>
  ) : (
    ''
  );
};

export default GoogleMaps;
