import React from 'react';
import AllPagesPDFViewer from './all-pages';
import { Col, Row } from 'react-bootstrap';

const WillowPdfviewer = ({ pdf }) => {  
  return (
    <>
      <AllPagesPDFViewer pdf={pdf} />
    </>
  );
};

export default WillowPdfviewer;
