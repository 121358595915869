import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth'; // for authentication
import SockJS from 'sockjs-client';

class API {
  environment = null;

  url = null;

  constructor(environment) {
    this.environment = environment;
    this.url = `https://api.${environment.DOMAIN}/`;
  }

  getUrl(path) {
    let url;
    if (path.indexOf('http') === 0) {
      url = path;
    } else {
      url = this.url + path;
    }
    return url;
  }

  async refreshToken() {
    if (firebase.auth().currentUser) {
      await firebase.auth().currentUser.refreshToken();
    }
  }

  async getToken() {
    if (firebase.auth().currentUser) {
      var token = await firebase.auth().currentUser.getIdToken();
      return token;
    }
    return null;
  }

  getSocket() {
    return new SockJS(this.getUrl('sockets'), null, { transports: ['xhr-polling'] });
  }

  checkCodes() {
    try {
      if (typeof window !== 'undefined') {
        var content = window.localStorage.getItem('willow-codes');
        if (content) {
          var value = JSON.parse(content);
          return value.promoCode;
        }
      }
    }
    catch (e) {
      console.log(e);
    }
    return null;
  }

  async getHeaders() {
    const headers = {
      'X-Willow-Codes': this.checkCodes()
    };
    if (firebase.auth().currentUser) {
      headers.Authorization = `Bearer ${await firebase
        .auth()
        .currentUser.getIdToken()}`;
    }
    return headers;
  }

  async get(path, options, header) {
    const result = await axios.get(this.getUrl(path), {
      headers: { ...(await this.getHeaders()), ...header },
      ...options,
    });
    if (result.data === '') {
      return result; // This was needed because some of the backend apis are not returning response
    }
    return result.data;
  }

  async post(path, content, header, options) {
    try {
      const result = await axios.post(this.getUrl(path), content, {
        headers: { ...(await this.getHeaders()), ...header },
        ...options,
      });
      if (result.data === '') {
        return result; // This was needed because some of the backend apis are not returning response
      }
      return result.data;
    } catch (error) {
      if (error.response && Array.isArray(error.response.data)) {
        throw error.response.data;
      }
      throw error;
    }
  }

  async delete(path, content) {
    try {
      const result = await axios.delete(this.getUrl(path), {
        headers: await this.getHeaders(),
        data: content,
      });
      return result.data;
    } catch (error) {
      if (Array.isArray(error.response.data)) {
        throw error.response.data;
      }
      throw error;
    }
  }
}

export { API };
