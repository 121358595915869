/* eslint-disable camelcase */
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { CHANGE_LOCALE } from 'containers/LanguageProvider/constants';
import { API_ENPOINT_PREFIX } from 'containers/App/constants';
import {
  makeSelectApi,
  makeSelectEnvVariablesData,
  makeSelectLocale,
  makeSelectLocation,
  makeSelectForm,
} from 'containers/App/selectors';
import { makeSelectUsers } from 'containers/App/Users/selectors';
import { makeSelectAuth } from 'containers/AuthProvider/selectors';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { wpurl, isServer } from 'utils/detection';
import request from 'utils/request';
import { reset } from 'redux-form';
import { USER_LOADED } from '../App/Users/constants';
import {
  loadAccountLayout,
  loadAccountLayoutFailure,
  loadAccountLayoutSuccess,
  setActiveTab,
  setErrorMessage,
  setAccountPageToast,
  setFlinksLoader,
  setTransferFundsLoader,
  setFlinksModal,
  toggleCapsule,
  loadUserDocuments,
  setInitialValues,
  setCustomerType,
} from './actions';
import {
  CONNECT_ACCOUNTS,
  REMOVE_ACCOUNT,
  STORE_FORM_DATA,
  TOGGLE_CREDENTIALS_MODAL,
  LOAD_ACCOUNT_LAYOUT_FAILURE,
  SET_TAB,
  LOAD_ACCOUNT_LAYOUT_SUCCESS,
} from './constants';
import { makeSelectAccountData } from './selectors';
import { notify } from '../App/actions';
import { SERVER_EVENT } from '../App/Events/constants';
import { formatters } from '../../utils/formatters';

// function* getAccountLayout(API_URL) {
//   const API_URL_ACCOUNT_LAYOUT = `${API_URL}/getaccountpage`;
//   const locale = yield select(makeSelectLocale());
//   // yield put(loadAccountLayout());
//   const requestUrl = `${API_URL_ACCOUNT_LAYOUT}/${locale}`;
//   try {
//     const someData = yield call(request, requestUrl);
//     console.log('someData: ', someData);
//     // yield put(loadAccountLayoutSuccess(someData));
//   } catch (e) {
//     yield put(loadAccountLayoutFailure(e));
//   }
// }

function* getUserDocuments() {
  const user = yield select(makeSelectUsers());
  if (user.initialized && user.current.isAuthenticated) {
    const api = yield select(makeSelectApi());
    try {
      const result = yield api.get(`documents/user/documents/${user.current.id}`);
      yield put(loadUserDocuments(result));
    } catch (err) {
      console.error('Error fetching issues', err);
    }
  }
}

// function* getDocumentUrl({document}) {
//   const user = yield select(makeSelectUsers());
//   if (user.initialized && user.current.isAuthenticated) {
//     const api = yield select(makeSelectApi());
//     try {
//         const result = yield api.get(`documents/user/documents/${document.id}/url`);
//         window.open(result.url,'_blank');
//     } catch (err) {
//       console.error('Error fetching issues', err);
//     }
//   }
// }

function* submitPersonalDetails(data) {
  try {
    const api = yield select(makeSelectApi());
    yield api.post('users/users/current', {
      address: data.address,
      phoneNumber: data.phoneNumber,
      businessNumber: data.businessNumber,
      unitNumber: data.unitNumber,
      city: data.city,
      province: data.province,
      postalCode: data.postalCode,
    });
    yield put(setAccountPageToast('Update was successful!!'));
  } catch (error) {
    // TODO: handle error
    if (error.length) {
      yield put(
        setAccountPageToast(`Failed to Submit: ${error.map(e => e.message)}`),
      );
    } else {
      yield put(setAccountPageToast('Something went wrong'));
    }
  }
}

function* submittingForm({ formData }) {
  yield put(setErrorMessage(false)); // this resets the error message for next submission
  if (formData.formname === 'personal' || formData.formname === 'business') {
    yield submitPersonalDetails(formData.data);
  } else if (formData.formname === 'transfer_funds') {
    yield put(setTransferFundsLoader('transferredfunds'));
    const {
      formData,
      data: { errorlookup },
    } = yield select(makeSelectAccountData());
    const locale = yield select(makeSelectLocale());

    try {
      const api = yield select(makeSelectApi());
      const { account, amount, transfer_direction } = formData.data;

      const response = yield api.post('accounting/accounts/transfer', {
        accountId: account,
        amount: parseFloat(amount),
        // RENTAL_INCOME("RENTAL_INCOME", 0), DISTRIBUTION("DISTRIBUTION", 1), DEPOSIT("DEPOSIT", 2), WITHDRAWAL("WITHDRAWAL", 3), PURCHASE("PURCHASE", 4);
        type:
          transfer_direction.indexOf(' to ') > 0 ||
          transfer_direction.indexOf(' vers ') > 0
            ? 2
            : 3,
      });
      if (response.status === 204) {
        yield put(reset('transfer_funds'));
        yield put(
          setAccountPageToast(
            errorlookup.find(o2 => o2.code === 'successtoast').message,
          ),
        );
      }
      yield put(setTransferFundsLoader(false));
    } catch (error) {
      if (error.length > 0) {
        yield put(
          setAccountPageToast(
            error.find(o1 => errorlookup.some(o2 => o1.code === o2.code))
              .message,
          ),
        );
        yield put(reset('transfer_funds'));
      } else {
        yield put(setAccountPageToast(error.message));
      }
      yield put(setTransferFundsLoader(false));
    }
  } else if (formData.formname === 'acc_documents') {
    const requestDocUrl = `documents/user/document`;
    try {
      const api = yield select(makeSelectApi());
      const formDataOp = new FormData();
      formDataOp.append('document', formData.data.upload_document[0]);
      formDataOp.append('fileName', formData.data.upload_document[0].name);
      formDataOp.append('locale', 'en');
      yield api.post(requestDocUrl, formDataOp);
      yield put(reset('acc_documents'));
      yield call(loadRegistration);
      yield call(getUserDocuments);
    } catch (error) {
      // TODO: handle error
      if (error.response && error.response.status === 409) {
        yield put(
          notify(
            'Failed to Submit',
            'You can only upload PDF and PNG files',
            'danger',
            true,
          ),
        );
      } else if (error.length) {
        yield put(
          notify(
            'Failed to Submit',
            error.map(e => e.message),
            'danger',
            true,
          ),
        );
      } else {
        console.log(error);
        yield put(
          setAccountPageToast('Failed to load resource, try again later!'),
        );
      }
    }
  }
}

function* handleConnectAccounts({ data }) {
  try {
    const api = yield select(makeSelectApi());
    yield api.post('accounting/accounts/link', data);
  } catch (error) {
    yield put(setFlinksLoader(false));
    if (error.length) {
      yield put(notify('Connect Failed', error[0].message, 'danger', true));
      yield put(setAccountPageToast('Connection Failed'));
    } else {
      console.error(error);
      yield put(setAccountPageToast('Connection Failed'));
    }
  }
}

function* handleServerEvent({ event }) {
  if (/^bank account/i.test(event.name)) {
    yield put(setFlinksLoader(false));
    yield put(setFlinksModal(false));
    yield put(setTransferFundsLoader(false));
  }
}

function* handleRemoveAccount({ id }) {
  try {
    const api = yield select(makeSelectApi());
    yield api.post('accounting/accounts/remove', {
      accountId: id,
    });
  } catch (error) {
    yield put(setFlinksLoader(false));
    console.error(error);
    yield put(setAccountPageToast('Connection Failed'));
  }
}
function* checkActiveTab() {
  const currentLocation = yield select(makeSelectLocation());
  const paths = currentLocation.pathname.split('/');
  if (paths.indexOf('account') > 0) {
    yield put(setActiveTab(currentLocation.pathname));
    
    const lastPart = paths[paths.length - 1];
    yield put(toggleCapsule(lastPart));
    // yield put(push(currentLocation.pathname));
  }
}
function* reloadPage({ credentialsModal }) {
  if (!credentialsModal) {
    window.location.reload(false);
  }
}
function* resetActiveCapsule() {
  yield put(toggleCapsule(false));
}

function* loadRegistration() {
  const user = yield select(makeSelectUsers());
  if (user.current.isAuthenticated) {
    try {
      const api = yield select(makeSelectApi());
      const result = yield api.get('kyc/registration/current');
      if (result.customerType === 'BUSINESS') {
        yield put(setCustomerType(result.customerType));
        yield put(setInitialValues(result.tradeAuthority));
      } else {
        yield put(setCustomerType(result.customerType));
        yield put(setInitialValues(result.personal));
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }
}
export default function* accountPageSaga() {
  // const { variables } = yield select(makeSelectEnvVariablesData());
  yield call(checkActiveTab);
  yield call(loadRegistration);
  yield call(getUserDocuments);
  // yield call(getAccountLayout, API_URL);
  const users = yield select(makeSelectUsers());
  const auth = yield select(makeSelectAuth());
  if (users.initialized) {
    const locale = yield select(makeSelectLocale());
    // it works because initially isAuthenticated is false so it will show a flash of login page on refresh
    if (!users.current.isAuthenticated && !auth.isAuthenticated) {
      yield put(push(`/${locale}/login`));
    }
  }
  // yield takeLatest(
  //   [TOGGLE_DOWNLOAD],
  //   getDocumentUrl
  // );
  // event handlers
  // yield takeLatest([LOCATION_CHANGE, CHANGE_LOCALE], getAccountLayout, API_URL);
  // yield takeLatest([LOAD_ACCOUNT_LAYOUT_FAILURE], getAccountLayout, API_URL); // used when blank page appears, which locally isn't occuring
  yield takeLatest([LOCATION_CHANGE, CHANGE_LOCALE], checkActiveTab);
  yield takeLatest(
    [LOCATION_CHANGE, CHANGE_LOCALE, USER_LOADED],
    loadRegistration,
  );
  yield takeLatest(USER_LOADED, getUserDocuments);
  yield takeLatest(LOAD_ACCOUNT_LAYOUT_SUCCESS, getUserDocuments);
  yield takeLatest(STORE_FORM_DATA, submittingForm);
  yield takeLatest(CONNECT_ACCOUNTS, handleConnectAccounts);
  yield takeLatest(REMOVE_ACCOUNT, handleRemoveAccount);
  yield takeLatest(TOGGLE_CREDENTIALS_MODAL, reloadPage);
  yield takeLatest([SERVER_EVENT], handleServerEvent);
  yield takeLatest([SET_TAB], resetActiveCapsule);
}
