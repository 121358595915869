/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

// import Label from "./../Label";
import { AccordionContext, Accordion, Card } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Icon from 'components/Icon';

const StyledCard = styled(Card)`
  ${({ theme }) => css`
    border: 0;
    border-radius: 0;
    background-color: transparent;
  `}
`;

const ChildrenWithProps = props => {
  const elements = React.Children.toArray(props.children).map(element =>
    React.cloneElement(element, {
      disabled: props.disabled,
      size: props.size,
    }),
  );
  return <>{elements}</>;
};

const AccordionItem = props => (
  <StyledCard>
    <ChildrenWithProps {...props} />
  </StyledCard>
);

// Toggle
const StyledToggleButton = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    border: none;
    border: 2px solid transparent;
    border-bottom-color: ${theme.colors.greys.medium};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.small};
    color: black;
    transition: border-bottom ${theme.durations.medium} ${theme.durations.fast};

    font-size: ${theme.typography.size.large};
    :hover {
      background-color: ${theme.colors.greys.light};
    }
    &:focus-visible {
      border-color: ${theme.colors.brand.primary};
    }
  `}
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      font-size: ${theme.typography.size.medium};
      padding: ${theme.spacing.xsmall};
    `}
  ${({ open, theme }) =>
    open &&
    css`
      transition: border-bottom 0s 0s;
      border-bottom-color: transparent;
    `}
`;

const ContextAwareToggle = ({
  children,
  eventKey,
  callback,
  disabled,
  size,
}) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;

  return (
    <StyledToggleButton
      type="button"
      disabled={disabled}
      onClick={decoratedOnClick}
      size={size}
      open={isCurrentEventKey}
    >
      {children}
      <Icon
        variant={isCurrentEventKey ? 'less' : 'more'}
        size={size === 'sm' ? '' : 'xlarge'}
      />
    </StyledToggleButton>
  );
};

const StyledCardHeader = styled(({ eventKey, ...rest }) => (
  <Card.Header {...rest} />
))`
  ${({ theme }) => css`
    background-color: transparent;
    padding: 0;
    margin-bottom: 0 !important;
    border: none;
    font-size: ${theme.typography.size.xlarge};
  `}
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      font-size: ${theme.typography.size.medium};
    `}
`;

const AccordionToggle = props => (
  <StyledCardHeader {...props}>
    <ContextAwareToggle {...props}>{props.children}</ContextAwareToggle>
  </StyledCardHeader>
);

// Collapse
const StyledAccordionCollapse = styled(Accordion.Collapse)`
  ${({ theme }) => css`
    font-size: ${theme.typography.size.small};
    padding-left: ${theme.spacing.small};
    padding-right: ${theme.spacing.small};
    border-bottom: 2px solid ${theme.colors.greys.medium};
    & > div {
      padding-bottom: ${theme.spacing.large};
    }
  `}
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      font-size: ${theme.typography.size.xsmall};
      padding-left: ${theme.spacing.xxxsmall};
      padding-right: ${theme.spacing.xxxsmall};
      & > div {
        padding-bottom: ${theme.spacing.medium};
      }
    `}
`;

const AccordionCollapse = props => (
  <StyledAccordionCollapse {...props} className="px-4">
    <div>{props.children}</div>
  </StyledAccordionCollapse>
);

// Main
const ChildrenWithSizeProps = props => {
  const elements = React.Children.toArray(props.children).map(element =>
    React.cloneElement(element, {
      size: props.size,
    }),
  );
  return <>{elements}</>;
};

const WillowAccordion = props => (
  <Accordion {...props}>
    <ChildrenWithSizeProps {...props} />
  </Accordion>
);

WillowAccordion.Item = AccordionItem;
WillowAccordion.Toggle = AccordionToggle;
WillowAccordion.Collapse = AccordionCollapse;

export default WillowAccordion;
