import React, { useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
const PlaidIdentity = ({token, btnfun}) => {
  const history = useHistory();

  const onSuccess = useCallback((publicToken, metadata) => {
      
    console.log("PLaid Success", publicToken, metadata);
  }, []);

  const onEvent = useCallback((eventName, metadata) => {
    "IDENTITY_VERIFICATION_PASS_SESSION" 
    if (eventName === "IDENTITY_VERIFICATION_PASS_SESSION") {
      btnfun("success");
    } else if (eventName === "IDENTITY_VERIFICATION_FAIL_SESSION") {
      btnfun("failed");
    } else if (eventName === "IDENTITY_VERIFICATION_CLOSE_UI") {
        history.push('home');
    }
    console.log("Plaid Event", eventName, metadata);
  }, []);
  
  const onExit = useCallback((error, metadata) => {
    if (error !== null) {
      // handle error
      console.error('Plaid Link exited with error: ', error);
    }
    console.log('Plaid Link exited: ', metadata);
  }, []);

  const { open } = usePlaidLink({
    token,
    onSuccess,
    onEvent,
    onExit
  });

  return (
    <div className=''>
    <Button onClick={() => open()} className=''>
      Verify
    </Button>
    </div>
  );
};

export default PlaidIdentity;