export const LOAD_MARKETPLACE_PAGE_DATA =
  'app/MarketPlace/LOAD_MARKETPLACE_PAGE_DATA';
export const LOAD_MARKETPLACE_PAGE_DATA_SUCCESS =
  'app/MarketPlace/LOAD_MARKETPLACE_PAGE_DATA_SUCCESS';
export const LOAD_MARKETPLACE_PAGE_DATA_FAILURE =
  'app/MarketPlace/LOAD_MARKETPLACE_PAGE_DATA_FAILURE';
export const LOAD_PROPERTY_DETAILS = 'app/MarketPlace/LOAD_PROPERTY_DETAILS';
export const LOAD_PROPERTY_DETAILS_SUCCESS =
  'app/MarketPlace/LOAD_PROPERTY_DETAILS_SUCCESS';
export const LOAD_PROPERTY_DETAILS_FAILURE =
  'app/MarketPlace/LOAD_PROPERTY_DETAILS_FAILURE';
export const TOGGLE_VIEW = 'app/MarketPlace/LOAD_PROPERTY_DETAILS_FAILURE';
