import moment, { locale } from 'moment';

export const formatters = {
  none: value => {
    return value;
  },
  currency: value => {
    return new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
    }).format(value);
  },
  percent: value => {
    return new Intl.NumberFormat('en-CA', {
      style: 'percent',
      maximumFractionDigits: 2,
    }).format(value / 100);
  },
  short_date: value => {
    if (!value || !moment(value).isValid()) {
      return null;
    }
    return moment
      .utc(value)
      .local()
      .format('ll');
  },
  datetime: value => {
    if (!value || !moment(value).isValid()) {
      return null;
    }
    return moment
      .utc(value)
      .local()
      .format('lll');
  },
  units: value => {
    return `${value} unit(s)`;
  },
  years: value => {
    return `${value} years`;
  },
  frontage: value => {
    return `${value} ft`;
  },
  squarefootage: value => {
    return `${value.toLocaleString()}`;
  },
  amount: value => {
    if (!value) {
      return null;
    }
    const numericValue = parseFloat(value.replace(/[$,]/g, ''));
    return `$${numericValue.toLocaleString()}`;
  },
  locale: (locale, values) => {
    return values[locale] || values['en'];
  },
  trade_request_type: value => {
    return 'Buy';
  },
};
