/* eslint-disable react/prop-types */
import { Helmet } from 'react-helmet-async';
import React from 'react';
const Seo = ({
  title,
  description,
  url,
  keywords,
  image,
  type,
  lang,
  script,
}) => (
  <Helmet
    htmlAttributes={{ lang: lang || 'en' }}
    title={title}
    script={script}
    meta={[
      {
        name: 'description',
        content: description,
      },
      {
        name: 'keywords',
        content: keywords && keywords.join(),
      },
      { property: 'og:type', content: type },
      { property: 'og:title', content: title },
      {
        property: 'og:description',
        content: description,
      },
      { property: 'og:url', content: `https://${url}` },
      { property: 'og:image', content: image },
      { property: 'twitter:title', content: title },
      { property: 'twitter:description', content: description },
      { property: 'twitter:image', content: image },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:type', content: type },
      { property: 'twitter:url', content: `https://${url}` },
    ]}
    links={[
      {
        rel: 'canonical',
        href: url,
      },
    ]}
  />
);

export default Seo;
