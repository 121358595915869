/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import { combineReducers } from 'redux';
import firebase from 'firebase/app';
import 'firebase/auth'; // for authentication
import {
  AUTH_CHANGED,
  MFA_REQUIRED,
  REQUIRE_SMS_VERIFICATION,
  AUTH_FAILED,
  TRIGGER_AUTH_TRACKER,
  SET_PARAMS,
  INACTIVE,
  INACTIVE_MODAL,
  GIFT_BOX_STATE,
} from './constants';

export const initialState = {
  initialized: false,
  error: false,
  failedConnection: false,
  verificationId: false,
  resolver: false,
  authTracker: false,
  params: false,
  inactive: false,
  inactivemodal: false,
  giftBoxState: false,
  issuer: false,
  firebase: false
};

const data = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GIFT_BOX_STATE:
        draft.giftBoxState = action.giftBoxState;
        break;
      case INACTIVE:
        draft.inactive = action.inactive;
        break;
      case INACTIVE_MODAL:
        draft.inactivemodal = action.inactivemodal;
        break;
      case AUTH_CHANGED:
        draft.initialized = true;
        // draft.firebase = firebase.auth().currentUser;
        draft.firebase = action.firebase;
        draft.issuer = action.issuer;
        break;
      case REQUIRE_SMS_VERIFICATION:
        draft.verificationId = action.verificationId;
        break;
      case MFA_REQUIRED:
        draft.verificationId = action.verificationId;
        draft.resolver = action.resolver;
        break;
      case AUTH_FAILED:
        draft.error = action.error;
        break;
      case TRIGGER_AUTH_TRACKER:
        draft.authTracker = action.authTracker;
        break;
      case SET_PARAMS:
        draft.params = action.params;
        break;
    }
  });

const authReducer = combineReducers({
  data,
});

export default authReducer;
