export const segmentTrack = events => {
  events &&
    events.map(event => {
      const { type, location, title } = event;
      return null;
      // return (
      //   typeof window !== 'undefined' &&
      //   window.analytics.track(event.name, {
      //     type,
      //     location,
      //     title,
      //   })
      // );
    });
};

export const segmentIdentify = (events, email) =>
  events &&
  events.map(
    event =>
      typeof window !== 'undefined' &&
      window.analytics.identify(event.name, {
        email,
      }),
  );
