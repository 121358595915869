export const LOAD_AUTHPAGE_CONTENT = 'app/AuthPage/LOAD_AUTHPAGE_CONTENT';
export const LOAD_AUTHPAGE_CONTENT_SUCCESS =
  'app/AuthPage/LOAD_AUTHPAGE_CONTENT_SUCCESS';
export const LOAD_AUTHPAGE_CONTENT_FAILURE =
  'app/AuthPage/LOAD_AUTHPAGE_CONTENT_FAILURE';

export const TOAST = 'app/AuthPage/TOAST';
export const SET_PARAMS = 'app/AuthPage/SET_PARAMS';

export const TOGGLE_VERIFICATION_MODAL =
  'app/AuthPage/TOGGLE_VERIFICATION_MODAL';
export const STORE_VERIFICATION_CODE = 'app/AuthPage/STORE_VERIFICATION_CODE';

export const FORM_LOADER = 'app/AuthPage/FORM_LOADER';
export const SET_LOADER = 'app/AuthPage/SET_LOADER';

