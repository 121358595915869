/* eslint-disable react/prop-types */
/*
 *
 * AuthProvider
 *
 *
 *
 */

import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { makeSelectLocale, makeSelectLocation } from 'containers/App/selectors';
import { makeSelectUsers } from 'containers/App/Users/selectors';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer from './reducer';
import saga from './sagas';
import { makeSelectAuth } from './selectors';

const key = 'auth';
const stateSelector = createStructuredSelector({
  users: makeSelectUsers(),
  locale: makeSelectLocale(),
  location: makeSelectLocation(),
  auth: makeSelectAuth(),
});
const AuthProvider = props => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const { locale, users, location, auth } = useSelector(stateSelector);
  useEffect(() => {
    const currentLocation = location;
    const paths = currentLocation.pathname.split('/');
    const { registrationStatus } = users.current;

    if (users.current.isAuthenticated) {
      if (
        paths.indexOf('resetpassword') > 0 &&
        Object.keys(auth.params).length === 0 &&
        auth.params.constructor === Object
      ) {
        if (registrationStatus <= 1) {
          // when status is 1
          dispatch(push(`/${locale}/dashboard`));
        }
      }
      if (
        paths.indexOf('forgot') > 0 &&
        Object.keys(auth.params).length === 0 &&
        auth.params.constructor === Object
      ) {
        if (registrationStatus > 1) {
          // when status 2, 3, 4, 5..
          dispatch(push(`/${locale}/portfolio`));
        }
        if (registrationStatus <= 1) {
          // when status is 1
          dispatch(push(`/${locale}/dashboard`));
        }
      }
      if (
        paths.indexOf('forgot') > 0 &&
        Object.keys(auth.params).length === 0 &&
        auth.params.constructor === Object
      ) {
        dispatch(push(`/${locale}/portfolio`));
      }
      if (
        paths.indexOf('forgot') > 0 &&
        Object.keys(auth.params).length === 0 &&
        auth.params.constructor === Object
      ) {
        dispatch(push(`/${locale}/portfolio`));
      }
    }
  });
  return <>{React.Children.only(props.children)}</>;
};

export default AuthProvider;
