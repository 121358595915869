/* eslint-disable indent */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Container, Form } from 'react-bootstrap';
import InputHelperText from '../Input/InputHelperText';

const RadioSvg = fill =>
  `<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='${fill}'/></svg>`;

const CheckboxSvg = fill =>
  `<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0692 0.177898C15.5233 0.492264 15.6365 1.11522 15.3222 1.5693L6.32216 14.5693C6.15338 14.8131 5.8851 14.9694 5.58978 14.996C5.29445 15.0227 5.00254 14.9169 4.79286 14.7072L0.292863 10.2072C-0.0976614 9.81667 -0.0976614 9.18351 0.292863 8.79298C0.683387 8.40246 1.31655 8.40246 1.70708 8.79298L5.35981 12.4457L13.6778 0.43088C13.9921 -0.0232039 14.6151 -0.136468 15.0692 0.177898Z" fill="${fill}"/>
  </svg>
  `;

const StyledCheck = styled(({ status, helperMessage, ...rest }) => (
  <Form.Check {...rest} />
))`
  ${({ theme }) => css`
    .custom-control-label {
      font-weight: ${theme.typography.weight.regular};
      font-size: ${theme.typography.size.small};
      line-height: ${theme.typography.lineHeight.large} !important;
    }
    p {
      margin-bottom: 0px;
    }
    label {
      line-height: ${theme.typography.lineHeight.large} !important;
    }
    .custom-control-input {
      &:hover:not(:disabled) ~ .custom-control-label {
        &::before {
          border-color: ${theme.colors.greys.xdark};
        }
      }

      &:focus,
      &:active {
        &:not(:disabled) ~ .custom-control-label {
          &::before {
            background-color: ${theme.colors.greys.light};
            border-color: ${theme.colors.brand.medium} !important;
            box-shadow: none;
          }
        }
      }

      &:disabled ~ .custom-control-label {
        &::before {
          opacity: 50%;
        }
      }

      &:checked ~ .custom-control-label {
        &::before {
          background-color: ${theme.colors.greys.light};
          border-color: ${theme.colors.greys.medium};
          box-shadow: none;
        }
      }
    }

    .custom-control-label {
      line-height: 150%;
      cursor: pointer;
      &::before {
        color: ${theme.colors.greys.xdark} !important;
        background-color: ${theme.colors.greys.light};
        box-shadow: none !important;
      }
    }
  `}

  ${({ status, theme }) =>
    status === 'success' &&
    css`
      .custom-control-input {
        & ~ .custom-control-label {
          &::before {
            border-color: ${theme.colors.system.success} !important;
          }
        }
      }
    `}
  ${({ status, theme }) =>
    status === 'warning' &&
    css`
      .custom-control-input {
        & ~ .custom-control-label {
          &::before {
            border-color: ${theme.colors.system.warning} !important;
          }
        }
      }
    `}
  ${({ status, theme }) =>
    status === 'error' &&
    css`
      .custom-control-input {
        & ~ .custom-control-label {
          &::before {
            border-color: ${theme.colors.system.danger} !important;
          }
        }
      }
    `}

  ${({ type, theme }) =>
    type === 'radio' &&
    css`
      .custom-control-input {
        &:checked ~ .custom-control-label {
          &::after {
            background-image: url("data:image/svg+xml,${encodeURIComponent(
              RadioSvg(theme.colors.greys.xdark),
            )}");
          }
        }
      }
    `}

  ${({ type, theme }) =>
    type === 'checkbox' &&
    css`
      .custom-control-input {
        &:checked ~ .custom-control-label {
          &::after {
            background-image: url("data:image/svg+xml,${encodeURIComponent(
              CheckboxSvg(theme.colors.greys.xdark),
            )}");
          }
          // %23 === '#'
        }
      }
    `}
`;

const WillowCheckbox = props => {
  const { status, disabled, helperMessage } = props;
  return (
    <>
      <StyledCheck custom {...props} onChange={props.onChange} />
      {helperMessage && (
        <Container className="mw-100 px-5">
          <InputHelperText
            status={status}
            disabled={disabled}
            className="mt-0 mb-1"
          >
            {helperMessage}
          </InputHelperText>
        </Container>
      )}
    </>
  );
};

export default WillowCheckbox;
