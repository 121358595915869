/* eslint-disable react/prop-types */
import React from 'react';

const WillowProgressbar = ({ stepcount, totalSteps, type, ipoprogress }) => {
  const num = stepcount;
  const percent = 100 / totalSteps;

  return (
    <>
      {type === 'primary' && (
        <>
          <div
            className="my-3 bg-white w-100 rounded-pill"
            style={{ height: '8px' }}
          >
            <div
              className="progress-bar bg-black rounded-pill h-100"
              style={{ width: `${num * percent}%` }}
            ></div>
          </div>
        </>
      )}

      {type === 'secondary' && (
        <>
          <div
            className="my-2 w-100 rounded-pill bg-greys-light"
            style={{ height: '8px' }}
          >
            <div
              className="progress-bar bg-primary rounded-pill h-100"
              style={{ width: `${ipoprogress}%` }}
            ></div>
          </div>
        </>
      )}
    </>
  );
};

export default WillowProgressbar;
