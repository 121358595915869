/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

const LocalLogic = ({ defaultTab, lat, lng, locale }) => {
  useEffect(() => {
    const locallogicscript = document.getElementById('locallogicscript');
    locallogicscript.onload = loadedCallback();

    function loadedCallback() {
      try {
        // eslint-disable-next-line no-undef
        var widget = new locallogic.LocalContent('local-content-widget', {
          lat,
          lng,
          locale: locale === 'en' ? 'en' : 'fr',
          // designId: 'll-2019',
        });
      } catch (err) {
        console.log('LocalLogic error', err);
      }
    }
  }, [defaultTab]);

  return defaultTab === 'Location' || defaultTab === 'Emplacement' ? (
    <div className="w-100 bg-white mb-n3" id="local-content-widget"></div>
  ) : (
    ''
  );
};

export default LocalLogic;
