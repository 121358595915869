/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Dropdown } from 'react-bootstrap';

const StyledMenu = styled.div`
  ${({ theme }) => css`
    border: none;
    border-radius: ${theme.shapes.borderRadiusMedium};

    width: auto;
    padding: ${theme.spacing.none};

    transition: border ${theme.durations.fast} ease-in;

    background-color: ${theme.colors.greys.white};
    color: ${theme.colors.greys.dark};

    box-shadow: ${theme.depths['2']};
    top: 100%;
    right:0;
    .dropdown-item {
      padding: ${theme.spacing.xxsmall} ${theme.spacing.xsmall};
      :hover,
      :focus {
        background-color: ${theme.colors.greys.light};
      }
      :focus {
        // outline: 2px solid ${theme.colors.brand.primary};
      }
      :active {
        color: ${theme.colors.brand.xdark};
        background-color: ${theme.colors.brand.primary};
      }
      :active .nav-link {
        /* color: ${theme.colors.brand.xdark}; */
        color: ${theme.colors.greys.white} !important;
        background-color: ${theme.colors.brand.primary};
      }
      
    }
    .dropdown-item.active{
        color: ${theme.colors.greys.xdark};
        background-color: ${theme.colors.brand.primary};
    }
  `}

  ${({ isExpanded, theme }) =>
    isExpanded &&
    css`
      // transform: translate(0px, 43px) !important;
      border-color: ${theme.colors.brand.medium};
    `}

  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      .dropdown-item {
        font-size: ${theme.typography.size.xxsmall};
        padding: ${theme.spacing.xxxsmall} ${theme.spacing.xsmall};
      }
    `}

  ${({ size, isExpanded, theme }) =>
    size === 'sm' &&
    isExpanded &&
    css`
      transform: translate(0px, 38px) !important;
    `}
`;

const CustomMenu = React.forwardRef(
  ({ size, children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    // const [value, setValue] = useState("");
    const value = '';
    const isExpanded = className.includes('show');

    return (
      <StyledMenu
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
        isExpanded={isExpanded}
        size={size}
      >
        <ul className={`p-0 my-2`}>
          {React.Children.toArray(children).filter(
            child =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </StyledMenu>
    );
  },
);

const DropdownMenu = props => {
  const popperConfig = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 6],
        },
      },
    ],
  };

  return (
    <Dropdown.Menu as={CustomMenu} popperConfig={popperConfig} {...props} />
  );
};

export default DropdownMenu;
