import { css } from 'styled-components';

/**
 * Bootstrap has 4 breakpoints that you can use:

.col-sm for larger mobile phones (devices with resolutions ≥ 576px);
.col-md for tablets (≥768px);
.col-lg for laptops (≥992px);
.col-xl for desktops (≥1200px)

 */
export const media = {
  tiny: (...args) => css`
    @media screen and (max-width: 320px) {
      ${css(...args)}
    }
  `,
  extrasmall: (...args) => css`
    @media screen and (min-width: 360px) and (max-width: 410px) {
      ${css(...args)}
    }
  `,
  pixel2: (...args) => css`
    @media screen and (min-width: 411px) and (max-width: 731px) {
      ${css(...args)}
    }
  `,
  landscape: (...args) => css`
    @media screen and (max-device-width: 844px) and (orientation: landscape) {
      ${css(...args)}
    }
  `,
  small: (...args) => css`
    @media screen and (max-width: 768px) {
      ${css(...args)}
    }
  `,
  medium: (...args) => css`
    @media screen and (max-width: 992px) {
      ${css(...args)}
    }
  `,
  large: (...args) => css`
    @media screen and (max-width: 1200px) {
      ${css(...args)}
    }
  `,
  extralarge: (...args) => css`
    @media screen and (min-width: 1200px) {
      ${css(...args)}
    }
  `,
  print: (...args) => css`
    @media (print) {
      ${css(...args)}
    }
  `,

  /* CARDS */
  extraextralarge: (...args) => css`
    @media screen and (min-width: 768px) and (max-width: 1500px) {
      ${css(...args)}
    }
  `,

  /* TRADE VIEW */
  mobile: (...args) => css`
    @media screen and (min-width: 0px) and (max-width: 767px) {
      ${css(...args)}
    }
  `,
  tablet: (...args) => css`
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      ${css(...args)}
    }
  `,
  desktop: (...args) => css`
    @media screen and (min-width: 1200px) {
      ${css(...args)}
    }
  `,
};
export default media;
