/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-eval */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-const-assign */
/* eslint-disable react/prop-types */
import Button from 'components/Button';
import DropDown from 'components/DropDown';
import Link from 'components/Link';
import Logo from 'components/Logo';
import React, { useState, useEffect, useRef } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { isClientMobile, isClientTablet } from 'utils/detection';
import { segmentTrack } from 'utils/segment';
import { useHistory, useLocation } from 'react-router-dom';
import NavDropdownIcon from '../../images/icons8-menu-50.png';

const StyledNavbarDiv = styled.div`
  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .navbar {
    animation: 1s ease-in-out 0s 1 slideIn;
  }
  .navbar-toggle {
    z-index: 1;
  }
  .dropdown-item:after {
    border: none !important;
  }
  .dropdown-item {
    background: ${props => props.theme.colors.greys.white}!important;
    color: ${props => props.theme.colors.greys.dark}!important;
    .drop-link {
      color: ${props => props.theme.colors.greys.dark}!important;
    }
    a &:after {
      color: ${props => props.theme.colors.greys.dark}!important;
      border: none !important;
    }
  }
  .dropdown-item.active{
    background: ${props => props.theme.colors.brand.primary}!important;
    .drop-link {
      color: ${props => props.theme.colors.greys.white}!important;
    }
    a &:after {
      color: ${props => props.theme.colors.greys.white}!important;
      border: none !important;
    }
  }

  .dropdown-menu {
    .nav-link.active:after {
      border: none !important;
    }
  }
  .nav-link.active {
    /* color: ${props => props.theme.colors.greys.white}!important; */
    @media (min-width: 300px) and (max-width: 1050px) {
      &:after {
        border: none !important;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 100%;
      border-bottom-style: solid;
      border-bottom-width: ${props => props.theme.shapes.borderWidthMedium};
      border-color: ${props => props.theme.colors.brand.medium};

      transform: scaleX(1);
      transition: all ${props => props.theme.durations.fast} ease;
    }
  }
  .navbar-light .navbar-toggler.collapsed {
    .navbar-toggler-icon {
      width: 30px;
      height: 30px;
      ${props =>
        props &&
        css`
          background-image: url(${NavDropdownIcon}) !important;
        `}
    }
  }
  .dropdown-item:focus, .dropdown-item:hover {
    background: ${props => props.theme.colors.brand.primary}!important;
    color: ${props => props.theme.colors.greys.white}!important;
    
    .drop-link {
      color: ${props => props.theme.colors.greys.white}!important;
    }
    .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
      background: ${props => props.theme.colors.brand.primary}!important;
      color: ${props => props.theme.colors.greys.white}!important;
    }
  }
  
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  .navbar-light .navbar-toggler {
    .navbar-toggler-icon {
      width: 20px;
      height: 20px;
      ${props =>
        props &&
        css`
          background-image: url(${props.data.togglerCloseIcon}) !important;
        `}
    }
  }
  .dropdown {
    font-family: ${props => props.theme.typography.primary};
  }
  .navbar--hidden {
    top: -105px;
    padding-bottom: 0px;
  }
  .floating--navbar {
    width: 100%;
    left: 50%;
    transform: translatex(-50%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  .drop-link {
     color: ${props => props.theme.colors.greys.white}!important;
  }

  .navbar-light .navbar-toggler {
    border: none !important;
  }
  /* .dropdown */
  @media (max-width: 400px) {
    .navbar-collapse {
      max-height: 400px;
      overflow-y: scroll;
    }
  }
`;

const CustomNavbar = props => {
  const {
    localeName,
    locale, // this is used as context
    changeLocale, // this is used as context
    logout, // this is used as context
    user,
    giftBoxState,
    navData,
    location
  } = props;
  const ref = useRef();
  const history = useHistory();

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (expanded && ref.current && !ref.current.contains(e.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [expanded]);
  const NavLink = ({ url, name, eventKey }) => (
    <Nav.Link
      to={url}
      eventKey={eventKey}
      as={Link}
      onClick={() => setExpanded(false)}
      className={
        location.pathname === url
          ? 'active d-flex nav-link align-items-center py-3 py-md-3 px-3 px-xl-5 border-bottom-0'
          : 'd-flex nav-link align-items-center py-3 py-md-3 px-3 px-xl-5 border-bottom-0 text-black'
      }
    >
      {name}
    </Nav.Link>
  );
  const NavButton = ({ url, name, events, id }) => (
    <Link
      to={url}
      className="text-decoration-none d-flex align-items-center p-1 text-black"
      key={id}
    >
      <Button
        size="sm"
        onClick={() => {
          // segmentTrack(events && events);
          setExpanded(false);
        }}
        variant="primary-flat-alt"
      >
        {name}
      </Button>
    </Link>
  );
  const NavDropDown = ({ name, options, userName, id, locate }) => {
    try {
      return (
        <DropDown
          className="align-items-center ps-md-2 pe-md-3 pt-xl-2 pb-xl-2 pt-3 d-block"
          key={id}
          toggleClassName="text-black"
        >
          <DropDown.Toggle
            label={userName || localeName}
            value={userName || localeName}
            variant="none"
            id="dropdown-basic"
            className="me-3"
          />
          <DropDown.Menu>
            {options.map(option => (
              <DropDown.Item
                key={option.name}
                onClick={() =>{
                  history.push(option.url);
                  option.onclick && eval(option.onclick)(option.url);}
                }
                className={
                  location.pathname === option.url
                    ? 'active' : ''
                }
              >
                {name !== 'locale' ? (
                  <Nav.Link
                    as={Link}
                    to={option.url}
                    className={`text-decoration-none d-flex align-items-center p-0 drop-link ${option.url === locate.pathname ? "active": ''}`}
                    eventKey={option.name}
                    onClick={() => setExpanded(false)}
                    key={Math.random()}
                  >
                    {option.name}
                  </Nav.Link>
                ) : (
                  option.name
                )}
              </DropDown.Item>
            ))}
          </DropDown.Menu>
        </DropDown>
      );
    } catch (e) {
      console.log('navbar error');
    }
  };

  const rightItems = navData.menu.slice(
    navData.menu.findIndex(item => item.type === 'Brand') + 1,
  );
  const leftItems = navData.menu.slice(
    0,
    navData.menu.findIndex(item => item.type === 'Brand'),
  );
  const brand = navData.menu.find(item => item.type === 'Brand');
  let isMobile = isClientMobile();
  const isTablet = isClientTablet();
  if (
    typeof window !== 'undefined' &&
    window.matchMedia('(max-width: 977px)').matches
  ) {
    isMobile = true;
  }
  const currentSubdomain = window.location.hostname.split('.')[0];
  return (
    <StyledNavbarDiv data={navData || 'false'} ref={ref}>
      <Navbar
        collapseOnSelect
        bg="white"
        expand="xl"
        className="navbar-expand-custom slideIn p-0 "
        fixed="top"
        expanded={expanded}
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
      >
        <Container fluid>
          {isMobile || isTablet ? (
            <Navbar.Brand
              to={`/${locale}/home`}
              as={Link}
              className=" justify-content-center py-3 px-3"
              aria-label="Midasboard - home"
            >
              <Logo variant="named" />
            </Navbar.Brand>
          ) : (
            <Navbar.Brand
              href={`/${locale}/home`}
              className="justify-content-center py-3 px-3 pt-2"
              aria-label="Midasboard - home"
            >
              <Logo variant="named" />
            </Navbar.Brand>
          )}
          <Navbar.Toggle
            onClick={() => {
              setExpanded(oldState => !oldState);
            }}
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            className="close-navbar-toggler collapsed"
          />
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav className="me-auto">
              {navData &&
                leftItems.map((item, leftkey) => {
                  if (item.type === 'Link') {
                    return (
                      <NavLink
                        url={item.url}
                        name={item.name}
                        key={`leftkey${leftkey}`}
                      />
                    );
                  }
                  if (item.type === 'Dropdown') {
                    return (
                      <NavDropDown
                        name={item.name}
                        options={item.options}
                        key={`leftkey${leftkey}`}
                        locate={location.pathname}
                      />
                    );
                  }
                })}
            </Nav>
            <Nav>
              {navData &&
                rightItems.map((item, rightkey) => {
                  if (item.type === 'Lang') {
                    return (
                      <NavDropDown
                        name={item.name}
                        options={item.options}
                        localeName={localeName}
                        key={`rightkey${rightkey}`}
                        locate={location.pathname}
                      />
                    );
                  }
                  if (item.type === 'Dropdown') {
                    return (
                      <NavDropDown
                        locate={location.pathname}
                        name={item.name}
                        options={item.options}
                        userName={user.firstName ? user.firstName : 'USER'}
                        key={`rightkey${rightkey}`}
                      />
                    );
                  }
                  if (item.type === 'Link') {
                    return (
                      <NavLink
                        url={item.url}
                        name={item.name}
                        eventKey={item.name}
                        key={`rightkey${rightkey}`}
                      />
                    );
                  }
                  if (item.type === 'Button') {
                    return (
                      <NavButton
                        url={item.url}
                        name={item.name}
                        events={item.events}
                        key={`rightkey${rightkey}`}
                      />
                    );
                  }
                  if (item.name === 'giftBox' && giftBoxState) {
                    return (
                      <div className="d-flex pt-3 pt-md-2">
                        <Button
                          variant="primary"
                          className="text-black  align-items-center shadow-none px-3 py-2 mb-md-3 mb-lg-2"
                          onClick={() => eval(item.btnfunc)(true)}
                          aria-label="Close Panel"
                          key={`rightkey${rightkey}`}
                        >
                          🎁 Rewards
                        </Button>
                      </div>
                    );
                  }
                })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </StyledNavbarDiv>
  );
};

export default CustomNavbar;
