/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import * as d3 from 'd3';

import { isClientMobile } from 'utils/detection';
const json = require('./geojson.json');
export default function CanadaMap({ userProperties, allProperties }) {
  const isMobile = isClientMobile();
  const result = allProperties.filter(allprop =>
    userProperties.some(usprop => allprop.symbol === usprop.symbol),
  );
  const markers = result.map(res => ({
    long: res.coordinates.longitude,
    lat: res.coordinates.latitude,
    image: `${res.images
      .find(img => img.category === 'main')
      .url.replace(/ /g, '%20')}?w=1000&ar=16:9&fit=crop`,
    location: res.location,
    name: res.name,
  }));

  useEffect(() => {
    const width = 400;
    const height = 500;
    // The svg
    const svg = d3
      .select('#my_dataviz')
      .attr('width', '920')
      .attr('height', height)
      .attr('class', 'rounded-3');
    // Map and projection
    const projection = d3
      .geoMercator()
      .scale(280)
      .center([-95.423639, 50.052275]) // centers map at given coordinates
      .translate([width / 2, height / 2]); // translate map to svg

    // Draw the map
    svg
      // .call(
      //   d3.zoom().on('zoom', function() {
      //     svg.attr('transform', d3.event.transform);
      //   }),
      // )
      .append('g')
      .selectAll('path')
      .data(json.features)
      .enter()
      .append('path')
      .attr('fill', '#EDEDED')
      .attr('d', d3.geoPath().projection(projection))
      .style('stroke', 'none');
    if (isMobile) {
      d3.select('body')
        .append('div')
        .attr('id', 'tooltip')
        .attr('style', 'position: absolute; opacity: 0; width: 50%;');
    } else {
      d3.select('body')
        .append('div')
        .attr('id', 'tooltip')
        .attr('style', 'position: absolute; opacity: 0; width: 20%;');
    }

    svg
      .selectAll('.m')
      .data(markers)
      .enter()
      .append('image')
      .attr('x', -10)
      .attr('y', -10)
      .attr('class', 'circle')
      .style('fill', '69b3a2')
      .attr('stroke', '#69b3a2')
      .attr('stroke-width', 3)
      .attr('fill-opacity', 0.4)
      .attr('width', 25)
      .attr('height', 25)
      .style('cursor', 'pointer')
      .on('mouseover', function(d) {
        d3.select('#tooltip').style('opacity', 1);
        const text = `<div class="row"><div class="col-md-4 col-5"><img class="rounded-3" width="100%" height="100%" src=${d.image}/></div><div class="col-md-8 col-7"><p class="mb-0 small text-greys-dark">${d.location}</p><p class="mb-0 text-black v-10">${d.name}</p></div></div>`;
        d3.select('#tooltip')
          .html(text)
          .style('background', 'white')
          .style('border-radius', '12px')
          .style('padding', '12px')
          .style('filter', 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.2))');
      })
      .on('mouseout', function(d) {
        d3.select('#tooltip').style('opacity', 0);
      })
      .on('mousemove', function(d) {
        d3.select('#tooltip')
          .style('left', `${d3.event.pageX}px`)
          .style('top', `${d3.event.pageY}px`);
      })

      .attr(
        'xlink:href',
        'https://willow.imgix.net/2022/05/mapindicator.png?fm=png&ixlib=php-3.3.0',
      )
      .attr('transform', d => {
        const p = projection([d.long, d.lat]);
        return `translate(${p[0] - 10}, ${p[1] - 10})`;
      });
  }, []);

  return <svg id="my_dataviz" width="100%" height="100%"></svg>;
}
