/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React from 'react';
import ListLayout from 'components/List/ListLayout';
import ListGroup from 'components/List';
import Icon from 'components/Icon';
import { getDate, currency } from 'utils/tradeview';
import moment from 'moment';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { toggleCapsule } from '../../../../containers/AccountPage/actions';
import { uid } from '../../../../utils/ui';

const PreviousOrder = ({
  tabs,
  primarytraderequests,
  registrationStatus,
  appraisalSection,
  propertyDetail,
  locale,
  loader,
}) => {
  // const neutralURL =
  //   propertyDetail &&
  //   propertyDetail.documents.filter(item => item.title.includes('Appraisal'));
  // const neutralLink =
  //   neutralURL && neutralURL.filter(n => n.language === locale);

  const dispatch = useDispatch();

  const seeAll = url => {
    dispatch(toggleCapsule('trades'));
    dispatch(push(url));
  };

  return (
    <div className="px-4 px-md-0">
      {registrationStatus > 2 && (
        <>
          <div className="pt-7 pb-4 w-100 d-flex justify-content-between">
            {tabs &&
              tabs.map((item, i) =>
                item.link !== '' ? (
                  <span
                    onClick={() => {
                      seeAll(item.link);
                    }}
                    key={i}
                    className="text-decoration-underline"
                  >
                    {item.header}{' '}
                    <Icon variant="chevronRight" size="sm" color="#4cd683" />
                  </span>
                ) : (
                  <span key={item.header} className="fw-bold">
                    {item.header}
                  </span>
                ),
              )}
          </div>
          {/* <ListGroup condensed="true">
            <ListGroup.Header>
              {tabs &&
                tabs.map((item, i) =>
                  item.link !== '' ? (
                    <ListGroup.Header.Link to={item.link} key={i}>
                      {item.header}
                    </ListGroup.Header.Link>
                  ) : (
                    <ListGroup.Header.Title key={item.header}>
                      {item.header}
                    </ListGroup.Header.Title>
                  ),
                )}
            </ListGroup.Header>
          </ListGroup> */}
          {loader && (
            <div className="mb-5 h-75 text-dark">
              <div className="dot-pulse ms-5 mt-5"></div>
            </div>
          )}
          {(!primarytraderequests || primarytraderequests.length === 0) && (
            <div>No previous reservations.</div> // TODO: needs translation
          )}
          {primarytraderequests && primarytraderequests.length > 0 && (
            <ListLayout
              rawdata={[
                {
                  columns: [
                    ...primarytraderequests.slice(0, 5).map(item => ({
                      rows: [
                        {
                          hr: true,
                          condensed: true,
                          columns: [
                            {
                              content: (
                                <p className="mb-1" key={uid()}>
                                  <strong>{`${'Reservation'} - ${moment
                                    .utc(item.timeStamp)
                                    .local()
                                    .calendar()}`}</strong>
                                  <br />
                                  {`${item.quantity} Unit(s) @ ${currency(
                                    item.amount,
                                  )}`}
                                </p>
                              ),
                              widths: { xs: '7' },
                            },
                            {
                              content: (
                                <p
                                  className="mb-1 px-2 d-flex flex-row-reverse"
                                  key={uid()}
                                >
                                  <span className="ps-2">{item.status}</span>
                                  <Icon variant="dot" className="text-xlight" />
                                </p>
                              ),
                            },
                          ],
                        },
                      ],
                      content: '',
                      widths: { sm: '12' },
                    })),
                  ],
                },
              ]}
            />
          )}
        </>
      )}

      {/* <div className="my-4 p-1 py-auto rounded-pill text-medium w-100 bg-primary">
        <a
          href={neutralLink[0] && neutralLink[0].url}
          className="text-dark text-decoration-none"
          target="_blank"
        >
          <div className="d-flex align-items-center p-3 ">
            {appraisalSection &&
              appraisalSection.map(aps => (
                <>
                  <div className="flex-shrink-0 ms-5 ms-xxl-8">
                    <img src={aps.appraisal_image} alt="colliers" />
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <p className="mb-0 h6 font-bold">{aps.neutral_point}</p>

                    <p className="mt-1 h6 mb-0 text-dark">{aps.third_party}</p>
                  </div>
                </>
              ))}
          </div>
        </a>
      </div> */}
    </div>
  );
};

export default PreviousOrder;
