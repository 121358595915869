/* eslint-disable import/no-unresolved */
/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { API } from 'utils/api';
import { initialState } from './reducer';

const selectGlobal = state => state.global || initialState;
const selectRouter = state => state.router;
const selectLanguage = state => state.language;
const selectForm = state => state.form;
const selectEvents = state => state.events;

const makeSelectForm = () =>
  createSelector(selectForm, globalState => globalState);

const makeSelectPathParams = () =>
  createSelector(selectForm, globalState => globalState.pathParams);

const makeSelectNotifications = () =>
  createSelector(selectGlobal, globalState => globalState.notifications);

const makeSelectLocale = () =>
  createSelector(selectLanguage, languageState => languageState.locale);
const makeSelectLocaleName = () =>
  createSelector(selectLanguage, languageState => languageState.localeName);

const makeSelectLocation = () =>
  createSelector(selectRouter, routerState => routerState.location);

const makeSelectEvents = () =>
  createSelector(selectEvents, events => events.data);

const makeSelectEnvVariablesData = () =>
  createSelector(selectGlobal, globalState => globalState.environment);

const makeSelectApi = () =>
  createSelector(selectGlobal, state => new API(state.environment.variables));

const makeSelectNavBarState = () =>
  createSelector(selectGlobal, globalState => globalState.navbar);

const makeSelectFooterState = () =>
  createSelector(selectGlobal, globalState => globalState.footer);

const makeSelectLookups = () =>
  createSelector(selectGlobal, globalState => globalState.lookups);

const makeSelectTradeProperties = () =>
  createSelector(selectGlobal, globalState => globalState.properties);
export {
  selectGlobal,
  makeSelectLocation,
  makeSelectLocale,
  makeSelectLocaleName,
  makeSelectForm,
  makeSelectNavBarState,
  makeSelectFooterState,
  makeSelectEnvVariablesData,
  makeSelectPathParams,
  makeSelectApi,
  makeSelectLookups,
  makeSelectNotifications,
  makeSelectTradeProperties,
  makeSelectEvents,
};
