/* eslint-disable react/prop-types */
/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import NotFound from 'components/NotFound';

function NotFoundPage() {
  return (
    <section className="h-100 min-vh-100 bg-primary d-flex align-items-center">
      <NotFound code="404" />
    </section>
  );
}
export default {
  component: NotFoundPage,
};
