/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Container } from 'react-bootstrap';
import WillowButton from 'components/Button';
import { isClientMobile } from 'utils/detection';
import CurvedBanner from './curvedbanner';
import Tab from '../Tab';

import mediasizes from '../../utils/sizes';

const StyledContainer = styled(
  ({ size, variant, bgImage, height, defaultactivekey, ...rest }) => (
    <Container {...rest} />
  ),
)`
  ${({ height }) =>
    css`
      min-height: 300px;
      height: ${height};
      ${mediasizes.landscape`
        height: 400vh;
      `}
    `}

  ${({ theme }) => css`
    width: 100%;
    max-width: 100%;
    position: relative;
    padding: 0;
    // padding-left: ${theme.spacing.xxlarge};
    margin: 0;
    display: flex;
    flex-direction: column;
  `}
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      min-height: 174px;
      padding-left: ${theme.spacing.xsmall};
      ${StyledHeading} {
        font-size: ${theme.typography.size.xlarge};
      }
    `}
  ${({ variant, theme }) =>
    variant === 'tabs' &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-color: ${theme.colors.greys.light};
      ${StyledHeading} {
        margin-bottom: ${theme.spacing.medium};
        padding-left: ${theme.spacing.xsmall};
      }
    `}
  ${({ variant, bgImage, theme }) =>
    variant === 'willow' &&
    css`
      background-color: ${theme.colors.brand.dark};
      background-image: url(${bgImage});
      background-position: center center;
      background-size: cover;
    `}
  ${(
    { variant, bgImage, theme }, //this variant is created for images to reflect full face of the celeb on mobile in that article
  ) =>
    variant === 'willow-illusart' &&
    css`
      background-color: ${theme.colors.brand.dark};
      background-image: url(${bgImage});
      background-position: right;
      background-size: cover;
    `}
`;

const StyledHeading = styled.h1`
  ${({ theme }) => css`
    z-index: 1;
    margin-top: auto;
    margin-bottom: auto;
    font-size: ${theme.typography.size.super};
  `}
  ${({ variant, theme }) =>
    variant === 'willow' &&
    css`
      //width: 70%;
      color: ${theme.colors.greys.white};
      font-size: ${theme.typography.size.xsuper};
      font-weight: ${theme.typography.weight.regular};
      ${mediasizes.mobile`
        //width: 80%;
        font-size: ${theme.typography.size.super};
      `}
    `}
`;

const Wave = ({ className }) => (
  <svg
    viewBox="0 0 1329 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 240C857.924 240 868.97 35.5383 1329.23 0.00012207L1329.23 240L0 240Z"
      fill="#EDEDED"
    />
  </svg>
);

const SmWave = ({ className }) => (
  <svg
    viewBox="0 0 377 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M-7 100C240.74 100 243.93 14.8076 376.839 0L376.838 100L-7 100Z"
      fill="#EDEDED"
    />
  </svg>
);

const StyledWave = styled(Wave)`
  ${({ theme }) => css`
    position: absolute;
    left: ${theme.spacing.none};
    right: ${theme.spacing.none};
    bottom: ${theme.spacing.minusone};
    width: 100%;
  `}
`;

const StyledSmWave = styled(SmWave)`
  ${({ theme }) => css`
    position: absolute;
    left: ${theme.spacing.none};
    right: ${theme.spacing.none};
    bottom: ${theme.spacing.none};
    width: 100%;
  `}
`;

const RenderWave = ({ size }) =>
  size === 'sm' ? <StyledSmWave /> : <StyledWave />;

const WillowPageHeader = props => {
  const {
    variant,
    size,
    options = [],
    curve,
    bgimage,
    defaultactivekey,
  } = props;
  const isMoblie = isClientMobile();
  return (
    <StyledContainer
      variant={props.variant}
      height={props.height}
      bgImage={props.bgImage}
      {...props}
    >
      <StyledHeading variant={props.variant}>{props.children}</StyledHeading>
      {variant === 'willow' && curve === 'true' && <RenderWave size={size} />}
      {variant === 'basic' && <RenderWave size={size} />}
      {variant === 'article' && <CurvedBanner bgimage={bgimage}></CurvedBanner>}
      {variant === 'tabs' && options.length > 0 && (
        <Tab onSelect={e => props.action(e)}>
          <Tab.Control>
            {options.map((option, i) => (
              <Tab.Item
                key={i}
                eventKey={option.key}
                itemClassName={defaultactivekey === option.key && `active`}
              >
                {option.label}
              </Tab.Item>
            ))}
          </Tab.Control>
        </Tab>
      )}
    </StyledContainer>
  );
};

export default WillowPageHeader;
