import {
  LOAD_PORTFOLIO_PAGE_DATA,
  LOAD_PORTFOLIO_PAGE_DATA_SUCCESS,
  LOAD_PORTFOLIO_PAGE_DATA_FAILURE,
  SET_TOAST,
  SET_PURCHASES
} from './constants';

export function setPurchases(purchases) {
  return {
    type: SET_PURCHASES,
    purchases,
  };
}

export function setPorfolioPageToast(toast) {
  return {
    type: SET_TOAST,
    toast,
  };
}

export function loadPortfolioPageData() {
  return {
    type: LOAD_PORTFOLIO_PAGE_DATA,
  };
}

export function loadPortfolioPageDataSuccess(data) {
  return {
    type: LOAD_PORTFOLIO_PAGE_DATA_SUCCESS,
    data,
  };
}

export function loadPortfolioPageDataFailure() {
  return {
    type: LOAD_PORTFOLIO_PAGE_DATA_FAILURE,
  };
}
