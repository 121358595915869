/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import { combineReducers } from 'redux';
import {
  LOAD_MARKETPLACE_PAGE_DATA,
  LOAD_MARKETPLACE_PAGE_DATA_SUCCESS,
  LOAD_MARKETPLACE_PAGE_DATA_FAILURE,
  LOAD_PROPERTY_DETAILS,
  LOAD_PROPERTY_DETAILS_SUCCESS,
  LOAD_PROPERTY_DETAILS_FAILURE,
} from './constants';

const initialProperties = {
  data: false,
  loading: false,
  error: false,
};
const initialWpContent = {
  data: false,
  loading: false,
  error: false,
};
export const initialState = {
  property: initialProperties,
  wpcontent: initialWpContent,
};
const properties = (state = initialProperties, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_PROPERTY_DETAILS:
        draft.data = false;
        draft.error = false;
        draft.loading = true;
        break;
      case LOAD_PROPERTY_DETAILS_SUCCESS:
        draft.data = action.data;
        draft.error = false;
        draft.loading = false;
        break;
      case LOAD_PROPERTY_DETAILS_FAILURE:
        draft.data = false;
        draft.error = true;
        draft.loading = false;
        break;
    }
  });
const wpcontent = (state = initialWpContent, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_MARKETPLACE_PAGE_DATA:
        draft.loading = true;
        draft.error = false;
        draft.data = false;
        break;
      case LOAD_MARKETPLACE_PAGE_DATA_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.data = action.data;
        break;
      case LOAD_MARKETPLACE_PAGE_DATA_FAILURE:
        draft.loading = false;
        draft.error = true;
        draft.data = false;
        break;
    }
  });

const marketPlacePageReducer = combineReducers({
  properties,
  wpcontent,
});

export default marketPlacePageReducer;
