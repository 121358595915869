/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import RenderField from 'components/RenderField';
import Button from 'components/Button';
import { Field } from 'redux-form';
import {
  required,
  email,
} from 'utils/validation';
function execFn(fnName, ctx, params) {
  if (params) {
    return ctx[fnName](...params);
  }
  return ctx[fnName];
}
const context = {
  required,
  email,
};
const renderSingleField = (item, index2, director) => (
  <Col className="mb-2" key={index2} sm={12} md={12}>
    {item.paragraphs &&
      item.paragraphs.map((para, paraIndex) => (
        <Elements key={paraIndex} tag={para.tag} className={para.class}>
          {para.content}
        </Elements>
      ))}
    <Field
      name={`${director}.${item.name}`}
      type={item.type}
      label={item.label}
      stickyph={item.stickyph}
      className={item.class}
      autofillcolor={item.autofillcolor}
      component={RenderField}
      disabled={item.disabled}
      options={[]}
      validate={
        item.validate &&
        (item.validate === 'ignorevalidation'
          ? reasonForNoTINRequired
            ? [required]
            : null
          : [execFn(item.validate, context), required])
      }
      max={''}
      decimalscale={item.decimalscale}
    />
  </Col>
);

const renderFieldsRow = (formRow, index, director) => (
  <Row key={`form-row-${index}`} className={formRow.class}>
    {formRow.fields &&
      formRow.fields.map((item, index2) => renderSingleField(item, index2, director))}
  </Row>
);

const renderFields = ({ layout, fields, buttons }) => {
  useEffect(() => {
    if (fields.length < 1) {
      fields.push({});
    }
  }, []);
  return (
  <div>
    {fields.map((director, index) => (
      <div key={index}>
        
        {layout.map((formRow, lyindex) => renderFieldsRow(formRow, lyindex, director))}
        <Row key={`form-row-add-button}`} className="justify-content-center"> 

        {fields.length > 1 && buttons.filter(btn => btn.value === 'removeFields').map(item => <Button
                  type={item.type}
                  variant={item.variant}
                  className={item.class}
                  id={item.id ? item.id : item.label}
                  key={item.id ? item.id : item.label}
                  disabled={item.disabled && item.disabled}
                  onClick={() => fields.remove(index)}
                  aria-label={item.label}
                >
                {item.label}
                </Button>)}
                </Row>
      </div>
    ))}
    <Row key={`form-row-add-button}`} className="justify-content-center"> 
    {buttons.filter(btn => btn.value === 'addFields').map(item => <Button
                  type={item.type}
                  variant={item.variant}
                  className={item.class}
                  id={item.id ? item.id : item.label}
                  key={item.id ? item.id : item.label}
                  disabled={item.disabled && item.disabled}
                  onClick={() => fields.push({})}
                  aria-label={item.label}
                >
                {item.label}
                </Button>)}
                </Row>
                
  </div>
)};

export default renderFields;


