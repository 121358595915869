export const flinks = (
  emailaddress,
  triggerAuthTracker,
  signOut,
  auth,
  requireSmsVerification,
  toggleVerificationModal,
  toggleCredentialsModal,
  authFailed,
  authChanged,
  dispatch,
  accountPage,
  setAccountPageToast,
  verificationId,
  resolver,
  setModalErrorMessage,
  setFlinksModal,
) => {
  dispatch(setFlinksModal(true));
};
