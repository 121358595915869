import React from 'react';
import './Sidepanel.scss';
import Icon from 'components/Icon';

const WillowSidepanel = ({ children, isVisible, toggleSidepanel }) => {
  const iconColor = {
    color: '#191919',
  };

  const hidden = !isVisible ? 'ishidden' : '';

  return (
    <div className={`${hidden} mainContainer`}>
      <section className="container">
        <label
          className={`${hidden} sidepanel__close`}
          htmlFor="sidepanelBtn"
          onClick={toggleSidepanel}
        ></label>

        <div className={`${hidden} sidepanel`}>
          <div className="d-flex justify-content-end mt-5">
            <label
              className="sidepanel__button bg-white"
              htmlFor="sidepanelBtn"
              role="button"
            >
              <span className="d-flex justify-content-center p-sm-0 p-md-1">
                <Icon
                  variant="close"
                  style={iconColor}
                  aria-hidden="true"
                  onClick={toggleSidepanel}
                  size="small"
                />
              </span>
            </label>
          </div>

          <p
            className="px-5"
            dangerouslySetInnerHTML={{ __html: children }}
          ></p>
        </div>
      </section>
    </div>
  );
};

export default WillowSidepanel;
