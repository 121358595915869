/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
const StyledIcon = styled(Icon)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.xxxsmall};
    color: ${theme.colors.brand.primary};
  `}
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.greys.medium};
    `}
`;
const StyledA = styled(Link)`
  ${({ theme }) => css`
      cursor: pointer;
      /* text-decoration: underline; */
      color: ${theme.colors.greys.xdark};
      outline: none;
      border: 2px solid transparent;
      padding: ${theme.spacing.xxxsmall};
      position: relative;
      &:hover {
        color: ${theme.colors.greys.dark};
      }

      &::before {
        content: "";
        position: absolute;
        opacity: 0;
        width: calc(100% + 2px);
        height: calc(100% + 1px);
        top: 0px;
        left: -2px;
        right: 0;
        bottom: 0;
        box-shadow: 0 0 0 2px ${theme.colors.brand.xlight};
        background-color: transparent !important;
        transition: all ${theme.durations.fast};
      }

      &:focus {
        &::before {
          opacity: 1;
        }
      }

      &:focus:not(:focus-visible) {
        &::before {
          opacity: 0;
        }
      }
    }
  `}
  ${({ variant, theme }) =>
    variant === 'light' &&
    css`
      color: ${theme.colors.greys.xlight};
      &:hover {
        color: ${theme.colors.greys.light};
      }
    `}
  ${({ variant, theme }) =>
    variant === 'emphasis' &&
    css`
      color: ${theme.colors.brand.dark};
      &:hover {
        color: ${theme.colors.brand.medium};
      }
    `}
  ${({ variant, theme }) =>
    variant === 'cta' &&
    css`
      color: ${theme.colors.brand.dark};
      &:hover {
        color: ${theme.colors.brand.medium};
      }
    `}
    //no decoration
    
    ${({ nodecoration }) =>
      nodecoration &&
      css`
        text-decoration: none;
      `}
  // Disabled
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      text-decoration: none;
    `}

  ${({ disabled, variant, theme }) =>
    disabled &&
    variant === 'emphasis' &&
    css`
      color: ${theme.colors.greys.dark};
    `}

  ${({ disabled, variant, theme }) =>
    disabled &&
    variant === 'cta' &&
    css`
      color: ${theme.colors.greys.dark};
    `}
`;

const WillowLink = props => (
  <StyledA
    {...props}
    as={props.type === 'mail' || props.type === 'external' ? 'a' : undefined}
    href={
      props.type === 'mail' || props.type === 'external' ? props.to : undefined
    }
    to={props.to}
  >
    {props.children}
    {props.variant === 'cta' && (
      <StyledIcon disabled={props.disabled} variant="chevronRight" />
    )}
  </StyledA>
);

export default WillowLink;
