/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const StyledDiv = styled(Container)`
svg {
  width: 100px;
  display: block;
  margin: 0px auto 0;
  background: #F4D424;
  border-radius: 50px;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash .9s ease-in-out;
    animation: dash .9s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash .9s .35s ease-in-out forwards;
    animation: dash .9s .35s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check .9s .35s ease-in-out forwards;
    animation: dash-check .9s .35s ease-in-out forwards;
  }
}

p {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
  &.success {
    color: #73AF55;
  }
  &.error {
    color: #D06079;
  }
}


@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}


  .circle-loader {
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-left-color: #d7b02f;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    vertical-align: top;
    border-radius: 50%;
    width: 1em;
    height: 1em;
  }

  .load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: #d7b02f;
    transition: border 500ms ease-out;
  }

  .checkmark {
    display: none;
  }
  .checkmark.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }
  .checkmark:after {
    opacity: 1;
    height: 0.75em;
    width: 0.3em;
    webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    border-right: 3px solid #d7b02f;
    border-top: 3px solid #d7b02f;
    content: '';
    left: 0.1em;
    top: 0.6em;
    position: absolute;
  }

  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: 0.3em;
      opacity: 1;
    }
    40% {
      height: 0.75em;
      width: 0.3em;
      opacity: 1;
    }
    100% {
      height: 0.75em;
      width: 0.3em;
      opacity: 1;
    }
  }
`;
const Loader = ({ complete, message }) => (
  <StyledDiv className="justify-content-center">
    {/* <div className={`circle-loader ${complete && 'load-complete'} `}>
      <div className={`checkmark draw ${complete && 'd-block'}`}></div>
    </div> */}
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
      <circle class="path circle" fill="none" stroke="#3E2F5A" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
      <polyline class="path check" fill="none" stroke="#3E2F5A" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
    </svg>
    {/* <p class="success">Oh Yeah!</p>
    <p class="error">Bummer!</p> */}
    {complete && (
      <div
        className="text-black text-center mt-2"
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      ></div>
    )}
  </StyledDiv>
);

export default Loader;
