import moment from 'moment';
import Stomp from 'stompjs';

let socket = null;
let stomp = null;
let count = 0;
let fails = [];

const check = async () => {
  if (fails.length > 1) { // If it has failed couple times
    const last = fails[fails.length - 1];
    if (Math.abs(last.diff(moment(), 'seconds')) < 2) {
      count++;
    } else {
      count--;
    }
    if (count > 5) {
      console.error('Failed to maintain a connection with the web socket.')
      await new Promise(resolve => {
        setTimeout(() => { }, 1000)
      })
    }
  }
  fails.push(moment()); // When it fails  
}

export const connect = async (api, callback) => {
  if (stomp !== null) {
    try {
      await stomp.disconnect();
      stomp = null;
    }
    catch (e) {
      // fail silently
    }
  }
  if (socket !== null) {
    // TODO: check for dispose
    socket = null;
  }
  const token = await api.getToken();
  socket = api.getSocket();
  stomp = Stomp.over(socket);
  stomp.heartbeat.incoming = 5000;
  stomp.heartbeat.outgoing = 5000;

  stomp.debug = function (str) {
    // do not show logs
  };

  stomp.connect(
    {
      token: `${token}`,
    },
    frame => {
      try {
        stomp.subscribe('/events', async message => {
          const response = JSON.parse(message.body);
          callback(response);
        });
        stomp.subscribe('/user/events', async message => {
          const response = JSON.parse(message.body);
          await callback(response);
        });
      }
      catch (error) {
        console.error(error);
      }
    },
    async () => {
      await check();
      setTimeout(() => connect(api, callback), 1);
    },
  );
};
