import React, { useState, useEffect } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  Sector,
  ResponsiveContainer,
} from 'recharts';

const colors = ['#317040', '#4cd583', '#45965a'];

const Tip = ({ setShowTooltip, ...rest }) => {
  const [payload, setPayload] = useState(rest.payload);

  useEffect(() => {
    rest.payload.length && setPayload(rest.payload);
  }, [rest.payload]);

  return payload.length ? (
    <div
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      // Prevent Rechart events while the mouse is over the tooltip
      onMouseMove={e => e.stopPropagation()}
      className="bg-black p-3 text-center rounded"
    >
      <p className="h5 text-white">{payload[0].payload.location}</p>
      <span className="h6 text-white">
        {payload[0].payload.assetPerformance.toFixed(2)}%
      </span>
    </div>
  ) : null;
};

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />

      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        fill="#000"
        textAnchor={textAnchor}
        dominant-baseline="middle"
        // filter="url(#solid)"
      >
        {`${payload.payload.propertyLocation}`}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={21}
        textAnchor={textAnchor}
        fill="#999"
        // filter="url(#solid)"
      >
        {`${payload.payload.assetPerformance}%`}
      </text>
    </g>
  );
};

const PortfolioPieChart = ({ data }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div>
      <ResponsiveContainer width="100%" height={280}>
        <PieChart
          width={225}
          height={300}
          onMouseLeave={() => {
            setShowTooltip(false);
          }}
        >
          <Pie
            data={data && data}
            onMouseEnter={() => {
              setShowTooltip(true);
            }}
            cx="50%"
            cy="50%"
            outerRadius={70}
            innerRadius={45}
            fill="#8884d8"
            dataKey="assetPerformance"
            // label={renderActiveShape}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>

          {showTooltip && (
            <Tooltip
              isAnimationActive={false}
              content={<Tip setShowTooltip={setShowTooltip} />}
              wrapperStyle={{ visibility: 'visible', pointerEvents: 'auto' }}
            />
          )}

          <Legend
            payload={data.map((item, index) => ({
              id: item.name,
              type: 'square',
              value: `${item.location} (${item.assetPerformance.toFixed(2)}%)`,
              color: colors[index % colors.length],
            }))}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PortfolioPieChart;
