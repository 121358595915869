/* eslint-disable react/prop-types */
/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import Link from 'components/Link';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'components/Button';
import Logo404 from './logo.png';
import Logo502 from './BadGateWay.png';

function NotFound({ code }) {
  return (
    <Container className="bg-primary">
      <Row className="justify-content-center align-items-center">
        <Col md={12} className="text-center">
          <Row className="justify-content-center">
            {code === '404' ? (
              <img src={Logo404} alt="404" className="pb-5 w-25" />
            ) : (
              <img src={Logo502} alt="502" className="pb-5 w-25" />
            )}
          </Row>
          <Row className="justify-content-center">
            {code === '404' ? (
              <h3 className="text-white m-2">Oops!</h3>
            ) : (
              <h3 className="text-white m-2">Bad Gateway</h3>
            )}
          </Row>
          <Row className="justify-content-center">
            <h6 className="p-b text-white">{code} Error</h6>
          </Row>
          <Row className="justify-content-center my-4">
            {code === '404' ? (
              <p className="h3 p-b text-white">
                We can&apos;t seem to find the page you&apos;re looking for.
              </p>
            ) : (
              <p className="h3 p-b text-white">
                Whoops, someone dropped a wrench. We will be back up quickly.
                Please try again soon.
              </p>
            )}
          </Row>
        </Col>
        {code === '404' ? (
          <Col md={4} className="text-center">
            <Link
              to="https://www.midasboard.com/"
              variant="basic"
              disabled={false}
              nodecoration="true"
              type="external"
            >
              <Button variant="primary-alt">Home Page</Button>
            </Link>
          </Col>
        ) : null}
      </Row>
    </Container>
  );
}
export default NotFound;
