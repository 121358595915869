/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { Overlay, Popover } from 'react-bootstrap';

import Button from '../Button';
import Icon from '../Icon';
// import mediasizes from "../../utils/sizes";

// Main
const StyledPopover = styled(Popover)`
  ${({ theme }) => css`
    width: ${props => props.bodywidth};
    border: 1px solid ${theme.colors.greys.black};
    border-radius: ${theme.shapes.borderRadiusMedium};
    background-color: ${theme.colors.greys.black};
    color: ${theme.colors.greys.white};
    box-shadow: ${theme.depths['3']};
  `}
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      padding-bottom: ${theme.spacing.medium};
    `}
  ${({ placement, theme }) =>
    placement === 'left' &&
    css`
      .popover-arrow::after {
        border-left-color: ${theme.colors.greys.black};
      }
    `}
  ${({ placement, theme }) =>
    placement === 'top' &&
    css`
      .popover-arrow::after {
        border-top-color: ${theme.colors.greys.black};
      }
    `}
  ${({ placement, theme }) =>
    placement === 'right' &&
    css`
      .popover-arrow::after {
        border-right-color: ${theme.colors.greys.black};
      }
    `}
  ${({ placement, theme }) =>
    placement === 'bottom' &&
    css`
      .popover-arrow::after {
        border-bottom-color: ${theme.colors.greys.black};
      }
    `}
`;

const StyledClose = styled(Icon)`
  ${({ theme }) => css`
    cursor: pointer;
    padding-bottom: ${theme.spacing.small};
    :focus {
      outline: none;
      box-shadow: 0 0 0 2px ${theme.colors.brand.xlight};
    }
  `}
`;

const StyledPopoverTitle = styled(props => <Popover.Title {...props} />)`
  ${({ theme }) => css`
    border-radius: ${theme.shapes.borderRadiusSmall};
    color: ${theme.colors.greys.white};
    font-weight: ${theme.typography.weight.medium};
    font-size: ${theme.typography.size.small};
    line-height: ${theme.typography.lineHeight.medium};
    margin-bottom: 0;
    align-self: flex-end;
  `}
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      font-size: ${theme.typography.size.xsmall};
    `}
`;

const StyledPopoverContent = styled(props => <Popover.Body {...props} />)`
  ${({ theme }) => css`
    border-radius: ${theme.shapes.borderRadiusSmall};
    color: ${theme.colors.greys.white};
    // padding: ${theme.spacing.xsmall} ${theme.spacing.none} ${theme.spacing.xsmall};
    font-weight: ${theme.typography.weight.regular};
    font-size: ${theme.typography.size.small};
    line-height: ${theme.typography.lineHeight.medium};
  `}
  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      font-size: ${theme.typography.size.xsmall};
      // padding: ${theme.spacing.xxsmall} ${theme.spacing.none} ${theme.spacing.xsmall};
    `}
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const WillowTooltip = props => {
  // const [show, setShow] = useState(false);
  // const [target, setTarget] = useState(null);
  const ref = useRef(null);

  // const handleClick = (event) => {
  //   setShow(!show);
  //   setTarget(event.target);
  // };

  const { placement = 'top', message, button, size, target, show } = props;
  return (
    <div>
      {/* <Button onClick={handleClick}>Click</Button> */}

      <Overlay
        show={show}
        target={target}
        placement={placement}
        // container={ref.current}
      >
        <StyledPopover>
          {/* <div className="d-flex justify-content-between"> */}
          {/* <StyledPopoverTitle size={size} as="h3">
              {heading}
            </StyledPopoverTitle> */}
          {/* <StyledClose
              variant="close"
              onClick={() => setShow(false)}
              onKeyPress={() => setShow(false)}
              tabIndex="0"
            /> */}

          {/* </div> */}

          <StyledPopoverContent size={size}>{message}</StyledPopoverContent>
          {/* {button && (
            <StyledButton
              variant="basic-alt"
              size="sm"
              onClick={button.onClick}
            >
              {button.text}
            </StyledButton>
          )}
          */}
        </StyledPopover>
      </Overlay>
    </div>
  );
};

export default WillowTooltip;
