/* eslint-disable no-return-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import Button from 'components/Button';
import Loader from 'components/Loader';
import Seo from 'components/Seo';
import CenterPanel from 'components/Widget/Tradeview/CenterPanel';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FloatingBtns, Overlay } from 'components/Panel/tradeview';
import LeftPanel from 'components/Panel/LeftPanel/LeftPanel';
import RightPanel from 'components/Widget/Tradeview//RightPanel';
import { makeSelectUsers } from 'containers/App/Users/selectors';
import { getKycStatus } from 'utils/kyc';
import {
  makeSelectApi,
  makeSelectTradeProperties,
  makeSelectLocale,
  makeSelectEnvVariablesData,
} from 'containers/App/selectors';
import { makeSelectTradeViewPage } from './selectors';
import {
  toggleStage,
  toggleTradeTab,
  toggleMainTab,
  submitForm,
  submitReviewOrder,
  submitWatchlist,
  removeWatchlist,
  toggleCapsule,
  setSymbol,
  loadTradeviewContentSuccess,
  toggleDownload,
  clickedDocument,
} from './actions';
import reducer from './reducer';
import saga from './saga';

const key = 'tradeview';

const stateSelector = createStructuredSelector({
  pageData: makeSelectTradeViewPage(),
  users: makeSelectUsers(),
  api: makeSelectApi(),
  env: makeSelectEnvVariablesData(),
  tradeProperties: makeSelectTradeProperties(),
  locale: makeSelectLocale(),
});

const TradeView = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const location = useLocation();
  const { pageData, api, users, locale, env } = useSelector(stateSelector);
  const currentURL = `${env.variables.DOMAIN}${location.pathname}`;

  const { wpcontent, reviewData, ipoDoc } = pageData;

  const [data, setPageData] = useState(null);
  const [kycFinancialData, setKycFinancialData] = useState(null);

  const kycData = kycFinancialData && kycFinancialData;

  useEffect(() => {
    // setLoader(true);
    const loadData = async () => {
      const someData = await import(`./pageContent_${locale}.json`);
      const dataBasedOnPath = someData.default;
      dispatch(loadTradeviewContentSuccess(dataBasedOnPath))
      setPageData(dataBasedOnPath);
      // setLoader(false);
    };
    loadData();
  }, []);

  useEffect(() => {
    // setLoader(true);
    const loadData = async () => {
      const someData = await import(`./kycFinancial_${locale}.json`);
      const dataBasedOnPath = someData.default;
      setKycFinancialData(dataBasedOnPath);
      // setLoader(false);
    };
    loadData();
  }, []);

  

  const pageContent = data && data.content;

  const propertyContent = wpcontent.issue && wpcontent.issue;
  const userDocuments = wpcontent.documents && wpcontent.documents;

  const { symbol } = useParams();

  const [isLeftOpen, setIsLeftOpen] = useState(false);
  const [isRightOpen, setIsRightOpen] = useState(false);

  // List all the properties from redux store
  // const property = tradeProperties && tradeProperties.data.results;
  const property = wpcontent.issues && wpcontent.issues.results;

  const authenticationStatus =
    users && users.current && users.current.isAuthenticated;

  const isUserAuthenticated = authenticationStatus; // firebase
  const tradingStatus = 'IPO'; // Property Object
  const kycRegistrationStatus =
    users &&
    users.current &&
    users.current.registration &&
    users.current.registration.status;
  const anybankTransaction =
    users &&
    users.current &&
    users.current.account &&
    users.current.account.bankTransactions;

  const anyAvailableFunds =
    users &&
    users.current &&
    users.current.account &&
    users.current.account.availableFunds;
  const kyccompletionStatus =
    users && users.current && users.current.registrationStatus;

  const isKycDone = kyccompletionStatus > 2; // Kyc service
  const centerPanlekycStatus = isKycDone ? 'kyc_done' : 'kyc_pending';

  const userAuthStatus =
    isUserAuthenticated === true ? 'authenticated' : 'unauthenticated';

  const centerPanelContent =
    pageContent &&
    pageContent
      .filter(item => typeof item.center_panel !== 'undefined')
      .map(item => item.center_panel);
  const rightPanelContent =
    pageContent &&
    pageContent
      .filter(item => typeof item.right_panel !== 'undefined')
      .map(item => item.right_panel);
  const centerPanelContentBasedOnStatus =
    centerPanelContent &&
    centerPanelContent.find(
      item =>
        item.status === userAuthStatus &&
        item.view === centerPanlekycStatus &&
        item.category_name === tradingStatus,
    );
   
  const rightPanelContentBasedOnStatus =
    rightPanelContent &&
    rightPanelContent.find(
      item =>
        item.status === userAuthStatus &&
        item.view ===
          getKycStatus(
            kycRegistrationStatus,
            anybankTransaction,
            anyAvailableFunds,
          ) &&
        item.category_name === tradingStatus,
    );
  // List the individual property
  const propertyData = wpcontent.issue && wpcontent.issue.detail

  const reviewOrder = reviewData && reviewData.data;
  const revieworderError = reviewData && reviewData.errorMessage;

  useEffect(() => {
    if (reviewData.data !== false) {
      const openSigningWindow = () => {
        if (!reviewOrder.embeddedURL) {
          console.error('Signing URL is not available');
          return;
        }
        // Open the signing URL in a new tab
        const signingWindow = window.open(reviewOrder.embeddedURL, '_blank');
  
        // Check if the signing window is available before attaching onload event
        if (!signingWindow) {
          console.error('Failed to open signing window');
          return;
        }
  
  
        // Listen for the window load event
        signingWindow.onload = () => {
          // Initialize DocuSign and embed the agreement using the window's document
          window.DocuSign.loadDocuSign('bccd7ade-ddab-417a-8ee3-0e605fc588f8').then((docusign) => {
            const signing = docusign.signing({
              displayFormat: 'focused',
              style: {
                branding: {
                  primaryButton: {
                    backgroundColor: '#333',
                    color: '#fff',
                  }
                },
                signingNavigationButton: {
                  finishText: 'Custom Button Text',
                  position: 'bottom-left'
                }
              }
            });
  
            // Mount the agreement within the signing window's document
            signing.mount(signingWindow.document.body);
  
            // Listen for the ready event
            signing.on('ready', (event) => {
              console.log('UI is rendered');
            });
  
            // Listen for the sessionEnd event
            signing.on('sessionEnd', (event) => {
              console.log('Session ended:', event);
              // Close the signing window when the session ends
              signingWindow.close();
            });
          }).catch((ex) => {
            // Handle errors
            console.error('Error loading DocuSign:', ex);
          });
        };
      };
  
      openSigningWindow();
    }
    
  }, [reviewData]);

  const primarytraderequests =
    users &&
    users.current &&
    users.current.primaryTradeRequests &&
    users.current.primaryTradeRequests[symbol];

  const account = users && users.current && users.current.account;
  const tradingFee =
    users &&
    users.current &&
    users.current.account &&
    users.current.account.tradingFee;

  const watchList = users && users.current && users.current.watchlist;

  const holdings =
    users &&
    users.current &&
    users.current.userProperties &&
    users.current.userProperties.properties.filter(
      item => item.symbol === symbol,
    );

  const registrationStatus =
    users && users.current && users.current.registrationStatus;

  // const investorType =
  //   kycData &&
  //   kycData.registration &&
  //   kycData.registration.financial &&
  //   kycData.registration.financial.investorType;

  // const invName =
  //   kycData && kycData.registration && kycData.registration.personal;

  // const investorName = `${invName && invName.firstName} ${
  //   invName && invName.middleName ? invName.middleName : ''
  // } ${invName && invName.lastName}`;
  const investorName = '';

  // LEFT PANEL CONTROLLER
  const toggleLeft = () => {
    if (isRightOpen) {
      setIsRightOpen(false);
    }
    setIsLeftOpen(!isLeftOpen);
  };

  // RIGHT PANEL CONTROLLER
  const toggleRight = () => {
    if (isLeftOpen) {
      setIsLeftOpen(false);
    }
    setIsRightOpen(!isRightOpen);
  };

  useEffect(() => {
    if (isLeftOpen || isRightOpen) {
      document.body.style.overflow = 'hidden';
    } 
    else {
      document.body.style.overflow = 'visible';
    }
  }, [isLeftOpen, isRightOpen]);

  // const { tags } = kycData.registration && kycData.registration.financial;
  if (users.initialized || pageData) {
  return (
    <Container fluid="xxl" className="position-relative">
      <Seo
        title={propertyContent && propertyContent.name}
        description={propertyContent && propertyContent.description}
        // image={propertyData.images[0].url}
        url={currentURL}
        script={[
          {
            async: true,
            defer: true,
            id: 'locallogicscript',
            src:
              'https://cdn.locallogic.co/sdk/?token=ac4a6b76b0d61b916e1967b83759f2b8b4e43b6a4978b1065418c6ff836ed0e33b4c29f62bf51a43',
            type: 'text/javascript',
          },
        ]}
      />
      <Row>
        {property && (
          <LeftPanel
            active={symbol}
            property={property}
            toggleLeft={toggleLeft}
            isLeftOpen={isLeftOpen}
            api={api}
          />
        )}

        <Overlay
          className="d-block d-xl-none"
          active={isLeftOpen || isRightOpen ? 1 : 0}
          onClick={toggleLeft}
        />
        {centerPanelContentBasedOnStatus && property && (
          <Col className="px-3 ps-xl-6 pb-8">
            <Row className="py-9 py-xl-7"></Row>
            <Row className="no-gutters">
              <CenterPanel
                // lat={propertyData && propertyData.coordinates.latitude}
                // lng={propertyData && propertyData.coordinates.longitude}
                rawdata={centerPanelContentBasedOnStatus}
                toggleMainTab={v => dispatch(toggleMainTab(v))}
                activeMainTab={wpcontent.activeMainTab}
                activeTradeTab={wpcontent.activeTradeTab}
                toggleTradeTab={v => {
                  dispatch(toggleTradeTab(v));
                }}
                toggleCapsule={v => dispatch(toggleCapsule(v))}
                activeCapsule={wpcontent.activeCapsule}
                propertyContent={propertyContent}
                tradeImages={propertyData && propertyData.images}
                propertyDetail={propertyData && propertyData}
                symbol={symbol}
                holdings={holdings}
                submitWatchlist={v => dispatch(submitWatchlist(v, symbol))}
                removeWatchlist={v => dispatch(removeWatchlist(v, symbol))}
                watchList={watchList}
                authenticationStatus={authenticationStatus}
                locale={locale}
                userDocuments={userDocuments}
                toggleDownload={(v) => dispatch(toggleDownload(v))}
                clickDocument={(v) => dispatch(clickedDocument(v))}
              />
              {propertyData && (
                <RightPanel
                  loader={wpcontent.orderboxLoader}
                  // tags={tags}
                  tags=""
                  financialfields={kycData}
                  rawdata={rightPanelContentBasedOnStatus}
                  // orders={propertyData && propertyData.orders}
                  toggleRight={toggleRight}
                  isRightOpen={isRightOpen}
                  toggleStage={v => dispatch(toggleStage(v))}
                  activeStage={wpcontent.activeStage}
                  redirect={v => dispatch(push(v))}
                  submitReviewOrder={v =>
                    dispatch(submitReviewOrder(v, symbol))
                  }
                  submitForm={v => dispatch(submitForm(v, symbol))}
                  account={account}
                  propertyContent={propertyContent}
                  // ipoDetails={propertyData && propertyData.ipoDetails}
                  symbol={symbol}
                  reviewOrder={reviewOrder}
                  primarytraderequests={primarytraderequests}
                  revieworderError={revieworderError}
                  tradingFee={tradingFee}
                  registrationStatus={registrationStatus}
                  // investorType={investorType}
                  investorName={investorName}
                  locale={locale}
                  propertyDetail={propertyData && propertyData}
                />
              )}
            </Row>
          </Col>
        )}
      </Row>
      {/* <FloatingBtns
        className="d-md-none"
        fluid
        show={!isLeftOpen && !isRightOpen ? 1 : 0}
      >
        <Button aria-label="Buy" className="w-100" onClick={toggleRight}>
          {locale === 'en' ? 'Buy' : 'Acheter'}
        </Button>
      </FloatingBtns> */}
    </Container>
  )}
  else {
    return (
      <>
        <Seo
          // title={propertyContent.name}
          // description={propertyContent.description}
          // image={propertyData.images[0].url} // TODO: this needs to be a generic image that comes from wp
          url={currentURL}
        />
        <Loader />
      </>
    );
  }
};

export default {
  component: withTheme(TradeView),
};
