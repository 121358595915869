export const AUTH_CHANGED = 'App/Auth/AUTH_CHANGED';
export const REQUIRE_SMS_VERIFICATION = 'App/Auth/REQUIRE_SMS_VERIFICATION';

export const MFA_REQUIRED = 'App/Auth/MFA_REQUIRED';

export const RESET_PASSWORD = 'App/Auth/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'App/Auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'App/Auth/RESET_PASSWORD_FAIL';

export const APPLY_ACTION = 'App/Auth/APPLY_ACTION';

export const SIGN_OUT_SUCCESS = 'App/Auth/SIGN_OUT_SUCCESS';

export const SIGN_OUT_ALL_DEVICES = 'App/Auth/SIGN_OUT_ALL_DEVICES';

export const TRIGGER_AUTH_TRACKER = 'app/AuthPage/TRIGGER_AUTH_TRACKER';
export const AUTH_FAILED = 'app/AuthPage/AUTH_FAILED';

export const SET_PARAMS = 'app/AuthProvider/SET_PARAMS';
export const INACTIVE_MODAL = 'INACTIVE_MODAL';
export const INACTIVE = 'INACTIVE';

export const GIFT_BOX_STATE = 'GIFT_BOX_STATE';
