/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import { LOCATION_CHANGE } from 'connected-react-router';
import { API_ENPOINT_PREFIX } from 'containers/App/constants';
import {
  makeSelectEnvVariablesData,
  makeSelectLocale,
  makeSelectLocation,
} from 'containers/App/selectors';
import { CHANGE_LOCALE } from 'containers/LanguageProvider/constants';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { wpurl, isServer } from 'utils/detection';
import request from 'utils/request';
import { setErrorMessage } from '../AccountPage/actions'; // TODO: What is this used for?
import { loadAuthPageContentSuccess, setParams } from './actions';
import { LOAD_AUTHPAGE_CONTENT } from './constants';

// function* loadContent(API_URL) {
//   // Wordpress content
//   const locale = yield select(makeSelectLocale());
//   const slug = yield select(makeSelectLocation());
//   const pagename = slug.pathname.split('/')[2];
//   const authPages = ['login', 'resetpassword', 'register', 'forgot'];
//   const API_URL_AUTHPAGE = `${API_URL}/getauthpagecontent`;
//   if (authPages.includes(pagename)) {
//     const requestUrl = `${API_URL_AUTHPAGE}/${locale}/${pagename}`;
//     try {
//       const result = yield call(request, requestUrl);
//       yield put(loadAuthPageContentSuccess(result));
//     } catch (error) {
//       // yield put(loadAuthPageContentFailure(error));
//     }
//   }
// }

function* getParams() {
  // need this to get oobCode and email to reset password
  const location = yield select(makeSelectLocation());
  if (
    location.query &&
    Object.keys(location.query).length !== 0 &&
    Object.getPrototypeOf(location.query) === Object.prototype
  ) {
    if (typeof location.query.email !== 'undefined') {
      location.query.email = unescape(location.query.email);
      yield put(setParams(location.query));
    }
  }
}

export default function* authPageSaga() {
  // const { variables } = yield select(makeSelectEnvVariablesData());
  // const API_URL = `${wpurl(variables)}/${API_ENPOINT_PREFIX}`; // wordpressURL
  if (!isServer) {
    // page load
    yield call(getParams);
    // yield call(loadContent, API_URL);
  }
  // action handlers
  // yield takeLatest(
  //   [LOCATION_CHANGE, LOAD_AUTHPAGE_CONTENT, CHANGE_LOCALE],
  //   loadContent,
  //   API_URL,
  // );
  yield takeLatest([CHANGE_LOCALE, LOCATION_CHANGE], function*() {
    yield put(setErrorMessage(false));
  });
}
