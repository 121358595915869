/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import mediasizes from '../../utils/sizes';

const StyledModal = styled(({ hideClose, ...rest }) => <Modal {...rest} />)`
  ${({ hideClose }) =>
    hideClose &&
    css`
      .btn-close {
        display: none;
      }
    `}

  ${({ dialogWidth }) =>
    dialogWidth &&
    css`
      .modal-dialog {
        max-width: 94%;
      }
    `}

  ${({ videodialogWidth }) =>
    videodialogWidth &&
    css`
      .modal-dialog {
        max-width: 50%;
      }
      ${mediasizes.tablet`
      .modal-dialog {
        max-width: 75%;
      }
    `}
      ${mediasizes.mobile`
      .modal-dialog {
        max-width: 100%;
      }
    `}
    `}

  ${({ bodyBackground }) =>
    bodyBackground &&
    css`
      .modal-body {
        background: #ededed;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
      }
    `}
    .modal-backdrop. {
      opacity: ${props => (props.hidebackground ? 0.9 : 0.5)};
    }
      /* ${({ hidebackground }) =>
        hidebackground &&
        css`
          .modal-backdrop.in {
            opacity: 0.9 !important;
          }
        `} */
`;

function PrimaryModal({
  show,
  close,
  body,
  modalTitle,
  footerProps,
  hideClose,
  dialogWidth,
  videodialogWidth,
  bodyBackground,
  hidebackground,
  blockscroll,
}) {
  if (hidebackground && show) {
    if (document.getElementsByClassName('modal-backdrop')) {
      const elems = document.getElementsByClassName('modal-backdrop');
      let index = 0;
      const { length } = elems;
      for (index; index < length; index++) {
        elems[index].style.opacity = 0.9;
      }
    }
  }
  useEffect(() => {
    if (show && blockscroll) {
      document.body.style.overflow = 'hidden';
    }
    document.body.style.overflow = 'unset';
  }, [show]);
  return (
    <StyledModal
      show={show}
      onHide={() => close && close()}
      backdrop="static"
      keyboard={false}
      style={{ fontFamily: 'Helvetica' }}
      hideClose={hideClose}
      dialogWidth={dialogWidth}
      videodialogWidth={videodialogWidth}
      bodyBackground={bodyBackground}
    >
      <Modal.Header closeButton={() => close()}>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {footerProps && <Modal.Footer>{footerProps}</Modal.Footer>}
    </StyledModal>
  );
}

export default PrimaryModal;
