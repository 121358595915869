/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import { combineReducers } from 'redux';
import { USER_LOADED } from './constants';

export const initialState = {
  initialized: false,
  current: {
    isAuthenticated: false,
  },
};

const data = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case USER_LOADED:
        draft.current = {
          ...action.data,
          isAuthenticated: !!action.data.id,
          account: action.account,
        };
        draft.initialized = true;
        break;
    }
  });

const usersReducer = combineReducers({
  data,
});

export default usersReducer;
