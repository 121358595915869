/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import React from 'react';
import { Container } from 'react-bootstrap';
import styled, { css } from 'styled-components';

const StyledBanner = styled(Container)`
  position: relative;
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;

  .bannerTitle {
    font-size: ${props => props.theme.typography.size.super};
    color: white;
    top: 2%;
    width: 100%;
    height: 200px;
  }

  ${props =>
    props &&
    css`
      background-image: url(${props.bg});
      background-position: bottom right;
    `}

  .curve {
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    width: 102%;
  }

  .curve-container {
    height: 600px;
  }

  @media screen and (max-device-width: 767px) {
    .curve-container {
      height: 350px;
    }
  }

  @media screen and (max-device-width: 823px) and (orientation: landscape) {
    .curve-container {
      height: 250px;
    }
  }
`;

const CurvedBanner = props => (
  <StyledBanner bg={props.bgimage} fluid className="pe-0 ps-0">
    <div className="curve-container">
      <svg
        className="curve"
        width="100%"
        viewBox="0 0 1435 109"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <path
          d="M1435 33.617C822.135 215.25 843.592 -7.37488e-05 0 0V109H1435V33.617Z"
          fill="white"
        />
      </svg>
      {props.children}
    </div>
  </StyledBanner>
);

export default CurvedBanner;
