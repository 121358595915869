import firebase from 'firebase/app';
import 'firebase/auth'; // for authentication
import {jwtDecode} from 'jwt-decode';
import { stopSubmit } from 'redux-form';
import { isServer } from './detection';

export const initialize = environment => {
  if (firebase.apps.length < 1) {
    firebase.initializeApp({
      apiKey: environment.FIREBASE_API_KEY,
      authDomain: environment.AUTHDOMAIN,
      databaseURL: environment.DATABASEURL,
      projectId: environment.PROJECTID,
      storageBucket: environment.STORAGEBUCKET,
      messagingSenderId: environment.MESSAGINGSENDERID,
      appId: environment.APPID,
      measurementId: environment.MEASUREMENTID,
    });
    const auth = firebase.auth();
    auth.tenantId = environment.TENANT;
  }
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(resolve, reject);
  });
};

export const login = (values, authparams, errorlookup) => {
  const { data } = values;
  const {
    authFunc: {
      dispatch,
      authChanged,
      triggerAuthTracker,
      storePhoneVerificationData,
      toggleVerificationModal,
    },
  } = authparams;
  const { wperrors } = errorlookup;
  firebase
    .auth()
    .signInWithEmailAndPassword(data.email, data.password)
    .then(authobj => {
      authobj.user.getIdToken()
        .then(token => {
          // Decode the token to get the issuer
          const decodedToken = jwtDecode(token);
          const issuer = decodedToken.issuer;
          dispatch(authChanged(authobj.user, issuer));
          dispatch(triggerAuthTracker('signin', data.email));
        })
        .catch(tokenError => {
          console.error('Error getting ID token: ', tokenError);
        });
    })
    .catch(async error => {
      const applicationVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        },
      );
      if (error.code === 'auth/multi-factor-auth-required') {
        // dispatch(authFailed(error.message));
        dispatch(
          stopSubmit('login', {
            _error: (
              wperrors.find(item => item.code === error.code) || {
                message: 'Unknown error',
              }
            ).message,
          }),
        );
        const { resolver } = error;
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
        // Send SMS verification code
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

        phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, applicationVerifier)
          .then(verificationId => {
            dispatch(storePhoneVerificationData(verificationId, resolver));
            dispatch(toggleVerificationModal(true));
          })
          .catch(e => {
            // dispatch(authFailed(e.message));
            dispatch(
              stopSubmit('login', {
                _error: wperrors.find(item => item.code === e.code).message,
              }),
            );
          });
      } else {
        dispatch(
          stopSubmit('login', {
            _error: (
              wperrors.find(item => item.code === error.code) || {
                message: 'Unknown error',
              }
            ).message,
          }),
        );
      }
    });
};

export const logout = (authChanged, signOutSuccess, setToast, dispatch) => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(authChanged(false, false));
      dispatch(signOutSuccess());
    })
    .catch(error => {
      dispatch(setToast(error));
    });
};

export const enableMfa = (
  emailaddress,
  triggerAuthTracker,
  signOut,
  auth,
  requireSmsVerification,
  toggleVerificationModal,
  toggleCredentialsModal,
  authFailed,
  authChanged,
  dispatch,
  accountPage,
  setAccountPageToast,
  verificationId,
  resolver,
  setModalErrorMessage,
  setFlinksModal,

  phone,
) => {
  auth.multiFactor
    .getSession()
    .then(multiFactorSession => {
      // Specify the phone number and pass the MFA session.
      const applicationVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container-verification',
        {
          size: 'invisible',
        },
      );
      window.recaptchaVerifier = applicationVerifier;
      window.recaptchaVerifier.render().then(function(widgetId) {
        window.recaptchaWidgetId = widgetId;
      });

      let value = phone.replace(/\D/g, '');
      if (value.indexOf('+') === -1) {
        value = `+${value}`;
      }
      const phoneInfoOptions = {
        phoneNumber: value,
        session: multiFactorSession,
      };
      // Send SMS verification code.
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      return phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, applicationVerifier)
        .then(id => {
          dispatch(requireSmsVerification(id));
          dispatch(toggleVerificationModal(true));
        })
        .catch(e => {
          dispatch(authFailed(e.message));
          if (e.code === 'auth/requires-recent-login') {
            window.recaptchaVerifier.render().then(function(widgetId) {
              grecaptcha.reset(widgetId);
            });
            dispatch(toggleCredentialsModal(true));
          } else {
            console.log('erroe', e);
            dispatch(setAccountPageToast(e.message));
            dispatch(authFailed(e.message)); // TODO: toast
            // debugger;
          }
        });
    })
    .catch(e => {
      console.log('e', e);
      dispatch(setAccountPageToast(e.message));
      dispatch(authFailed(e.message));
    }); // TODO: toast
};

export const applyVerificationCode = (
  emailaddress,
  triggerAuthTracker,
  signOut,
  auth,
  requireSmsVerification,
  toggleVerificationModal,
  toggleCredentialsModal,
  authFailed,
  authChanged,
  dispatch,
  accountPage,
  setAccountPageToast,
  verificationId,
  resolver,
  setModalErrorMessage,
  setFlinksModal,

  phone,
  verificationcode,
) => {
  // const user = auth.currentUser;
  const cred = firebase.auth.PhoneAuthProvider.credential(
    verificationId,
    verificationcode,
  );
  const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
    cred,
  );
  auth.multiFactor
    .enroll(multiFactorAssertion, multiFactorAssertion.factorId)
    .then(() => {
      dispatch(authChanged(firebase.auth().currentUser, false));
      dispatch(toggleVerificationModal(false));
      dispatch(setAccountPageToast('MFA is activated'));
    })
    .catch(e => {
      console.log('error', e); // TODO: add toast
    });
};

export const signInWithCode = (
  auth,
  verificationId,
  resolver,
  verificationcode,
  authChanged,
  triggerAuthTracker,
  toggleVerificationModal,
  loadAuthPageContent,
  authFailed,
) => {
  if (isServer) {
    return;
  }

  const cred = firebase.auth.PhoneAuthProvider.credential(
    verificationId,
    verificationcode,
  );
  const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
    cred,
  );
  resolver
    .resolveSignIn(multiFactorAssertion)
    .then(() => {
      authChanged(firebase.auth().currentUser, false);
      triggerAuthTracker('signInWithCode');
      toggleVerificationModal(false);
      loadAuthPageContent();
    })
    .catch(e => {
      if (e.code === 'auth/invalid-verification-code') {
        authFailed(e.message);
      }
      console.log('error', e); // TODO: add toast
    });
};
export const disableMfa = (
  emailaddress,
  triggerAuthTracker,
  signOut,
  auth,
  requireSmsVerification,
  toggleVerificationModal,
  toggleCredentialsModal,
  authFailed,
  authChanged,
  dispatch,
  accountPage,
  setAccountPageToast,
) => {
  const options = firebase.auth().currentUser.multiFactor.enrolledFactors;
  return firebase
    .auth()
    .currentUser.multiFactor.unenroll(options[0])
    .then(() => {
      dispatch(
        setAccountPageToast('User successfully unenrolled selected factor'),
      );
      // User successfully unenrolled selected factor.
    })
    .catch(error => {
      if (error.code === 'auth/requires-recent-login') {
        dispatch(setAccountPageToast(error.message));
      } else {
        dispatch(setAccountPageToast(error.message));
      }
    });
};

export const register = (values, authparams, errorlookup) => {
  const { data } = values;
  const {
    authFunc: { dispatch, authChanged, triggerAuthTracker },
  } = authparams;
  const { wperrors } = errorlookup;
  firebase
    .auth()
    .createUserWithEmailAndPassword(data.email, data.password)
    .then(obj => {
      firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password)
        .then(payload => {
          dispatch(authChanged(payload.user, false));
          dispatch(triggerAuthTracker('signup', data.email));
        })
        .catch(error => {
          // dispatch(authFailed(error.message));
          dispatch(
            stopSubmit('register', {
              _error: wperrors.find(item => item.code === error.code).message,
            }),
          );
        });
    })
    .catch(error => {
      // dispatch(authFailed(error.message));
      dispatch(
        stopSubmit('register', {
          _error: wperrors.find(item => item.code === error.code).message,
        }),
      );
    });
};

export const forgotpassword = (
  // This function is used under Account Page security Tab, it is type button and value of button needs to match function name 'forgotpassword'
  emailaddress,
  triggerAuthTracker,
  signOut,
  auth,
  requireSmsVerification,
  toggleVerificationModal,
  toggleCredentialsModal,
  authFailed,
  authChanged,
  dispatch,
  page,
  setAccountPageToast,
) => {
  dispatch(triggerAuthTracker('resetPassword', auth.email));
  dispatch(
    setAccountPageToast(
      'An email has been sent to your email address to reset password.',
    ),
  );
};

export const forgot = (
  // This function is used under Auth Page, it is type submit
  values,
  authparams,
) => {
  const { data } = values;
  const {
    authFunc: { dispatch, triggerAuthTracker },
  } = authparams;
  dispatch(triggerAuthTracker('resetPassword', data.email));
};

export const sendVerification = (
  emailaddress,
  triggerAuthTracker,
  signOut,
  auth,
  requireSmsVerification,
  toggleVerificationModal,
  toggleCredentialsModal,
  authFailed,
  authChanged,
  dispatch,
  page,
  setAccountPageToast,
) => {
  dispatch(triggerAuthTracker('verify', emailaddress));
  dispatch(
    setAccountPageToast('An email has been sent to confirm your address.'),
  );
};

export const resetpassword = (values, authparams, errorlookup) => {
  const { data } = values;
  const {
    authFunc: {
      dispatch,
      authChanged,
      storePhoneVerificationData,
      toggleVerificationModal,
      triggerAuthTracker,
    },
    params: { email, oobCode },
  } = authparams;
  const { wperrors } = errorlookup;
  firebase
    .auth()
    .confirmPasswordReset(oobCode, data.password)
    .then(() => {
      if (email) {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, data.password)
          .then(result => {
            dispatch(authChanged(result.user, false));
            dispatch(triggerAuthTracker('setNewPassword'));
          })
          .catch(error => {
            const applicationVerifier = new firebase.auth.RecaptchaVerifier(
              'recaptcha-container',
              {
                size: 'invisible',
              },
            );
            if (error.code === 'auth/multi-factor-auth-required') {
              dispatch(
                stopSubmit('resetpassword', {
                  _error: wperrors.find(item => item.code === error.code)
                    .message,
                }),
              );

              const { resolver } = error;
              const phoneInfoOptions = {
                multiFactorHint: resolver.hints[0],
                session: resolver.session,
              };
              // Send SMS verification code
              const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
              phoneAuthProvider
                .verifyPhoneNumber(phoneInfoOptions, applicationVerifier)
                .then(verificationId => {
                  dispatch(
                    storePhoneVerificationData(verificationId, resolver),
                  );
                  dispatch(toggleVerificationModal(true));
                })
                .catch(e => {
                  dispatch(
                    stopSubmit('resetpassword', {
                      _error: wperrors.find(item => item.code === e.code)
                        .message,
                    }),
                  );
                });
            }
          });
      }
    })
    .catch(error => {
      if (error.code === 'auth/invalid-action-code') {
        dispatch(
          stopSubmit('resetpassword', {
            _error: wperrors.find(item => item.code === error.code).message,
          }),
        );
      }
      dispatch(
        stopSubmit('resetpassword', {
          _error: wperrors.find(item => item.code === error.code).message,
        }),
      );
    });
};

export const logOutOfAllDevices = (
  emailaddress,
  triggerAuthTracker,
  signOut,
  auth,
  requireSmsVerification,
  toggleVerificationModal,
  toggleCredentialsModal,
  authFailed,
  authChanged,
  dispatch,
  accountPage,
  setAccountPageToast,
  verificationId,
  resolver,
  setModalErrorMessage,
  setFlinksModal,
) => {
  dispatch(signOut());
};

export const refreshCredentials = (
  emailaddress,
  triggerAuthTracker,
  signOut,
  auth,
  requireSmsVerification,
  toggleVerificationModal,
  toggleCredentialsModal,
  authFailed,
  authChanged,
  dispatch,
  accountPage,
  setAccountPageToast,
  verificationId,
  resolver,
  setModalErrorMessage,
  setFlinksModal,
  phone,
  verificationcode,
  email,
  password,
) => {
  firebase
    .auth()
    .currentUser.reauthenticateWithCredential(
      firebase.auth.EmailAuthProvider.credential(email, password),
    )
    .then(() => {
      dispatch(toggleCredentialsModal(false));
    })
    .catch(error => {
      dispatch(setAccountPageToast(error.message));
    });
};
