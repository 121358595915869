/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import {
  // routerReducer,
  LOCATION_CHANGE,
  connectRouter,
} from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { history as initialHistory } from 'utils/history';
// import history from 'utils/history';
import globalReducer from 'containers/App/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import homeReducer from 'containers/HomePage/reducer';
import authPageReducer from 'containers/AuthPage/reducer';
import dashboardReducer from 'containers/Dashboard/reducer';
import defaultPageReducer from 'containers/DefaultPage/reducer';
import singleNewsPageReducer from 'containers/SingleNewsPage/reducer';
import newsPageReducer from 'containers/NewsPage/reducer';
import propsharingReducer from 'containers/PropSharing/reducer';
import accountPageReducer from 'containers/AccountPage/reducer';
import tradeviewReducer from 'containers/TradeView/reducer';
import marketplaceReducer from 'containers/MarketplacePage/reducer';
import portfolioPageReducer from 'containers/Portfolio/reducer';
import authReducer from 'containers/AuthProvider/reducer';
import usersReducer from './containers/App/Users/reducer';
import eventsReducer from './containers/App/Events/reducers';

// routerReducer
export function location(state = null, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return action.payload;
    default:
      return state;
  }
}

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(
  injectedReducers = {},
  history = initialHistory,
) {
  // one other option, would be to create this history, again, right here, if we need to.
  const rootReducer = combineReducers({
    global: globalReducer,
    language: languageProviderReducer,
    router: connectRouter(history),
    form: formReducer,
    location,
    home: homeReducer,
    auth: authReducer,
    users: usersReducer,
    events: eventsReducer,
    portfolio: portfolioPageReducer,
    authPage: authPageReducer,
    dashboard: dashboardReducer,
    defaultPage: defaultPageReducer,
    singleNewsPage: singleNewsPageReducer,
    newsPage: newsPageReducer,
    propsharing: propsharingReducer,
    account: accountPageReducer,
    tradeview: tradeviewReducer,
    marketplace: marketplaceReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
