import styled, { css } from 'styled-components';
import mediasizes from '../../utils/sizes';

export const PanelContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

export const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow: auto;
  min-height: 0;
  padding-bottom: 32px;
`;

export const BtnCloseWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 15px;
  transform: translate(0, -150%);

  ${mediasizes.tablet` display: none !important; `}
  ${mediasizes.desktop` display: none !important; `}
`;

export const BtnWatchlistWrapper = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 0;
  transform: translate(0, 100%);

  ${mediasizes.mobile`
		top: 0;
		left: 0;
		transform: none;
	`}
`;

export const Overlay = styled.div`
  z-index: 99;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity ${props => props.theme.durations.xslow} ease;

  pointer-events: none;
  opacity: 0;
  ${props =>
    props.active &&
    css`
      pointer-events: auto;
      opacity: 1;
    `}

  ${mediasizes.desktop` display: none !important; `}
`;

export const FloatingBtns = styled.div`
  z-index: 2;

  position: sticky;
  right: 0;
  bottom: ${props => props.theme.spacing.large};
  text-align: right;
  padding: ${props => props.theme.spacing.medium}
    ${props => props.theme.spacing.xxsmall};

  ${mediasizes.mobile` 
		padding: ${props => props.theme.spacing.small} ${props =>
    props.theme.spacing.large};
	`}

  visibility: hidden;
  * {
    opacity: 0;
    transform: translate3d(0, 15%, 0) !important;
  }
  ${props =>
    props.show &&
    css`
      visibility: visible;
      * {
        opacity: 1;
        transform: translate3d(0, 0, 0) !important;
      }
    `}
`;
