/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
// import { Form, InputGroup, Container } from "react-bootstrap";
import { InputGroup } from 'react-bootstrap';
import Icon from 'components/Icon';

/*
 */

const StyledInputGroupPrepend = styled(props => (
  <InputGroup.Prepend {...props} />
))`
  ${({ theme }) => css`
    border: none;
    padding-right: 0;
    background-color: transparent !important;
    span {
      border: none;
    }
  `}
  ${({ disabled, theme }) =>
    disabled &&
    css`
      svg {
        opacity: 50%;
      }
    `}
`;

const StyledInputGroupText = styled(props => <InputGroup.Text {...props} />)`
  ${({ theme }) => css`
    padding-right: 0;
    /* background-color: transparent !important; */
  `}
`;

const InputPrependIcon = ({ icon, disabled }) => {
  if (icon) {
    return (
      <StyledInputGroupPrepend disabled={disabled}>
        <StyledInputGroupText>
          <Icon variant={icon} size="xlarge" />
        </StyledInputGroupText>
      </StyledInputGroupPrepend>
    );
  }
  return <></>;
};

export default InputPrependIcon;
