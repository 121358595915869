/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'react-bootstrap';

const StyledButton = styled(({ active, iconOnly, ...rest }) => (
  <Button {...rest} />
))`
  ${({ theme }) => css`
    position: relative;
    transition: all ${theme.durations.fast} ease-in;

    height: 40px;

    border: none;
    border-style: solid;
    border-width: ${theme.shapes.borderWidthSmall};
    border-radius: ${theme.shapes.borderRadiusLarge};
    border-color: ${theme.colors.greys.medium};

    outline: none;
    cursor: pointer;

    padding-left: ${theme.spacing.small};
    padding-right: ${theme.spacing.small};

    font-size: ${theme.typography.size.xxsmall};
    font-family: ${theme.typography.primary};
    line-height: ${theme.typography.lineHeight.small};
    letter-spacing: ${theme.typography.letterSpacing.small};
    white-space: nowrap;

    background-color: ${theme.colors.greys.xlight} !important;
    color: ${theme.colors.greys.xdark} !important;

    &::before {
      content: "";
      position: absolute;
      opacity: 0;
      width: calc(100% + ${theme.spacing.xxxsmall} * 2);
      height: calc(100% + ${theme.spacing.xxxsmall} * 2);
      top: -${theme.spacing.xxxsmall};
      left: -${theme.spacing.xxxsmall};
      right: 0;
      bottom: 0;
      border-radius: ${theme.shapes.borderRadiusLarge};
      box-shadow: 0 0 0 2px ${theme.colors.brand.xlight};
      background-color: transparent !important;
      transition: all ${theme.durations.fast};
    }

    &:hover {
      border-color: transparent;
      border-color: ${theme.colors.greys.medium};
    }

    :active {
    }

    &:focus {
      &::before {
        opacity: 1;
      }
    }

    &.focus,
    &:focus {
      border-color: ${theme.colors.greys.medium} !important;
      box-shadow: ${theme.depths['0']} !important;
    }

    &:focus:not(:focus-visible) {
      &::before {
        opacity: 0;
      }
    }

    :disabled {
      opacity: 100%;
      color: ${theme.colors.greys.medium} !important;
      background-color: ${theme.colors.greys.light} !important;
      border-color: ${theme.colors.greys.light} !important;
    }

    // &:nth-of-type(n + 2) {
    //   margin-left: ${theme.spacing.xxsmall};
    // }
  `}
  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.colors.greys.white} !important;
      background-color: ${theme.colors.greys.xdark} !important;
      border-color: ${theme.colors.greys.xdark} !important;
    `}
`;

const StyledContainer = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    flex-wrap: wrap;
    gap: ${theme.spacing.xxsmall};
  `}
`;

const WillowFilter = props => (
  <StyledContainer className={props.className}>
    {props &&
      props.options &&
      props.options.map(option => {
        const key = option.key === undefined ? option : option.key;
        return (
        <StyledButton
          key={key}
          active={
            props.activeKey !== false
              ? props.activeKey === key
              : props.defaultKey === key
          }
          onClick={() => props.onSelect(key)}
          {...option}
        >
          {option.label === undefined ? option : option.label}
        </StyledButton>
      )})}
  </StyledContainer>
);

export default WillowFilter;
