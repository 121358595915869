import moment from 'moment';

export const currency = amount => {
  // if (amount instanceof String) {
  //   amount = parseFloat(amount.replace(/,/g, ".")).toFixed(2)
  // }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}

export const percent = amount =>
  new Intl.NumberFormat('en-US', { style: 'percent' }).format(amount / 100);

export const getDate = value => moment(value).calendar();
