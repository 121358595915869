/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Form } from 'react-bootstrap';

const StyledFormText = styled(({ status, ...rest }) => <p {...rest} />)`
  margin-bottom: 0px;
  ${({ theme }) => css`
    color: ${theme.colors.greys.dark};
  `}
  ${({ status, theme }) =>
    status === 'success' &&
    css`
      color: ${theme.colors.system.success};
    `}
  ${({ status, theme }) =>
    status === 'error' &&
    css`
      color: ${theme.colors.system.danger};
    `}
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.greys.medium};
    `}
`;

const InputHelperText = props => (
  <StyledFormText {...props}>{props.children}</StyledFormText>
);

export default InputHelperText;
