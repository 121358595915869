/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'react-bootstrap';
import Icon from '../Icon';

const StyledButton = styled(({ iconOnly, ...rest }) => <Button {...rest} />)`
  ${({ theme }) => css`
    position: relative;
    transition: all ${theme.durations.fast} ease-in;

    border: none;
    border-width: ${theme.shapes.borderWidthSmall};
    border-radius: ${theme.shapes.borderRadiusLarge};

    outline: none;
    cursor: pointer;

    padding: ${theme.spacing.xxsmall} ${theme.spacing.small};
    background-color: ${theme.colors.greys.dark};

    color: ${theme.colors.greys.white};

    font-size: ${theme.typography.size.xxsmall};
    font-family: ${theme.typography.primary};
    line-height: ${theme.typography.lineHeight.small};
    letter-spacing: ${theme.typography.letterSpacing.small};
    white-space: nowrap;

    svg {
      fill: ${theme.colors.greys.white};
      margin-right: ${theme.spacing.xxsmall};
    }

    &::before {
      content: '';
      position: absolute;
      opacity: 0;
      width: calc(100% + ${theme.spacing.xxxsmall} * 2);
      height: calc(100% + ${theme.spacing.xxxsmall} * 2);
      top: -${theme.spacing.xxxsmall};
      left: -${theme.spacing.xxxsmall};
      right: 0;
      bottom: 0;
      border-radius: ${theme.shapes.borderRadiusLarge};
      box-shadow: 0 0 0 2px ${theme.colors.brand.xlight};
      background-color: transparent !important;
      transition: all ${theme.durations.fast};
    }

    &:focus {
      &::before {
        opacity: 1;
      }
    }

    &.focus,
    &:focus {
      box-shadow: ${theme.depths['0']} !important;
    }

    &:focus:not(:focus-visible) {
      &::before {
        opacity: 0;
      }
    }
  `}

  ${({ theme, variant }) =>
    variant === 'secondary' &&
    css`
      background-color: ${theme.colors.greys.xlight} !important;
      border-color: ${theme.colors.greys.medium};
      border-style: solid;
      color: ${theme.colors.greys.dark} !important;
      fill: ${theme.colors.greys.xdark};
    `}

  ${({ theme, variant }) =>
    variant === 'important' &&
    css`
      background-color: ${theme.colors.accent.blue} !important;
      color: ${theme.colors.greys.xdark} !important;
    `}
`;

const WillowFlag = props => {
  const { icon } = props;
  return (
    <StyledButton {...props}>
      {icon && <Icon variant={props.icon} />}
      {props.children}
    </StyledButton>
  );
};

export default WillowFlag;
