/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-const-assign */
/* eslint-disable react/prop-types */
import React from 'react';
import { reduxForm } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import FormStatus from 'components/Form/Status';
import { connect } from 'react-redux';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { isDirty } from 'redux-form/immutable';
import { segmentTrack } from 'utils/segment';
// import { uet_report_conversion } from 'utils/bingTags';
import { gtag_report_conversion } from 'utils/googleTags';

let RenderForm = props => {
  const {
    handleSubmit,
    children,
    submitFunction,
    message,
    resources = {}, // TODO: I don't remember what this is used for
    warningStyle,
    buttonClassName,
    apiErrorMessage,
    errorlookup,
    buttonArray,
    buttonLayout,
    buttonFunction,
    stacked,
    centered,
    content,
    className,
    disableButton,
    transferFundsLoader,
    submitting,
    pristine,
    error,
  } = props;
  return (
    <form
      onSubmit={handleSubmit(submitFunction)}
      className={
        warningStyle === 'centre'
          ? 'justify-content-center mb-5 pb-5 px-md-3'
          : className
      }
      id={props.id}
      key={props.key}
    >
      {content}
      <Row
        className={
          warningStyle === 'centre' ? 'justify-content-center mx-0 mb-5' : ''
        }
        key={`row${props.id}`}
      >
        {children}
        {buttonArray &&
          !stacked &&
          buttonArray
            .filter(e => !!e)
            .map((item, index) => (
              <Col
                key={`buttonArray${index}`}
                className={
                  warningStyle === 'centre'
                    ? 'mt-md-0 mt-5 col-xl-2 col-lg-3 col-md-5 col-sm-12 col-12'
                    : ''
                }
              >
                <Button
                  type={item.type ? item.type : 'submit'}
                  variant={item.variant}
                  name={item.name && item.name}
                  className={
                    item.class
                      ? `animated animatedFadeInUp fadeInUp ${item.class}`
                      : `${buttonClassName} animated animatedFadeInUp fadeInUp`
                  }
                  id={item.id ? item.id : item.label}
                  key={item.id ? item.id : item.label}
                  disabled={item.disabled && item.disabled}
                  onClick={e =>
                    item.type === 'button'
                      ? buttonFunction(e)
                      : submitFunction(e)
                  }
                  aria-label={item.label}
                >
                  {item.link ? (
                    <Link
                      to={item.link}
                      className={item.linkclass && item.linkclass}
                    >
                      {item.label}
                    </Link>
                  ) : (
                    item.label
                  )}
                </Button>
              </Col>
            ))}

        {buttonArray &&
          stacked &&
          buttonArray
            .filter(e => !!e)
            .map((item, index) => (
              <Row
                key={`subrow${index}`}
                className={
                  centered
                    ? 'mx-0 mb-2 w-100 justify-content-center'
                    : 'mx-0 mb-2 w-100'
                }
              >
                <Col md={10}>
                  <Button
                    type={item.type ? item.type : 'submit'}
                    variant={item.variant}
                    name={item.name && item.name}
                    className={
                      item.class
                        ? `animated animatedFadeInUp fadeInUp ${item.class}`
                        : `${buttonClassName} animated animatedFadeInUp fadeInUp`
                    }
                    id={item.id ? item.id : item.label}
                    // disabled={transferFundsLoader || item.disabled}
                    disabled={
                      props.form === 'transfer_funds' ||
                      props.form === 'personal'
                        ? transferFundsLoader ||
                          submitting ||
                          pristine ||
                          item.disabled
                        : item.disabled
                    }
                    onClick={e => item.type === 'button' && buttonFunction(e)}
                    aria-label={item.label}
                  >
                    {item.link ? (
                      <Link
                        to={item.link}
                        className={item.linkclass && item.linkclass}
                        style={{lineHeight: "19.2px"}}
                      >
                        {item.label}
                      </Link>
                    ) : (
                      item.label
                    )}
                  </Button>
                </Col>
              </Row>
            ))}
      </Row>
      {props.submitFailed ? (
        <>
          {message && (
            <>
              {warningStyle === 'centre' ? (
                <Row className="justify-content-center mx-md-0 mx-2 mt-2">
                  <FormStatus status="error" className="mt-md-0 mt-2 w-100">
                    {message}
                  </FormStatus>
                </Row>
              ) : (
                <Row className="pt-5">
                  <Col>
                    <FormStatus
                      status="error"
                      className="mt-md-0 mt-2 w-100 mb-2 mx-3 "
                    >
                      {message}
                    </FormStatus>
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {apiErrorMessage &&
            (!Array.isArray(apiErrorMessage) ? (
              <Row className="justify-content-center mx-md-0 mx-5">
                <FormStatus status="error" className="mt-md-0 mt-2 w-100">
                  {apiErrorMessage}
                </FormStatus>
              </Row>
            ) : (
              apiErrorMessage.map(e => (
                <Row
                  key={e.code}
                  className="justify-content-center mx-md-0 mx-5"
                >
                  <FormStatus status="error" className="mt-md-0 mt-2 w-100">
                    {resources[e.code] || e.code}
                  </FormStatus>
                </Row>
              ))
            ))}
        </>
      )}
      {error && ( // This shows the form message for example: firebase message or api error message
        <>
          {error && (
            <Row className="justify-content-center">
              <Col>
                <FormStatus
                  status="error"
                  className="mt-md-0 mt-2 w-100 mb-2 mx-3 text-black"
                >
                  {error}
                </FormStatus>
              </Col>
            </Row>
          )}
        </>
      )}
      {props.submitFailed &&
      typeof error === 'undefined' && ( // generic error message when fields fails validation
          <>
            {errorlookup &&
              errorlookup.map(e => {
                if (e.code === 'generic') {
                  return (
                    <Row key={e.code} className="justify-content-center">
                      <FormStatus
                        status="error"
                        className="mt-md-0 mt-2 w-100 text-black"
                      >
                        {e.message}
                      </FormStatus>
                    </Row>
                  );
                }
              })}
          </>
        )}
      {/* Button Layout is currently being used in the FormLayout for Kyc pages */}
      {buttonLayout &&
        buttonLayout.map((layout, btnkey) => (
          <Row key={`buttonLayout${btnkey}`}>
            <Col>
              {layout.row.map((rw, rwkey) => (
                <Row className={rw.class} key={`row${rwkey}`}>
                  {rw.columns.map((cl, clkey) => (
                    <Col
                      className={cl.class}
                      {...(cl.widths && JSON.parse(cl.widths))}
                      key={`cl${clkey}`}
                    >
                      {cl.buttons.map(btn =>
                        btn.kind === 'accept' ? (
                          <Button
                            type={btn.type ? btn.type : 'submit'}
                            variant={btn.variant}
                            disabled={disableButton}
                            name={btn.name && btn.name}
                            className={
                              btn.class
                                ? `animated animatedFadeInUp fadeInUp ${btn.class}`
                                : `${buttonClassName} animated animatedFadeInUp fadeInUp`
                            }
                            id={btn.id ? btn.id : btn.label}
                            key={btn.id ? btn.id : btn.label}
                            onClick={e => {
                              if (btn.type === 'button') {
                                buttonFunction(e);
                              }
                            }}
                            aria-label={btn.label}
                          >
                            {btn.link ? (
                              <Link
                                to={btn.link}
                                className={btn.linkclass && btn.linkclass}
                              >
                                {btn.label}
                              </Link>
                            ) : (
                              btn.label
                            )}
                          </Button>
                        ) : (
                          <Button
                            type={btn.type ? btn.type : 'submit'}
                            variant={btn.variant}
                            name={btn.name && btn.name}
                            className={
                              btn.class
                                ? `animated animatedFadeInUp fadeInUp ${btn.class}`
                                : `${buttonClassName} animated animatedFadeInUp fadeInUp`
                            }
                            id={btn.id ? btn.id : btn.label}
                            key={btn.id ? btn.id : btn.label}
                            disabled={btn.disabled && btn.disabled}
                            onClick={e => {
                              if (btn.type === 'button') {
                                buttonFunction(e);
                              }

                              // if (props.invalid === false) {
                              //   // segmentTrack(btn.events && btn.events);
                              //   // location.pathname.includes('register') &&
                              //     // uet_report_conversion('signup');

                              //   // location.pathname.includes('register') &&
                              //     // gtag_report_conversion(
                              //     //   'AW-643504403/tSliCMiv5Z8DEJOy7LIC',
                              //     // );
                              // }
                            }}
                            aria-label={btn.label}
                          >
                            {btn.link ? (
                              <Link
                                to={btn.link}
                                className={btn.linkclass && btn.linkclass}
                              >
                                {btn.label}
                              </Link>
                            ) : (
                              btn.label
                            )}
                          </Button>
                        ),
                      )}
                    </Col>
                  ))}
                </Row>
              ))}
            </Col>
          </Row>
        ))}
    </form>
  );
};

RenderForm = reduxForm({
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
})(RenderForm);

export default connect((state, props) => ({
  initialValues: props.initializeValue,
  dirty: isDirty(props.form),
  destroyOnUnmount: props.destroyonunmount,
}))(RenderForm);
