/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import Icon from '../Icon';

const StyledToggle = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    outline: none;

    height: 40px;

    padding-left: ${theme.spacing.xsmall};
    padding-right: ${theme.spacing.xsmall};

    border: 1px solid ${theme.colors.greys.light} !important;
    border-radius: ${theme.shapes.borderRadiusLarge};

    background-color: ${theme.colors.greys.white};
    color: ${theme.colors.greys.dark};

    transition: border ${theme.durations.fast} ease-in;

    font-size: ${theme.typography.size.small};

    &::before {
      content: '';
      position: absolute;
      opacity: 0;
      width: calc(100% + ${theme.spacing.xxxsmall} * 2);
      height: calc(100% + ${theme.spacing.xxxsmall} * 2);
      top: -${theme.spacing.xxxsmall};
      left: -${theme.spacing.xxxsmall};
      right: 0;
      bottom: 0;
      border-radius: ${theme.shapes.borderRadiusLarge};
      box-shadow: 0 0 0 2px ${theme.colors.brand.xlight};
      background-color: transparent !important;
      transition: all ${theme.durations.fast};
    }

    &:focus {
      &::before {
        opacity: 1;
      }
    }

    &.focus,
    &:focus {
      box-shadow: none !important;
    }

    &:focus:not(:focus-visible) {
      &::before {
        opacity: 0;
      }
    }

    :hover {
      text-decoration: none;
      color: ${theme.colors.greys.xdark};
    }

    &:disabled {
      background-color: ${theme.colors.greys.light};
      color: ${theme.colors.greys.medium} !important;
      &:hover {
        transform: translate3d(0, 0, 0);
      }
    }
  `}

  ${({ isExpanded, theme }) =>
    isExpanded &&
    css`
      border-color: ${theme.colors.brand.medium};
    `}

  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      font-size: ${theme.typography.size.xxsmall};
    `}

  ${({ width }) => css`
    width: ${width};
  `}
`;

const CustomToggle = React.forwardRef(
  (
    {
      size,
      label,
      value,
      width = 'auto',
      disabled,
      // children,
      onClick,
      toggleClassName,
      'aria-expanded': isExpanded,
    },
    ref,
  ) => (
    <StyledToggle
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
        // insent.widget('minimize');
      }}
      isExpanded={isExpanded}
      disabled={disabled}
      size={size}
      width={width}
      className={toggleClassName}
    >
      {value || label}
      <Icon
        className="ms-3"
        variant={isExpanded ? 'chevronUp' : 'chevronDown'}
      />
    </StyledToggle>
  ),
);

const DropdownToggle = props => (
  <Dropdown.Toggle as={CustomToggle} {...props} />
);

export default DropdownToggle;
