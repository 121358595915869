/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';

import { combineReducers } from 'redux';

import {
  LOAD_NEWSPAGE_CONTENT,
  LOAD_NEWSPAGE_CONTENT_SUCCESS,
  LOAD_NEWSPAGE_CONTENT_FAILURE,
} from './constants';

const generalContentSliceInitialState = {
  loading: false,
  error: false,
  data: false,
};

export const initialState = {
  content: generalContentSliceInitialState,
};
const data = (state = generalContentSliceInitialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_NEWSPAGE_CONTENT:
        draft.data = false;
        draft.error = false;
        draft.loading = true;
        break;
      case LOAD_NEWSPAGE_CONTENT_SUCCESS:
        draft.data = action.data;
        draft.error = false;
        draft.loading = false;
        break;
      case LOAD_NEWSPAGE_CONTENT_FAILURE:
        draft.data = false;
        draft.error = true;
        draft.loading = false;
        break;
    }
  });
const newsPageReducer = combineReducers({
  data,
});

export default newsPageReducer;
