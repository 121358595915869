/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import {
  LOAD_PROP_SHARING_PAGE_DATA,
  LOAD_PROP_SHARING_PAGE_DATA_SUCCESS,
  LOAD_PROP_SHARING_PAGE_DATA_FAILURE,
} from './constants';

export const initialState = {
  loading: false,
  error: false,
  data: false,
};

const propSharingPageReducer = produce((draft, action) => {
  switch (action.type) {
    case LOAD_PROP_SHARING_PAGE_DATA:
      draft.loading = true;
      draft.error = false;
      draft.data = false;
      break;
    case LOAD_PROP_SHARING_PAGE_DATA_SUCCESS:      
      draft.error = false;
      draft.data = action.data;
      break;
    case LOAD_PROP_SHARING_PAGE_DATA_FAILURE:
      draft.loading = false;
      draft.error = true;
      draft.data = false;
      break;
  }
}, initialState);

export default propSharingPageReducer;
