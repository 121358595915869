/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { isClientMobile } from 'utils/detection';
import mediasizes from '../../utils/sizes';

const StyledCurve = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  
  ${props =>
    props &&
    css`
      background-position: ${props.bg ? props.bg : 'bottom right'};
    `}

  /* // adjust height for mobile as you see fit */
  ${mediasizes.mobile`
    height: 10vh; 
  `}

  .curve {
    left: -1px;
    right: -1px;
    bottom: -1px;
  }
`;

const StyledBanner = styled.div`
  .curve {
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    width: 102%;
  }

  .curve-container {
    height: 600px;
  }

  @media screen and (max-device-width: 767px) {
    .curve-container {
      height: 350px;
    }
  }

  @media screen and (max-device-width: 823px) and (orientation: landscape) {
    .curve-container {
      height: 250px;
    }
  }
`;

const BottomCurve = props => (
  <StyledCurve
    position={props.position}
    className="position-relative vw-100 vh-25 m-0"
  >
    <svg
      className="curve position-absolute w-101"
      width="100%"
      viewBox="0 0 1329 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <path
        d="M0 240C857.924 240 868.97 35.5383 1329.23 0.00012207L1329.23 240L0 240Z"
        fill="#ededed"
      />
    </svg>
  </StyledCurve>
);

const BottomPrimaryCurve = () => (
  <div className="position-relative vw-100 m-0">
    <svg
      viewBox="0 60 500 90"
      preserveAspectRatio="none"
      className="w-101 position-absolute"
      width="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      // style={{ margin: '-12px' }}
    >
      <path
        d="M-13.54,146.53 C149.99,150.00 278.78,52.78 512.97,85.36 L500.00,0.00 L0.00,0.00 Z"
        fill="#4cd683"
      />
    </svg>
  </div>
);

const ArticleCurve = props => (
  <StyledBanner>
    <div className="curve-container">
      <svg
        className="curve"
        width="100%"
        viewBox="0 0 1435 109"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <path
          d="M1435 33.617C822.135 215.25 843.592 -7.37488e-05 0 0V109H1435V33.617Z"
          fill="white"
        />
      </svg>
      {props.children}
    </div>
  </StyledBanner>
);

const TopCurve = props => {
  const isMobile = isClientMobile();
  return (
    <>
      {isMobile ? (
        <svg
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 765 770"
          version="1.1"
          fill="none"
        >
          <defs>
            <pattern id="bannerImgMobile" height="100%" width="100%">
              <image
                href={props.bgimage}
                x="0"
                y="0"
                width="1420px"
                height="800px"
              />
            </pattern>
          </defs>
          <path
            d="M-1.99993 0L-1.99994 147.447C228.463 147.447 307.289 110.278 385.787 73.264C463.636 36.5561 541.162 4.89811e-05 765.952 6.10352e-05L67.7914 0H768V770H-2V0H-1.99993Z"
            fill="url(#bannerImgMobile)"
          />
        </svg>
      ) : (
        <svg
          className="mt-md-n5"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1437 810"
          version="1.1"
          fill="none"
        >
          <defs>
            <pattern id="bannerImg" height="100%" width="100%">
              <image
                href={props.bgimage}
                x="0"
                y="0"
                width="100%"
                height="100%"
              />
            </pattern>
          </defs>
          <path
            d="M1440 0C1018.49 -6.45855e-06 873.121 167.351 727.146 335.397C579.953 504.845 432.146 675 0 675V810H1440V0Z"
            fill="url(#bannerImg)"
          />
        </svg>
      )}
    </>
  );
};

const Curve = props => {
  switch (props.variant) {
    case 'bottom':
      return <BottomCurve {...props} />;
    case 'bottom-primary':
      return <BottomPrimaryCurve {...props} />;
    case 'article-curve':
      return <ArticleCurve {...props} />;
    case 'top':
      return <TopCurve {...props} />;
    default:
      return <BottomCurve {...props} />;
  }
};
export default Curve;
