export const LOAD_KYC_TEMPLATE = 'boilerlplate/App/LOAD_KYC_TEMPLATE';
export const LOAD_KYC_TEMPLATE_SUCCESS =
  'boilerlplate/App/LOAD_KYC_TEMPLATE_SUCCESS';
export const LOAD_KYC_TEMPLATE_FAILURE =
  'boilerlplate/App/LOAD_KYC_TEMPLATE_FAILURE';

export const LOAD_WPFINANCIAL_CONTENT =
  'app/AccountPage/LOAD_WPFINANCIAL_CONTENT';
export const LOAD_WPFINANCIAL_CONTENT_SUCCESS =
  'app/AccountPage/LOAD_WPFINANCIAL_CONTENT_SUCCESS';
export const LOAD_WPFINANCIAL_CONTENT_FAILURE =
  'app/AccountPage/LOAD_WPFINANCIAL_CONTENT_FAILURE';

export const REGISTRATION_LOADED = 'app/dashboard/REGISTRATION_LOADED';

export const CTA_BUTTON = 'app/dashboard/CTA_BUTTON';

export const ACCEPT = 'app/dashboard/ACCEPT';
export const PREVIOUS = 'app/dashboard/PREVIOUS';

export const SET_TOAST = 'app/dashboard/SET_TOAST';

export const TOGGLE_KYC_TAB = 'app/dashboard/TOGGLE_KYC_TAB';
export const TAB_STATUS = 'app/dashboard/TAB_STATUS';
export const TOGGLE_SIDEPANEL = 'app/dashboard/TOGGLE_SIDEPANEL';

export const SET_PDF = 'app/dashboard/SET_PDF';
export const STORE_RESPONSE = 'app/dashboard/STORE_RESPONSE';

export const SET_ACCOUNT_TYPE = 'app/dashboard/SET_ACCOUNT_TYPE';
export const SET_JSON_DATA = 'app/dashboard/SET_JSON_DATA';
export const SET_LOADER = 'app/dashboard/SET_LOADER';
export const CLICKEDPLAID = 'app/dashboard/CLICKEDPLAID';


