import { RELOAD_USER, USER_LOADED } from './constants';

export function userLoaded(data, account) {
  return {
    type: USER_LOADED,
    data,
    account,
  };
}

export function reloadUser() {
  return {
    type: RELOAD_USER,
  };
}
