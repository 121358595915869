import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectTradeViewPageDomain = state => state.tradeview || initialState;
const makeSelectTradeViewPage = () =>
  createSelector(selectTradeViewPageDomain, substate => substate);
const selectForm = state => state.form;

export default makeSelectTradeViewPage;
export { selectTradeViewPageDomain, makeSelectTradeViewPage, selectForm };
