import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import Logo from 'components/Logo';
const StyledDiv = styled(Container)`
  .loader {
    width: 64px;
    height: 64px;
    animation: pulse 2s infinite;
    -webkit-animation: pulse 2s infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
const Loader = () => (
  <StyledDiv className="justify-content-center">
    <div className="loader">
      <Logo />
    </div>
  </StyledDiv>
);

export default Loader;
