export const ENV_VARIABLES = 'app/App/ENV_VARIABLES';
export const API_ENPOINT_PREFIX = 'wp-json/willow-content-api/v1';

export const LOAD_NAVBAR_CONTENT = 'boilerlplate/App/LOAD_NAVBAR_CONTENT';
export const LOAD_NAVBAR_CONTENT_SUCCESS =
  'boilerlplate/App/LOAD_NAVBAR_CONTENT_SUCCESS';
export const LOAD_NAVBAR_CONTENT_FAILURE =
  'boilerlplate/App/LOAD_NAVBAR_CONTENT_FAILURE';

export const LOAD_FOOTER_CONTENT = 'boilerlplate/App/LOAD_FOOTER_CONTENT';
export const LOAD_FOOTER_CONTENT_SUCCESS =
  'boilerlplate/App/LOAD_FOOTER_CONTENT_SUCCESS';
export const LOAD_FOOTER_CONTENT_FAILURE =
  'boilerlplate/App/LOAD_FOOTER_CONTENT_FAILURE';

export const LOAD_ALLPOSTS_URL = 'app/global/LOAD_ALLPOSTS_URL';
export const LOAD_ALLPOSTS_URL_SUCCESS = 'app/global/LOAD_ALLPOSTS_URL_SUCCESS';
export const LOAD_ALLPOSTS_URL_FAILURE = 'app/global/LOAD_ALLPOSTS_URL_FAILURE';

export const LOOKUPS_LOADING = 'App/Users/LOOKUPS_LOADING';
export const LOOKUPS_LOADING_SUCCESS = 'App/Users/LOOKUPS_LOADING_SUCCESS';
export const LOOKUPS_LOADING_FAILURE = 'App/Users/LOOKUPS_LOADING_FAILURE';

export const NOTIFY = 'App/NOTIFY';
export const DISMISS_NOTIFICATION = 'App/DISMISS_NOTIFICATION';

export const USER_PROPERTIES_LOADED = 'App/Users/USER_PROPERTIES_LOADED';
export const USER_PROPERTIES_LOADED_SUCCESS =
  'App/Users/USER_PROPERTIES_LOADED_SUCCESS';
export const USER_PROPERTIES_LOADED_FAILURE =
  'App/Users/USER_PROPERTIES_LOADED_FAILURE';
