import { put, select, takeLatest, call } from 'redux-saga/effects';
import {
  AUTH_CHANGED,
  SIGN_OUT_SUCCESS,
} from 'containers/AuthProvider/constants';
import { makeSelectAuth } from 'containers/AuthProvider/selectors';
import { makeSelectLocation, makeSelectApi } from 'containers/App/selectors';

import { LOCATION_CHANGE } from 'connected-react-router';

import { reloadUser, userLoaded } from './actions';
import { RELOAD_USER } from './constants';
import { SERVER_EVENT } from '../Events/constants';
import { setToast } from '../../AuthPage/actions';
import { loadReviewData } from '../../TradeView/actions';

function* handleAuthChanged({ issuer, data }) {
  const { host } = window.location;
  const subdomain = host.split('.')[0];
  const isDev = host.includes('.dev');
  const domain = isDev ? '.midasboard.dev' : '.midasboard.com';
  if (issuer && issuer !== subdomain) {
    window.location.href = `https://${issuer}${domain}/en/login`;
  }
  // TODO: this should be in AuthPage - this should not be page-based.  the user can deep link in and already be authenticated.
  const location = yield select(makeSelectLocation());
  const { oobCode } = location.query;
  const auth = yield select(makeSelectAuth());
  if (
    auth.initialized &&
    auth.firebase !== null &&
    auth.firebase !== false &&
    typeof oobCode === 'undefined'
  ) {
    if (!auth.uid) {
      yield put(userLoaded({}));
      return;
    }
    try {
      const api = yield select(makeSelectApi());
      const result = yield api.get('api/users/current');
      const account = yield api.get('api/accounting/account/current');
      yield put(userLoaded(result, account));
      yield put(loadReviewData()); // This resets the Tradeview page error messages
    } catch (e) {
      console.log('e: ', e);
      yield put(userLoaded({}));
      yield put(setToast('Something went wrong, try again later'));
    }
  } else {
    yield put(userLoaded({}));
  }
}

function* handleServerEvent({ event }) {
  try {
    if (
      /(exchange)/i.test(event.source.join()) &&
      event.name === 'Order Placed'
    ) {
      yield put(reloadUser());
      return;
    }
    if (
      /(users|accounting|registration)/i.test(event.source.join()) &&
      event.userId
    ) {
      yield put(reloadUser());
      return;
    }
    // if (
    //   /(registration)/i.test(event.source.join())
    //   ) {
    //     // if (typeof event.truliooIdVerficationResult === 'undefined' || event.truliooIdVerficationResult === 'match') {
    //     //   yield put(reloadUser());
    //     // }
    //   if (typeof event.truliooIdVerficationResult !== 'undefined' && event.truliooIdVerficationResult === 'match') {
    //     console.log('I did this reload');
    //     yield put(reloadUser());
    //   } else if (typeof event.truliooIdVerficationResult === 'undefined') {
    //     console.log('I did this reload');

    //     yield put(reloadUser());
    //   }
    //   return;
    // }
  } catch (error) {
    console.error(error);
  }
}
function* unassignUser() {
  yield put(userLoaded({ current: { isAuthenticated: false } }));
}



// Check for any willow specific codes here
// Promotion codes will use "promo", others should use a prefix.
function* checkForCodes() {
  const location = yield select(makeSelectLocation());
  const { promo } = location.query;
  if (promo) {
    localStorage.setItem('willow-codes', JSON.stringify({
      promoCode: promo
    }));
  }
}

export default function* usersSaga() {
  yield takeLatest([AUTH_CHANGED, RELOAD_USER], handleAuthChanged);

  // auth changes runs whenever the page is loaded, so we check for codes here
  yield takeLatest([AUTH_CHANGED], checkForCodes);
  yield takeLatest(SIGN_OUT_SUCCESS, unassignUser);
  yield takeLatest([SERVER_EVENT], handleServerEvent);
}
