/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '../../Icon';
import { Container } from 'react-bootstrap';

const StyledContainer = styled(Container)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    max-width: 100%;
    width: fit-content;
    margin: initial;
    padding: ${theme.spacing.xxsmall} ${theme.spacing.xsmall};
    border-radius: ${theme.shapes.borderRadiusSmall};
    background-color: ${theme.colors.greys.white};
    font-size: ${theme.typography.size.small};
  `}
`;

const FormError = props => (
  <StyledContainer {...props}>
    <Icon variant={props.status} className="me-2 d-flex" />
    {props.children}
  </StyledContainer>
);

export default FormError;
