/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Field, reset } from 'redux-form';
import RenderForm from 'components/RenderForm';
import RenderField from 'components/RenderField';
import Button from 'components/Button';
import Card from 'components/Card';
import Tab from 'components/Tab';
import { Container, Row, Col } from 'react-bootstrap';
import PrimaryModal from 'components/PrimaryModal';
import Icon from 'components/Icon';
import ListLayout from 'components/List/ListLayout';
import { required } from 'utils/validation';
import Progressbar from 'components/Progressbar';
import Link from 'components/Link';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { segmentTrack } from 'utils/segment';
// import { uet_report_conversion } from 'utils/bingTags';
import { gtag_report_conversion } from 'utils/googleTags';
import { quantcast_conversion } from 'utils/quantcastTags';
import { uid } from '../../../../utils/ui';
import { formatters } from '../../../../utils/formatters';
import Tooltip from 'components/TooltipV1';

const context = {
  required,
};

function execFn(fnName, ctx, params) {
  if (params) {
    return ctx[fnName](...params);
  }
  return ctx[fnName];
}

function getFormat(field) {
  const { format } = field;
  const current = formatters[format];
  if (current != null) {
    return current;
  }
  return null;
}

const fieldArray = (data, propertyContent) => {
  const result = data.map((field, index) => {
    // if (typeof modalform !== 'undefined') {
    //   const choices = [];
    //   if (tags && JSON.parse(tags).investorTypeKey === field.id) {
    //     choices.push(field);
    //   }
    //   if (field.id in financialfields === false) {
    //     choices.push(field);
    //   }
    //   const CustomLabel = ({ label }) => (
    //     <p
    //       dangerouslySetInnerHTML={{
    //         __html: label,
    //       }}
    //     />
    //   );
    //   return choices.map(finanfield => (
    //     <Col className="mb-2" key={index}>
    //       <Field
    //         name={finanfield.name}
    //         type={finanfield.type}
    //         radiovalue={finanfield.label}
    //         label={<CustomLabel label={finanfield.label} />}
    //         fieldid={`row${index}-col${index}-${finanfield.name}`}
    //         component={RenderField}
    //         disabled={finanfield.disabled}
    //         format={getFormat(finanfield)}
    //         options={finanfield.options ? finanfield.options : 'false'}
    //         validate={
    //           finanfield.validate === 'ignorevalidation'
    //             ? null
    //             : [execFn(finanfield.validate, context), required]
    //         }
    //         min="1"
    //       />
    //     </Col>
    //   ));
    // }

    return (
      <Col className="mb-3" key={index}>
        <Field
          name={field.name}
          defaultValue={field.name === "currency" ? propertyContent.currency : null}
          type={field.type}
          label={field.label}
          fieldid={`row${index}-col${index}-${field.name}`}
          component={RenderField}
          disabled={field.disabled}
          format={getFormat(field)}
          options={field.options ? field.options : 'false'}
          validate={
            field.validate === 'ignorevalidation'
              ? null
              : [execFn(field.validate, context), required]
          }
          // min="1"
        />
      </Col>
    );
  });
  return result;
};

const FormLayout = ({
  rawdata,
  toggleStage,
  toggleRight,
  // modalContent,
  submitForm,
  submitReviewOrder,
  // ipoDetails,
  symbol,
  reviewOrder,
  revieworderError,
  account,
  tags,
  financialfields,
  redirect,
  investmentTotal,
  locale,
  numberOfUnits,
  totalTax,
  propertyDetail,
  errorlookup,
  propertyContent
}) => {
  const dispatch = useDispatch();
  const docHeader =
    rawdata &&
    rawdata.hasmodal &&
    rawdata.modaldata.length &&
    rawdata.modaldata.map(item => item.documentheader).join(' ');

  // const ipoInfo = ipoDetails;

  const wallet = account && account.availableFunds;

  // const getValue = ({symbol, amount, request}) => {
  //   // const format =
  //   //   formatters[column.columns.map(sub => sub.format)] || formatters.none;
  //   // if (rawdata.stagename !== 'review_order') {
  //   //   return format(
  //   //     ipoInfo &&
  //   //       ipoInfo[
  //   //         column.columns
  //   //           //.filter(cl => cl.id !== 'closingDate')
  //   //           .map(subcol => subcol.id)
  //   //       ],
  //   //   );
  //   // }
  //   return format(
  //     reviewOrder && reviewOrder[column.columns.map(subcol => subcol.id)],
  //   );
  // };

  const propState = propertyDetail && propertyDetail.state;
  const reviewInfo = {symbol, requestType: 'BUY', amount: `${reviewOrder.amount} ${reviewOrder.currency}` }
  return (
    <>
      <Container className="px-4 pb-6">
        <div className="d-block d-md-none pb-2">
          <Button
            variant="basic"
            icon="chevronLeft"
            iconOnly="true"
            size="sm"
            className="my-2"
            onClick={toggleRight}
            aria-label="Close Panel"
          ></Button>
        </div>

        {rawdata && rawdata.hasheader ? (
          <h3 className=" align-items-center w-100 mb-0 text-black text-bold text-center">
            {rawdata && rawdata.header}
            {/* {rawdata.infobubble && (
              <span className="ms-n1">
                <Tooltip
                  placement="bottom"
                  size="sm"
                  buttonProps={{
                    iconOnly: true,
                    icon: 'questionCircle',
                    variant: 'basic-alt',
                    iconClass: 'pe-0 text-xdark',
                    size: 'small',
                  }}
                  message={rawdata.infobubble}
                />
              </span>
            )} */}
          </h3>
        ) : (
          <h3 className="w-100 mb-5 text-black text-center">
            {propertyDetail.name}
          </h3>
        )}

        {rawdata && rawdata.hasProgressbar && propState !== 'IPO_ACQUIRED' ? (
          <>
            <Row>
              <Col xs={7} className="mt-2 pt-2 bg-transparent">
                <h4 className="mb-0 px-0 text-nowrap">
                  {/* {ipoInfo && formatters.currency(ipoInfo.totalInvestedAmount)} */}
                </h4>
                <p className="mb-2">
                  {locale === 'en' ? 'Funding' : 'Le financement'}
                </p>
              </Col>
              <Col xs={5} className="mt-2 pt-2 bg-transparent">
                <h4 className="mb-0 px-0 d-flex flex-row-reverse">
                  {/* {ipoInfo && formatters.percent(ipoInfo.reservedPercentage)} */}
                </h4>
                {/* <small className="mb-0 d-flex flex-row-reverse">Reserved</small> */}
              </Col>
            </Row>
            <Progressbar
              type="secondary"
              // ipoprogress={ipoInfo && ipoInfo.reservedPercentage}
            />
          </>
        ) : rawdata && rawdata.haspropertyacquired ? (
          <>
            <Row>
              <Col className="mt-2 pt-2 bg-transparent">
                <span className="text-black p">
                  {locale === 'en' ? 'Added to Midasboard' : 'Ajouté à Midasboard'}
                  <Icon variant="Verified" color="" className="ps-1" />
                </span>{' '}
                <img
                  className="img-fluid float-end mt-n3 mb-4"
                  alt="ipo_acquired"
                  src="https://willow.imgix.net/2022/04/ipoacquired_small.png"
                />
              </Col>
            </Row>
          </>
        ) : (
          ''
        )}

        {rawdata && rawdata.hasmodal && (
          <PrimaryModal
            show={rawdata && rawdata.hasmodal}
            hideClose
            dialogWidth
            bodyBackground
            modalTitle={`${docHeader}`}
            body={
              <div className="mb-6 text-center justify-content-center">
                {rawdata && rawdata.hasmodal && rawdata.modaldata.length
                  ? rawdata.modaldata.map(item => {
                      const acceptBtn = item.buttons.filter(a =>
                        a.label.includes('Accept'),
                      );
                      return (
                        <RenderForm
                          centered={true}
                          id={`${item.modalformname}1`}
                          form={item.modalformname}
                          key={uid()}
                          submitFunction={e => {
                            submitForm({
                              // content: modalContent,
                              formvalues: e,
                            });
                            // segmentTrack(
                            //   acceptBtn[0].events && acceptBtn[0].events,
                            // );
                            // quantcast_conversion('Trade');
                            // uet_report_conversion('purchase', investmentTotal); // investmentTotal - units * price/share
                            // gtag_report_conversion(
                            //   'AW-643504403/KgYZCJy2oKADEJOy7LIC',
                            //   investmentTotal,
                            // );
                          }}
                          buttonFunction={() =>
                            toggleStage(
                              item && item.buttons
                                ? item.buttons.find(x => x.type === 'button')
                                    .link
                                : '',
                            )
                          }
                          buttonLayout={
                            item.buttons
                              ? [
                                  {
                                    row: [
                                      {
                                        columns: [
                                          {
                                            class:
                                              'bg-white py-4 mt-0 mb-n5 d-flex flex-column align-items-center',
                                            buttons: item.buttons,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ]
                              : ''
                          }
                          stacked
                          errorlookup={errorlookup}
                        >
                          <Row
                            className="mx-5 mt-0 mb-6"
                            style={{ height: '600px', overflowY: 'scroll' }}
                          >
                            {/* <p
                              className="w-100"
                              dangerouslySetInnerHTML={{
                                __html: modalContent,
                              }}
                            /> */}
                            {/* <iframe src={`data:text/html;charset=utf-8,${reviewOrder.embeddedURL}`} width="600" height="800"></iframe> */}
                            {/* <div id="agreement"></div> */}
                          </Row>
                        </RenderForm>
                      );
                    })
                  : ''}
              </div>
            }
          />
        )}

        {rawdata && rawdata.haslist && (
          <ListLayout
            rawdata={
              rawdata &&
              rawdata.listdata.map((item, i) => {
                return {
                  columns: [
                    {
                      id: `orderboxSection${i}`,
                      content: '',
                      rows: item.content.map(col => {
                        return {
                          hr: true,
                          condensed: true,
                          columns: [
                            {
                              id: `orderboxSubSection${uid()}`,
                              content: col.columns
                                //.filter(y => y.id !== 'closingDate')
                                .map(subcol => subcol.label),
                              widths: { xs: '7' },
                              classes: 'text-start mt-0',
                            },
                            {
                              id: `orderboxSubSection${uid()}`,
                              content: col.columns.map(subcol => reviewInfo[subcol.id]),
                              classes: 'text-end',
                            },
                          ],
                        };
                      }),
                    },
                  ],
                };
              })
            }
          />
        )}

        {rawdata &&
          rawdata.hasform &&
          rawdata.formdata &&
          rawdata.formdata.map(item => (
            <RenderForm
            centered
              id={`${item.formname && item.formname}-12`}
              key={`${item.formname && item.formname}-22`}
              destroyonunmount={item.destroyonunmount}
              // initializeValue={{
              //   price: ipoInfo && ipoInfo.pricePerShare,
              // }}
              form={item && item.formname}
              submitFunction={e => {
                submitReviewOrder({ formvalues: e, symbol, currency: propertyContent.currency });
              }}
              className="mb-2"
              buttonArray={item && item.buttons ? item.buttons : ''}
              stacked
              errorlookup={errorlookup}
              buttonFunction={() => {
                toggleStage(
                  item && item.buttons
                    ? item.buttons.find(x => x.type === 'button').link
                    : '',
                );
              }}
              keepDirtyOnReinitialize={false}
              apiErrorMessage={revieworderError}
            >
              <Row className="mt-0 justify-content-center" key="formheader">
                {item.form_header && (
                  <p className="w-100 mt-0 mb-3 text-greys-xdark text-center">
                    {item.form_header ? item.form_header : ''}
                  </p>
                )}

                {item.layout &&
                  item.layout.map((row, index) => (
                    <Row key={`form-row-${index}`} className="pe-2 justify-content-center">
                      {fieldArray(row.fields, propertyContent)}
                    </Row>
                  ))}
                {rawdata.blurb && (
                  <>
                    {numberOfUnits && (
                      <p className="text-black w-100 mb-2">
                        {/* {formatters.currency(
                          numberOfUnits * ipoInfo.pricePerShare +
                            account.tradingFee +
                            totalTax,
                        )}{' '} */}
                        {locale === 'en' //trading fee
                          ? `${
                              account.tradingFee === 0
                                ? '(trading fee waived)'
                                : `(incl $${account.tradingFee.toFixed(
                                    2,
                                  )} fee + $${(
                                    account.tradingFee.toFixed(2) * 0.13
                                  ).toFixed(2)} tax)`
                            }`
                          : `${
                              account.tradingFee === 0
                                ? '(frais de transaction supprimés)'
                                : `(compris des taxes de $${account.tradingFee.toFixed(
                                    2,
                                  )} et $${(
                                    account.tradingFee.toFixed(2) * 0.13
                                  ).toFixed(2)})`
                            }`}
                      </p>
                    )}
                    {/* <p className="text-greys-dark w-100 mb-3">
                      <strong>{formatters.currency(wallet)}</strong>{' '}
                      {rawdata.blurb ? rawdata.blurb : ''},{' '}
                      {wallet + (account.tradingFee + totalTax) >
                      ipoInfo.pricePerShare ? (
                        locale === 'en' ? (
                          <span>
                            can buy a max of{' '}
                            <strong>
                              {parseInt(
                                (wallet - (account.tradingFee + totalTax)) /
                                  ipoInfo.pricePerShare,
                              )}{' '}
                              unit(s)
                            </strong>
                          </span>
                        ) : (
                          <span>
                            peut acheter un maximum de{' '}
                            <strong>
                              {parseInt(
                                (wallet - (account.tradingFee + totalTax)) /
                                  ipoInfo.pricePerShare,
                              )}{' '}
                              unités
                            </strong>
                          </span>
                        )
                      ) : locale === 'en' ? (
                        'insufficient to purchase a unit'
                      ) : (
                        'insuffisant pour acheter une unité'
                      )}
                    </p> */}
                  </>
                )}
              </Row>
            </RenderForm>
          ))}

        {/* {rawdata.hasdescript && (
          <Row className="my-3 mx-2" key={uid()}>
            {rawdata.icon && (
              <Icon
                variant={rawdata.icon}
                aria-hidden="true"
                size="xlarge"
                className="mb-3"
              />
            )}
            <p
              className="text-greys-dark w-100 mb-3"
              dangerouslySetInnerHTML={{
                __html: rawdata.blurb ? rawdata.blurb : '',
              }}
            />
          </Row>
        )} */}
        {rawdata && rawdata.buttons
          ? rawdata.buttons.map(button => (
              <Row className="mx-2 justify-content-center" key={uid()}>
                {rawdata.submittedStatusText && <h5 className='text-center pb-3'>{rawdata.submittedStatusText}</h5>}
                {rawdata.hasQueryForMidasboard && <p className='text-center mb-2'>Questions for Midasboard?</p>}
                <Col md={10}>
                <Button
                  onClick={() =>
                    {
                      if (button.pagelink) {
                        redirect(button.pagelink && button.pagelink)
                      } else if (button.link === '/en/dashboard'){
                        submitForm({
                          symbol
                        })
                        toggleStage(button.link && button.link)
                        dispatch(push(button.link));
                      } else if (button.link === 'issue_status'){
                        toggleStage(button.link && button.link)
                        dispatch(reset('issue_status'));
                      } else {
                        submitForm({
                          symbol
                        })
                        toggleStage(button.link && button.link)
                      }
                    }
                  }
                  variant={button.variant}
                  className={`${
                    button.variant === 'transparent'
                      ? 'bg-transparent border-0 w-100'
                      : 'w-100 mb-4 text-wrap'
                  } ${button.class}`}
                  id={button.id}
                  aria-label={button.label}
                >
                  {button.mailto_link ? <Link
                          variant="basic"
                          disabled={false}
                          type="mail"
                          to={button.mailto_link}
                          nodecoration="true"
                          className="text-white"
                          style={{lineHeight: "19.2px"}}
                          onClick={() => {
                            // list.events && segmentTrack(list.events);
                          }}
                        >
                          {button.label}
                        </Link> : button.label}
                </Button>
                </Col>
              </Row>
            ))
          : ''}
      </Container>
    </>
  );
};
// TODO
// const replacements = Object.assign({}, rawdata, {
//   blurb: rawdata.blurb.replace('$1000', currency(account.availableFunds))
// })

const OrderBox = ({
  tabs,
  rawdata,
  toggleStage,
  theme,
  toggleRight,
  // modalContent,
  submitForm,
  submitReviewOrder,
  account,
  // ipoDetails,
  symbol,
  reviewOrder,
  revieworderError,
  financialfields,
  redirect,
  tags,
  loader,
  investmentTotal,
  locale,
  numberOfUnits,
  totalTax,
  propertyDetail,
  errorlookup,
  propertyContent
}) => (
  <Card fullMobile style={{ overflow: 'visible' }} className="mt-0 mt-md-9">
    <Container className="px-0 pt-1">
      {loader && (
        <Container className="text-center mt-5 py-9">
          <div className="spinner-border text-warning" style={{ color: "#D7B02F" }} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="text-dark">{revieworderError}</p>
        </Container>
      )}
      {tabs && (
        <Tab responsivewidth="true" defaultActiveKey={tabs[0].label}>
          <Tab.Control>
            {tabs &&
              tabs.map((tab, i) => (
                <Tab.Item key={i} eventKey={tab.label}>
                  {tab.label}
                </Tab.Item>
              ))}
          </Tab.Control>
        </Tab>
      )}
    </Container>
    {!loader && (
      <FormLayout
        rawdata={rawdata}
        toggleStage={toggleStage}
        theme={theme}
        toggleRight={toggleRight}
        // modalContent={modalContent}
        submitForm={submitForm}
        // ipoDetails={ipoDetails}
        symbol={symbol}
        submitReviewOrder={submitReviewOrder}
        reviewOrder={reviewOrder}
        revieworderError={revieworderError}
        account={account}
        financialfields={financialfields}
        redirect={redirect}
        tags={tags}
        investmentTotal={investmentTotal}
        locale={locale}
        numberOfUnits={numberOfUnits}
        totalTax={totalTax}
        propertyDetail={propertyDetail}
        errorlookup={errorlookup}
        propertyContent={propertyContent}
      />
    )}
  </Card>
);

export default OrderBox;
