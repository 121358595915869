/* eslint-disable no-underscore-dangle */
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';
import { setup } from 'axios-cache-adapter';
import { isLocal, isServer } from './detection';

// `async` wrapper to configure `localforage` and instantiate `axios` with `axios-cache-adapter`
async function configure() {
  if (isServer) {
    return setup({
      // `axios-cache-adapter` options
      cache: {
        // TODO: make configurable
        maxAge: 3 * 60 * 1000,
      },
    });
  }

  // Register the custom `memoryDriver` to `localforage`
  await localforage.defineDriver(memoryDriver);

  // Create `localforage` instance
  const forageStore = localforage.createInstance({
    // List of drivers used
    driver: [
      localforage.INDEXEDDB,
      localforage.LOCALSTORAGE,
      memoryDriver._driver,
    ],
    // Prefix all storage keys to prevent conflicts
    name: 'willow-cache',
  });

  // Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
  return setup({
    // `axios-cache-adapter` options
    cache: {
      // TODO: make configurable
      maxAge: 3 * 60 * 1000,
      store: forageStore, // Pass `localforage` store to `axios-cache-adapter`
    },
  });
}

let api = null;

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default async function request(
  url,
  loading,
  successMeth,
  failureMeth,
  options,
) {
  api = api || await configure();
  const result = await api.get(url, loading, successMeth, failureMeth);
  if (result.status < 200 || result.status > 300) {
    const error = new Error(result.data);
    error.response = result;
    throw error;
  }
  return result.data;
}
