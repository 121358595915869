/* eslint-disable react/react-in-jsx-scope */
import { put, select, takeLatest, call, delay } from 'redux-saga/effects';
import firebase from 'firebase/app';
import 'firebase/auth'; // for authentication
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { CHANGE_LOCALE } from 'containers/LanguageProvider/constants';
import { initialize } from 'utils/firebase';
import {
  makeSelectEnvVariablesData,
  makeSelectLocale,
  makeSelectApi,
  makeSelectLocation,
} from 'containers/App/selectors';
import { setAccountPageToast } from 'containers/AccountPage/actions';
import { setToast, setFormLoader } from 'containers/AuthPage/actions';
import { isServer } from 'utils/detection';
import { makeSelectUsers } from 'containers/App/Users/selectors';
import { userLoaded } from 'containers/App/Users/actions';
import { USER_LOADED } from 'containers/App/Users/constants';
import { makeSelectPageContent } from 'containers/AuthPage/selectors';

import {
  authChanged,
  setParams,
  triggerInactiveState,
  signOutSuccess,
  triggerInactiveModal,
  setGiftBoxInNavbar,
} from './actions';
import { makeSelectAuth } from './selectors';
import {
  SIGN_OUT_ALL_DEVICES,
  TRIGGER_AUTH_TRACKER,
  SIGN_OUT_SUCCESS,
  AUTH_CHANGED,
  INACTIVE,
} from './constants';
import { setPorfolioPageToast } from '../Portfolio/actions';
// NOTE: registrationStatus: 1 means EmailisVerified, registrationStatus: 2 means KycIsDone, registrationStatus: 3 means Funds are added, registrationStatus: 4 means InvestedInProperty
function* trackAuth({ authTracker, email }) {
  const { data } = yield select(makeSelectPageContent());
  const location = yield select(makeSelectLocation());

  if (isServer) {
    return;
  }
  const locale = yield select(makeSelectLocale());
  if (authTracker === 'signin' || authTracker === 'signInWithCode') {
    try {
      yield put(setFormLoader(true));
      yield put(setFormLoader('tick'));
      const api = yield select(makeSelectApi());
      api.post('users/users/track-sign-in');
      yield delay(2000);
      yield put(setFormLoader(false));
    } catch (error) {
      if(error.response.status === 401) {
        yield call(unassignUser())
        yield put(setToast('Make sure you are on the correct subdomain.'));
      } else {
        yield put(setToast('Server unable to respond, try again later!!'));
      }
      console.log('error:In Track Sign In  ', error);
      yield put(setFormLoader(false));
      // swallow and let system log it
    }
  } else if (authTracker === 'verify') {
    try {
      const api = yield select(makeSelectApi());
      yield api.post('api/users/email/verify', {
        // url: `http://localhost:8081/${locale}/portfolio`,
        url: `https://www.${api.environment.DOMAIN}/${locale}/portfolio`,
        email,
      });
    } catch (error) {
      yield put(
        setToast(
          error.find(o1 => data.errorlookup.some(o2 => o1.code === o2.code))
            .message,
        ),
      );
    }
  } else if (authTracker === 'signup') {
    try {
      const api = yield select(makeSelectApi());
      const response = yield api.post('api/users/email/verify', {
        // url: `http://localhost:8081/${locale}/register`,
        url: `https://www.${api.environment.DOMAIN}/${locale}/register`,
        email,
      });
      setTimeout(() => {
        api.post('users/users/track-sign-in');
      }, 750);

      yield put(setFormLoader(true));
      if (response.status === 204) {
        yield put(setFormLoader('tick'));
      }
      yield delay(3000);
      yield put(push(`/${locale}/dashboard`));
      yield put(setFormLoader(false));
      
    } catch (error) {
      console.log('error', error);
      // yield put(
      //   setToast(
      //     error.find(o1 => data.errorlookup.some(o2 => o1.code === o2.code))
      //       .message,
      //   ),
      // );
    }
  } else if (authTracker === 'resetPassword') {
    try {
      const api = yield select(makeSelectApi());
      yield api.post('api/users/email/reset-password', {
        // url: `http://localhost:8081/${locale}/resetpassword`,
        url: `https://www.${api.environment.DOMAIN}/${locale}/resetpassword`,
        email,
      });
      if (location.pathname.includes('forgot')) {
        // I don't have error lookup for account page
        yield put(
          setToast(
            data.errorlookup.find(item => item.code === 'toast').message,
          ),
        );
      }
    } catch (e) {
      yield put(setToast(e.message));
    }
  } else if (authTracker === 'setNewPassword') {
    yield put(setFormLoader(true));
    yield delay(500);
    yield put(setFormLoader('tick'));
    yield delay(2000);
    yield put(setFormLoader(false));
    yield put(push(`/${locale}/portfolio`));
  }
}

function* handleSignOutAllDevices() {
  try {
    const api = yield select(makeSelectApi());
    yield api.post('api/users/signout', {});
    yield put(setAccountPageToast('SignOut Of All device SuccessFull!!'));
    window.location.reload(false);
  } catch (error) {
    yield put(setAccountPageToast('Something went wrong'));
  }
}

// Checks Url for OOBCODE to Complete Registration
function* handleApplyAction(oobCode) {
  const locale = yield select(makeSelectLocale());

  if (isServer) {
    return;
  }
  try {
    yield firebase.auth().applyActionCode(oobCode);
    if (firebase.auth().currentUser) {
      yield firebase.auth().currentUser.reload();
      yield firebase.auth().currentUser.getIdToken(true);
      yield put(authChanged(firebase.auth().currentUser, false));
      const users = yield select(makeSelectUsers());
      const { registrationStatus } = users.current;

      if (users.current.isAuthenticated) {
        if (registrationStatus > 1) {
          // when status 2, 3, 4, 5..
            yield put(push('home'));
        }
        if (registrationStatus <= 1) {
          // when status is 1
          yield put(push(`/${locale}/dashboard`));
        }
      } else {
        yield put(push(`/${locale}/login`));
      }
    } else {
      yield put(push(`/${locale}/login`));
    }
  } catch (error) {
    yield put(setPorfolioPageToast(error.message));
    yield put(setToast(error.message));
  }
}

function* protectPathOfAccountPages() {
  const location = yield select(makeSelectLocation());
  const locale = yield select(makeSelectLocale());
  const users = yield select(makeSelectUsers());
  const auth = yield select(makeSelectAuth());
  if (location.pathname.includes('account') && users.initialized) {
    if (users.current.isAuthenticated && auth.isAuthenticated) {
      const { registrationStatus } = users.current;
      if (registrationStatus <= 1) {
        yield put(push(`/${locale}/dashboard`));
      }
      // it works because initially isAuthenticated is false so it will show a flash of login page on refresh
      if (!users.current.isAuthenticated && !auth.isAuthenticated) {
        yield put(push(`/${locale}/login`));
      }
    } else {
      yield put(push(`/${locale}/login`));
    }
  }
}

function* protectDashboardPath() {
  const location = yield select(makeSelectLocation());
  const users = yield select(makeSelectUsers());
  if (location.pathname.includes('dashboard') && users.initialized) {
    const locale = yield select(makeSelectLocale());
    if (users.current.isAuthenticated) {
      const { registrationStatus } = users.current;
      if (registrationStatus > 1) {
        // push to portfolio when status is 2, 3, 4
          yield put(push('home'));
      }
    } else {
      yield put(push(`/${locale}/login`));
    }
  }
}

function* protectPortfolioPath() {
  const location = yield select(makeSelectLocation());
  const users = yield select(makeSelectUsers());
  const auth = yield select(makeSelectAuth());
  if (location.pathname.includes('portfolio') && users.initialized) {
    const locale = yield select(makeSelectLocale());
    // it works because initially isAuthenticated is false so it will show a flash of login page on refresh
    if (!users.current.isAuthenticated && !auth.isAuthenticated) {
      yield put(push(`/${locale}/login`));
    }
  }
}

function* protectAuthPagePath() {
  const location = yield select(makeSelectLocation());
  const users = yield select(makeSelectUsers());
  const auth = yield select(makeSelectAuth());
  if (location.pathname.includes('login') && users.initialized) {
    const locale = yield select(makeSelectLocale());
    if (users.current.isAuthenticated) {
      const { registrationStatus } = users.current;
      if (registrationStatus > 1) {
        // when status 2, 3, 4, 5..
          yield put(push('home'));
      }
      if (registrationStatus <= 1) {
        // when status is 1
        yield put(push(`/${locale}/dashboard`));
      }
    }
  }
}

function* getParams() {
  const location = yield select(makeSelectLocation());
  yield put(setParams(location.query));
}

function* inactivesaga({ type }) {
  const users = yield select(makeSelectUsers());
  const auth = yield select(makeSelectAuth());
  const { inactive, inactivemodal } = auth;

  if (users.current.isAuthenticated) {
    // yield delay(8000);
    yield delay(360000); // sets timer for 6minutes after idle state

    if (inactive) {
      if (inactivemodal) {
        yield delay(60000); // sets timer for 1minute after idle state
        // yield delay(10000);
        yield put(signOutSuccess()); // logs user out after 1 minute after idle state
      } else if (type === 'INACTIVE_MODAL' && inactivemodal === false) {
        yield put(triggerInactiveState(false)); // kills the inactive state
      }
    }
    yield put(triggerInactiveState(true)); // sets watcher for modal after 14minutes of idle state
  }
}

function* unassignUser() {
  yield put(triggerInactiveState(false));
  yield put(triggerInactiveModal(false));
  firebase.auth().signOut();
  yield put(authChanged(false, false));
  yield put(userLoaded({ current: { isAuthenticated: false } }));
}

function* completeSignUpIfUrlHasOOBCode() {
  const location = yield select(makeSelectLocation());
  if (
    location.pathname.includes('portfolio') ||
    location.pathname.includes('register') ||
    location.pathname.includes('login')
  ) {
    const { oobCode } = location.query;
    if (oobCode) {
      yield call(handleApplyAction, oobCode);
    }
  }
}

function* resetPasswordIfUrlHasOOBCode() {
  const location = yield select(makeSelectLocation());
  if (location.pathname.includes('resetpassword')) {
    const { oobCode } = location.query;
    if (oobCode) {
      firebase.auth().signOut(); // user needs to be logged out or else it redirects user to portfolio page
    }
  }
}

function* setInactiveModal({ inactive }) {
  if (inactive) {
    yield put(triggerInactiveModal(true));
  }
}

// function* sendRouteChange() {
//   if (!isServer) {
//     const user = firebase.auth().currentUser;
//     if (window.appInsights) {
//       try {
//         window.appInsights.setAuthenticatedUserContext(
//           user ? user.email : null,
//         );
//       } catch (e) {
//         // swallow.  analyzers will pick this up
//       }
//     }
//     if (window.rg4js) {
//       if (user) {
//         try {
//           window.rg4js('setUser', {
//             identifier: user.email,
//             isAnonymous: false,
//             email: user.email,
//           });
//         } catch (e) {
//           // swallow.  analyzers will pick this up
//         }
//       }
//       const location = yield makeSelectLocation();
//       try {
//         window.rg4js('trackEvent', {
//           type: 'pageView',
//           path: location.pathname,
//         });
//       } catch (e) {
//         // swallow.  analyzers will pick this up
//       }
//     }
//   }
// }

export default function* authSaga() {
  const { variables } = yield select(makeSelectEnvVariablesData());
  
  // initialize firebase
  yield initialize(variables);

  yield call(resetPasswordIfUrlHasOOBCode);
  yield call(getParams);
  yield call(completeSignUpIfUrlHasOOBCode);
  yield put(authChanged(firebase.auth().currentUser, false));
    yield call(protectDashboardPath);
  yield call(protectPathOfAccountPages);
  yield call(protectPortfolioPath);
  yield takeLatest([() => true], inactivesaga);

  // action handlers
  yield takeLatest(TRIGGER_AUTH_TRACKER, trackAuth);
  yield takeLatest(USER_LOADED, protectPathOfAccountPages);
  yield takeLatest(USER_LOADED, protectDashboardPath);
  yield takeLatest([USER_LOADED, LOCATION_CHANGE], protectPortfolioPath);
  yield takeLatest(USER_LOADED, protectAuthPagePath);
  yield takeLatest(SIGN_OUT_ALL_DEVICES, handleSignOutAllDevices);
  yield takeLatest(SIGN_OUT_SUCCESS, unassignUser);
  yield takeLatest(INACTIVE, setInactiveModal);
  // yield takeLatest([LOCATION_CHANGE], sendRouteChange);
}
