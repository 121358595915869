/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import { combineReducers } from 'redux';
import { SERVER_EVENT } from './constants';

export const initialState = {
  name: false,
  content: false,
};

const data = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SERVER_EVENT:
        draft.event = action.event;
        break;
    }
  });

const eventReducers = combineReducers({
  data,
});

export default eventReducers;
