import theme from '../../sass/export.scss';

export const colors = {
  brand: {
    primary: theme['colors-primary'], // '#4CD683',
    secondary: theme['colors-secondary'], // '#45975A',
    xlight: theme['colors-xlight'], // '#4CD683',
    light: theme['colors-light'], // '#45975A',
    medium: theme['colors-medium'], // '#327242',
    dark: theme['colors-dark'], // '#1F5728',
    xdark: theme['colors-xdark'], // '#143F1B',
  },
  accent: {
    yellow: '#F4D424',
    blue: '#CFDAE0',
    pink: '#F2D7E9',
  },
  greys: {
    white: theme['greys-white'], // '#FFFFFF',
    xlight: theme['greys-xlight'], // '#FFFFFF',
    light: theme['greys-light'], // '#EDEDED',
    medium: theme['greys-medium'], // '#BCBCBC',
    dark: theme['greys-dark'], // '#666666',
    xdark: theme['greys-xdark'], // '#191919',
    black: theme['greys-black'], // '#000000',
  },
  system: {
    success: theme['colors-success'], // #FFECA0
    warning: theme['colors-warning'], // #FFCF70
    error: theme['colors-error'], // #E85C4A
    info: theme['colors-info'], // #FFECA0
    danger: theme['colors-danger'], // #E85C4A
  },
};

export const spacing = {
  xxxlarge: theme['spacers-9'], // '80px',
  xxlarge: theme['spacers-8'], // '64px',
  xlarge: theme['spacers-7'], // '48px',
  large: theme['spacers-6'], // '32px',
  medium: theme['spacers-5'], // '24px',
  small: theme['spacers-4'], // '20px',
  xsmall: theme['spacers-3'], // '16px',
  xxsmall: theme['spacers-2'], // '8px',
  xxxsmall: theme['spacers-1'], // '4px',
  none: 0,
  minusone: '-1px',
};

// TBD: liekly handled in Sass
export const typography = {
  primary:
    'Helvetica, Arial, Tahoma, sans-serif',
  size: {
    base: '16px',
    xsuper: '6.25rem',
    super: '3rem',
    xlarge: '1.875rem',
    large: '1.5rem',
    medium: '1.125rem',
    small: '1rem',
    xsmall: '0.875rem',
    xxsmall: '0.75rem',
  },
  lineHeight: {
    large: '160%',
    medium: '150%',
    small: '120%',
  },
  weight: {
    regular: '400',
    medium: '600',
    book: '700',
  },
  letterSpacing: {
    large: '0.1rem',
    medium: '0.05rem',
    small: '0.01rem',
  },
};

// TO DO: import from sass theme
export const shapes = {
  borderRadiusSmall: '4px',
  borderRadiusMedium: '12px',
  borderRadiusLarge: '30px',
  borderWidthSmall: '1px',
  borderWidthMedium: '3px',
};

// TO DO: import from sass theme?
export const depths = {
  '-1': 'inset 2px 2px 7px rgba(0, 0, 0, 0.2)',
  '0': 'none',
  '1': '0px 2px 5px rgba(0, 0, 0, 0.3)',
  '2': '0px 10px 20px rgba(0, 0, 0, 0.2)',
  '3': '0px 30px 30px rgba(0, 0, 0, 0.15)',
};

export const durations = {
  xfast: '0.1s',
  fast: '0.2s',
  medium: '0.3s',
  slow: '0.6s',
  xslow: '0.7s',
  xxslow: '1s',
  xxxslow: '2s',
};

export const easing = {
  in: 'cubic-bezier(0.55, 0, 1, 0.45)',
  out: 'cubic-bezier(0, 0.55, 0.45, 1)',
};

const Theme = {
  colors,
  spacing,
  typography,
  shapes,
  depths,
  durations,
  easing,
  // mediasizes
};

export default Theme;
