/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PrimaryModal from 'components/PrimaryModal';
import Navbar from 'components/NavbarV1';
import Button from 'components/Button';
import Elements from 'components/Elements';
import Grid from 'components/Grid';
import { setToast } from 'containers/AuthPage/actions';
import {
  authChanged,
  signOutSuccess,
  triggerInactiveModal,
} from 'containers/AuthProvider/actions';
import Seo from 'components/Seo';
import SocialShare from 'components/SocialShare';
import { makeSelectAuth } from 'containers/AuthProvider/selectors';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { Toast, ToastContainer, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import ScrollToTop from 'components/ScrollToTop';
import { SagaInjectionModes, useInjectSaga } from 'redux-injectors';
import { createStructuredSelector } from 'reselect';
import { ThemeProvider } from 'styled-components';
import { setActiveTab } from 'containers/AccountPage/actions';
import { logout } from 'utils/firebase';
import { copyUrl } from 'utils/ui';
import { replaceTokens } from 'utils/detection';
import { dismissNotification } from './actions';
import eventsSaga from './Events/saga';
import saga from './sagas';

import {
  makeSelectEnvVariablesData,
  makeSelectFooterState,
  makeSelectLocale,
  makeSelectLocaleName,
  makeSelectLocation,
  makeSelectNavBarState,
  makeSelectNotifications,
} from './selectors';
import theme from './theme';
import usersSaga from './Users/saga';
import { makeSelectUsers } from './Users/selectors';

const key = 'app';
const stateSelector = createStructuredSelector({
  locale: makeSelectLocale(),
  localeName: makeSelectLocaleName(),
  navbarState: makeSelectNavBarState(),
  footerState: makeSelectFooterState(),
  location: makeSelectLocation(),
  env: makeSelectEnvVariablesData(),
  users: makeSelectUsers(),
  auth: makeSelectAuth(),
  notifications: makeSelectNotifications(),
});
const App = ({ route }) => {
  useInjectSaga({
    key: 'events',
    saga: eventsSaga,
    mode: SagaInjectionModes.DAEMON,
  });
  useInjectSaga({
    key: 'users',
    saga: usersSaga,
    mode: SagaInjectionModes.DAEMON,
  });
  useInjectSaga({ key, saga, mode: SagaInjectionModes.DAEMON });
  const dispatch = useDispatch();
  const {
    locale,
    localeName,
    navbarState,
    payloadData,
    users,
    notifications,
    auth,
    env,
  } = useSelector(stateSelector);
  // eslint-disable-next-line no-unused-vars
  const [copyreward, setrewardcopy] = useState(false);
  const { inactivemodal, giftBoxState } = auth;

  // NOTE: Don't block rendering with Loader or it will break SSR. You can add loader in the container of the page
  return (
    <div className="bg-greys-light min-vh-100 m-auto d-flex flex-column">
      <Seo lang={locale} url={env.variables.DOMAIN} />
      <ThemeProvider theme={theme}>
        {navbarState.data && (
          <Navbar
            navData={navbarState.data}
            locale={locale}
            localeName={localeName}
            user={users.current}
            notifications={notifications}
            changeLocale={value => dispatch(changeLocale(value))}
            payloadData={payloadData}
            logout={() =>
              logout(authChanged, signOutSuccess, setToast, dispatch)
            }
            setActiveTab={v => dispatch(setActiveTab(v))}
            giftBoxState={giftBoxState}
            location={window.location}
          />
        )}

        <PrimaryModal
          show={inactivemodal}
          hideClose
          modalTitle="Still here?"
          body={
            <>
              <Row className="justify-content-center">
                <Col md={12} className="text-center pb-5">
                  Click here to continue or you will be signed out.
                </Col>
                <Col md={5} className="text-center">
                  <Button
                    variant="primary"
                    onClick={() => dispatch(triggerInactiveModal(false))}
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </>
          }
        />
        <ScrollToTop>{renderRoutes(route.routes)}</ScrollToTop>
        <ToastContainer
          position="bottom-end"
          style={{
            zIndex: 3000,
            paddingBottom: 25,
            paddingRight: 25,
          }}
        >
          {notifications &&
            notifications.items
              .filter(e => e.toast)
              .map((e, i) => (
                <Toast
                  autohide
                  bg={e.type}
                  key={i}
                  onClose={() => dispatch(dismissNotification(e))}
                >
                  <Toast.Header>
                    <strong className="me-auto">{e.title}</strong>
                  </Toast.Header>
                  <Toast.Body>{e.message}</Toast.Body>
                </Toast>
              ))}
        </ToastContainer>
      </ThemeProvider>
    </div>
  );
};

export default {
  component: App,
};
