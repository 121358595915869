export const AUTH_METHOD = 'app/global/AUTH_METHOD';
export const API_ENPOINT_PREFIX = 'wp-json/willow-content-api/v1';

export const LOAD_HOMEPAGE_CONTENT = 'boilerlplate/App/LOAD_HOMEPAGE_CONTENT';
export const LOAD_HOMEPAGE_CONTENT_SUCCESS =
  'boilerlplate/App/LOAD_HOMEPAGE_CONTENT_SUCCESS';
export const LOAD_HOMEPAGE_CONTENT_FAILURE =
  'boilerlplate/App/LOAD_HOMEPAGE_CONTENT_FAILURE';
export const SUBMIT_WAITLIST_FORM = 'boilerlplate/Home/SUBMIT_WAITLIST_FORM';
export const TOGGLE_WAITLIST_SUCCESS_MODAL =
  'boilerlplate/Home/TOGGLE_WAITLIST_SUCCESS_MODAL';
  
export const TOGGLE_VIDEO = 'boilerlplate/Home/TOGGLE_VIDEO';
