// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export const isLocal = () => {
  if (typeof window !== 'undefined') {
    if (window.location.hostname === 'localhost') {
      return true;
    }
    return false;
  }
  return false;
};

export const wpurl = env => {
  let wordpressurl = null;
  if (env.NODE_ENV === 'production') {
    if (!isServer) {
      wordpressurl = `https://wp.${env.DOMAIN}`;
    } else {
      // route directly within the cluster
      wordpressurl = 'http://wordpress';
    }
  } else {
    wordpressurl = `http://${env.DOMAIN}`;
  }
  return wordpressurl;
};

export const isClientMobile = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  const mql = window.matchMedia(
    '(min-device-width: 320px) and (max-device-width: 480px)',
  );

  // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && mql.matches ) {
  //     // console.log(`isClientMobile()`, true);
  //     return true;
  // }
  if (mql.matches) {
    // console.log(`isClientMobile()`, true);
    return true;
  }

  // console.log(`isClientMobile()`, false);
  return false;
};

export const isClientTablet = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  const mql = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');

  // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && mql.matches ) {
  //     // console.log(`isClientMobile()`, true);
  //     return true;
  // }
  if (mql.matches) {
    // console.log(`isTablet()`, true);
    return true;
  }

  // console.log(`isClientMobile()`, false);
  return false;
};
export const isClientTabletPro = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  const mql = window.matchMedia(
    '(min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)',
  );

  // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && mql.matches ) {
  //     // console.log(`isClientMobile()`, true);
  //     return true;
  // }
  if (mql.matches) {
    // console.log(`isTablet()`, true);
    return true;
  }

  // console.log(`isClientMobile()`, false);
  return false;
};

export const isUserAgentBot = () => {
  /* we can only detect this, with window.navigator.userAgent,
  if we are running on the client, because only then will we actually have a window */

  if (typeof window === 'undefined') {
    return null;
  }

  const userAgentString = window.navigator.userAgent.toLowerCase(); // make it lowercase to do bot substring detection
  if (userAgentString.indexOf('bot') === -1) {
    // console.log(`isUserAgentBot==>`, false);
    return false;
  }
  // console.log(`isUserAgentBot==>`, true);
  return true;
};

export function elementInViewport(el) {
  // console.log(`elementInViewport`, el, `typeof el==`, typeof el);
  let top = el.offsetTop;
  let left = el.offsetLeft;
  const width = el.offsetWidth;
  let height = el.offsetHeight;
  if (height === 0) {
    height = 1; // re-assign it to 1; a hack
  }

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
}

// basically the same as determineElementsInViewPort, but they don't have to be in the viewport
export function determineElementsInPlay(elementPrefix, stripPrefix = false) {
  if (typeof window === 'undefined') {
    return null;
  }
  let potentialItems = document.querySelectorAll(`[id^="${elementPrefix}"]`);
  const itemsInPlay = [];
  potentialItems = potentialItems !== null ? potentialItems : []; // make it always return an array
  for (let i = 0; i < potentialItems.length; i++) {
    const item = potentialItems[i];
    itemsInPlay.push(item.id);
  }
  if (stripPrefix) {
    return itemsInPlay.map(item => Number(item.replace(elementPrefix, ``)));
  }
  return itemsInPlay;
}

// it might be better to work with refs in react, but o well, this works.
export function determineElementsInViewPort(
  elementPrefix,
  stripPrefix = false,
) {
  // this only works if the ads in question follow a strict naming convention
  // this works on ad unit components, not on ad unit holder containers
  // this is becasue of a couple reasons ... ads don't always appear in ad holders
  // also, becasue of the trict id naming convention requirement, it is better to have a minimum # of places where these things are named, in order to enforce the id naming requirement more easily / reliably.
  // this is the pattern / convention: ad_leaderboard_${this.props.index}
  if (typeof window === 'undefined') {
    return null;
  }
  let potentialItems = document.querySelectorAll(`[id^="${elementPrefix}"]`);
  const itemsInViewport = [];
  potentialItems = potentialItems !== null ? potentialItems : []; // make it always return an array
  if (!potentialItems) {
    return itemsInViewport;
  }
  // now determine what of these things are actually in the viewport, and return those.

  for (let i = 0; i < potentialItems.length; i++) {
    const item = potentialItems[i];
    if (elementInViewport(item)) {
      itemsInViewport.push(item.id);
    }
  }

  if (stripPrefix) {
    return itemsInViewport.map(item => Number(item.replace(elementPrefix, ``)));
  }
  return itemsInViewport;
}

export function getPageName(url) {
  const filename = url.split('/').pop();
  const str = filename.replace(/-/g, ' ');
  return str.toUpperCase();
}

export const replaceTokens = (text, token) => {
  String.prototype.replaceArray = function(find, replace) {
    let replaceString = this;
    for (let i = 0; i < find.length; i++) {
      replaceString = replaceString.replace(find[i], replace[i]);
    }
    return replaceString;
  };

  const tokenisedObj = token.filter(tkn => text.indexOf(tkn.pattern) > -1);
  text = text.replaceArray(
    tokenisedObj.map(obj => obj.pattern),
    tokenisedObj.map(obj =>
      typeof obj.replaceWith === 'undefined' ? '__' : obj.replaceWith,
    ),
  );
  return text;
};

export function execFn(fnName, ctx, params) {
  if (params) {
    return ctx[fnName](...params);
  }
  return ctx[fnName];
}
export const getStatusVariant = status => {
  // const index = data.indexOf(item);
  switch (status) {
    case 0:
      return 'Start';
    case 1:
      return 'InProgress';
    case 2:
      return 'Completed';
    default:
      return 'Locked';
  }
};

export function getFirstBrowserLanguage() {
  const nav = window.navigator;
  const browserLanguagePropertyKeys = [
    'language',
    'browserLanguage',
    'systemLanguage',
    'userLanguage',
  ];
  let i;
  let language;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i];
      if (language && language.length) {
        if (language.indexOf('-') !== -1) {
          return (language = language.split('-')[0]);
        }
      }
    }
  }
  // console.log('here you have', language)

  // support for other well known properties in browsers
  // for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
  //   language = nav[browserLanguagePropertyKeys[i]];
  //   if (language && language.length) {
  //     if (language.indexOf('-') !== -1) {
  //       return language = language.split('-')[0];
  //     }
  //   }
  // }
  // console.log('here you have', language)

  return language;
}

export default determineElementsInViewPort;
