/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
const addLocaleData = require('react-intl').addLocaleData; //eslint-disable-line
const enLocaleData = require('react-intl/locale-data/en');
const frLocaleData = require('react-intl/locale-data/fr');


const enTranslationMessages = require('./translations/en.json');
const frTranslationMessages = require('./translations/fr.json');

addLocaleData(enLocaleData);
addLocaleData(frLocaleData);

const DEFAULT_LOCALE = 'en';

// prettier-ignore
const appLocales = [
  'en',
  'fr',
];
function getFirstBrowserLanguage() {
  var nav = window.navigator,
  browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
  i,
  language;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i];
      if (language && language.length) {
        if (language.indexOf('-') !== -1) {
          return language = language.split('-')[0];
        }
      }
    }
  }
  // support for other well known properties in browsers
  // for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
  //   language = nav[browserLanguagePropertyKeys[i]];
  //   if (language && language.length) {
  //     if (language.indexOf('-') !== -1) {
  //       return language = language.split('-')[0];
  //     }
  //   }
  // }

  return language;
};

function determineUserLang(acceptedLangs, path = null) {
  // check url for /en/foo where en is a supported language code
  if (path !== null) {
    const urlLang = path.trim().split("/")[1];

    const matchingUrlLang = findFirstSupported([stripCountry(urlLang)]);

    if (matchingUrlLang) {
      return matchingUrlLang;
    }
  }

  // check browser-set accepted langs
  const matchingAcceptedLang = findFirstSupported(
    acceptedLangs.map(stripCountry),
  );
  
  return matchingAcceptedLang || DEFAULT_LOCALE;
}

function findFirstSupported(langs) {
  const supported = appLocales;
  return langs.find(code => supported.includes(code));
}

function stripCountry(lang) {
  return lang
    .trim()
    .replace("_", "-")
    .split("-")[0];
}

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  fr: formatTranslationMessages('fr', frTranslationMessages),
};

exports.appLocales = appLocales;
exports.formatTranslationMessages = formatTranslationMessages;
exports.findFirstSupported = findFirstSupported;
exports.determineUserLang = determineUserLang;
exports.getFirstBrowserLanguage = getFirstBrowserLanguage;
exports.translationMessages = translationMessages;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
