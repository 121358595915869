import { uid } from '../../utils/ui';
import {
  LOAD_NAVBAR_CONTENT,
  LOAD_NAVBAR_CONTENT_SUCCESS,
  LOAD_NAVBAR_CONTENT_FAILURE,
  LOAD_FOOTER_CONTENT,
  LOAD_FOOTER_CONTENT_SUCCESS,
  LOAD_FOOTER_CONTENT_FAILURE,
  LOAD_ALLPOSTS_URL,
  LOAD_ALLPOSTS_URL_FAILURE,
  LOAD_ALLPOSTS_URL_SUCCESS,
  ENV_VARIABLES,
  LOOKUPS_LOADING,
  LOOKUPS_LOADING_SUCCESS,
  LOOKUPS_LOADING_FAILURE,
  USER_PROPERTIES_LOADED,
  USER_PROPERTIES_LOADED_SUCCESS,
  USER_PROPERTIES_LOADED_FAILURE,
  NOTIFY,
  DISMISS_NOTIFICATION,
} from './constants';

export function loadUserProperties() {
  return {
    type: USER_PROPERTIES_LOADED,
  };
}

export function loadUserPropertiesSuccess(data) {
  return {
    type: USER_PROPERTIES_LOADED_SUCCESS,
    data,
  };
}

export function loadUserPropertiesFailure(error) {
  return {
    type: USER_PROPERTIES_LOADED_FAILURE,
    error,
  };
}

// these actions sets the countries lookup
export function lookupsLoading() {
  return {
    type: LOOKUPS_LOADING,
  };
}

export function lookupsLoadedSuccess(data) {
  return {
    type: LOOKUPS_LOADING_SUCCESS,
    data,
  };
}

export function lookupsLoadedFailure(error) {
  return {
    type: LOOKUPS_LOADING_FAILURE,
    error,
  };
}

// this action sets the env variables
export function setEnvVariables(variables) {
  return {
    type: ENV_VARIABLES,
    variables,
  };
}

// these actions are to set navbar content
export function loadNavBarContent() {
  return {
    type: LOAD_NAVBAR_CONTENT,
  };
}

export function loadNavBarContentSuccess(data) {
  return {
    type: LOAD_NAVBAR_CONTENT_SUCCESS,
    data,
  };
}

export function loadNavBarContentFailure() {
  return {
    type: LOAD_NAVBAR_CONTENT_FAILURE,
  };
}

export function notify(title, message, type, toast) {
  return {
    type: NOTIFY,
    notification: {
      message,
      title,
      type,
      id: uid(),
      toast,
    },
  };
}

export function dismissNotification(item) {
  return {
    type: DISMISS_NOTIFICATION,
    notification: item,
  };
}

// these actions are to set footer content
export function loadFooterContent() {
  return {
    type: LOAD_FOOTER_CONTENT,
  };
}

export function loadFooterContentSuccess(data) {
  return {
    type: LOAD_FOOTER_CONTENT_SUCCESS,
    data,
  };
}

export function loadFooterContentFailure() {
  return {
    type: LOAD_FOOTER_CONTENT_FAILURE,
  };
}

// these actions are to set sitemap urls
export function loadAllPostUrl() {
  return {
    type: LOAD_ALLPOSTS_URL,
  };
}

export function loadAllPostUrlSuccess(data) {
  return {
    type: LOAD_ALLPOSTS_URL_SUCCESS,
    data,
  };
}

export function loadAllPostUrlFailure() {
  return {
    type: LOAD_ALLPOSTS_URL_FAILURE,
  };
}
