/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { Container } from 'react-bootstrap';
import ListLayout from 'components/List/ListLayout';
import { formatters } from '../../../../utils/formatters';
import Tooltip from 'components/TooltipV1';

const RateSection = ({ data, infobubble }) => {
  if (data) {
  return (
    <Container fluid className="pt-4 text-center bg-white">
      <ListLayout
        fluid
        rawdata={[
          {
            columns: [
              ...data.map((item, i) => {
                const format = formatters[item.format] || formatters.none;
                return {
                  id: `rateSection${i}`,
                  widths: { xs: '6', md: '6', lg: '3' },
                  content: (
                    <React.Fragment key={i}>
                      {infobubble && (
                        <div
                          className="position-absolute top-0"
                          style={{ right: '-32px' }}
                        >
                          <Tooltip
                            placement="top"
                            size="sm"
                            buttonProps={{
                              iconOnly: true,
                              icon: 'questionCircle',
                              variant: 'basic-alt',
                              iconClass: 'p-0 text-xdark',
                              size: 'small',
                            }}
                            message={infobubble}
                          />
                        </div>
                      )}

                      <span className="text-muted p">{item.label}</span>
                      <h3 className="mt-0 mx-0 mb-2 mb-md-3">
                        {format(item.value)}
                      </h3>
                    </React.Fragment>
                  ),
                  id: `rateSection${i}`,
                };
              }),
            ],
          },
        ]}
      />
    </Container>
  );
} else {
  return '';
}

};

export default RateSection;
