/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-destructuring */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { combineReducers } from 'redux';
import {
  LOAD_NAVBAR_CONTENT,
  LOAD_NAVBAR_CONTENT_SUCCESS,
  LOAD_NAVBAR_CONTENT_FAILURE,
  LOAD_FOOTER_CONTENT,
  LOAD_FOOTER_CONTENT_SUCCESS,
  LOAD_FOOTER_CONTENT_FAILURE,
  LOAD_ALLPOSTS_URL,
  LOAD_ALLPOSTS_URL_FAILURE,
  LOAD_ALLPOSTS_URL_SUCCESS,
  ENV_VARIABLES,
  LOOKUPS_LOADING,
  LOOKUPS_LOADING_SUCCESS,
  LOOKUPS_LOADING_FAILURE,
  USER_PROPERTIES_LOADED,
  USER_PROPERTIES_LOADED_SUCCESS,
  USER_PROPERTIES_LOADED_FAILURE,
  NOTIFY,
  DISMISS_NOTIFICATION,
} from './constants';

// const testData = import('./data//navbar_en.json');

const initialCountriesState = {
  error: false,
  loading: true,
  data: false,
};

const initialAllPostUrl = {
  data: false,
  loading: false,
  error: false,
};
const initialNavBarState = {
  data: false,
  loading: false,
  error: false,
};
const initialFooterState = {
  data: false,
  loading: false,
  error: false,
};

const initialEnvVariables = {
  variables: false,
};

const initialUserProperties = {
  data: false,
  loading: false,
  error: false,
};

export const initialState = {
  lookups: initialCountriesState,
  navbar: initialNavBarState,
  footer: initialFooterState,
  environment: initialEnvVariables,
  properties: initialUserProperties,
};

const properties = (state = initialUserProperties, action) =>
  produce(state, draft => {
    switch (action.type) {
      case USER_PROPERTIES_LOADED:
        // draft.data = false;
        // draft.loading = true;
        // draft.error = false;
        break;
      case USER_PROPERTIES_LOADED_SUCCESS:
        draft.data = action.data;
        // draft.loading = false;
        // draft.error = false;
        break;
      case USER_PROPERTIES_LOADED_FAILURE:
        // draft.data = false;
        // draft.loading = false;
        // draft.error = true;
        break;
    }
  });

const lookups = (state = initialCountriesState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOOKUPS_LOADING:
        // draft.data = false;
        // draft.loading = true;
        // draft.error = false;
        break;
      case LOOKUPS_LOADING_SUCCESS:
        draft.data = action.data;
        // draft.loading = false;
        // draft.error = false;
        break;
      case LOOKUPS_LOADING_FAILURE:
        // draft.data = false;
        // draft.loading = false;
        // draft.error = true;
        break;
    }
  });
const environment = (state = initialEnvVariables, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ENV_VARIABLES:
        draft.variables = action.variables;
        break;
    }
  });

const navbar = (state = initialNavBarState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_NAVBAR_CONTENT:
        // draft.data = false;
        // draft.loading = true;
        draft.error = false;
        break;
      case LOAD_NAVBAR_CONTENT_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        draft.error = false;
        break;
      case LOAD_NAVBAR_CONTENT_FAILURE:
        // draft.data = false;
        // draft.loading = false;
        // draft.error = true;
        break;
    }
  });

const footer = (state = initialFooterState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_FOOTER_CONTENT:
        // draft.data = false;
        // draft.error = false;
        // draft.loading = true;
        break;
      case LOAD_FOOTER_CONTENT_SUCCESS:
        draft.data = action.data;
        // draft.error = false;
        // draft.loading = false;
        break;
      case LOAD_FOOTER_CONTENT_FAILURE:
        // draft.data = false;
        // draft.error = true;
        // draft.loading = false;
        break;
    }
  });

const notifications = (
  state = {
    items: []
  },
  action,
) =>
  produce(state, draft => {
    switch (action.type) {
      case NOTIFY:
        draft.items.push(action.notification);
        break;
      case DISMISS_NOTIFICATION:
        const index = state.items.indexOf(action.notification);
        draft.items[index].toast = false;
        break;
    }
  });

const appReducer = combineReducers({
  navbar,
  footer,
  environment,
  lookups,
  notifications,
  properties,
});

export default appReducer;
