import {
  APPLY_ACTION,
  AUTH_CHANGED, // used
  MFA_REQUIRED,
  REQUIRE_SMS_VERIFICATION,
  SIGN_OUT_ALL_DEVICES,
  SIGN_OUT_SUCCESS,
  TRIGGER_AUTH_TRACKER, // used
  AUTH_FAILED,
  SET_PARAMS,
  INACTIVE_MODAL,
  INACTIVE,
  GIFT_BOX_STATE,
} from './constants';

export function setGiftBoxInNavbar(giftBoxState) {
  return {
    type: GIFT_BOX_STATE,
    giftBoxState,
  };
}

export function triggerInactiveState(inactive) {
  return {
    type: INACTIVE,
    inactive,
  };
}

export function triggerInactiveModal(inactivemodal) {
  return {
    type: INACTIVE_MODAL,
    inactivemodal,
  };
}

export function setParams(params) {
  return {
    type: SET_PARAMS,
    params,
  };
}

export function authChanged(firebase, issuer) {
    // used
  return {
    type: AUTH_CHANGED,
    firebase,
    issuer
  };
}

export function triggerAuthTracker(authTracker, email) {
  // used
  return {
    type: TRIGGER_AUTH_TRACKER,
    authTracker,
    email,
  };
}

export function authFailed(error) {
    // used
  return {
    type: AUTH_FAILED,
    error,
  };
}

export function storePhoneVerificationData(verificationId, resolver) {
  // used
  return {
    type: MFA_REQUIRED,
    verificationId,
    resolver,
  };
}

export function requireSmsVerification(verificationId) {
  // used in accountpage container
  return {
    type: REQUIRE_SMS_VERIFICATION,
    verificationId,
  };
}

export function applyAction(oobCode) {
  return {
    type: APPLY_ACTION,
    oobCode,
  };
}

export function signOutSuccess() {
  // used to update Navbar items
  return {
    type: SIGN_OUT_SUCCESS,
  };
}

export function signOutOnAllDevices() {
  // used under AccountPage
  return {
    type: SIGN_OUT_ALL_DEVICES,
  };
}
