/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
import moment from 'moment';

/* eslint-disable camelcase */
export const required = value =>
  value === 0 || value
    ? undefined
    : window.location.href.includes('en')
    ? 'Required'
    : 'Obligatoire';

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? window.location.href.includes('en')
      ? 'Invalid email address'
      : 'Adresse e-mail invalide'
    : undefined;

export const phoneNumber = value =>
  value &&
  !/^(\+\d{1,2}\s)?\(?[2-9](?!11)\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(value)
    ? window.location.href.includes('en')
      ? 'Please verify your number'
      : 'Veuillez vérifier votre numéro'
    : undefined;

export const dob = value =>
  value && !moment(value).isValid()
    ? window.location.href.includes('en')
      ? 'Incorrect Date of Birth'
      : 'Date de naissance incorrecte'
    : undefined;

export const pdfFile = value => {
  if (value && value[0]) {
    const fileType = value[0].type;
    if (fileType && fileType !== 'application/pdf') {
      return 'Only PDF files are allowed';
    } else {
      return undefined;
    }
  }
  return undefined;
};

export const filefield = value => {
  if (value === undefined) {
    return 'Please select a file before clicking Upload';
  }
  return undefined;
};

export const postalCode = value =>
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
    value,
  )
    ? undefined
    : window.location.href.includes('en')
    ? 'Incorrect postal code'
    : 'Code postal erroné';

export const sin = value =>
  value && !/^[0-9]{3}[\- ]?[0-9]{3}[\- ]?[0-9]{3}$/i.test(value)
    ? window.location.href.includes('en')
      ? 'Incorrect SIN.'
      : 'NAS incorrect.'
    : undefined;
// eslint-disable-next-line consistent-return
export const notallzero = (value, allValues) => {
  if (
    (allValues.liabilities === '0' ||
      allValues.liabilities === '$0' ||
      allValues.liabilities === 0) &&
    (allValues.annualIncome === '0' ||
      allValues.annualIncome === '$0' ||
      allValues.annualIncome === 0) &&
    (allValues.fixedAssets === '0' ||
      allValues.fixedAssets === '$0' ||
      allValues.fixedAssets === 0) &&
    (allValues.liquidAssets === '0' ||
      allValues.liquidAssets === '$0' ||
      allValues.liquidAssets === 0) &&
    (value === 0 || value === '0' || value === '$0')
  ) {
    if (window.location.href.includes('en')) {
      return `Not all values can be zero`;
    }
    return `Toutes les valeurs ne peuvent pas etre $0`;
  } else if (value) {
    return undefined;
  }
};
export const confirm_password = (value, allValues) =>
  value !== allValues.password
    ? window.location.href.includes('en')
      ? 'Passwords do not match'
      : 'Les mots de passe ne correspondent pas'
    : undefined;

export const password = value =>
  value && !/^(?=.*?[a-z])(?=.*?[0-9]|.*?[#?!@$%^&*-]).{8,}$/i.test(value)
    ? window.location.href.includes('en')
      ? 'Your password must have at least 8 characters and a mix of numbers or symbols'
      : 'Votre mot de passe doit comporter au moins 8 caractères et un mélange de chiffres ou de symboles'
    : undefined;

export const checkbox = value =>
  value
    ? undefined
    : window.location.href.includes('en')
    ? 'You must agree to the T&C'
    : 'Vous devez accepter les CGU';

export const percentage = value =>
  value && !/^\d+$/i.test(value)
    ? window.location.href.includes('en')
      ? 'Provide a valid number'
      : 'Provide a valid number'
    : undefined;