/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
// import Icon from "../Icon";
import Link from '../Link';

/*
 */

// Container
const StyledContainer = styled(({ twoCol, condensed, ...rest }) => (
  <Container {...rest} />
))`
  ${({ theme }) => css`
    border: none;
    padding: ${theme.spacing.none};
    padding-top: ${theme.spacing.large};
    padding-bottom: ${theme.spacing.xxsmall};
    border-radius: ${theme.shapes.borderRadiusMedium}
      ${theme.shapes.borderRadiusMedium} 0 0; // Radius at the top only
  `}
`;

export const ListHeader = props => {
  return (
    <StyledContainer fluid {...props}>
      <Row>{props.children}</Row>
    </StyledContainer>
  );
};

// Title
const StyledListHeaderCol = styled(Col)`
  ${({ theme }) => css`
    font-weight: ${theme.typography.weight.book};
  `};
`;
export const ListHeaderTitle = props => {
  return <StyledListHeaderCol {...props}>{props.children}</StyledListHeaderCol>;
};

// Link
const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.greys.xdark};
    font-weight: ${theme.typography.weight.regular};
    line-height: ${theme.typography.lineHeight.large};
    letter-spacing: ${theme.typography.lineHeight.small};
    :hover {
      color: ${theme.colors.greys.xdark};
    }
  `}
`;

export const ListHeaderLink = props => {
  return (
    <Col className={`text-right ${props.colClassName}`}>
      <StyledLink {...props} variant="cta">
        {props.children}
      </StyledLink>
    </Col>
  );
};
