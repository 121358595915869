/* eslint-disable react/prop-types */
/*
 *
 * LanguageProvider
 *
 * This component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { IntlProvider } from 'react-intl';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { makeSelectLocale } from './selectors';
import { appLocales } from '../../i18n';

const stateSelector = createSelector(makeSelectLocale(), locale => ({
  locale,
}));

function findFirstSupported(langs) {
  const supported = appLocales;

  return langs.find(code => supported.includes(code));
}
function stripCountry(lang) {
  return lang
    .trim()
    .replace('_', '-')
    .split('-')[0];
}
export function determineUserLang(path = null) {
  // check url for /en/foo where en is a supported language code
  if (path !== null) {
    const urlLang = path.trim().split('/')[1];

    const matchingUrlLang = findFirstSupported([stripCountry(urlLang)]);

    if (matchingUrlLang) {
      return matchingUrlLang;
    }
  }

  // check browser-set accepted langs
  const matchingAcceptedLang = findFirstSupported(appLocales.map(stripCountry));

  return matchingAcceptedLang || 'en';
}

const LanguageProvider = props => {
  const { locale } = useSelector(stateSelector);
  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={props.messages[locale]}
    >
      {React.Children.only(props.children)}
    </IntlProvider>
  );
};

export default LanguageProvider;
