/* eslint-disable no-fallthrough */
/* eslint-disable no-duplicate-case */
export const handleKycButton = (
  phone,
  verificationcode,
  twoFaemail,
  twoFapassword,
  allValues,
  currentStep,
  kind,
  dispatch,
  triggerCtaButton,
) => {
  dispatch(triggerCtaButton(currentStep, allValues, kind));
};

export function getKycStatus(registration, bankTransaction, availableFunds) {
  let kycStatus = 'none';
  // NONE, INPROGRESS, SUBMITTED, APPROVED, REJECTED, SUSPENDED;
  switch (registration) {
    case 'NONE':
      kycStatus = 'none'; //
      break;
    case 'INPROGRESS':
      kycStatus = 'inprogress';
      break;
    // case 'SUBMITTED':
    //   kycStatus = 'submitted';
    //   break;
    // case 'SUBMITTED':
    //   if (
    //     bankTransaction.length > 0 &&
    //     bankTransaction.find(trans => trans.state === 'CANCELED')
    //   ) {
    //     kycStatus = 'submitted&fundrequested'; // It can be cancelled here at this stage
    //     break;
    //   }
    // case 'SUBMITTED':
    //   if (bankTransaction.length === 0) {
    //     kycStatus = 'submitted&nofundrequested';
    //     break;
    //   }
    case 'SUBMITTED':
      // if (bankTransaction.length > 0) {
        kycStatus = 'submitted';
        break;
      // }
    case 'APPROVED':
      kycStatus = 'approved&fundrequested&fundsavailable';
      break;
    // case 'APPROVED':
    //   if (
    //     bankTransaction.length === 1 &&
    //     bankTransaction.find(trans => trans.state === 'CANCELED')
    //   ) {
    //     kycStatus = 'approved&fundrequested&fundsavailable';
    //     break;
    //   }
    // case 'APPROVED':
    //   if (bankTransaction.length === 0) {
    //     kycStatus = 'approved&nofundrequested'; // Add funds
    //     break;
    //   }
    // case 'APPROVED':
    //   if (
    //     bankTransaction.length > 0 &&
    //     bankTransaction.every(trans => trans.state === 'CANCELED') &&
    //     availableFunds === 0
    //   ) {
    //     kycStatus = 'approved&fundrequested&fundsavailable';
    //     break;
    //   }
    // case 'APPROVED':
    //   if (
    //     bankTransaction.length > 0 &&
    //     bankTransaction.find(trans => trans.state === 'CANCELED')
    //   ) {
    //     kycStatus = 'approved&fundrequested&fundsavailable'; // Show the form
    //     break;
    //   }

    // case 'APPROVED':
    //   if (
    //     bankTransaction.length > 0 &&
    //     !bankTransaction.find(trans => trans.state === 'CANCELED')
    //   ) {
    //     kycStatus = 'approved&fundrequested&fundsavailable'; // Show the form
    //     break;
    //   }

    // case 'APPROVED':
    //   if (bankTransaction.length > 0 && availableFunds > 0) {
    //     kycStatus = 'approved&fundrequested&fundsavailable';
    //     break;
    //   }

    case 'REJECTED':
      kycStatus = 'rejected';
      break;
    case 'SUSPENDED':
      kycStatus = 'suspended';
      break;
    default:
      kycStatus = 'none';
      break;
  }

  return kycStatus;
}
