import {
  LOAD_TRADEVIEW_CONTENT,
  LOAD_TRADEVIEW_CONTENT_SUCCESS,
  LOAD_TRADEVIEW_CONTENT_FAILURE,
  LOAD_SINGLE_PROPERTY_CONTENT,
  LOAD_SINGLE_PROPERTY_CONTENT_SUCCESS,
  LOAD_SINGLE_PROPERTY_CONTENT_FAILURE,
  LOAD_KYC_WP_CONTENT,
  LOAD_KYC_WP_CONTENT_SUCCESS,
  LOAD_KYC_WP_CONTENT_FAILURE,
  TOGGLE_STAGE,
  TOGGLE_TRADE_TAB,
  TOGGLE_MAIN_TAB,
  REMOVE_WATCHLIST,
  SUBMIT_WATCHLIST,
  SUBMIT_REVIEW_ORDER,
  SUBMIT_FORM_DATA,
  LOAD_REVIEW_DATA,
  LOAD_REVIEW_DATA_SUCCESS,
  LOAD_REVIEW_DATA_FAILURE,
  TOGGLE_CAPSULE,
  LOAD_REGISTRATION,
  ORDERBOX_LOADER,
  LOAD_ISSUES_DATA,
  LOAD_ISSUE_DATA,
  LOAD_USER_DOCUMENTS,
  TOGGLE_DOWNLOAD,
  CLICKED_DOCUMENT
} from './constants';

export function clickedDocument(document) {
  return {
    type: CLICKED_DOCUMENT,
    document,
  };
}

export function loadIssuesData(issues) {
  return {
    type: LOAD_ISSUES_DATA,
    issues,
  };
}

export function loadUserDocuments(documents) {
  return {
    type: LOAD_USER_DOCUMENTS,
    documents,
  };
}

export function toggleDownload(document) {
  return {
    type: TOGGLE_DOWNLOAD,
    document
  };
}

export function loadIssueData(issue) {
  return {
    type: LOAD_ISSUE_DATA,
    issue,
  };
}

export function setOrderBoxLoader(orderboxLoader) {
  return {
    type: ORDERBOX_LOADER,
    orderboxLoader,
  };
}

export function loadRegistration(registration) {
  return {
    type: LOAD_REGISTRATION,
    registration,
  };
}

export function loadKYCWPContent() {
  return {
    type: LOAD_KYC_WP_CONTENT,
  };
}

export function loadKYCWPContentSuccess(data) {
  return {
    type: LOAD_KYC_WP_CONTENT_SUCCESS,
    data,
  };
}

export function loadKYCWPContentFailure(error) {
  return {
    type: LOAD_KYC_WP_CONTENT_FAILURE,
    error,
  };
}

export function submitWatchlist(symbol) {
  return {
    type: SUBMIT_WATCHLIST,
    symbol,
  };
}

export function removeWatchlist(symbol) {
  return {
    type: REMOVE_WATCHLIST,
    symbol,
  };
}

export function submitReviewOrder(formdata, symbol) {
  return {
    type: SUBMIT_REVIEW_ORDER,
    formdata: formdata,
    symbol,
  };
}

export function submitForm(formdata, symbol) {
  return {
    type: SUBMIT_FORM_DATA,
    // content: formdata.content,
    formdata: formdata ? formdata : null,
    // units: formdata.formvalues.no_of_units,
    symbol,
  };
}

export function toggleMainTab(activeMainTab) {
  return {
    type: TOGGLE_MAIN_TAB,
    activeMainTab,
  };
}
export function toggleTradeTab(activeTradeTab) {
  return {
    type: TOGGLE_TRADE_TAB,
    activeTradeTab,
  };
}

export function toggleStage(activeStage) {
  return {
    type: TOGGLE_STAGE,
    activeStage,
  };
}
export function loadTradeviewContent() {
  return {
    type: LOAD_TRADEVIEW_CONTENT,
  };
}

export function loadTradeviewContentSuccess(pageContent) {
  return {
    type: LOAD_TRADEVIEW_CONTENT_SUCCESS,
    pageContent,
  };
}

export function loadTradeviewContentFailure(pageContentError) {
  return {
    type: LOAD_TRADEVIEW_CONTENT_FAILURE,
    pageContentError,
  };
}
export function loadSinglePropertyContent() {
  return {
    type: LOAD_SINGLE_PROPERTY_CONTENT,
  };
}

export function loadSinglePropertyContentSuccess(propertyContent) {
  return {
    type: LOAD_SINGLE_PROPERTY_CONTENT_SUCCESS,
    propertyContent,
  };
}

export function loadSinglePropertyContentFailure(propertyContentError) {
  return {
    type: LOAD_SINGLE_PROPERTY_CONTENT_FAILURE,
    propertyContentError,
  };
}

export function toggleCapsule(activeCapsule) {
  return {
    type: TOGGLE_CAPSULE,
    activeCapsule,
  };
}

export function loadReviewData() {
  return {
    type: LOAD_REVIEW_DATA,
  };
}

export function loadReviewDataSuccess(data) {
  return {
    type: LOAD_REVIEW_DATA_SUCCESS,
    data,
  };
}

export function loadReviewDataFailure(errorMessage) {
  return {
    type: LOAD_REVIEW_DATA_FAILURE,
    errorMessage,
  };
}
