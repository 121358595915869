/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { replaceTokens } from 'utils/detection';
import Footer from 'components/Footer';
import {
  makeSelectLocale,
  makeSelectNavBarState,
  makeSelectFooterState,
  makeSelectEnvVariablesData,
} from 'containers/App/selectors';
import Carousel from 'components/Carousel';
import Grid from 'components/Grid';
import { makeSelectUsers } from 'containers/App/Users/selectors';
import saga from './sagas';
import reducer from './reducer';
import { LOAD_MARKETPLACE_PAGE_DATA } from './constants';
import { makeSelectMarketPlacePage } from './selectors';
import { uid } from '../../utils/ui';
import Seo from '../../components/Seo';
import Tooltip from 'components/TooltipV1';

const key = 'marketplace';
const stateSelector = createStructuredSelector({
  pagedata: makeSelectMarketPlacePage(),
  navbarState: makeSelectNavBarState(),
  footerState: makeSelectFooterState(),
  locale: makeSelectLocale(),
  env: makeSelectEnvVariablesData(),
  users: makeSelectUsers(),
});

const Marketplace = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch({ type: LOAD_MARKETPLACE_PAGE_DATA });
  }, []);
  const { pagedata, env, users, locale, footerState } = useSelector(
    stateSelector,
  );
  const { properties, wpcontent } = pagedata;
  const currentURL = `${env.variables.DOMAIN}${location.pathname}`;

  const primaryData =
    properties &&
    properties.data &&
    properties.data.results.filter(
      prop => prop.state === 'IPO_ACQUIRED' || prop.state === 'COMING_SOON',
    );

  const count = primaryData.length;

  const ownership =
    users &&
    users.current &&
    users.current.userProperties &&
    users.current.userProperties.properties;

  const allProperties =
    properties &&
    properties.data &&
    properties.data.results.filter(
      j => j.state === 'IPO_ACQUIRED' || j.state === 'COMING_SOON',
    );
  return wpcontent.data ? (
    <section className="pt-4">
      <Seo
        title={wpcontent.data && wpcontent.data.pageTitle}
        description={wpcontent.data.pageDescription}
        url={currentURL}
        image={wpcontent.data.ogImage}
      />
      {wpcontent.data &&
        wpcontent.data.section &&
        wpcontent.data.section.map(item => {
          const daysLeft = item && item.daysleftlabel;
          const invested = item && item.investedlabel;
          const reserved = item && item.reservelabel;
          const owned = item && item.ownedlabel;
          const primaryOffering = item && item.offeringlabel1;
          const secondaryOffering = item && item.offeringlabel2;
          const events = item && item.events;
          const infobubble = item && item.info_icon_blurb;

          return (
            <Grid
              key={uid()}
              hasbanner={item.hasbanner}
              bannerproperties={{
                size: 'lg',
                variant: 'basic',
                height: '400px',
                className: 'bg-white',
              }}
              container={[
                {
                  type: 'fluid',
                  rows: [
                    ...item.rows.map((row, i) => ({
                      id: `portSection2-${i}`,
                      class: 'justify-content-center',
                      columns: [
                        ...row.columns.map(col => ({
                          widths: col.widths && JSON.parse(col.widths),
                          id: `portSection3-${col.id}`,
                          class: col.class,
                          content: (
                            <>
                              <p className={col.headerclass}>
                                {col.heading}
                                {col.heading && infobubble && (
                                  <Tooltip
                                    placement="right"
                                    size="sm"
                                    buttonProps={{
                                      iconOnly: true,
                                      icon: 'questionCircle',
                                      variant: 'basic-alt',
                                      iconClass: 'p-0 text-xdark',
                                      size: 'small',
                                    }}
                                    message={infobubble}
                                  />
                                )}
                              </p>

                              <p className={col.subheaderclass}>
                                {col.hasprimarycarousel
                                  ? replaceTokens(col.subheading, [
                                      {
                                        pattern: '[Count]',
                                        replaceWith: count,
                                      },
                                    ])
                                  : replaceTokens(col.subheading, [
                                      {
                                        pattern: '[Count]',
                                        replaceWith: allProperties.length,
                                      },
                                    ])}
                              </p>

                              {col.hascarousel && (
                                <Carousel
                                  id={`carousel-${col.id}`}
                                  data={properties.data.results}
                                  ownership={ownership}
                                  daysLeftLabel={daysLeft}
                                  investedLabel={invested}
                                  reserved={reserved}
                                  ownedLabel={owned}
                                  primaryOfferingLabel={primaryOffering}
                                  secondaryOfferingLabel={secondaryOffering}
                                  locale={locale}
                                  events={events}
                                />
                              )}
                            </>
                          ),
                        })),
                      ],
                    })),
                  ],
                },
              ]}
            />
          );
        })}
      {footerState && <Footer rawdata={footerState.data} locale={locale} />}
    </section>
  ) : (
    <section>
      <Seo
        title={wpcontent.data && wpcontent.data.pageTitle}
        description={wpcontent.data.pageDescription}
        url={currentURL}
        image={wpcontent.data.ogImage}
      />
      <>
        <Grid
          key="GridLoader"
          hasbanner
          bannerproperties={{
            variant: 'basic',
            size: 'lg',
            height: '400px',
            className: 'bg-white',
          }}
          container={[
            {
              type: 'fluid',
              class: 'px-0',
              widths: { md: 11, xl: 7, lg: 9, sm: 8, xs: 12 },
              key: 'loadingContainerForBanner',
              rows: [
                {
                  class: 'justify-content-center',
                  id: 'loadingRow',
                  columns: [{ class: '', id: 'loadingBanner', content: '' }],
                },
              ],
            },
          ]}
        />
      </>
      <Grid
        key="carouselGrid"
        container={[
          {
            type: 'fluid',
            class: 'pt-9 mt-9',
            rows: [
              {
                id: `carouselRow`,
                class: 'my-9',
                columns: [
                  {
                    id: 'carouselOuterCol',
                    class: 'ms-0 ms-md-7',
                    widths: { md: '12' },
                    content: <Carousel loading="true" />,
                  },
                ],
              },
            ],
          },
        ]}
      />
    </section>
  );
};

export default {
  component: Marketplace,
};
