/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
/* eslint-disable react/no-danger */
/* eslint-disable no-eval */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import PageHeader from 'components/PageHeader';
import PrimaryModal from 'components/PrimaryModal';
import { push } from 'connected-react-router';
import Sidepanel from 'components/Sidepanel';
import Filter from 'components/Filter';
import Seo from 'components/Seo';
import CheckLoader from 'components/CheckLoader';
import Button from 'components/Button';
import Elements from 'components/Elements';
import ListLayout from 'components/List/ListLayout';
import FormLayout from 'components/FormLayout';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import React, { useEffect, useState } from 'react';
import Footer from 'components/Footer';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'components/Icon';
import moment from 'moment';
import { currency } from 'utils/tradeview';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { createStructuredSelector } from 'reselect';
import { segmentTrack } from 'utils/segment';
// import { uet_report_conversion } from 'utils/bingTags';
import { gtag_report_conversion } from 'utils/googleTags';
import { quantcast_conversion } from 'utils/quantcastTags';

import {
  requireSmsVerification,
  authFailed,
  authChanged,
  signOutOnAllDevices,
  triggerAuthTracker,
} from 'containers/AuthProvider/actions';
import { makeSelectAuth } from 'containers/AuthProvider/selectors';
import {
  makeSelectApi,
  makeSelectEnvVariablesData,
  makeSelectFooterState,
  makeSelectLocale,
} from 'containers/App/selectors';
import { phoneNumber, postalCode, required, filefield } from 'utils/validation';
import { replaceTokens, execFn } from 'utils/detection';
import { flinks } from 'utils/flinks';
import {
  logOutOfAllDevices,
  enableMfa,
  disableMfa,
  applyVerificationCode,
  refreshCredentials,
  forgotpassword,
} from 'utils/firebase';
import { makeSelectUsers } from '../App/Users/selectors';

import {
  setActiveTab,
  storeFormData,
  toggleCapsule,
  setModalErrorMessage,
  toggleCredentialsModal,
  toggleVerificationModal,
  setAccountPageToast,
  connectAccounts,
  removeAccount,
  setFlinksModal,
  setFlinksLoader,
  toggleSidepanel,
  setTransferFundsLoader,
  loadAccountLayoutSuccess,
} from './actions';
import reducer from './reducer';
import saga from './saga';
import { makeSelectAccountData } from './selectors';
import { formatters } from '../../utils/formatters';

const key = 'account';
const stateSelector = createStructuredSelector({
  layoutData: makeSelectAccountData(),
  env: makeSelectEnvVariablesData(),
  users: makeSelectUsers(),
  auth: makeSelectAuth(),
  api: makeSelectApi(),
  footerState: makeSelectFooterState(),
  locale: makeSelectLocale(),
});
const Account = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const {
    layoutData,
    users,
    env,
    auth,
    api,
    footerState,
    locale,
  } = useSelector(stateSelector);

  const {
    data,
    activeTab,
    loading,
    activeCapsule,
    errorMessage,
    verificationModal, // Don't remove this, it is used as a context
    credentialsModal, // Don't remove this, it is used as a context
    modalErrorMessage,
    toast,
    flinksModal, // Don't remove this, it is used as a context
    flinksLoader,
    transferFundsLoader,
    sidepanel,
    sidepanelContent,
    documents,
    initialValues,
    customerType
  } = layoutData;
  const { errorlookup } = data;
  const [flinksFailureText, setFlinksFailureTest] = useState(false);
  const [flinksStatus, setFlinksStatus] = useState(false);

  useEffect(() => {
    // This is Iframe communicator
    if (flinksModal === true) {
      dispatch(setFlinksLoader(true));
      // Error 1: flinks iframe never mounted
      const flinksiframe = document.getElementById('flinksiframe'); // Note: make sure this id is giving to the iframe in the wordpress

      flinksiframe.onload = function(f) {
        try {
          if (
            flinksiframe.src.includes('https://willow-iframe.private.fin.ag')
          ) {
            // eslint-disable-next-line no-unused-expressions
            flinksiframe.contentWindow.name;
          }
        } catch (e) {
          if (e.message.includes('cross-origin')) {
            dispatch(setFlinksLoader(false));
            setFlinksFailureTest(
              '<div class="p-9"><strong>Loading Resource...</strong> <br/> If it takes longer than expected, please try clearing your browser cache (Windows: Ctrl-F5, Apple: SHIFT+Reload toolbar button, or CMD+SHIFT+R) to clear your cache. <br/> This issue may also be caused by certain browser extensions that inhibit cross-site scripting, or Antivirus software. <br/> <small>Still having trouble?  Please reach out to us! <a type="mail" to="mailto:support@willow.ca " class="Link__StyledA-sc-15xgqoj-1 fnCTpG text-primary border-0 mt-0 mx-0 mb-1 ps-0" href="mailto:support@willow.ca ">support@willow.ca </a> </small></div>',
            );
          } else console.log('preload', e.message);
        }
      };
      if (!flinksiframe) {
        dispatch(setFlinksLoader(false));
        setFlinksFailureTest('Something went wrong, try again later.');
      }
      window.addEventListener('message', e => {
        // Error 2: flinks iframe is mounted but the src failed, this happens for many reasons, eg: slow internet, cors issue, chrome extension
        if (e.origin.startsWith('https://willow-iframe.private.fin.ag')) {
          if (e.data) {
            setFlinksFailureTest(false);
            setFlinksStatus(e.data.step);
            if (
              e.data &&
              e.data.step === 'COMPONENT_LOAD_INSTITUTION_SELECTOR'
            ) {
              dispatch(setFlinksLoader(false));
            }
            if (e.data && e.data.step === 'REDIRECT' && e.data.loginId) {
              dispatch(connectAccounts(e.data));
              dispatch(setTransferFundsLoader('bank_account_connected'));
              setTimeout(() => {
                dispatch(setFlinksModal(false));
              }, 5000);
            }
          } else {
            setFlinksFailureTest(
              '<div class="p-9"><strong>Loading Resource...</strong> <br/> If it takes longer than expected, please try clearing your browser cache (Windows: Ctrl-F5, Apple: SHIFT+Reload toolbar button, or CMD+SHIFT+R) to clear your cache. <br/> This issue may also be caused by certain browser extensions that inhibit cross-site scripting, or Antivirus software. <br/> <small>Still having trouble?  Please reach out to us! <a type="mail" to="mailto:support@willow.ca " class="Link__StyledA-sc-15xgqoj-1 fnCTpG text-primary border-0 mt-0 mx-0 mb-1 ps-0" href="mailto:support@willow.ca ">support@willow.ca </a> </small></div>',
            );
          }
        }
      });
    }
  }, [flinksModal]);

  useEffect(() => {
    // setLoader(true);
    const loadData = async () => {
      const someData = await import(`./account_${locale}.json`);
      const dataBasedOnPath = someData.default;
      dispatch(loadAccountLayoutSuccess(dataBasedOnPath));
      // setLoader(false);
    };
    loadData();
  }, []);

  const layoutBasedOnTab =
    data && data.accountField.find(item => item.key === activeTab && item.customerType === customerType);

  const submitEvent =
    layoutBasedOnTab &&
    layoutBasedOnTab.formdata &&
    layoutBasedOnTab.formdata.filter(item =>
      item.formname.includes('transfer_funds'),
    );

  useEffect(() => {
    const revenue =
      layoutData && layoutData.formData && layoutData.formData.data.amount;

    const element = document.getElementById('fundSubmit');
    element && element.addEventListener('click', myHandler);
    function myHandler() {
      // segmentTrack(
      //   submitEvent[0].buttons[0].events && submitEvent[0].buttons[0].events,
      // );
      // quantcast_conversion('AddFund');
      // uet_report_conversion('other'); // revenue --- amount transferred/withdrawn from the the account
      // gtag_report_conversion('AW-643504403/21eMCInrl6ADEJOy7LIC'); // addfunds
    }
  });

  const linkingaccountEvent =
    layoutBasedOnTab &&
    layoutBasedOnTab.formdata &&
    layoutBasedOnTab.formdata.filter(
      item =>
        item.formname.includes('missing_accounts') ||
        item.formname.includes('bank_apps'),
    );

  useEffect(() => {
    const element = document.getElementById('flinks');
    // element && element.addEventListener('click', myclickHandler);
    // function myclickHandler() {
    //   // segmentTrack(
    //   //   linkingaccountEvent[0].buttons[0].events &&
    //   //     linkingaccountEvent[0].buttons[0].events,
    //   // );
    // }
  });

  const groupByDate = items => {
    const grouped = {};
    const updated = [...items];
    updated
      .sort((a, b) => moment(b.timestamp).diff(moment(a.timestamp), 'minutes'))
      .forEach(e => {
        const date = moment
          .utc(e.timestamp)
          .local()
          .format('ll');
        if (!grouped[date]) {
          grouped[date] = [e];
        } else {
          grouped[date].push(e);
        }
      });
    return grouped;
  };

  let groupedSignIns = {};
  if (users && users.current.recentSignIns) {
    groupedSignIns = groupByDate(users.current.recentSignIns);
  }
  if (!users.initialized) {
    return (
      <>
        <Loader />
      </>
    );
  }
  if (loading || !users.current.isAuthenticated) {
    return <Loader />;
  }

  const initialValuesOfForm = initialValues;

  const wallet =
    users && users.current.account && users.current.account.availableFunds;

  const bankAccounts =
    (users &&
      users.current.account &&
      users.current.account.bankAccounts &&
      users.current.account.bankAccounts
        .filter(e => e.active)
        .map(e => ({
          img_url:
            'https://willow-iframe.private.fin.ag/v2/assets/images/logos/circle/1.svg',
          name: e.displayName,
          id: e.id,
          account_added: `Added ${moment
            .utc(e.added)
            .local()
            .calendar()}`,
          button: 'Remove',
          onRemove: () => {
            dispatch(removeAccount(e.id));
            dispatch(setFlinksLoader(true));
          },
        }))) ||
    [];
  const getBankAccountOptions = item => {
    if (item.name === 'account') {
      return bankAccounts.map(e => ({
        label: e.name,
        value: e.id,
      }));
    }
    return item.options ? item.options : 'false';
  };
  const tokens = [
    // {
    //   pattern: '[PHONE_NUMBER]',
    //   replaceWith:
    //     auth.multiFactor.enrolledFactors.length > 0 &&
    //     auth.multiFactor.enrolledFactors[0].phoneNumber,
    // },
    {
      pattern: '<Willow Users Account>',
      replaceWith: auth.email,
    },
    {
      pattern: '<Available Funds>',
      replaceWith: wallet ? formatters.currency(wallet) : '$0',
    },
    {
      pattern: '<Account Number>',
      replaceWith: !users.current.account.referenceNumber
        ? formatters.locale(locale, {
            en: 'Calculating your unique account number...',
            fr: 'Calcul de votre numéro de compte unique...',
          })
        : users.current.account.referenceNumber.toString().padStart(7, '0'),
    },
  ];
  const Activity = () => {
    // Don't remove this, it is used as a context
    const transactions = initialBlurb => {
      if (users.current.account.bankTransactions.length === 0) {
        return {
          status: 'inactive',
          content: initialBlurb.paragraphs.map((
            para,
            ind, // TODO: get id from wordpress
          ) => (
            <Elements
              key={`activity${ind}`}
              tag={para.tag}
              className={para.class}
            >
              {para.content}
            </Elements>
          )),
        };
      }
      const getName = type =>
        type.replace('_', ' ').replace(/\w\S*/g, function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });

      const current = [...users.current.account.bankTransactions];
      const target = current
        .sort((a, b) =>
          moment(b.timestamp).diff(moment(a.timestamp), 'seconds'),
        )
        .map(e => ({
          date: moment
            .utc(e.timestamp)
            .local()
            .format('lll'),
          description: getName(e.type),
          amount: currency(parseFloat(e.amount)),
          state: e.state,
          note: e.note ? e.note : '',
        }));
      return { status: 'active', content: target };
    };
    const trades = initialBlurb => {
      if (
        Object.keys(users.current.primaryTradeRequests).length === 0 &&
        users.current.primaryTradeRequests.constructor === Object
      ) {
        return {
          status: 'inactive',
          content: initialBlurb.paragraphs.map((
            para,
            ind, // TODO: get id from wordpress
          ) => (
            <Elements
              key={`activity${ind}`}
              tag={para.tag}
              className={para.class}
            >
              {para.content}
            </Elements>
          )),
        };
      }

      const getName = type =>
        type.replace('_', ' ').replace(/\w\S*/g, function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });

      const current = [];
      Object.keys(users.current.primaryTradeRequests).forEach(keyname =>
        users.current.primaryTradeRequests[keyname].map(req =>
          current.push(req),
        ),
      );
      const target = current
        .sort((a, b) =>
          moment(b.timeStamp).diff(moment(a.timeStamp), 'seconds'),
        )
        .map(e => ({
          date: moment
            .utc(e.timeStamp)
            .local()
            .format('lll'),
          description: e.type ? getName(e.type) : e.symbol,
          amount: currency(parseFloat(e.amount * e.quantity)),
          state: e.state || e.status,
        }));
      return {
        status: 'active',
        content: target,
      };
    };

    if (layoutBasedOnTab && (layoutBasedOnTab.layout_type === 'capsule' || layoutBasedOnTab.layout_type === 'formandcapsule')) {
      let capsuleState = layoutBasedOnTab.capsule[0].key; // initialState
      if (!activeCapsule) {
        capsuleState = capsuleState;
      }
      if (capsuleState !== activeCapsule && activeCapsule) {
        capsuleState = activeCapsule;
      }
      const getCapsuleContent = layoutBasedOnTab.capsule.find(
        cap => cap.key === capsuleState,
      );
      return eval(capsuleState)(getCapsuleContent);
    }
  };

  const Documents = () => {
    // Don't remove this, it is used as a context
    if (users.current.documents && documents) {
      const documentss = documents;
      return {
        status: 'active',
        content: documentss
          // .sort((a, b) => moment(b.created).diff(moment(a.created), 'seconds'))
          .map(e => ({
            date: moment
              .utc(e.created)
              .local()
              .calendar(),
            description: e.title,
            id: e.id,
            download: async item => {
              const response = await api.get(
                `documents/user/documents/${item.id}/url`, // TODO: replace the locale
              );
              window.open(response.url,'_blank');
              // const url = window.URL.createObjectURL(new Blob([response]));
              // const link = document.createElement('a');
              // link.href = url;
              // link.setAttribute('download', `${e.title}.pdf`); // or any other extension
              // document.body.appendChild(link);
              // link.click();
              // link.remove();
            },
          })),
      };
    }
    return {
      status: 'inactive',
      content: ["No Documents Available to Display"],
    };
  };
  const listForCapsuleView =
    layoutBasedOnTab &&
    (layoutBasedOnTab.layout_type === 'capsule' || layoutBasedOnTab.layout_type === 'formandcapsule') &&
    eval(layoutBasedOnTab.label)();
    
  return (
    <div>
      <section className="bg-white" style={{ minHeight: '60vh' }}>
        <Seo title={data && data.accountPageTitle} />
        <Container fluid className="bg-greys-light">
          <PageHeader
            size="lg"
            className="mt-5 mx-md-9"
            variant="tabs"
            options={data.accountField ? data.accountField.filter(field => field.customerType === customerType) : []}
            defaultactivekey={activeTab}
            action={v => {
              dispatch(setActiveTab(v));
              dispatch(push(v));
            }}
          >
            <p className="h2 mt-5 mb-2 ml-4 ">{data.accountPageTitle}</p>
          </PageHeader>
        </Container>
        {sidepanel && (
          <Sidepanel
            isVisible={sidepanel}
            toggleSidepanel={() => dispatch(toggleSidepanel(false, false))}
          >
            {replaceTokens(sidepanelContent, tokens)}
          </Sidepanel>
        )}
        <Container className="bg-white mt-9 pb-9" style={{ minHeight: '39vh' }}>
          {toast && (
            <>
              <Container
                style={{
                  position: 'fixed',
                  left: 15,
                  // top: 55,
                  zIndex: 3000,
                }}
              >
                <Toast
                  autohide
                  priority="low"
                  show={toast}
                  delay={5000}
                  message={toast}
                  onClose={() => dispatch(setAccountPageToast(false))}
                />
              </Container>
            </>
          )}

          {data.hasmodal &&
            data.modals.map(modal => (
              <PrimaryModal
                show={eval(modal.show)}
                close={() => {
                  dispatch(
                    execFn(
                      modal.close,
                      {
                        setFlinksModal, // TODO: this needs to move in wordpress
                      },
                      [false],
                    ),
                  );
                }}
                dialogWidth
                modalTitle={modal.modaltitle}
                body={
                  <>
                    {flinksFailureText !== false && (
                      <div
                        id="flinksdiv"
                        className={flinksFailureText && 'p-md-9 mx-md-9'}
                        dangerouslySetInnerHTML={{
                          __html: flinksFailureText,
                        }}
                      ></div>
                    )}
                    {flinksStatus === 'REDIRECT' ? (
                      <div className="p-md-9 mx-md-9 text-center">
                        <h3 className="text-dark pt-4 fw-bold">Thank you! </h3>{' '}
                        <p>
                          You have successfully connected your account. <br />{' '}
                          We will now redirect you, where you can see the status
                          of your account.
                        </p>
                      </div>
                    ) : (
                      <div
                        id="flinksdiv"
                        dangerouslySetInnerHTML={{
                          __html: modal.body,
                        }}
                      ></div>
                    )}
                    {flinksLoader && <Loader />}
                  </>
                }
              />
            ))}
          
          {layoutBasedOnTab && layoutBasedOnTab.layout_type === 'form' && (
            <FormLayout
              className="pb-4"
              locale={locale}
              submit="event"
              loader={flinksLoader}
              explicitForm={users.current.customerType}
              errorlookup={errorlookup}
              transferFundsLoader={transferFundsLoader}
              currentStep="false"
              forms={layoutBasedOnTab.formdata.filter(form =>
                form.condition ? (eval(form.condition) ? form : false) : true,
              )}
              iconClick={blurb => dispatch(toggleSidepanel(true, blurb))}
              initialValuesOfForm={{
                ...initialValuesOfForm,
                ...Object.assign(
                  {},
                  ...layoutBasedOnTab.formdata
                    .filter(form => form.initialvalues)
                    .map(acc => ({
                      [acc.key]: acc.value,
                    })),
                ),
              }}
              submitFunction={v => {

                dispatch(storeFormData(v));
              }}
              dropDownOptions={v => getBankAccountOptions(v)}
              replaceTokens={(text, formtoken) =>
                replaceTokens(text, formtoken || tokens)
              }
              context={{
                phoneNumber,
                required,
                postalCode,
                filefield,
                logOutOfAllDevices,
                enableMfa,
                disableMfa,
                flinks,
                forgotpassword,
                user: users.current,
              }}
              buttonFunctionArray={[
                users.current.email,
                triggerAuthTracker,
                signOutOnAllDevices,
                auth,
                requireSmsVerification,
                toggleVerificationModal,
                toggleCredentialsModal,
                authFailed,
                authChanged,
                dispatch,
                { accountPage: true },
                setAccountPageToast,
                auth.verificationId,
                auth.resolver,
                setModalErrorMessage,
                setFlinksModal,
              ]}
              apiErrorMessage={errorMessage}
              modals={
                data.hasModalForms && [
                  ...data.modalforms.map(modal => ({
                    modalProperties: {
                      modalTitle: modal.modalTitle,
                      key: modal.key,
                      show: eval(modal.show),
                      close: () =>
                        dispatch(
                          execFn(
                            modal.close,
                            {
                              toggleCredentialsModal,
                              toggleVerificationModal,
                            },
                            [false],
                          ),
                        ),
                    },
                    apiErrorMessage: modalErrorMessage,
                    context: {
                      refreshCredentials,
                      applyVerificationCode,
                    },
                    buttonFunctionArray: [
                      users.current.email,
                      triggerAuthTracker,
                      signOutOnAllDevices,
                      auth,
                      requireSmsVerification,
                      toggleVerificationModal,
                      toggleCredentialsModal,
                      authFailed,
                      authChanged,
                      dispatch,
                      { accountPage: true },
                      setAccountPageToast,
                      auth.verificationId,
                      auth.resolver,
                      setModalErrorMessage,
                      setFlinksModal,
                    ],
                    submitFunction: () => {},
                    forms: modal.forms,
                  })),
                ]
              }
              content={[
                {
                  name: 'recent_logins',
                  data: (
                    <>
                      {users.current.recentSignIns && (
                        <>
                          <hr className="mt-1 mb-1" />
                          <ListLayout
                            rawdata={[
                              ...Object.keys(groupedSignIns)
                                .slice(0, 9)
                                .map(item => ({
                                  columns: [
                                    {
                                      widths: { md: 3 },
                                      content: (
                                        <>
                                          <h5
                                            className={`${`font-bold pt-4 pb-3`}`}
                                            style={{ fontSize: 16 }}
                                          >
                                            {item}
                                          </h5>
                                        </>
                                      ),
                                    },
                                    {
                                      content: '',
                                      rows: [
                                        {
                                          columns: [
                                            ...groupedSignIns[item].map(
                                              signins => ({
                                                widths: { md: 12 },
                                                content: '',
                                                rows: [
                                                  {
                                                    columns: [
                                                      {
                                                        widths: { md: 2 },
                                                        content: (
                                                          <h5
                                                            className="text-greys-medium"
                                                            style={{
                                                              fontSize: 16,
                                                            }}
                                                          >
                                                            {moment
                                                              .utc(
                                                                signins.timestamp,
                                                              )
                                                              .local()
                                                              .format(
                                                                'hh:mm A',
                                                              )}
                                                          </h5>
                                                        ),
                                                      },
                                                      {
                                                        widths: { md: 8 },
                                                        content: (
                                                          <>
                                                            <h5
                                                              className="font-bold"
                                                              style={{
                                                                fontSize: 16,
                                                              }}
                                                            >
                                                              {signins.browser},{' '}
                                                              {signins.location ||
                                                                'Unknown'}
                                                            </h5>
                                                            <p className="text-greys-medium small">
                                                              {formatters.locale(
                                                                locale,
                                                                {
                                                                  en: `(IP Address: ${signins.ipAddress})`,
                                                                  fr: `(Adresse IP: ${signins.ipAddress})`,
                                                                },
                                                              )}
                                                            </p>
                                                          </>
                                                        ),
                                                      },
                                                      {
                                                        content: (
                                                          <h5
                                                            className="text-greys-medium"
                                                            style={{
                                                              fontSize: 16,
                                                            }}
                                                          >
                                                            {
                                                              signins.operatingSystem
                                                            }
                                                          </h5>
                                                        ),
                                                      },
                                                    ],
                                                  },
                                                ],
                                              }),
                                            ),
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                })),
                            ]}
                          />
                          <hr className="mt-1 mb-1" />
                        </>
                      )}
                    </>
                  ),
                },
                {
                  name: 'bank_apps',
                  data: (
                    <>
                      {bankAccounts.length > 0 && (
                        <>
                          <hr className="mt-1 mb-1" />
                          <ListLayout
                            rawdata={[
                              ...bankAccounts.map(ba => ({
                                columns: [
                                  {
                                    content: '',
                                    rows: [
                                      {
                                        columns: [
                                          {
                                            widths: {
                                              xs: 2,
                                              md: 2,
                                              lg: 1,
                                            },
                                            classes: 'text-center',
                                            content: (
                                              <>
                                                <Icon
                                                  size="xlarge"
                                                  variant={ba.name
                                                    .substring(0, 2)
                                                    .toLowerCase()}
                                                />
                                              </>
                                            ),
                                          },
                                          {
                                            widths: {
                                              xs: 6,
                                              md: 8,
                                              lg: 9,
                                            },
                                            content: (
                                              <>
                                                <p className="mb-0 text-black">
                                                  {ba.name}
                                                </p>

                                                <p className="mb-0 text-greys-dark small">
                                                  {ba.account_added}
                                                </p>
                                              </>
                                            ),
                                          },
                                          {
                                            widths: {
                                              xs: 4,
                                              md: 2,
                                              lg: 2,
                                            },
                                            content: (
                                              <>
                                                <Button
                                                  variant="basic"
                                                  className="border-0 bg-transparent w-100"
                                                  onClick={ba.onRemove}
                                                >
                                                  {ba.button}
                                                </Button>
                                              </>
                                            ),
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              })),
                            ]}
                          />
                          <hr className="mt-1 mb-1" />
                        </>
                      )}
                    </>
                  ),
                },
              ]}
            />
          )}

          {layoutBasedOnTab && layoutBasedOnTab.layout_type === 'capsule' && (
            <Container className="pt-8 pb-8">
              <Filter
                className="pb-4"
                options={layoutBasedOnTab.capsule && layoutBasedOnTab.capsule}
                defaultKey={layoutBasedOnTab.capsule[0].key}
                activeKey={activeCapsule}
                onSelect={v => dispatch(toggleCapsule(v))}
              />
              <hr className="mt-1 mb-1" />
              <ListLayout
                rawdata={[
                  ...listForCapsuleView.content.map(doc => ({
                    columns: [
                      {
                        content:
                          listForCapsuleView.status === 'inactive' && doc,
                        rows: [
                          {
                            columns: [
                              {
                                rows: doc.note && [
                                  {
                                    columns: [
                                      {
                                        content: doc.note,
                                        classes: 'py-1',
                                      },
                                    ],
                                  },
                                ],
                                content: doc.date,
                                widths: { xs: 8, md: 4 },
                                classes: 'pt-2 pb-4',
                              },
                              {
                                widths: { xs: 4, md: 3 },
                                content: doc.description,
                                classes: 'pt-2 pb-4',
                              },
                              {
                                widths: { xs: 8, md: 3 },
                                content: doc.state && doc.state,
                                classes: 'pt-2 pb-4',
                              },
                              {
                                widths: { xs: 4, md: 2 },
                                classes: 'pt-2 pb-4',
                                content: (
                                  <>
                                    {doc.amount && doc.amount}
                                    {doc.download && (
                                      <>
                                        <div
                                          onClick={() => doc.download(doc)}
                                          className="text-dark pe-2 text-decoration-underline d-inline"
                                          style={{cursor: 'pointer'}}
                                        >
                                          {formatters.locale(locale, {
                                            en: 'Download File',
                                            fr: 'Télécharger le File',
                                          })}
                                        </div>
                                        <Icon
                                          variant="download"
                                          color="#000000"
                                        />
                                      </>
                                    )}
                                  </>
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  })),
                ]}
              />
              <hr className="mt-1 mb-1" />
            </Container>
          )}

          {layoutBasedOnTab && layoutBasedOnTab.layout_type === 'formandcapsule' && 
          <>
          <Container className="pt-8 pb-8">
              <Filter
                className="pb-4"
                options={layoutBasedOnTab.capsule && layoutBasedOnTab.capsule}
                defaultKey={layoutBasedOnTab.capsule[0].key}
                activeKey={activeCapsule}
                onSelect={v => dispatch(toggleCapsule(v))}
              />
              <hr className="mt-1 mb-1" />
              <ListLayout
                rawdata={[
                  ...listForCapsuleView.content.map(doc => ({
                    columns: [
                      {
                        content:
                          listForCapsuleView.status === 'inactive' && doc,
                        rows: [
                          {
                            columns: [
                              {
                                rows: doc.note && [
                                  {
                                    columns: [
                                      {
                                        content: doc.note,
                                        classes: 'py-1',
                                      },
                                    ],
                                  },
                                ],
                                content: doc.date,
                                widths: { xs: 8, md: 4 },
                                classes: 'pt-2 pb-4',
                              },
                              {
                                widths: { xs: 4, md: 3 },
                                content: doc.description,
                                classes: 'pt-2 pb-4',
                              },
                              {
                                widths: { xs: 8, md: 3 },
                                content: doc.state && doc.state,
                                classes: 'pt-2 pb-4',
                              },
                              {
                                widths: { xs: 4, md: 2 },
                                classes: 'pt-2 pb-4',
                                content: (
                                  <>
                                    {doc.amount && doc.amount}
                                    {doc.download && (
                                      <>
                                        <div
                                          onClick={() => doc.download(doc)}
                                          className="text-dark pe-2 text-decoration-underline d-inline"
                                          style={{cursor: 'pointer'}}
                                        >
                                          {formatters.locale(locale, {
                                            en: 'Download File',
                                            fr: 'Télécharger le File',
                                          })}
                                        </div>
                                        <Icon
                                          variant="download"
                                          color="#000000"
                                        />
                                      </>
                                    )}
                                  </>
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  })),
                ]}
              />
              <hr className="mt-1 mb-1" />
            </Container>
            <FormLayout
              className="pb-4"
              locale={locale}
              submit="event"
              loader={flinksLoader}
              explicitForm={users.current.customerType}
              errorlookup={errorlookup}
              transferFundsLoader={transferFundsLoader}
              currentStep="false"
              forms={layoutBasedOnTab.formdata.filter(form =>
                form.condition ? (eval(form.condition) ? form : false) : true,
              )}
              iconClick={blurb => dispatch(toggleSidepanel(true, blurb))}
              initialValuesOfForm={{
                ...initialValuesOfForm,
                ...Object.assign(
                  {},
                  ...layoutBasedOnTab.formdata
                    .filter(form => form.initialvalues)
                    .map(acc => ({
                      [acc.key]: acc.value,
                    })),
                ),
              }}
              submitFunction={v => {
                dispatch(storeFormData(v));
              }}
              dropDownOptions={v => getBankAccountOptions(v)}
              replaceTokens={(text, formtoken) =>
                replaceTokens(text, formtoken || tokens)
              }
              context={{
                phoneNumber,
                required,
                postalCode,
                filefield,
                logOutOfAllDevices,
                enableMfa,
                disableMfa,
                flinks,
                forgotpassword,
                user: users.current,
              }}
              buttonFunctionArray={[
                users.current.email,
                triggerAuthTracker,
                signOutOnAllDevices,
                auth,
                requireSmsVerification,
                toggleVerificationModal,
                toggleCredentialsModal,
                authFailed,
                authChanged,
                dispatch,
                { accountPage: true },
                setAccountPageToast,
                auth.verificationId,
                auth.resolver,
                setModalErrorMessage,
                setFlinksModal,
              ]}
              apiErrorMessage={errorMessage}
              modals={
                data.hasModalForms && [
                  ...data.modalforms.map(modal => ({
                    modalProperties: {
                      modalTitle: modal.modalTitle,
                      key: modal.key,
                      show: eval(modal.show),
                      close: () =>
                        dispatch(
                          execFn(
                            modal.close,
                            {
                              toggleCredentialsModal,
                              toggleVerificationModal,
                            },
                            [false],
                          ),
                        ),
                    },
                    apiErrorMessage: modalErrorMessage,
                    context: {
                      refreshCredentials,
                      applyVerificationCode,
                    },
                    buttonFunctionArray: [
                      users.current.email,
                      triggerAuthTracker,
                      signOutOnAllDevices,
                      auth,
                      requireSmsVerification,
                      toggleVerificationModal,
                      toggleCredentialsModal,
                      authFailed,
                      authChanged,
                      dispatch,
                      { accountPage: true },
                      setAccountPageToast,
                      auth.verificationId,
                      auth.resolver,
                      setModalErrorMessage,
                      setFlinksModal,
                    ],
                    submitFunction: () => {},
                    forms: modal.forms,
                  })),
                ]
              }
              content={[
                {
                  name: 'recent_logins',
                  data: (
                    <>
                      {users.current.recentSignIns && (
                        <>
                          <hr className="mt-1 mb-1" />
                          <ListLayout
                            rawdata={[
                              ...Object.keys(groupedSignIns)
                                .slice(0, 9)
                                .map(item => ({
                                  columns: [
                                    {
                                      widths: { md: 3 },
                                      content: (
                                        <>
                                          <h5
                                            className={`${`font-bold pt-4 pb-3`}`}
                                            style={{ fontSize: 16 }}
                                          >
                                            {item}
                                          </h5>
                                        </>
                                      ),
                                    },
                                    {
                                      content: '',
                                      rows: [
                                        {
                                          columns: [
                                            ...groupedSignIns[item].map(
                                              signins => ({
                                                widths: { md: 12 },
                                                content: '',
                                                rows: [
                                                  {
                                                    columns: [
                                                      {
                                                        widths: { md: 2 },
                                                        content: (
                                                          <h5
                                                            className="text-greys-medium"
                                                            style={{
                                                              fontSize: 16,
                                                            }}
                                                          >
                                                            {moment
                                                              .utc(
                                                                signins.timestamp,
                                                              )
                                                              .local()
                                                              .format(
                                                                'hh:mm A',
                                                              )}
                                                          </h5>
                                                        ),
                                                      },
                                                      {
                                                        widths: { md: 8 },
                                                        content: (
                                                          <>
                                                            <h5
                                                              className="font-bold"
                                                              style={{
                                                                fontSize: 16,
                                                              }}
                                                            >
                                                              {signins.browser},{' '}
                                                              {signins.location ||
                                                                'Unknown'}
                                                            </h5>
                                                            <p className="text-greys-medium small">
                                                              {formatters.locale(
                                                                locale,
                                                                {
                                                                  en: `(IP Address: ${signins.ipAddress})`,
                                                                  fr: `(Adresse IP: ${signins.ipAddress})`,
                                                                },
                                                              )}
                                                            </p>
                                                          </>
                                                        ),
                                                      },
                                                      {
                                                        content: (
                                                          <h5
                                                            className="text-greys-medium"
                                                            style={{
                                                              fontSize: 16,
                                                            }}
                                                          >
                                                            {
                                                              signins.operatingSystem
                                                            }
                                                          </h5>
                                                        ),
                                                      },
                                                    ],
                                                  },
                                                ],
                                              }),
                                            ),
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                })),
                            ]}
                          />
                          <hr className="mt-1 mb-1" />
                        </>
                      )}
                    </>
                  ),
                },
                {
                  name: 'bank_apps',
                  data: (
                    <>
                      {bankAccounts.length > 0 && (
                        <>
                          <hr className="mt-1 mb-1" />
                          <ListLayout
                            rawdata={[
                              ...bankAccounts.map(ba => ({
                                columns: [
                                  {
                                    content: '',
                                    rows: [
                                      {
                                        columns: [
                                          {
                                            widths: {
                                              xs: 2,
                                              md: 2,
                                              lg: 1,
                                            },
                                            classes: 'text-center',
                                            content: (
                                              <>
                                                <Icon
                                                  size="xlarge"
                                                  variant={ba.name
                                                    .substring(0, 2)
                                                    .toLowerCase()}
                                                />
                                              </>
                                            ),
                                          },
                                          {
                                            widths: {
                                              xs: 6,
                                              md: 8,
                                              lg: 9,
                                            },
                                            content: (
                                              <>
                                                <p className="mb-0 text-black">
                                                  {ba.name}
                                                </p>

                                                <p className="mb-0 text-greys-dark small">
                                                  {ba.account_added}
                                                </p>
                                              </>
                                            ),
                                          },
                                          {
                                            widths: {
                                              xs: 4,
                                              md: 2,
                                              lg: 2,
                                            },
                                            content: (
                                              <>
                                                <Button
                                                  variant="basic"
                                                  className="border-0 bg-transparent w-100"
                                                  onClick={ba.onRemove}
                                                >
                                                  {ba.button}
                                                </Button>
                                              </>
                                            ),
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              })),
                            ]}
                          />
                          <hr className="mt-1 mb-1" />
                        </>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </>}
        </Container>

        <div id="recaptcha-container-verification"></div>
      </section>
      {footerState && (
        <Footer
          className="bottom-0 end-0 w-100"
          rawdata={footerState.data}
          locale={locale}
        />
      )}
    </div>
  );
};

export default {
  component: Account,
};
