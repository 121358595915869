import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectUsers = state => {
    return state.users || initialState;
}

const makeSelectUsers = () =>
    createSelector(selectUsers, substate => {        
        return substate.data;
    });

export { selectUsers, makeSelectUsers }