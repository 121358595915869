/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import styled from 'styled-components';
import PageHeader from 'components/PageHeader';
import VideoBanner from 'components/PageHeader/videobanner';
import FormLayout from 'components/FormLayout';
// NOTE: Don't use uid for keys or else it will create multiple re-rendering
const StyledHeader = styled(PageHeader)`
  background-color: ${props => props.backgroundColor || '#3E2F5A'};
  .step-border {
    border: 2px solid white;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    text-align: center;
    padding: 10px 0 10px 0;
    margin-bottom: 15px;
  }
  .step-border-complete {
    background-color: #4cd683;
    border: 2px solid #4cd683;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    text-align: center;
    padding: 10px 0 10px 0;
  }
`;

const PageHeaderWrapper = ({ hasbanner, hasvideobanner, wrapper, children }) =>
  hasbanner || hasvideobanner ? wrapper(children) : children;

const group = container => (
  <>
    {container.map(cont => (
      <Container
        className={`${cont.class}`}
        role={cont.role}
        style={{
          backgroundImage: cont.bgColor ? cont.bgColor : `url(${cont.bgImage})`,
          backgroundColor: cont.bgColor,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        fluid={cont.type === 'fluid'}
        key={cont.key}
      >
        {cont.rows &&
          cont.rows.map(row => (
            <Row
              hr={row.hr}
              className={row.class}
              key={row.key}
              condensed={row.condensed}
            >
              {row.columns &&
                row.columns.map(col => (
                  <Col
                    key={col.key}
                    {...col.widths}
                    ref={col.ref}
                    className={`${col.class}`}
                    onClick={col.onClick}
                    style={{
                      backgroundImage: col.bgImage ? `url(${col.bgImage})` : '',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }}
                  >
                    {col.content}
                    {col.hasform && (
                      <FormLayout
                        key={`formlayout${col.id}`}
                        formLoader={col.formLoader}
                        forms={col.forms}
                        currentStep={
                          col.currentStep ? col.currentStep : 'false'
                        }
                        initialValuesOfForm={
                          col.initialValuesOfForm
                            ? col.initialValuesOfForm
                            : false
                        }
                        submitFunction={e => col.submitFunction(e)}
                        context={col.context ? col.context : {}}
                        buttonFunctionArray={
                          col.buttonFunctionArray && col.buttonFunctionArray
                        }
                        errorlookup={col.errorlookup ? col.errorlookup : ''}
                        authFunc={col.authFunc && col.authFunc}
                      />
                    )}
                    {col.container && group(col.container)}
                  </Col>
                ))}
            </Row>
          ))}
        {cont.curve && cont.curve}
      </Container>
    ))}
  </>
);

const Grid = ({ container, hasbanner, hasvideobanner, bannerproperties }) => (
  <PageHeaderWrapper
    hasbanner={hasbanner}
    hasvideobanner={hasvideobanner}
    wrapper={children =>
      hasbanner ? (
        <StyledHeader {...bannerproperties}>{children}</StyledHeader>
      ) : (
        <>
          {hasvideobanner && (
            <VideoBanner {...bannerproperties}>{children}</VideoBanner>
          )}
        </>
      )
    }
  >
    {container ? group(container) : ''}
  </PageHeaderWrapper>
);
export default Grid;
