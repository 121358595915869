/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { ListGroup } from 'react-bootstrap';
import { ListHeader, ListHeaderLink, ListHeaderTitle } from './ListHeader';
import ListRow from './ListRow';

import { Container } from 'react-bootstrap';

import mediasizes from '../../utils/sizes';
/*
 */

const ChildrenWithProps = props => {
  const elements = React.Children.toArray(props.children).map(element => {
    return React.cloneElement(element, {
      twoCol: props.twoCol,
      condensed: props.condensed,
      className: props.className,
      fluid: props.fluid,
    });
  });
  return <>{elements}</>;
};

const ListChildrenWithProps = props => {
  const elements = React.Children.toArray(props.children).map(
    (element, key) => {
      return React.cloneElement(element, {
        key,
        twoCol: props.twoCol,
        condensed: props.condensed,
        isEven: props.children && props.children.length % 2 === 0,
        //className: props.className,
      });
    },
  );
  return <>{elements}</>;
};

const StyledListGroup = styled(({ twoCol, ...rest }) => (
  <ListGroup {...rest} />
))`
  ${({ theme }) => css`
    border-radius: 0 0 ${theme.shapes.borderRadiusMedium}
      ${theme.shapes.borderRadiusMedium}; // Apply radius at bottom, remove radius at the top
    padding: ${theme.spacing.none};
  `}
  ${({ twoCol, theme }) =>
    twoCol &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: ${theme.spacing.large};

      ${mediasizes.small`
        grid-template-columns: 1fr;
      `}
    `}
`;

const StyledListGroupContainer = styled(Container)`
  ${({ theme }) => css`
    padding: ${theme.spacing.none};
  `}
`;

const WillowListGroup = props => (
  <StyledListGroupContainer className={props.className} fluid={props.fluid}>
    <ChildrenWithProps {...props} />
  </StyledListGroupContainer>
);

// Main
const ListMain = props => {
  return (
    <StyledListGroup twoCol={props.twoCol} className={props.className}>
      <ListChildrenWithProps {...props} />
    </StyledListGroup>
  );
};

// From LinkHeader.js
WillowListGroup.Header = ListHeader;
WillowListGroup.Header.Title = ListHeaderTitle;
WillowListGroup.Header.Link = ListHeaderLink;

WillowListGroup.Main = ListMain;
WillowListGroup.Row = ListRow;

export default WillowListGroup;
