/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Form, InputGroup, Container } from 'react-bootstrap';
import Icon from '../../Icon';
import InputAppendIcon from './InputAppendIcon';
import InputPrependIcon from './InputPrependIcon';
import InputHelperText from './InputHelperText';

const StyledInputGroup = styled(InputGroup)`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    border-width: 1px;
    border-style: solid;
    min-height: 59px;
    @media screen and (max-device-width: 823px) and (orientation: landscape) {
      min-height: 40px;
    }
    align-items: center;
    border-radius: ${theme.shapes.borderRadiusSmall};
    background-color: ${theme.colors.greys.light};
    border-color: ${theme.colors.greys.medium};
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px ${theme.colors.greys.light} inset !important;
    }
    .input-group-text {
      background-color: #fff !important;
      padding: 0.375rem 0.75rem !important;
      border: 0 !important;
    }
  `}
  
  ${({ autofillcolor }) =>
    autofillcolor &&
    css`
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px ${autofillcolor} inset !important;
      }
    `}

  ${({ status, theme }) =>
    status === 'success' &&
    css`
      border-color: ${theme.colors.system.success};
    `}
  ${({ status, theme }) =>
    status === 'warning' &&
    css`
      border-color: ${theme.colors.system.warning};
    `}
  ${({ status, theme }) =>
    status === 'error' &&
    css`
      border-color: ${theme.colors.system.danger};
    `}
  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-color: ${theme.colors.greys.medium};
    `}
`;

const StyledContainer = styled(Container)`
  flex: 1;
  position: relative;
  padding: 0;
  margin: 0;
  max-width: none;
`;

const StyledFormGroup = styled(
  ({
    childRef,
    hasLabel,
    hasValue,
    helper,
    helperMessage,
    isPin,
    className,
    ...rest
  }) => <Form.Group {...rest} className={className} />,
)`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.spacing.none};
  `}
   
  ${({ hasLabel, theme }) =>
    hasLabel &&
    css`
      &:focus-within {
        ${StyledInputGroup} {
          border-color: ${theme.colors.brand.light};
        }
        ${StyledFormControl} {
          transform: translateY(12px);
        }
        ${StyledFormLabel} {
          margin-top: -10px;
          font-size: 12px;
        }
      }
    `}
  ${({ hasLabel }) =>
    (hasLabel === 'Date of birth' || hasLabel === "Spouse's Date of Birth") &&
    css`
      ${StyledFormControl} {
        transform: translateY(12px);
        line-height: 120% !important;
        padding: 0px 30px 0px 16px !important;
      }
      ${StyledFormLabel} {
        margin-top: -10px;
        font-size: 12px;
      }
    `}
  ${({ hasLabel, hasValue, theme }) =>
    hasLabel &&
    hasValue &&
    css`
      ${StyledFormControl} {
        transform: translateY(12px);
      }
      ${StyledFormLabel} {
        margin-top: -10px;
        font-size: 12px;
      }
    `}
  ${({ isPin, hasValue, theme }) =>
    isPin &&
    hasValue &&
    css`
      ${StyledFormControl} {
        transition: none;
        font-size: 1.5rem;
      }
    `}
    
`;

const StyledFormControl = styled(Form.Control)`
  ${({ theme }) => css`
    width: 100%;
    text-align: left;
    border: none;
    padding: ${theme.spacing.none} ${theme.spacing.xsmall};

    font-family: ${theme.typography.primary};
    font-weight: ${theme.typography.weight.book};
    font-size: ${theme.typography.size.small};
    letter-spacing: ${theme.typography.letterSpacing.small};
    line-height: 0 !important;

    background-color: ${theme.colors.greys.light};
    color: ${theme.colors.greys.xdark} !important;

    box-shadow: none !important;
    transition: all ${theme.durations.fast} ease-in;

    &:active,
    &:disabled,
    &:focus {
      background-color: ${theme.colors.greys.light};
      border-color: ${theme.colors.brand.dark};
    }
  `}
  ${({ label, theme }) =>
    label &&
    css`
      padding: ${theme.spacing.large} ${theme.spacing.xsmall}
        ${theme.spacing.medium};
    `}
  ${({ icon, theme }) =>
    icon &&
    css`
      padding-left: ${theme.spacing.large};
    `}
`;

const StyledFormLabel = styled(Form.Label)`
  ${({ theme }) => css`
    pointer-events: none;

    display: flex;
    align-items: center;
    position: absolute;

    z-index: 1;
    top: 0;
    left: ${theme.spacing.xsmall};
    height: 100%;

    font-size: ${theme.typography.size.small};
    font-weight: ${theme.typography.weight.normal};
    line-height: 120% !important;

    color: ${theme.colors.greys.dark};

    transition: all ${theme.durations.fast} ease-in;
  `}
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.greys.medium};
    `}
`;

const StyledStatusIcon = styled(Icon)`
  ${({ theme }) => css`
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    top: 0;
    right: ${theme.spacing.xsmall};
    font-size: ${theme.typography.size.small};
    transition: all ${theme.durations.fast} ease-in;
  `}
`;
const StyledStickyPlaceHolder = styled.div`
  ${({ theme }) => css`
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    top: 12px;
    left: 8px;
    font-size: ${theme.typography.size.small};
    transition: all ${theme.durations.fast} ease-in;
  `}
`;
const InputStickyPlaceHolder = ({ stickyph }) =>
  stickyph ? (
    <StyledStickyPlaceHolder size="xlarge">{stickyph}</StyledStickyPlaceHolder>
  ) : (
    <></>
  );
const InputStatusIcon = ({ status }) =>
  status ? <StyledStatusIcon variant={status} size="xlarge" /> : <></>;

const WillowInput = props => {
  const [hasValue, setHasValue] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');
  const {
    active,
    fieldid,
    childRef,
    label,
    value,
    disabled,
    controlId,
    icon,
    appendIcon,
    status,
    helperMessage,
    onChange,
    onClick,
    isPin,
    className,
    autofillcolor,
    prefix,
    postalCode,
    percentage,
    ...rest
  } = props;

  const defaultOnChange = e => setDefaultValue(e.target.value);
  useEffect(() => {
    setHasValue(!!(value || defaultValue));
  }, [value, defaultValue]);
  return (
    <StyledFormGroup
      isPin={isPin}
      hasLabel={label}
      hasValue={hasValue}
      type={props.type}
      controlId={fieldid || controlId}
    >
      <StyledInputGroup
        status={status}
        disabled={disabled}
        active={active}
        className={className}
        autofillcolor={autofillcolor}
      >
        <InputPrependIcon icon={icon} disabled={disabled} />
        <StyledContainer className={className}>
          <StyledFormControl
            ref={childRef}
            value={postalCode || percentage || value || defaultValue}
            onChange={onChange || defaultOnChange}
            onClick={onClick}
            disabled={disabled}
            {...rest}
            className={
              props.type === 'file'
                ? `custom-file-input ${className}`
                : `${className}`
            }
          />
          <InputStickyPlaceHolder stickyph={props.stickyph} />
          <StyledFormLabel
            // htmlFor={fieldId}
            className={props.type === 'file' && 'custom-file-label'}
            disabled={disabled}
          >
            {label}
          </StyledFormLabel>
          <InputStatusIcon status={status} />
        </StyledContainer>
        <InputAppendIcon icon={appendIcon} disabled={disabled} />
        {prefix && <StyledInputGroup.Text>{prefix}</StyledInputGroup.Text>}
      </StyledInputGroup>
      {helperMessage && (
        <InputHelperText
          className="small mb-0"
          status={status}
          disabled={disabled}
        >
          {helperMessage}
        </InputHelperText>
      )}
      {!helperMessage && (
        <InputHelperText
          className="small mb-0"
          status={status}
          disabled={false}
        >
          {/* &nbsp; */}
        </InputHelperText>
      )}
    </StyledFormGroup>
  );
};
export default WillowInput;
